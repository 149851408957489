export const DEVICES_TYPES = {
  IOS: 'iOS',
  ANDROID: 'Android',
  OTHERS: 'Others',
};

export const APP_ID_IN_APP_STORE = 1627517694;
export const APP_LINK_IN_APP_STORE = `https://itunes.apple.com/fr/app/id${APP_ID_IN_APP_STORE}?mt=8`;

export const APP_LINK_IN_PLAY_STORE = 'https://play.google.com/store/apps/details?id=fr.indy.compta';
