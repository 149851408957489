import georgesApi from '@/shared/apis/georges.js';

async function upsertBankAuth({ bankAuthConnectorId, connectorType }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/bank-connector/bank-auths`,
    data: {
      bankAuthConnectorId,
      connectorType,
    },
  });

  return data;
}

async function fetchBankAuths({ withBankAccounts } = {}) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/bank-connector/bank-auths`,
    params: { withBankAccounts },
  });

  return data.bankAuths;
}

async function deleteBankAuth({ bankAuthId }) {
  const { data } = await georgesApi({
    method: 'DELETE',
    url: `/bank-connector/bank-auths/${bankAuthId}`,
  });

  return data;
}

async function fetchAndUpdateBankAuthStatus({ bankAuthIds }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/bank-connector/bank-auths/status`,
    data: {
      bankAuthIds,
    },
  });

  return data.bankAuths;
}

export { deleteBankAuth, fetchAndUpdateBankAuthStatus, fetchBankAuths, upsertBankAuth };
