import get from 'lodash/get';
import moment from 'moment';

import { getDas2DeclarationActivity } from '@/modules/closing/das2Activity/das2Activity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

export const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    beneficiary: {
      title: 'Lister les honoraires versés à des tiers',
      shortTitle: 'Honoraires versés',
    },
    das2ValidateClosing: {
      title: 'Valider la clôture',
      shortTitle: 'Valider la clôture',
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        fiscalPeriodId: String(get(closingState, 'year', '')),
        minDateToClose: moment(activity.date?.minDate),
      },
      user,
      activity,
    });
  },

  // -- Refresh
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getDas2DeclarationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
