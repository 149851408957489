<template>
  <div>
    <AppNavigation />
    <SmartAppBanner />
    <BankResyncCtaBanner @bankResyncBannerDisplayed="bankResyncBannerIsShown = true" />
    <InvalidMaltTokenBanner v-if="!bankResyncBannerIsShown" @displayed="invalidMaltTokenBannerIsShown = $event" />
    <template v-if="!bankResyncBannerIsShown && !invalidMaltTokenBannerIsShown">
      <ReferralBoostedBanner />
      <ReferralFreemiumBanner />
    </template>
    <TopNavbar v-if="shouldShowTopNavbar" />
    <GPortalTarget name="panels" />
    <GPortalTarget name="panelFullscreens" />

    <GMain>
      <router-view v-slot="{ Component }">
        <transition name="slide-y-transition" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </GMain>

    <AppMobileNavigation />

    <div class="portals">
      <Categories />
    </div>
  </div>
</template>

<script>
// Mixins
import BankResyncCtaBanner from '@/modules/bankConnector/synchro/banners/BankResyncCtaBanner.vue';
import Categories from '@/modules/categories/Categories.vue';
import subscriptionModalsMixin from '@/modules/core/subscriptionModals/subscriptionModalsMixin';
import InvalidMaltTokenBanner from '@/modules/facturation/components/shared/invalidMaltTokenBanner/InvalidMaltTokenBanner.vue';
import ReferralBoostedBanner from '@/modules/referral/shared/ReferralBoostedBanner/ReferralBoostedBanner.vue';
import ReferralFreemiumBanner from '@/modules/referral/shared/ReferralFreemiumBanner/ReferralFreemiumBanner.vue';
// Components
import SmartAppBanner from '@/shared/components/SmartAppBanner/SmartAppBanner.vue';
import TopNavbar from '@/shared/components/TopNavbar/TopNavbar.vue';
import AppNavigation from '@/shared/globalComponents/app/AppNavigation.vue';
// Utils
import { isInMobileWebView } from '@/shared/utils/device';

export default {
  name: 'MainLayout',
  mixins: [subscriptionModalsMixin],
  components: {
    AppNavigation,
    SmartAppBanner,
    ReferralBoostedBanner,
    ReferralFreemiumBanner,
    InvalidMaltTokenBanner,
    Categories,
    TopNavbar,
    BankResyncCtaBanner,
  },
  data: () => ({
    bankResyncBannerIsShown: false,
    invalidMaltTokenBannerIsShown: false,
  }),
  computed: {
    shouldShowTopNavbar() {
      return !isInMobileWebView();
    },
  },
};
</script>
