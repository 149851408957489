import georgesApi from '@/shared/apis/georges.js';
import { trackEventIntercom } from '@/shared/services/intercom/intercom.services';
import { $gnotify } from '@/shared/utils/notify';

// -- Referral
export async function fetchReferralPromocode() {
  try {
    const {
      data: { promocode, campaign },
    } = await georgesApi({
      method: 'POST',
      url: '/users/me/referral/promocode',
    });
    return { promocode, campaign };
  } catch (err) {
    return { promocode: undefined, campaign: undefined };
  }
}

export async function fetchReferralInvitations() {
  const { data: prospects } = await georgesApi({
    method: 'GET',
    url: '/users/me/referral/invitations',
  });
  return prospects;
}

export async function sendReferralEmails({ emails }) {
  try {
    await georgesApi({
      method: 'POST',
      url: '/users/me/referral/emails',
      data: {
        emails,
      },
    });
    trackEventIntercom('Referral email sent');
  } catch (e) {
    $gnotify.error(`Impossible d'envoyer vos invitations. Veuillez vérifier les adresses emails ou essayer plus tard`);
    throw e;
  }
}

// -- Ambassador
export async function getAmbassadorEligibility() {
  const {
    data: { eligibilityStatus, prospectCounts, obfuscatedIban },
  } = await georgesApi({
    url: 'referral/ambassador/eligibility',
  });

  return { eligibilityStatus, prospectCounts, obfuscatedIban };
}

export async function saveAmbassadorIban({ iban, bic }) {
  const {
    data: { obfuscatedIban },
  } = await georgesApi({
    url: 'referral/ambassador/iban',
    method: 'POST',
    data: { iban, bic },
  });

  return { obfuscatedIban };
}

export async function joinAmbassadorProgram() {
  await georgesApi({
    url: 'referral/ambassador',
    method: 'POST',
  });
}

export async function getAmbassadorPayments() {
  const {
    data: { ambassadorPayments },
  } = await georgesApi({
    url: 'referral/ambassador/payment-history',
    method: 'GET',
  });

  return { ambassadorPayments };
}
