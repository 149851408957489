import georgesApi from '@/shared/apis/georges';

export async function getAccountingInitialisationActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/activities/sci/accounting-initialisation',
  });
  return data;
}

export async function validateAccountingInitialisationActivity() {
  await georgesApi({
    method: 'POST',
    url: '/activities/sci/accounting-initialisation/validation',
  });
}
