<template>
  <GCard class="d-flex flex-row flex-fill" scrollable>
    <GSheet class="d-none d-md-flex align-center justify-center" color="primary-light" width="40%" min-width="40%" tile>
      <img src="/img/illustrations/sca/sca-authentication-required.svg" alt="time" class="p-relative h-auto w-full" />
    </GSheet>

    <GCardText class="d-flex flex-column flex-fill justify-center pa-12 pa-md-16">
      <div class="space-y-16">
        <h2 class="font-weight-bold">Votre banque requiert une authentification</h2>
        <p>Vous n'avez plus accès à ces fonctionnalités :</p>
        <FeaturesByLegalForm :features-list="featuresList" />
        <p>
          Pour qu'Indy puisse de nouveau automatiser votre comptabilité, vous devez vous authentifier auprès de votre
          banque.
        </p>

        <div class="d-flex flex-column">
          <GButton :to="{ name: 'userSettingsStripe' }" color="primary" variant="tonal" size="large">
            M'authentifier
          </GButton>
        </div>

        <div class="border-t border-dashed m-t-16">
          <p class="m-t-16 text-bodyS text-medium">
            Votre historique est conservé temporairement : si vous mettez votre CB à jour, vos données seront de nouveau
            disponibles, et les opérations effectuées intactes. Attention : sans action de votre part, vos données
            seront définitivement supprimées au bout d'un an.
          </p>
        </div>
      </div>
    </GCardText>
  </GCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import FeaturesByLegalForm from '@/modules/core/subscriptionModals/FeaturesByLegalForm.vue';

export default {
  name: 'SubscriptionIncompleteModal',
  components: {
    FeaturesByLegalForm,
  },
  computed: {
    ...mapGetters({
      featuresList: 'settings/featuresSubscriptionModalsList',
    }),
  },

  created() {
    this.getSubscriptionData();
  },

  methods: {
    ...mapActions({
      getSubscriptionData: 'settings/getSubscriptionData',
    }),
  },
};
</script>
