import mapValues from 'lodash/mapValues';

export const getDecoratedClosingSteps = ({
  steps = {},
  isFirstFiscalYearOfActivity = false,
  showAdminInfo = false,
  isInitializedFromDocuments = false,
  isActivityDone = false,
}) => {
  // isFirstFiscalYearOfActivity will be wrong if a fiscal declaration was done
  // because current fiscal year is not the first one anymore.
  // so we don't mess around with steps visibility if the activity is already done.
  // To fix this behavior, isFirstFiscalYearOfActivity should not be based on the current fiscal year.
  if (isFirstFiscalYearOfActivity || isActivityDone) {
    return steps;
  }

  const {
    uploadLastFiscalYearDocuments,
    sciInformation,
    initRealEstateAssetsWithAmortization,
    associatesInformationWithCca,
    ...allRemainingSteps
  } = steps;

  return {
    uploadLastFiscalYearDocuments,
    sciInformation,
    initRealEstateAssetsWithAmortization,
    associatesInformationWithCca,
    ...mapValues(allRemainingSteps, (stepProps) => ({
      ...stepProps,
      isVisible:
        stepProps.isVisible !== undefined
          ? stepProps.isVisible && (showAdminInfo || isInitializedFromDocuments)
          : showAdminInfo || isInitializedFromDocuments,
    })),
  };
};
