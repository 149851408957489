import { fetchReferralPromocode, getAmbassadorEligibility, saveAmbassadorIban } from './api/referral.api';
import {
  AMBASSADOR_ELIGIBILITY_STATUS,
  AMBASSADOR_PROGRAM_ENABLED,
  REFERRAL_CAMPAIGN_TYPES,
} from './constants/referral.constants';
import { formatIban, getCurrentReferralCampaignType } from './models/referral.models';

export const stateData = {
  isReferralLoading: false,
  promocode: undefined,
  campaign: undefined,
  // ------------------------ Ambassador
  ambassadorState: 'loading',
  ambassadorEligibilityStatus: AMBASSADOR_ELIGIBILITY_STATUS.notEligible,
  ambassadorProspectCounts: 0,
  ambassadorObfuscatedIban: undefined,
};

export const mutations = {
  setIsReferralLoading: (state, { isReferralLoading }) => {
    state.isReferralLoading = isReferralLoading;
  },
  setPromocode: (state, promocode) => {
    state.promocode = promocode;
  },
  setCampaign: (state, campaign) => {
    state.campaign = campaign;
  },

  // ------------------------ Ambassador
  setAmbassadorState(state, value) {
    state.ambassadorState = value;
  },
  setAmbassadorEligibilityStatus: (state, { eligibilityStatus }) => {
    state.ambassadorEligibilityStatus = eligibilityStatus;
  },
  setAmbassadorProspectCounts: (state, { prospectCounts }) => {
    state.ambassadorProspectCounts = prospectCounts;
  },
  setAmbassadorObfuscatedIban: (state, { obfuscatedIban }) => {
    state.ambassadorObfuscatedIban = obfuscatedIban;
  },

  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const getters = {
  canAccessReferralPage: ({ campaign }, getters, _rootState, { 'user/hasActiveSubscription': hasActiveSubscription }) =>
    campaign?.slug?.startsWith('FREEMIUM_REFERRAL_CAMPAIGN') || (hasActiveSubscription && Boolean(getters.promocode)),
  isReferralLoading: ({ isReferralLoading }) => isReferralLoading,
  promocode: ({ promocode }) => promocode,
  campaign: ({ campaign }) => campaign,
  currentReferralCampaignType: ({ campaign }) => getCurrentReferralCampaignType({ currentCampaignTag: campaign?.tag }),
  isTemporaryCampaignActive: (_state, { currentReferralCampaignType }) =>
    currentReferralCampaignType === REFERRAL_CAMPAIGN_TYPES.temporary,

  // ------------------------ Ambassador
  getAmbassadorProspectCounts: ({ ambassadorProspectCounts }) => ambassadorProspectCounts,
  getAmbassadorObfuscatedIban: ({ ambassadorObfuscatedIban }) => ambassadorObfuscatedIban,

  ambassadorProgramEnabled: () => AMBASSADOR_PROGRAM_ENABLED,
  isAmbassadorStateReady: ({ ambassadorState }) => ambassadorState === 'ready',
  isAmbassadorStateError: ({ ambassadorState }) => ambassadorState === 'error',
  isEligibleToAmbassador: ({ ambassadorEligibilityStatus }) =>
    AMBASSADOR_PROGRAM_ENABLED && ambassadorEligibilityStatus === AMBASSADOR_ELIGIBILITY_STATUS.eligible,
  isAlreadyAmbassador: ({ ambassadorEligibilityStatus }) =>
    AMBASSADOR_PROGRAM_ENABLED && ambassadorEligibilityStatus === AMBASSADOR_ELIGIBILITY_STATUS.alreadyAmbassador,
};

export const actions = {
  getPromocode: async ({ commit }) => {
    commit('setIsReferralLoading', { isReferralLoading: true });
    const { promocode, campaign } = await fetchReferralPromocode();
    commit('setPromocode', promocode);
    commit('setCampaign', campaign);
    commit('setIsReferralLoading', { isReferralLoading: false });
  },

  // ------------------------ Ambassador
  fetchAmbassadorEligibility: async ({ commit }) => {
    if (!AMBASSADOR_PROGRAM_ENABLED) return;

    try {
      commit('setAmbassadorState', 'loading');
      const { prospectCounts, eligibilityStatus, obfuscatedIban } = await getAmbassadorEligibility();
      commit('setAmbassadorEligibilityStatus', { eligibilityStatus });
      commit('setAmbassadorProspectCounts', { prospectCounts });
      commit('setAmbassadorObfuscatedIban', { obfuscatedIban });
      commit('setAmbassadorState', 'ready');
    } catch (e) {
      commit('setAmbassadorState', 'error');
    }
  },

  saveAmbassadorIban: async ({ commit }, { iban, bic }) => {
    const { obfuscatedIban } = await saveAmbassadorIban({ ...formatIban({ iban }), bic });

    commit('setAmbassadorObfuscatedIban', { obfuscatedIban });
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  actions,
  getters,
};
