import { useStore } from 'vuex';

export default [
  {
    path: '/parrainage',
    name: 'referral',
    component: () => import('@/modules/referral/Referral.vue'),
    beforeEnter: (to, _from, next) => {
      const store = useStore();
      if (store.getters['referral/canAccessReferralPage']) {
        return next();
      }
      return next({ name: 'home' });
    },
    meta: {
      title: 'Parrainage',
      requiresAuth: true,
      updateIntercom: true,
      parent: {
        title: 'Paramètres',
        link: 'userSettingsNav',
      },
    },
  },
];
