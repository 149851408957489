<template>
  <GTable variant="outlined" with-bg-header text-align="mixed">
    <slot />
  </GTable>
</template>

<script>
/*
  This table is our default table inside our app with background color on thead
  Use this table inside cards, steps, dialogs, ...
*/
export default {
  name: 'AppTable',
};
</script>
