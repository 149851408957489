<template>
  <GBannerHeader
    v-if="isVisible"
    color="standard"
    variant="tonal"
    :title="title"
    @click:close="hideBanner"
    @click="goToReferralPage"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="bannerText" />
  </GBannerHeader>
</template>

<script setup>
// Services
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { REFERRAL_CAMPAIGN_TYPES } from '@/modules/referral/constants/referral.constants';
import { trackReferralEvent } from '@/modules/referral/referral.composable';

import { shouldBannerBeDisplayed } from './referralFreemiumBanner.model';

const router = useRouter();
const store = useStore();
const isDismissed = ref(false);

onBeforeMount(() => {
  const bannerHiddenSince = localStorage.getItem('bannerHiddenSince');
  if (bannerHiddenSince) {
    if (shouldBannerBeDisplayed({ bannerHiddenSince })) {
      localStorage.removeItem('bannerHiddenSince');
    } else {
      isDismissed.value = true;
    }
  }
});

const currentReferralCampaignType = computed(() => store.getters['referral/currentReferralCampaignType']);

const isUserEligibleForThisBanner = computed(() => {
  const freemiumCampaignTypes = [
    REFERRAL_CAMPAIGN_TYPES.freemium1,
    REFERRAL_CAMPAIGN_TYPES.freemium2,
    REFERRAL_CAMPAIGN_TYPES.freemium3,
  ];
  return freemiumCampaignTypes.includes(currentReferralCampaignType.value);
});

const isVisible = computed(() => {
  return isUserEligibleForThisBanner.value && !isDismissed.value;
});

const title = computed(() => {
  if (currentReferralCampaignType.value === REFERRAL_CAMPAIGN_TYPES.freemium2) {
    return 'Parrainez un proche et tentez de remporter le gros lot';
  }
  if (currentReferralCampaignType.value === REFERRAL_CAMPAIGN_TYPES.freemium3) {
    return 'Parrainez et soutenez une asso';
  }
  return 'Vous êtes nos meilleurs ambassadeurs';
});

const bannerText = computed(() => {
  if (currentReferralCampaignType.value === REFERRAL_CAMPAIGN_TYPES.freemium2) {
    return '<u>Participez à notre loterie</u> et gagnez jusqu’à 500 euros.';
  }
  if (currentReferralCampaignType.value === REFERRAL_CAMPAIGN_TYPES.freemium3) {
    return '5 euros sont reversés à chaque <u>parrainage</u>.';
  }
  return 'Aidez-nous à nous faire connaître en <u>parrainant vos proches</u>.';
});

const hideBanner = () => {
  isDismissed.value = true;
  trackReferralEvent({
    action: 'Click to close the referral freemium banner',
    currentReferralCampaignType: currentReferralCampaignType.value,
  });
  localStorage.setItem('bannerHiddenSince', new Date());
};

const goToReferralPage = () => {
  router.push({ name: 'referral' });
  trackReferralEvent({
    action: 'Click to go to referral page (from freemium referral banner)',
    currentReferralCampaignType: currentReferralCampaignType.value,
  });
};
</script>
