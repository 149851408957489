<template>
  <GBottomNavigationItem
    :icon="`fa ${icon}`"
    :badge="badge && badge.visible && badge.value ? badge.value : undefined"
    :badge-dot="badge && badge.visible && badge.dot"
    :active="isActive"
    :disabled="disabled"
    v-bind="linkProps"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppMobileNavigationLink',
  inheritsAttrs: false,
  props: {
    disabled: Boolean,
    href: String,
    icon: { type: String, required: true },
    isActive: Boolean,
    to: String,
    badge: Object,
    onDisplay: Function,
  },
  computed: {
    ...mapGetters({
      userId: 'user/userId',
    }),
    linkProps() {
      return {
        ...(this.to && { to: { path: this.to } }),
        ...(this.href && { href: this.href, target: '_blank' }),
      };
    },
  },

  mounted() {
    if (this.onDisplay && this.isMobile) {
      this.onDisplay({ userId: this.userId });
    }
  },
  methods: {
    isMobile() {
      return this.$georgesuikit.breakpoint.isMobile;
    },
  },
};
</script>
