<template>
  <AppTable :height="height" fixed-header variant="outlined">
    <slot />
  </AppTable>
</template>

<script>
/*
  This table is equivalent to AppTable but with fixed header available
*/
export default {
  name: 'AppTableFixedHeader',
  props: {
    height: { type: [String, Number], default: 300 },
  },
};
</script>
