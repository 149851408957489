import sumBy from 'lodash/sumBy';

import { VAT_REGULARISATION_STEP_STATUS } from '@/modules/closing/shared/sharedRegularisationTva/regularisation/constants';
import {
  VAT_SYNC_ERROR_CODES,
  VAT_SYNC_STATE,
} from '@/modules/closing/shared/sharedRegularisationTva/VATSync/constants';

export const state = {
  isLoading: false,
  syncStatus: VAT_SYNC_STATE.NOT_STARTED,
  source: undefined,
  edition: {
    canToggleEdition: false,
    disabled: true,
  },
  error: undefined,
  postSyncResult: undefined,
  isInVatIncluded: undefined,
  tvaDeclarationsGroupedByActivity: undefined,
  userConfirmedRegularisations: false,
  canForceCloseVatRegularisationStep: false,
  userHasSetASiret: undefined,
};

const MAX_REGULARISATION_AMOUNT_IN_CENTS = 400000; // 4 000€

export const getters = {
  isLoading(state) {
    return state.isLoading === true;
  },
  isInVatIncluded(state) {
    return state.isInVatIncluded === true;
  },
  regularisations(state) {
    return state?.postSyncResult?.regularisations;
  },
  hideAlertAboutLinesToCheck(state) {
    return state?.postSyncResult?.hideAlertAboutLinesToCheck;
  },
  sumOfRegularisationsInCents(state, getters) {
    return sumBy(Object.values(getters['regularisations'] || {}), 'total');
  },
  hasRegularisations(state, getters) {
    return getters['sumOfRegularisationsInCents'] !== 0;
  },
  regularisationsTooExpensive(state, getters) {
    if (!getters['regularisations']) return false;
    return Math.abs(getters['sumOfRegularisationsInCents']) > MAX_REGULARISATION_AMOUNT_IN_CENTS;
  },
  tvaDeclarations(state) {
    return state?.postSyncResult?.declarations;
  },
  filteredTvaDeclarations(state, getters) {
    const declarations = getters['tvaDeclarations'];
    if (!declarations) return;
    return declarations.filter((declaration) => !declaration.isIgnored);
  },
  tvaDeclarationsGroupedByActivity(state) {
    return state?.tvaDeclarationsGroupedByActivity;
  },
  declarationActivitiesAvailable(state, getters) {
    // Some synced declarations may not have linked activity (for retrocompatibility)
    const declarations = getters['tvaDeclarations'];
    return declarations && declarations.every((declaration) => declaration.activity);
  },
  subActivitiesCount(state, getters) {
    if (!getters['declarationActivitiesAvailable']) return 0;
    return getters['tvaDeclarationsGroupedByActivity'].length;
  },
  syncSource(state) {
    return state.source;
  },
  parseDate(state) {
    return state.postSyncResult?.parseDate;
  },
  possibleFranchisePeriod(state) {
    return state.postSyncResult?.postSyncStatus?.possibleFranchisePeriod;
  },
  fiscalPeriodStartDate(state) {
    return state.postSyncResult?.postSyncStatus?.fiscalPeriodStartDate;
  },
  firstSyncedDeclarationStartDate(state) {
    return state.postSyncResult?.postSyncStatus?.firstSyncedDeclarationStartDate;
  },
  isSyncStateSynced(state) {
    return state.syncStatus === VAT_SYNC_STATE.SYNCED;
  },
  isSyncStateNotStarted(state) {
    return state.syncStatus === VAT_SYNC_STATE.NOT_STARTED;
  },
  isSyncStateProcessing(state) {
    return state.syncStatus === VAT_SYNC_STATE.PROCESSING;
  },
  isSyncSkipAvailable(state) {
    return state.syncStatus === VAT_SYNC_STATE.SYNC_SKIP_AVAILABLE;
  },
  isSyncStateError(state) {
    return state.syncStatus === VAT_SYNC_STATE.ERROR;
  },
  hasMissingDeclarations(state) {
    return (
      state.postSyncResult?.postSyncStatus?.status === VAT_REGULARISATION_STEP_STATUS.MISSING_LAST_EXERCISE_DECLARATIONS
    );
  },
  canEdit(state) {
    return state.edition?.disabled === false;
  },
  canToggleEdition(state) {
    return state.edition?.canToggleEdition === true;
  },
  hasPossibleMissingFranchise(state) {
    return (
      state.postSyncResult?.postSyncStatus?.status ===
      VAT_REGULARISATION_STEP_STATUS.MISSING_FRANCHISE_CONFIGURATION_ON_EXERCISE_BEGINNING
    );
  },
  hasAllDeclarationsDone(state) {
    return state.postSyncResult?.postSyncStatus?.status === VAT_REGULARISATION_STEP_STATUS.ALL_DECLARATIONS_DONE;
  },
  hasPossibleBadFiscalPeriodDate(state) {
    return state.postSyncResult?.postSyncStatus?.status === VAT_REGULARISATION_STEP_STATUS.BAD_EXERCISE_FISCAL_DATE;
  },
  hasIncorrectOd(state) {
    return state.postSyncResult?.postSyncStatus?.status === VAT_REGULARISATION_STEP_STATUS.INCORRECT_OD;
  },
  hasStepShouldNotBeDisplayed(state) {
    return state.postSyncResult?.postSyncStatus?.status === VAT_REGULARISATION_STEP_STATUS.STEP_SHOULD_NOT_BE_DISPLAYED;
  },
  isUserInVATIncluded(state) {
    return state.error?.errorCode === VAT_SYNC_ERROR_CODES.USER_IN_VAT_INCLUDED;
  },
  isWrongSiren(state) {
    return state.error?.errorCode === VAT_SYNC_ERROR_CODES.WRONG_SIREN;
  },
  isMissingFiscalAccount(state) {
    return state.error?.errorCode === VAT_SYNC_ERROR_CODES.MISSING_FISCAL_ACCOUNT_ERROR;
  },
  isWrongCredentials(state) {
    return state.error?.errorCode === VAT_SYNC_ERROR_CODES.WRONG_CREDENTIALS;
  },
  postSyncStatus(state) {
    return state.postSyncResult?.postSyncStatus?.status;
  },
  stepCanBeClosed(state, getters) {
    if (!getters.hasAllDeclarationsDone) return false;
    if (getters.hasRegularisations && !state.userConfirmedRegularisations) return false;
    return true;
  },
  canForceCloseVatRegularisationStep(state) {
    return state.canForceCloseVatRegularisationStep;
  },
  userHasSetASiret(state) {
    return state.userHasSetASiret;
  },
};

export const mutations = {
  setIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },
  setIsInVatIncluded(state, { isInVatIncluded }) {
    state.isInVatIncluded = isInVatIncluded;
  },
  setCanForceCloseVatRegularisationStep(state, { canForceCloseVatRegularisationStep }) {
    state.canForceCloseVatRegularisationStep = canForceCloseVatRegularisationStep;
  },
  setSyncStatus(state, { status, source, edition }) {
    state.syncStatus = status;
    state.source = source;
    state.edition = edition;
  },
  setError(state, { error }) {
    state.error = error;
  },
  setPostSyncResult(state, { postSyncResult }) {
    state.postSyncResult = postSyncResult;
  },
  setTvaDeclarationsGroupedByActivity(state, { tvaDeclarationsGroupedByActivity }) {
    state.tvaDeclarationsGroupedByActivity = tvaDeclarationsGroupedByActivity;
  },
  setUserConfirmedRegularisations(state, userConfirmedRegularisations) {
    state.userConfirmedRegularisations = userConfirmedRegularisations;
  },
  setUserHasSetASiret(state, { userHasSetASiret }) {
    state.userHasSetASiret = userHasSetASiret;
  },
};

export const actions = {
  reset({ commit }) {
    commit('setSyncStatus', {
      status: VAT_SYNC_STATE.NOT_STARTED,
      source: undefined,
      edition: {
        disabled: true,
        canToggleEdition: true,
      },
    });
    commit('setError', { error: {} });
    commit('setPostSyncResult', { postSyncResult: undefined });
    commit('setTvaDeclarationsGroupedByActivity', { tvaDeclarationsGroupedByActivity: undefined });
  },
  updateState({ commit }, { errorCode, state, edition, source }) {
    commit('setSyncStatus', { status: state, edition, source });
    commit('setError', { error: { errorCode } });
  },
  createSyncRequest({ commit }) {
    commit('setSyncStatus', { status: VAT_SYNC_STATE.PROCESSING, source: undefined });
    commit('setError', { error: {} });
  },
  declineSyncSkip({ commit, state }) {
    if (state.syncStatus !== VAT_SYNC_STATE.SYNC_SKIP_AVAILABLE) return;
    commit('setSyncStatus', {
      status: VAT_SYNC_STATE.NOT_STARTED,
      source: undefined,
      edition: {
        disabled: true,
        canToggleEdition: true,
      },
    });
  },
};

export default {
  namespaced: true,
  state: {
    ...state,
  },
  getters,
  mutations,
  actions,
};
