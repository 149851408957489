<template>
  <GNavigation class="sidebar" variant="outlined">
    <template #prepend>
      <AppNavigationHeader />
    </template>

    <GList class="px-16 py-24 space-y-4">
      <AppNavigationLink
        v-for="link in links"
        :key="link.name"
        :disabled="link.disabled"
        :href="link.href"
        :icon="link.icon"
        :title="link.name"
        :to="link.path"
        :is-active="isActiveRoutes(link.isActiveRouteConditions)"
        :on-display="link.onDisplay"
      >
        <template #badge v-if="link.badge && link.badge(badgesContext).visible">
          <GIcon v-if="link.badge(badgesContext).icon" :name="link.badge(badgesContext).icon" />
          <GBadge v-else :text="link.badge(badgesContext).value" inline />
        </template>
      </AppNavigationLink>

      <div v-if="isAdmin" class="d-flex w-full px-16 p-t-24">
        <GSwitch
          :model-value="showAdminInfo"
          label="Infos admin"
          class="m-t-0"
          @change="toggleShowAdminInfo"
          hide-details
        />
      </div>
    </GList>

    <template #append>
      <AppNavigationFooter />
    </template>
  </GNavigation>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { isActiveRoute } from '@/shared/utils/routing';

import AppNavigationFooter from './AppNavigationFooter.vue';
import AppNavigationHeader from './AppNavigationHeader.vue';
import AppNavigationLink from './AppNavigationLink.vue';
import { generateNavigationLinks, getNavigationBadges } from './navigation.model';

export default {
  name: 'AppNavigation',
  components: {
    AppNavigationHeader,
    AppNavigationLink,
    AppNavigationFooter,
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      hasAllRequiredPermissions: 'auth/hasAllRequiredPermissions',
      isImpersonating: 'user/isImpersonating',
      showAdminInfo: 'user/showAdminInfo',
      currentFiscalYearConfiguration: 'user/currentFiscalYearConfiguration',
      activitiesToDoCount: 'newActivities/activitiesToDoCount',
      transactionsToCategorizeCount: 'transactions/transactionsToCategorizeCount',
    }),

    isAdmin() {
      return this.hasAllRequiredPermissions(['admin']);
    },

    links() {
      return generateNavigationLinks({
        isAdmin: this.isAdmin,
        isImpersonating: this.isImpersonating,
        fiscalConfiguration: this.currentFiscalYearConfiguration,
        user: this.currentUser,
      });
    },

    badgesContext() {
      return getNavigationBadges({
        transactionsToCategorizeCount: this.transactionsToCategorizeCount,
        activitiesToDoCount: this.activitiesToDoCount,
        user: this.currentUser,
      });
    },
  },
  methods: {
    ...mapMutations({
      toggleShowAdminInfo: 'user/toggleShowAdminInfo',
    }),
    isActiveRoute,
    isActiveRoutes(conditions) {
      if (!conditions) return false;
      return conditions.some(({ path, options }) => this.isActiveRoute(path, options));
    },
  },
};
</script>
