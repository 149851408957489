import flow from 'lodash/flow';
import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import moment from 'moment';

export default function generateStepsForAnActivity({
  steps = {},
  closingStateSteps = {},
  stepProperties = {},
  user = {},
  activity = {},
  todaysDate = Date.now(),
} = {}) {
  const stepList = merge({}, steps, closingStateSteps);
  const stepListWithProps = addStepPropertiesToSteps({
    steps: stepList,
    stepProperties,
    user,
    activity,
    todaysDate,
  });

  return flow(filterAvailableSteps, filterVisibleSteps)(stepListWithProps, closingStateSteps);
}

function addStepPropertiesToSteps({ steps, stepProperties, ...rest }) {
  return Object.entries(steps).reduce((result, [key, step]) => {
    const properties = { stepKey: key, ...step, ...stepProperties };

    return {
      ...result,
      [`${key}`]: {
        ...properties,
        title: generateTitle({ ...properties, user: rest.user }),
        shortTitle: generateShortTitle({ ...properties, user: rest.user }),
        tooEarly: Boolean(isStepTooEarly({ ...properties })),
        limitedToClient: Boolean(isStepLimitedToClient({ ...properties, ...rest })),
        disabled: Boolean(isStepDisabled({ ...properties, ...rest })),
      },
    };
  }, {});
}

function filterAvailableSteps(mergedSteps, closingStateSteps) {
  return pickBy(mergedSteps, (step, key) => Object.keys(closingStateSteps).includes(key) || step.isValidatingStep);
}

function filterVisibleSteps(steps) {
  return pickBy(steps, ({ isVisible = true }) => isVisible);
}

// Exported for tests
export function generateTitle({ title, ...props }) {
  return isFunction(title) ? title(props) : title;
}

export function generateShortTitle({ shortTitle, ...props }) {
  return isFunction(shortTitle) ? shortTitle(props) : shortTitle;
}

export function isStepTooEarly({
  minDateToClose,
  isInEarlyClosing,
  availableBeforeEndYear,
  todaysDate,
  forceTooEarly, // allow to force lock the step with too early reason (useful for steps working in progress)
}) {
  if (forceTooEarly) return true;
  if (!minDateToClose) return false;

  const isDateString = moment(minDateToClose, 'YYYY-MM-DD', true).isValid();

  return !availableBeforeEndYear && !isInEarlyClosing && (!isDateString || moment(todaysDate).isBefore(minDateToClose));
}

export function isStepLimitedToClient({ availableOnlyForClients, user }) {
  if (!availableOnlyForClients) return false;
  if (user.userIsDemo) return false;
  return Boolean(!user.userIsClient || user.userIsChurned);
}

export function isStepLockedByActivity({ activity }) {
  return activity.isNextOccurrence || Boolean(activity.status?.lockedBy);
}

export function isStepLockedByPreviousSteps({ stepKey, previousStepsMustBeDone, closingState }) {
  if (previousStepsMustBeDone) {
    const stepsEntries = Object.entries(closingState.steps);
    const stepIndex = stepsEntries.findIndex(([key, _value]) => key === stepKey);
    const stepsToCheck = stepsEntries.slice(0, stepIndex);
    return stepsToCheck.some(([_key, step]) => step.status !== 'done');
  }
  return false;
}

export function isStepDisabled(args) {
  return (
    isStepTooEarly(args) ||
    isStepLimitedToClient(args) ||
    isStepLockedByActivity(args) ||
    isStepLockedByPreviousSteps(args)
  );
}
