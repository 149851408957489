<template>
  <div class="d-flex flex-grow-1">
    <GInputText
      v-if="!hasSubtitleChips"
      ref="inputText"
      v-model="internalSearchText"
      placeholder="Ex. : repas, essence, mutuelle..."
      type="search"
      name="search"
      prepend-inner-icon="fal fa-search"
      hide-details
      clearable
      density="compact"
    />

    <GCombobox
      v-else
      v-model="internalSearchText"
      placeholder="Ex. : repas, essence, mutuelle..."
      type="search"
      name="search"
      prepend-inner-icon="fal fa-search"
      :append-inner-icon="false"
      density="compact"
      readonly
      chips
      hide-details
      clearable
      persistent-clear
      @click:clear="onClearInput"
    />
  </div>
</template>

<script>
import get from 'lodash/get';

export default {
  name: 'CategoriesMobileSearch',
  emits: ['update:modelValue'],

  props: {
    isAnimating: Boolean,
    isSubpageVisible: Boolean,
    showPage: { type: Function, default: undefined },
    subpageTitle: String,
    modelValue: String,
  },

  computed: {
    internalSearchText: {
      get() {
        return this.modelValue || this.subpageTitle;
      },
      set(modelValue = '') {
        this.$emit('update:modelValue', modelValue != null ? modelValue : '');
      },
    },
    hasSubtitleChips() {
      return Boolean(this.subpageTitle);
    },
  },

  watch: {
    isAnimating: 'focusOrBlur',
  },

  mounted() {
    // Delay focus on mobile while panel is animating
    const input = get(this.$refs, 'inputText');
    if (!this.isAnimating && input) input.focus();
  },

  methods: {
    onClearInput() {
      if (this.subpageTitle) this.showPage('categoriesList');
    },
    focusOrBlur(isAnimating) {
      const inputText = get(this.$refs, 'inputText');
      if (!inputText) return;

      if (isAnimating) {
        inputText.blur();
        return;
      }
      inputText.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.g-select__slot .g-input__append-inner:last-child) {
  display: none;
}

:deep(input:placeholder-shown) {
  text-overflow: ellipsis;
}
</style>
