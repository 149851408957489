<template>
  <GButton
    v-if="variant !== 'link'"
    :variant="variant"
    :size="xLarge ? 'x-large' : undefined"
    :append-icon="appendIcon"
    @click="openCalendly"
  >
    <slot />
  </GButton>
  <GButtonLink v-else :size="xLarge ? 'x-large' : undefined" :append-icon="appendIcon" @click="openCalendly">
    <slot />
  </GButtonLink>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import { getCalendlyId, getCalendlyLink } from './calendlyButton.model';

export default {
  name: 'CalendlyButton',
  emits: ['eventScheduled', 'click'],

  props: {
    xLarge: { type: Boolean, default: false },
    calendlyId: { type: String, default: undefined },
    variant: { type: String, default: 'link' },
    appendIcon: { type: String, default: undefined },
    context: { type: String, default: undefined },
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
    }),
    calendlyLink() {
      const calendlyId = this.calendlyId || getCalendlyId({ user: this.currentUser, context: this.context });
      return getCalendlyLink({
        calendlyId,
      });
    },
  },

  mounted() {
    // L'event ne peut pas être déclenché dans la plupart des calendlies car ils redirigent vers un autre onglet
    window.addEventListener('message', this.onCalendlyEventScheduled);
  },

  unmounted() {
    window.removeEventListener('message', this.onCalendlyEventScheduled);
  },

  methods: {
    onCalendlyEventScheduled(e) {
      if (get(e, 'data.event') === 'calendly.event_scheduled') {
        this.$emit('eventScheduled');
      }
    },
    openCalendly() {
      this.$emit('click');
      if (!window.Calendly) return;
      window.Calendly.initPopupWidget({
        url: this.calendlyLink,
      });
    },
  },
};
</script>
