import { analyticsSignOutUser } from '../utils/analytics';

export default async function ({ to, next }, { store }) {
  if (hasToSignOut(to, store)) {
    await store.dispatch('user/signOut');
    analyticsSignOutUser({});
  }
  return getNext(to, next);
}

const isSignOutForced = (to) => to.query?.signout?.toLowerCase() === 'true';

const hasToSignOut = (to, store) => {
  const isUserAuthenticated = store.getters['user/isAuthenticated'];
  return isUserAuthenticated && isSignOutForced(to);
};

const getNext = (to, next) => {
  if (isSignOutForced(to)) {
    const query = { ...to.query };
    delete query.signout;
    const nextTo = {
      ...to,
      query,
    };

    return next(nextTo);
  }

  return next();
};
