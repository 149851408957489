import { useStore } from 'vuex';

import { canAccessOnboardingComptePro } from '@/modules/comptePro/comptePro.models';
import { $gnotify } from '@/shared/utils/notify';

export function onboardingCompteProGuard({ next }) {
  const store = useStore();

  const isInScopeComptePro = store.getters['user/isInScopeComptePro'];
  const userCompteProStatus = store.getters['comptePro/userCompteProStatus'];

  if (
    !canAccessOnboardingComptePro({
      userCompteProStatus,
      isInScopeComptePro,
    })
  ) {
    $gnotify.error("Impossible d'accéder à cette page");

    return next({ name: 'transactions', replace: true });
  }

  return next();
}
