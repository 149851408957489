import { groupBy } from 'lodash/collection';
import { capitalize } from 'lodash/string';
import moment from 'moment';

export function transformPayslipDeclarations(payslipDeclarations) {
  return payslipDeclarations
    .sort((payslipA, payslipB) => {
      const startDateA = moment.utc(payslipA.periodId.startDate);
      const startDateB = moment.utc(payslipB.periodId.startDate);

      if (startDateA.isBefore(startDateB)) {
        return 1;
      } else if (startDateA.isAfter(startDateB)) {
        return -1;
      }

      return 0;
    })
    .map((payslipDeclaration) => ({
      title: capitalize(moment.utc(payslipDeclaration.periodId.endDate).format('MMMM YYYY')),
      grossSalary: payslipDeclaration.salaireBrutInCents,
      fiscalPeriodId: moment.utc(payslipDeclaration.periodId.endDate).format('YYYY-MM'),
    }));
}

export function transformPayslipDeclarationsToPeriodChoices(payslipDeclarations) {
  const formattedPayslipDeclarations = payslipDeclarations.map((payslipDeclaration) => ({
    year: moment.utc(payslipDeclaration.periodId.endDate).year(),
    fiscalPeriodId: moment.utc(payslipDeclaration.periodId.endDate).format('YYYY-MM'),
  }));
  return groupBy(formattedPayslipDeclarations, 'year');
}
