import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  MAINTENANCE_LEVELS,
  MAINTENANCE_MESSAGE,
  SCA_ACTIONS,
  USER_STATUS,
} from '@/modules/comptePro/comptePro.constants';
import { getUrlWithoutQueryParams } from '@/shared/utils/url';

export function canUseComptePro({ userStatus }) {
  return userStatus === USER_STATUS.VERIFIED;
}

export function buildScaRedirectUrl({
  scaAction = SCA_ACTIONS.CREATE_VIRTUAL_CARD,
  baseUrl = getUrlWithoutQueryParams(),
}) {
  return `${baseUrl}?action=${scaAction}`;
}

export function isCompteProDisabled({ userStatus, maintenanceLevel }) {
  return userStatus !== USER_STATUS.VERIFIED || maintenanceLevel === MAINTENANCE_LEVELS.DISABLED;
}

export function isCompteProWaitingForIdentityVerification({ userStatus }) {
  return userStatus === USER_STATUS.WAITING_FOR_IDENTITY_VERIFICATION;
}

export function isCompteProWaitingForPartnerApprobation({ userStatus }) {
  return userStatus === USER_STATUS.WAITING_FOR_PARTNER_APPROBATION;
}

export function isCompteProInMaintenanceMode({ maintenanceLevel }) {
  return maintenanceLevel === MAINTENANCE_LEVELS.DISABLED || maintenanceLevel === MAINTENANCE_LEVELS.WARNING;
}

export function getMaintenanceMessage({ maintenance }) {
  if (maintenance?.level === MAINTENANCE_LEVELS.NO_MAINTENANCE) {
    return '';
  }

  if (maintenance?.message) {
    return maintenance.message;
  }

  if (maintenance?.level === MAINTENANCE_LEVELS.WARNING) {
    return MAINTENANCE_MESSAGE.WARNING;
  }

  return MAINTENANCE_MESSAGE.DEFAULT;
}

export function canAccessOnboardingComptePro({ isInScopeComptePro, userCompteProStatus }) {
  return Boolean(isInScopeComptePro && userCompteProStatus === USER_STATUS.NO_BANK_ACCOUNT);
}

export function isOnboardingBlocked({ hasStepDataError, stepList, stepKey }) {
  return hasStepDataError || isEmpty(stepList) || !stepKey;
}

export function isCompteProWebOnboardingEnabled(
  { featureFlags = window.GEORGES_SETTINGS.featureFlags } = { featureFlags: window.GEORGES_SETTINGS.featureFlags },
) {
  return get(featureFlags, 'enableCompteProWebOnboarding', false);
}
