import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

const SENTRY_FRONT_CONFIG = window.GEORGES_SETTINGS?.sentryFront || {};

export function useSentry({ app, router }) {
  try {
    Sentry.init({
      app,
      dsn: SENTRY_FRONT_CONFIG.dsn,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'indy.fr', 'herokuapp.com', /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      logErrors: true,
    });
  } catch (error) {
    console.error('Impossible to set up Sentry:', error);
  }
}
