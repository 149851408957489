import mixpanel from 'mixpanel-browser';

import { MIXPANEL_CONFIG } from '@/shared/services/mixpanel/config';

export function initMixpanel() {
  mixpanel.init(MIXPANEL_CONFIG.key, {
    debug: MIXPANEL_CONFIG.debug,
    api_host: MIXPANEL_CONFIG.proxyUrl,
    ignore_dnt: MIXPANEL_CONFIG.ignoreDnt,
  });
  mixpanel.track('App loading');
}
