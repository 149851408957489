import georgesApi from '@/shared/apis/georges';

const getUrl = (path) => `/activities/micro-bic/fiscal-declaration${path}`;

export async function getFiscalDeclarationActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl(''),
  });
  return data;
}

export async function validateFiscalDeclarationActivity() {
  const { data } = await georgesApi({
    method: 'POST',
    url: getUrl('/validation'),
  });
  return data;
}
