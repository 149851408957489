export {
  clearImpersonateUserId,
  getImpersonateUserId,
  // only for tests
  getImpersonateUserIdFromRouteContext,
  initImpersonateUserId,
};

const STORAGE_KEY = 'georges/impersonateUserId';

function initImpersonateUserId(routeContext) {
  const impersonateUserId = getImpersonateUserIdFromRouteContext(routeContext);
  if (impersonateUserId) {
    setImpersonateUserId({ impersonateUserId });
  }
  return getImpersonateUserId();
}

function getImpersonateUserId() {
  // fraking sessionStorage returns 'null', and 'null' is chaotic evil, 'undefined' is lawful neutral
  return window.sessionStorage.getItem(STORAGE_KEY) || undefined;
}

function setImpersonateUserId({ impersonateUserId }) {
  return window.sessionStorage.setItem(STORAGE_KEY, impersonateUserId);
}

function clearImpersonateUserId() {
  return window.sessionStorage.removeItem(STORAGE_KEY);
}

function getImpersonateUserIdFromRouteContext(routeContext) {
  return routeContext?.params?.impersonateUserId ?? routeContext?.query?.imiter;
}
