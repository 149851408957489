import { $gnotify } from '@/shared/utils/notify';

export default function notifyImitationFailure({ to, next }) {
  if (to.query.imitating_admin === 'true') {
    $gnotify.error(`Impossible d'imiter un admin`);
  } else if (to.query.imitating_deleted_user === 'true') {
    $gnotify.error(`Impossible d'imiter un utilisateur supprimé`);
  } else if (to.query.imitation_failed !== undefined) {
    $gnotify.error(`Impossible d'imiter cet utilisateur`);
  }

  next();
}
