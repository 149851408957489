export function shouldShowTrialOverModal({
  isTrialExpired,
  isUserClient,
  hasStartedComptePro,
  isBookkeeping,
  isSupportedForDeclaration,
}) {
  return isTrialExpired && isSupportedForDeclaration && !hasStartedComptePro && !isUserClient && !isBookkeeping;
}

export function shouldShowLastChargeFailModal({ isLastChargeFailed, isBookkeeping }) {
  return isLastChargeFailed && !isBookkeeping;
}

export function shouldShowChurnedSciScmModal({ hasChurnedInSciOrScm, hasStartedComptePro }) {
  return hasChurnedInSciOrScm && !hasStartedComptePro;
}

export function getSubscriptionProblemModalToShow({
  isTrialExpired,
  isUserClient,
  hasStartedComptePro,
  isLastChargeFailed,
  isBookkeeping,
  isUserSubscriptionIncomplete,
  hasChurnedInSciOrScm,
  isSupportedForDeclaration,
}) {
  if (
    shouldShowTrialOverModal({
      isTrialExpired,
      isUserClient,
      hasStartedComptePro,
      isBookkeeping,
      isSupportedForDeclaration,
    })
  ) {
    return 'trialOverModal';
  } else if (shouldShowLastChargeFailModal({ isLastChargeFailed, isBookkeeping })) {
    return 'lastChargeFailModal';
  } else if (isUserSubscriptionIncomplete) {
    return 'lastChargeIncompleteModal';
  } else if (shouldShowChurnedSciScmModal({ hasChurnedInSciOrScm, hasStartedComptePro })) {
    return 'churnedSciScmModal';
  }
}
