import get from 'lodash/get';

import { getAccountingInitialisationActivity } from '@/modules/closing/sciAccountingInitialisationActivity/accountingInitialisationActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    uploadLastFiscalYearDocuments: {
      title: 'Munissez-vous du bilan de la dernière clôture',
      shortTitle: 'Bilan de la dernière clôture',
      availableOnlyForClients: true,
    },
    sciInformation: {
      title: 'Informations sur votre SCI',
      shortTitle: 'Votre SCI',
      availableOnlyForClients: true,
    },
    realEstateInformation: {
      title: 'Informations sur vos biens',
      shortTitle: 'Vos biens',
      availableOnlyForClients: true,
    },
    realEstateInformationAsAsset: {
      title: 'Informations sur vos biens',
      shortTitle: 'Vos biens',
      availableOnlyForClients: true,
    },
    initAutresImmobilisationsIr: {
      title: 'Autres immobilisations',
      shortTitle: 'Autres immobilisations',
      availableOnlyForClients: true,
    },
    associatesInformation: {
      title: 'Informations sur les associés',
      shortTitle: 'Vos associés',
      availableOnlyForClients: true,
    },
    associatesInformationWithCca: {
      title: `Comptes courants des associés`,
      shortTitle: 'Vos associés',
      availableOnlyForClients: true,
    },
    initCapitauxPropres: {
      title: 'Indiquer les capitaux propres',
      shortTitle: 'Capitaux propres',
      availableOnlyForClients: true,
    },
    initCapitauxPropresWithoutBilan: {
      title: 'Indiquer les capitaux propres',
      shortTitle: 'Capitaux propres',
      availableOnlyForClients: true,
    },
    initComptesFinanciers: {
      title: 'Initialiser les comptes financiers',
      shortTitle: 'Soldes financiers',
      availableOnlyForClients: true,
    },
    initEmpruntsEnCours: {
      title: 'Préciser les emprunts en cours',
      shortTitle: 'Emprunts en cours',
      availableOnlyForClients: true,
    },
    initAutresCreancesDettes: {
      title: 'Indiquer les autres créances et dettes',
      shortTitle: 'Autres créances et dettes',
      availableOnlyForClients: true,
    },
    initCreancesClients: {
      title: 'Préciser les créances clients',
      shortTitle: 'Créances clients',
      availableOnlyForClients: true,
    },
    initDettesFournisseurs: {
      title: 'Préciser les dettes fournisseurs',
      shortTitle: 'Dettes fournisseurs',
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: `Valider l'initialisation`,
      shortTitle: `Valider`,
      availableOnlyForClients: true,
    },
    validationDuBilan: {
      title: `Valider l'initialisation de votre bilan`,
      shortTitle: 'Valider le bilan',
      availableOnlyForClients: true,
    },
    validationSansBilan: {
      title: `Valider l'initialisation de votre SCI`,
      shortTitle: 'Valider',
      availableOnlyForClients: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getAccountingInitialisationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
