<template>
  <GBanner v-if="isVisible" variant="tonal" color="info">
    <template #title>Allez&nbsp;plus&nbsp;loin</template>

    <template #text>
      Automatisez toutes vos <b>déclarations</b> et bénéficiez des conseils de nos <b>spécialistes</b>
    </template>

    <template #actions>
      <GButton append-icon="far fa-arrow-right" :to="{ name: 'userSettingsStripe' }"> Je découvre </GButton>
    </template>
  </GBanner>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BookkeepingUpsellingLink',
  computed: {
    ...mapGetters({
      canUpgradeToDeclaration: 'settings/canUpgradeToDeclaration',
    }),
    isVisible() {
      return this.canUpgradeToDeclaration && !this.$route.matched.some(({ name }) => name === 'compte');
    },
  },
};
</script>
