import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Services
import { refreshCurrentClosingState } from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    profileInitialization: {
      title: 'Initialiser votre profil de paie',
      shortTitle: 'Profil de paie',
      availableOnlyForClients: true,
    },
    contratsOrganismesComplementaires: {
      title: 'Paramétrer vos contrats de Prévoyance et Mutuelle',
      shortTitle: 'Contrats Prévoyance et Mutuelle',
      availableOnlyForClients: true,
    },
    payslipInitializationValidateClosing: {
      title: "Valider l'initialisation de votre paie",
      shortTitle: 'Valider',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        minDateToClose: moment(activity.date?.minDate),
      },
      user,
      activity,
    });
  },

  // -- Refresh
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const { closingState } = await refreshCurrentClosingState({
      declaration: 'payslipInitialization',
    });
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
