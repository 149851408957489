import { userHasAccessToInvoice } from '@/modules/user/product/product.helpers';

export default function ({ to, next }, { store }) {
  const facturationBeta = to.matched.some((record) => record.meta.facturationBeta);

  if (!facturationBeta) {
    return next();
  }

  const user = store.getters['user/currentUser'];

  if (userHasAccessToInvoice({ user })) {
    return next();
  }

  return next({ name: 'home' });
}
