import { DECLARATIONS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Services
import {
  fetchClosingState,
  refreshClosingState,
  refreshCurrentClosingState,
} from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: false,
  closingStates: {},
  defaultSteps: {
    pvOfAgAffectationResultat: {
      title: 'Affectation du résultat',
      shortTitle: 'Affectation du résultat',
      availableOnlyForClients: true,
    },
    pvOfAgRemunerationRepresentant: {
      title: 'Rémunération du représentant légal',
      shortTitle: 'Rémunération du représentant légal',
      availableOnlyForClients: true,
    },
    pvOfAgCapitauxPropres: {
      title: 'Évolution des capitaux propres',
      shortTitle: 'Capitaux propres',
      availableOnlyForClients: true,
    },
    pvOfAgRedaction: {
      title: 'Rédaction du Procès-Verbal',
      shortTitle: 'Rédaction du PV',
      availableOnlyForClients: true,
    },
    validatePvOfAgDeclaration: {
      title: 'Dépôt des comptes sur formalites.entreprises.gouv.fr',
      shortTitle: 'Dépôt des comptes',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState:
    (state) =>
    ({ year }) =>
      state.closingStates[year],
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { year, closingState }) {
    state.closingStates = {
      ...state.closingStates,
      [year]: closingState,
    };
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingStates } = state;
    const closingState = sortBy(Object.values(closingStates), ['fiscalYear']).find(
      (closingState) => closingState && !closingState.isDone,
    );
    const userCurrentFiscalYear = closingState.fiscalYear;

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        minDateToClose: moment(activity.date?.minDate),
        fiscalPeriodId: String(userCurrentFiscalYear),
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    const { closingState } = await refreshCurrentClosingState({
      declaration: DECLARATIONS.D_BIC_IS_PV_AG.KEY,
    });
    const year = closingState.fiscalYear;
    commit('setClosingState', { year, closingState });
  },
  async refresh({ commit }, { year }) {
    const { closingState } = await refreshClosingState({
      declaration: DECLARATIONS.D_BIC_IS_PV_AG.KEY,
      fiscalPeriodId: year,
    });
    commit('setClosingState', { year, closingState });
  },
  async fetch({ commit }, { year }) {
    const { closingState } = await fetchClosingState({
      declaration: DECLARATIONS.D_BIC_IS_PV_AG.KEY,
      fiscalPeriodId: year,
    });
    commit('setClosingState', { year, closingState });
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
