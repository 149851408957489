export const COMPTE_PRO_ROUTE_NAME = 'comptePro';

export const COMPTE_PRO_BASE_URL_API = '/compte-pro';

export const USER_STATUS = {
  VERIFIED: 'VERIFIED',
  NO_BANK_ACCOUNT: 'NO_BANK_ACCOUNT',
  MISSING_SYNCHRONIZATION: 'MISSING_SYNCHRONIZATION',
  WAITING_FOR_IDENTITY_VERIFICATION: 'WAITING_FOR_IDENTITY_VERIFICATION',
  WAITING_FOR_PARTNER_APPROBATION: 'WAITING_FOR_PARTNER_APPROBATION',
};

export const USER_SCOPE = {
  IN_SCOPE: 'IN_SCOPE',
  HORS_SCOPE: 'HORS_SCOPE',
  WAIT_LIST: 'WAIT_LIST',
};

export const MAINTENANCE_LEVELS = {
  // no maintenance
  NO_MAINTENANCE: 'NO_MAINTENANCE',
  // all features are disabled
  DISABLED: 'DISABLED',
  // features are available but with a warning
  WARNING: 'WARNING',
};

export const SCA_ACTIONS = {
  CREATE_VIRTUAL_CARD: 'create-virtual-card',
  CREATE_PHYSICAL_CARD: 'create-physical-card',
  WIRE_TRANSFER: 'wire-transfer',
  ENABLE_MANDATE: 'enable-mandate',
  ADD_DGFIP_MANDATE: 'add-dgfip-mandate',
  VIEW_CARD_NUMBERS: 'view-card-numbers',
  RESUME_PHYSICAL_CARD: 'resume-physical-card',
  ACTIVATE_PHYSICAL_CARD: 'activate-physical-card',
  VIEW_PHYSICAL_CARD_CODE_PIN: 'view-physical-card-code-pin',
  UPDATE_CARD_SPENDING_LIMIT: 'update-card-spending-limit',
};

export const SCA_STATUSES = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const MUTATION_WITH_SCA_STATUSES = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  NEEDS_SCA: 'NEEDS_SCA',
};

export const MAINTENANCE_MESSAGE = {
  DEFAULT:
    'Votre compte pro Indy est actuellement en cours de maintenance pour améliorer ses performances et sa fiabilité. Merci de votre patience !',
  WARNING:
    'Votre compte pro Indy est actuellement en cours de maintenance pour améliorer ses performances et sa fiabilité. Il se peut que certaines fonctionnalités ne soient pas disponibles. Merci de votre patience !',
};

export const COMPTE_PRO_FEATURES_LIST = [
  {
    title: 'Compte pro 100% gratuit',
    available: true,
  },
  {
    title: 'IBAN français',
    available: true,
  },
  {
    title: 'Carte Mastercard physique et virtuelle',
    available: true,
  },
  {
    title: 'Envoi et réception de virements',
    available: true,
  },
  {
    title: "Encaissement de chèques et d'espèce",
    available: false,
  },
];

export const WIKI_COMPTA_COMPTE_PRO_URL =
  'https://wikicompta.indy.fr/fr/articles/8772917-qu-est-ce-que-c-est-le-compte-pro-indy';
