const recrutingMessage = `
 ______              __
/\\__  _\\            /\\ \\
\\/_/\\ \\/     ___    \\_\\ \\  __  __
   \\ \\ \\   /' _ \`\\  /'_\` \\/\\ \\/\\ \\
    \\_\\ \\__/\\ \\/\\ \\/\\ \\L\\ \\ \\ \\_\\ \\
    /\\_____\\ \\_\\ \\_\\ \\___,_\\/\`____ \\
    \\/_____/\\/_/\\/_/\\/__,_ /\`/___/> \\
                               /\\___/
                               \\/__/

  Bonjour toi 👋
  Tu cherches un nouveau job ? Ça tombe bien, on recrute !
  👉 C'est par ici : https://jobs.indy.fr

`;

export function displayRecruitingMessage() {
  // eslint-disable-next-line no-console
  console.log(recrutingMessage);
}
