import get from 'lodash/get';
import moment from 'moment';

import { getIncomeDeclarationActivity } from '@/modules/closing/incomeDeclarationActivity/incomeDeclarationActivity.service';
// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

export const stateData = {
  loading: false,
  closingState: {},
  defaultSteps: {
    informations: {
      title: 'Informations',
      shortTitle: 'Informations',
      availableOnlyForClients: true,
    },
    dspamcReplacement: {
      title: 'Recette des remplaçants',
      shortTitle: 'Remplacements',
      availableOnlyForClients: true,
    },
    dspamcForfaits: {
      title: 'Rémunérations forfaitaires CPAM',
      shortTitle: 'Forfaits CPAM',
      availableOnlyForClients: true,
    },
    dspamcAllowance: {
      title: 'Allocations et indemnités journalières',
      shortTitle: 'Allocations et IJ',
      availableOnlyForClients: true,
    },
    dspamcIncomeFromHealthCareStructure: {
      title: 'Revenus de structures de soins du type EHPAD, ESPIC, HAD, SSIAD, CMPP…',
      shortTitle: 'Revenus de structures de soins',
      availableOnlyForClients: true,
    },
    dspamcContractedActivityFees: {
      title: "Revenus de l'activité conventionnée",
      shortTitle: 'Activité conventionnée',
      availableOnlyForClients: true,
    },
    dspamcUrssafRate: {
      title: 'Taux « Urssaf »',
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: 'Déclarer vos revenus',
      shortTitle: 'Déclarer vos revenus',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const showAdminInfo = rootGetters['user/showAdminInfo'];
    const { defaultSteps, closingState } = state;
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        minDateToClose: moment(activity.date?.minDate),
        isInEarlyClosing: showAdminInfo,
        year: get(closingState, 'year'),
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getIncomeDeclarationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
