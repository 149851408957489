import { FISCAL_REGIMES_DEPRECATED, LEGAL_FORMS } from '@georges-tech/georges-lib';

export const tvaZoneRegimeEnum = {
  metropole: {
    key: 'metropole',
    display: 'Métropole',
    radioIsDom: 'Non',
  },
  domTom: {
    key: 'dom_tom',
    display: 'DOM',
    radioIsDom: 'Oui',
  },
};

export const tvaIntracomEnum = {
  eu: {
    key: 'eu',
  },
  notEu: {
    key: 'not-eu',
  },
};

export const tvaChoices = [
  { key: 'tva_exonerated', display: 'Sans TVA' },
  { key: 'tva_franchise_base', display: 'Sans TVA' },
  { key: 'tva_included', display: 'Avec TVA incluse' },
  { key: 'tva_ht', display: 'Avec TVA HT' },
];

// Easier to use, instead of using hardcoded strings everywhere
// and having to know all possible values...
export const tvaChoicesEnum = {
  exonerated: {
    key: 'tva_exonerated',
    display: 'Sans TVA',
  },
  tva_franchise_base: {
    key: 'tva_franchise_base',
    display: 'Sans TVA',
  },
  included: {
    key: 'tva_included',
    display: 'Avec TVA incluse',
  },
  ht: {
    key: 'tva_ht',
    display: 'Avec TVA HT',
  },
};

export const tvaFrequencies = [
  { key: 'monthly', display: 'Mensuelle', formLabel: 'Tous les mois (CA3)' }, // déclaration mensuelle
  { key: 'quarterly', display: 'Trimestrielle', formLabel: 'Tous les 3 mois (CA3)' },
  {
    key: 'yearly',
    display: 'Annuelle',
    formLabel: "Une fois par an (formulaire CA12), à la clôture de l'exercice, en versant 2 acomptes.",
  }, // by default
];

// Easier to use, instead of using hardcoded strings everywhere
// and having to know all possible values...
export const tvaFrequenciesEnum = {
  monthly: {
    key: 'monthly',
    tvaTagFormat: 'YYYY-MM',
    datePickerFormat: 'YYYY-MM',
  },
  quarterly: {
    key: 'quarterly',
    tvaTagFormat: 'YYYY-[Q]Q',
    datePickerFormat: 'YYYY-Q',
  },
  yearly: {
    key: 'yearly',
    tvaTagFormat: 'YYYY',
    datePickerFormat: 'YYYY',
  },
};

// Pourquoi avoir refait l'enum qui est dans georges lib FISCAL_REGIMES_DEPRECATED ,
export const fiscalRegimesEnum = {
  BNC: {
    key: FISCAL_REGIMES_DEPRECATED.BNC.KEY,
    label: 'BNC (Déclaration 2035)',
  },
  IS_SAS: {
    key: FISCAL_REGIMES_DEPRECATED.IS_SAS.KEY,
    label: 'Impôt sur les sociétés (Déclaration 2065)',
  },
  IS_SARL: {
    key: FISCAL_REGIMES_DEPRECATED.IS_SARL.KEY,
    label: 'Impôt sur les sociétés (Déclaration 2065)',
  },
};

/**
 * Possible VAT declaration data origins
 */
export const VAT_DECLARATION_ORIGINS = {
  /**
   * Declaration was computed from scratch
   * with current accounting lines
   */
  ACCOUNTING_LINES: 'acl',

  /**
   * Declaration was fetched from
   * stored information when declaration
   * was closed with Indy
   */
  GEORGES_DECLARATION: 'georges_declaration',

  /**
   * Declaration as it was actually
   * declared on impots.gouv.fr
   */
  DGFIP_DECLARATION: 'dgfip_declaration',
};

// Source: https://www.insee.fr/fr/information/2028129
export const legalFormsEnum = {
  EI: {
    key: LEGAL_FORMS.EI,
    code: '10',
    label: 'Entreprise individuelle',
  },
  SASU_SAS: {
    key: LEGAL_FORMS.SASU_SAS,
    code: '57',
    label: 'SASU / SAS',
  },
  EURL_SARL: {
    key: LEGAL_FORMS.EURL_SARL,
    code: '54',
    label: 'EURL / SARL',
  },
  SCI: {
    key: LEGAL_FORMS.SCI,
    code: '6540',
    label: 'SCI',
  },
  SCM: {
    key: LEGAL_FORMS.SCM,
    code: '6589',
    label: 'SCM',
  },
};

export function findLegalFormFromCode({ legalFormCode, legalFormChoices }) {
  const legalFormChoicesList = legalFormChoices.map(({ key }) => key);
  return Object.values(legalFormsEnum)
    .filter((legalForm) => legalFormChoicesList.includes(legalForm?.key))
    .find((legalForm) => legalFormCode.startsWith(legalForm.code));
}

const bicIsClosingTypes = {
  initialisationDuBilan: {
    key: 'initialisationDuBilan',
  },
  bicIsFiscalDeclaration: {
    key: 'bicIsFiscalDeclaration',
  },
  soldeIsDeclaration: {
    key: 'soldeIsDeclaration',
  },
  pvOfAg: {
    key: 'pvOfAg',
  },
};

export const closingTypes = {
  d2035: {
    key: 'd2035',
  },
  DAS2: {
    key: 'DAS2',
  },
  vat: {
    key: 'vat',
  },
  urssaf: {
    key: 'urssaf',
  },
  socialDeclaration: {
    key: 'socialDeclaration',
  },
  ...bicIsClosingTypes,
};

export const georgesInvoicesTvaRate = 2000;

export const rofChoices = ['BNC1', 'BNC2', 'BNC3', 'BNC4', 'BNC5'];
