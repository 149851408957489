import georgesApi from '@/shared/apis/georges.js';

export { refreshActivities };

async function refreshActivities() {
  const { data } = await georgesApi({
    url: '/activities',
  });
  return data;
}
