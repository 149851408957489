export const CARDS_MODAL_SCREENS = {
  CARDS_LIST: 'CARDS_LIST',
  CARD_DETAILS: 'CARD_DETAILS',
  CARD_PARAMETERS: 'CARD_PARAMETERS',
  DELETE_CARD: 'DELETE_CARD',
  RESUME_PHYSICAL_CARD: 'RESUME_PHYSICAL_CARD',
  ADD_VIRTUAL_CARD: 'ADD_VIRTUAL_CARD',
  ACTIVATE_PHYSICAL_CARD: 'ACTIVATE_PHYSICAL_CARD',
  CONFIRM_PHYSICAL_CARD_CREATION: 'CONFIRM_PHYSICAL_CARD_CREATION',
  ADD_PHYSICAL_CARD: 'ADD_PHYSICAL_CARD',
  CARD_CODE_PIN: 'CARD_CODE_PIN',
  UPDATE_CARD_SPENDING_LIMIT: 'UPDATE_CARD_SPENDING_LIMIT',
};

export const CARD_TYPES = {
  VIRTUAL: 'Virtual',
  VIRTUAL_AND_PHYSICAL: 'VirtualAndPhysical',
};

export const CARD_STATUSES = {
  CONSENT_PENDING: 'ConsentPending', // when the consent to add this card is pending
  PROCESSING: 'Processing', // when the card is in the process of being ready to use
  ENABLED: 'Enabled', // when the card is enabled
  CANCELED: 'Canceled', // when the card is canceled
  CANCELING: 'Canceling', // when the card is about to be canceled
};

export const PHYSICAL_CARD_STATUSES = {
  CONSENT_PENDING: 'ConsentPending', // Consent to authorize physical card production is pending
  PROCESSING: 'Processing', // Physical card is in processing and can’t be used yet
  ACTIVATED: 'Activated', // Physical card is activated and can be used
  SUSPENDED: 'Suspended', // Physical card is suspended and can’t be used
  CANCELED: 'Canceled', // Physical card is canceled, can’t be used, and can’t be restored
  CANCELING: 'Canceling', // Physical card is in the process of being canceled; card can’t be used or restored
  TO_ACTIVATE: 'ToActivate', // Physical card needs to be activated by cardholder
  TO_RENEW: 'ToRenew', // Physical card is in the process of being renewed
  RENEWED: 'Renewed', // Physical card is renewed but hasn’t made a transaction since renewal
};

export const SHIPPING_PROVIDERS = {
  LA_POSTE: {
    name: 'La Poste',
    getUrl: (trackingNumber) => `https://www.laposte.fr/outils/suivre-vos-envois?code=${trackingNumber}`,
  },
};

export const PHYSICAL_CARD_SUSPENDED_REASONS_LABELS = {
  IncorrectPinCodeAttemptsExceeded:
    'Votre carte a été suspendue pendant 24h suite à 3 saisies incorrectes de votre code PIN.',
  UserAction: 'Votre carte a été suspendue suite à votre demande.',
};

export const ADVANTAGES_OF_VIRTUAL_CARD = [
  {
    title: 'Sécurisé',
    description: 'Pas de risque de perte ou de vol',
  },
  {
    title: 'Pratique',
    description: 'Utilisable partout, sans limite de montant, via ApplePay et GooglePay',
  },
];
