// Utilities
import cloneDeep from 'lodash/cloneDeep';
import flatMap from 'lodash/flatMap';

// Services
import { getCategoryGroupsWithDecorations } from '@/modules/categories/services/categories.services';

import { highlightCategories } from './utils';

export const stateData = {
  categoryGroups: undefined,
  categoryGroupsWithHighlight: undefined,
  changeCategoryProps: {},
  onChange: undefined,
  opened: false,
};

export const getters = {
  categoryGroups: ({ categoryGroups }) => categoryGroups,
  categoryGroupsWithHighlight: ({ categoryGroupsWithHighlight }) => categoryGroupsWithHighlight,
  changeCategoryProps: ({ changeCategoryProps }) => changeCategoryProps,
  onChange: ({ onChange }) => onChange,
  opened: ({ opened }) => opened,

  // Search
  getMatchedCategoriesFromSearch: ({ categoryGroupsWithHighlight }) =>
    flatMap(categoryGroupsWithHighlight, 'accountingAccounts').filter((aa) => aa.shouldExpandKeywords),

  // Find CategoryGroup for an accounting account
  getCategoryGroupKeyFromAccountingNumber:
    ({ categoryGroups }) =>
    (number) => {
      return Object.keys(categoryGroups).find((key) => {
        const maybeAA = categoryGroups[key].accountingAccounts.find((aa) => aa.number === number);
        return maybeAA !== undefined;
      });
    },
};

export const mutations = {
  updateVisibility(state, { opened, changeCategoryProps, onChange }) {
    state.opened = opened;
    state.changeCategoryProps = changeCategoryProps;
    state.onChange = onChange;
  },
  updateCategoryGroups(state, categoryGroups) {
    state.categoryGroups = categoryGroups;
  },
  updateCategoryGroupsWithHighlight(state, categoryGroupsWithHighlight) {
    state.categoryGroupsWithHighlight = categoryGroupsWithHighlight;
  },
  resetCategoryGroupsHighlighted(state) {
    state.categoryGroupsWithHighlight = cloneDeep(state.categoryGroups);
  },
  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async initCategoryGroups({ commit }) {
    const accountsWithInfo = await getCategoryGroupsWithDecorations();
    commit('updateCategoryGroups', accountsWithInfo);
    commit('updateCategoryGroupsWithHighlight', accountsWithInfo);
  },
  highlightCategories({ state, commit }, { searchTerm }) {
    const newCategoryGroups = highlightCategories({
      categoryGroups: state.categoryGroups,
      searchTerm,
    });

    commit('updateCategoryGroupsWithHighlight', newCategoryGroups);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  getters,
  actions,
};
