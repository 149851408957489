<template>
  <GListItem
    v-tooltip="{
      disabled: !tooltip,
      content: tooltip,
      location: 'left',
      maxWidth: 300,
      openDelay: 500,
    }"
    class="category-link p-relative pa-8 p-l-16 d-flex flex-1 justify-space-between align-center text-wrap"
    :class="classes"
    density="compact"
    @click="onAccountingAccountClick(accountingAccount)"
  >
    <div>
      <span :class="{ 'font-weight-bold': accountingAccount.shouldExpandKeywords }" class="text-bodyS text-high">
        {{ accountingAccount.category_name }}
      </span>
      <GChip v-if="showAdminInfo" size="small" color="standard">
        {{ accountingAccount.number }}
      </GChip>
      <div v-if="accountingAccount.shouldExpandKeywords" class="m-t-4">
        <HighlightKeywords
          :text="accountingAccount.categorisation_modal.keywords"
          :highligh-indexes="accountingAccount.highlightedIndexes"
          class="text-labelL text-medium"
        />
      </div>
    </div>
    <CategoriesAdminEdit :accounting-account="accountingAccount" :category-group="categoryGroup" />
  </GListItem>
</template>

<script>
import { mapGetters } from 'vuex';

import CategoriesAdminEdit from '@/modules/categories/components/CategoriesAdminEdit.vue';
// Components
import HighlightKeywords from '@/shared/components/HighlightKeywords/HighlightKeywords.vue';

export default {
  name: 'CategoriesLink',
  emits: ['changeAccountingAccount'],

  components: {
    HighlightKeywords,
    CategoriesAdminEdit,
  },

  props: {
    categoryGroup: { type: Object, required: true },
    accountingAccount: { type: Object, default: () => ({}) },
    isSelected: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters({
      showAdminInfo: 'user/showAdminInfo',
    }),
    classes() {
      const categoryColor = this.categoryGroup.key;
      return {
        [`category-link--active background-${categoryColor} background-opacity-10 border-${categoryColor} border-l-2`]:
          this.isSelected,
      };
    },
    tooltip() {
      return this.accountingAccount?.categorisation_modal?.tooltip;
    },
  },

  methods: {
    onAccountingAccountClick(accountingAccount) {
      if (this.isSelected) return;
      this.$emit('changeAccountingAccount', accountingAccount);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-link {
  min-height: 40px;

  &--active {
    cursor: default;
  }
}
</style>
