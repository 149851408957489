import moment from 'moment';

export function getFiscalPeriodFromDates({ startDate, endDate, fiscalYearFallback }) {
  // we want to cover all timezones
  return {
    startDate: startDate
      ? moment(startDate).add(12, 'hours').startOf('day').toDate()
      : moment({ year: fiscalYearFallback }).startOf('year').toDate(),
    endDate: endDate
      ? moment(endDate).subtract(12, 'hours').endOf('day').toDate()
      : moment({ year: fiscalYearFallback }).endOf('year').toDate(),
  };
}

export function formatYear(timestamp) {
  if (timestamp) {
    return moment(timestamp).format('YYYY');
  }
  return '';
}

export function formatDay(timestamp) {
  if (timestamp) {
    return moment(timestamp).format('DD');
  }
  return '';
}

export function formatShortMonth(timestamp) {
  if (timestamp) {
    return moment(timestamp).format('MMM');
  }
  return '';
}

export function formatDateTime(value) {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

export function formatTime(value, displaySeconds = false) {
  if (!value) return '';

  const formatString = `HH:mm${displaySeconds ? ':ss' : ''}`;
  return moment(value).format(formatString);
}

export function formatDate(value, wantedFormat = 'DD/MM/YYYY') {
  if (!value) return '';

  const formats = [moment.ISO_8601, 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD', 'DD-MM-YYYY', 'DD/MM/YYYY'];

  const date = moment(value, formats, true);
  if (!date.isValid()) return value;
  return moment(value).format(wantedFormat);
}

export function formatDateText(value) {
  if (!value) return '';
  return moment(value).format('DD MMMM YYYY');
}

export function formatTimestampToDate(value) {
  if (!value || isNaN(Number(value))) return '';
  return new Date(Number(value));
}

export function isDateInFutureDaily(date) {
  return moment().endOf('day').isBefore(moment(date));
}
