import georgesApi from '@/shared/apis/georges';

export async function getFirstUncompletedStep() {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/signup-funnel/routing/get-first-uncompleted-page/`,
  });
  return data;
}

export async function isAccessToStepAllowed({ stepKey }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/signup-funnel/routing/is-access-to-page-allowed/${stepKey}`,
  });
  return data;
}

export async function getNextStepKey({ stepKey }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/signup-funnel/routing/get-next-page/${stepKey}`,
  });
  return data;
}

export async function getPreviousStepKey({ stepKey }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/signup-funnel/routing/get-previous-page/${stepKey}`,
  });
  return data;
}
