import { onboardingCompteProGuard } from '@/modules/comptePro/comptePro.guard';

const compteProRoutes = [
  {
    path: '/compte-pro',
    meta: { requiresAuth: true },
    children: [
      {
        path: 'cartes',
        redirect: {
          name: 'transactions',
          query: { openCompteProFeature: 'cartes' },
        },
      },
      {
        path: 'iban',
        redirect: {
          name: 'transactions',
          query: { openCompteProFeature: 'iban' },
        },
      },
      {
        path: 'virement',
        redirect: {
          name: 'transactions',
          query: { openCompteProFeature: 'virement' },
        },
      },
      {
        path: 'verification-identite',
        component: () => import('@/shared/layouts/BlankLayout/BlankLayout.vue'),
        children: [
          {
            path: '',
            name: 'comptePro.identityVerification',
            component: () =>
              import('@/modules/comptePro/components/IdentityVerification/CompleteIdentityVerification.vue'),
          },
        ],
      },
    ],
  },
];

const onboardingCompteProRoutes = [
  {
    path: '/compte-pro/creation',
    meta: { requiresAuth: true },
    component: () => import('@/shared/layouts/BlankLayout/BlankLayout.vue'),
    beforeEnter: (to, from, next) => onboardingCompteProGuard({ next }),
    children: [
      {
        path: '',
        name: 'comptePro.creation',
        component: () => import('@/modules/comptePro/components/Onboarding/Onboarding.vue'),
      },
      {
        path: 'finalisation',
        name: 'comptePro.creation.complete',
        component: () => import('@/modules/comptePro/components/Onboarding/CompleteOnboarding.vue'),
      },
    ],
  },
];

export { compteProRoutes, onboardingCompteProRoutes };
