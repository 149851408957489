// Utilities
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';

import { accountingAccountMatchesSearch } from './accountingAccountMatchesSearch';

export function highlightCategories({ categoryGroups, searchTerm }) {
  const categoryGroupsWithMatchResults = mapValues(categoryGroups, (categoryGroup) => ({
    ...categoryGroup,
    accountingAccounts: categoryGroup.accountingAccounts.map((accountingAccount) => {
      if (!accountingAccount.categorisation_modal.keywords) return accountingAccount;
      const matchResult = accountingAccountMatchesSearch(accountingAccount.categorisation_modal.keywords, searchTerm);
      return { ...accountingAccount, matchResult };
    }),
  }));

  // ------------------------
  // Detect number of exact matches
  // ------------------------
  const numberOfExactMatches = flatMap(categoryGroupsWithMatchResults, 'accountingAccounts').filter(
    (accountingAccount) => get(accountingAccount, 'matchResult.exact', false),
  ).length;
  // ------------------------
  // Update categoryGroups
  // ------------------------
  const newCategoryGroups = mapValues(categoryGroupsWithMatchResults, (categoryGroup) => ({
    ...categoryGroup,
    accountingAccounts: categoryGroup.accountingAccounts.map(({ matchResult, ...accountingAccount }) => {
      const hasResultAndThereIsNoExactMatch = matchResult && numberOfExactMatches === 0;
      const hasResultAndItsAnExactMatch = matchResult && matchResult.exact && numberOfExactMatches > 0;

      if (hasResultAndThereIsNoExactMatch || hasResultAndItsAnExactMatch) {
        return {
          ...accountingAccount,
          shouldExpandKeywords: true,
          highlightedIndexes: matchResult.highlightedIndexes,
        };
      }
      return accountingAccount;
    }),
  }));

  return newCategoryGroups;
}
