import { DECLARATIONS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Computed
import { everyStepsDone } from '@/modules/newActivities/utils/steps';
// Services
import {
  fetchClosingState,
  refreshClosingState,
  refreshCurrentClosingState,
} from '@/shared/services/closingStates/closingStates.services';
import { getVatConfigurationAtDate } from '@/shared/services/vat/vat.services';

export const stateData = {
  loading: true,
  vatBuilds: {},
  currentVatTag: undefined,
  vatConfiguration: undefined,
  defaultSteps: {
    vatCreditReportable: {
      title: 'Ajouter un crédit reportable à cette déclaration',
      shortTitle: 'Report de crédit',
      availableBeforeEndYear: true,
      availableOnlyForClients: false,
    },
    categorizeAll: {
      title: 'Catégoriser toutes les transactions',
      shortTitle: 'Catégoriser les transactions',
      availableOnlyForClients: false,
    },
    lunch: {
      title: 'Ventiler les repas hors domicile',
      shortTitle: 'Frais de repas',
      availableOnlyForClients: false,
    },
    vatRateVerification: {
      title: 'Vérifier les taux de TVA',
      shortTitle: 'Taux de TVA',
      availableOnlyForClients: false,
    },
    vatAdvancePayments: {
      title: 'Acomptes de TVA',
      shortTitle: 'Acomptes',
      availableOnlyForClients: true,
    },
    vatSalesBilled: {
      title: 'Ventes facturées',
      shortTitle: 'Ventes facturées',
      availableOnlyForClients: true,
    },
    vatCashingFromSales: {
      title: 'Encaissement des ventes',
      shortTitle: 'Encaissement des ventes',
      availableOnlyForClients: true,
    },
    vatOperationsEtranger: {
      title: 'TVA sur vos opérations à l’étranger (UE et hors UE)',
      shortTitle: 'Opérations à l’étranger',
      availableOnlyForClients: true,
    },
    vatVehicleTaxes: {
      title: 'Taxes sur les véhicules',
      shortTitle: 'Taxes sur les véhicules',
      availableOnlyForClients: true,
    },
    vatCreditRefundDemand: {
      title: 'Demande de remboursement de crédit de TVA',
      shortTitle: 'Remboursement de crédit',
      availableOnlyForClients: true,
    },
    companyIdentity: {
      title: ({ user: { userIsInBnc } }) => {
        return userIsInBnc ? 'Informations relatives à votre entreprise' : 'Informations relatives à votre société';
      },
      shortTitle: ({ user: { userIsInBnc } }) => {
        return userIsInBnc ? 'Votre entreprise' : 'Votre société';
      },
      availableOnlyForClients: true,
    },
    vatDeclarationSummary: {
      title: 'Récapitulatif de votre déclaration',
      shortTitle: 'Récapitulatif',
      availableOnlyForClients: true,
    },
    vatValidateClosing: {
      title: 'Finaliser la déclaration',
      shortTitle: 'Finaliser',
      isValidatingStep: true,
      availableOnlyForClients: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  currentVatTag: ({ currentVatTag }) => currentVatTag,

  // -- Get a specific VAT BUILD for a given TAG
  getVatBuild:
    ({ vatBuilds }) =>
    ({ vatTag }) =>
      get(vatBuilds, vatTag, {}),

  // -- Detect if current closing state steps are done
  currentClosingStepsAllDone: ({ currentVatTag: vatTag }, { getVatBuild }) => {
    const closingState = getVatBuild({ vatTag });
    return everyStepsDone({ steps: closingState?.steps });
  },
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCurrentVatTag(state, vatTag) {
    state.currentVatTag = vatTag;
  },
  setVatConfiguration(state, vatConfiguration) {
    state.vatConfiguration = vatConfiguration;
  },
  setVatBuild(state, { vatTag, vatBuild }) {
    state.vatBuilds = {
      ...state.vatBuilds,
      [vatTag]: vatBuild,
    };
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, getters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, currentVatTag, vatConfiguration } = state;
    const closingState = getters.getVatBuild({ vatTag: currentVatTag });
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        fiscalPeriodId: currentVatTag,
        minDateToClose: moment(activity.date?.minDate),
        vatConfiguration,
        periodLabel: activity.text.period,
      },
      user,
      activity,
    });
  },

  // -- Refresh
  async refreshCurrent({ commit }) {
    commit('setLoading', true);

    const { closingState: vatBuild } = await refreshCurrentClosingState({
      declaration: DECLARATIONS.D_CA12.KEY,
    });

    if (!vatBuild) commit('setCurrentVatTag');

    if (vatBuild) {
      const vatTag = vatBuild.tva_tag;
      commit('setCurrentVatTag', vatTag);
      commit('setVatBuild', { vatTag, vatBuild });
    }
    if (vatBuild?.fiscal_period?.start_date) {
      const vatConfiguration = await getVatConfigurationAtDate({ date: vatBuild?.fiscal_period?.start_date });
      commit('setVatConfiguration', vatConfiguration);
    }

    commit('setLoading', false);
  },

  // -- Refresh Specific
  async refresh({ commit }, { vatTag }) {
    commit('setLoading', true);
    const { closingState: vatBuild } = await refreshClosingState({
      declaration: DECLARATIONS.D_CA12.KEY,
      fiscalPeriodId: vatTag,
    });
    commit('setVatBuild', { vatTag, vatBuild });
    commit('setLoading', false);
  },

  // -- Fetch specific
  async fetch({ commit }, { vatTag }) {
    commit('setLoading', true);
    const { closingState: vatBuild } = await fetchClosingState({
      declaration: DECLARATIONS.D_CA12.KEY,
      fiscalPeriodId: vatTag,
    });
    commit('setVatBuild', { vatTag, vatBuild });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
