<template>
  <div class="d-flex flex-column align-start px-16 py-24 space-y-24">
    <!-- Logo -->
    <router-link :to="{ name: 'home' }" class="p-l-16">
      <GLazyImg
        src="https://georges-public-assets.s3-eu-west-1.amazonaws.com/images/indy/logo.svg"
        alt="logo"
        :max-width="80"
        :max-height="40"
        contain
      />
    </router-link>

    <!-- Menu -->
    <GButton
      v-if="currentUser"
      append-icon="fa fa-caret-down"
      class="px-8 text-bodyL font-weight-normal navigation-menu-button"
      variant="text"
    >
      <div class="d-flex align-center space-x-12 min-w-0">
        <GLazyImg src="/img/illustrations/Client.svg" height="32" width="32" />
        <span class="text-truncate">{{ buttonText }}</span>
      </div>

      <GMenu activator="parent" :min-width="220">
        <GList>
          <GListItem
            :to="{ name: 'userSettingsAccounting' }"
            :active="isActiveRoute('/compte')"
            prepend-icon="fal fa-user-alt"
            title="Paramètres"
          >
            <template v-if="hasSettingError" #append>
              <GBadge color="error" dot inline />
            </template>
          </GListItem>

          <ReferralMenuItem v-if="canAccessReferralPage" />

          <GListItem v-if="!isImpersonating" prepend-icon="fal fa-sign-out" title="Se déconnecter" @click="signOut" />
        </GList>
      </GMenu>
    </GButton>
  </div>
  <GDivider class="mx-16" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ReferralMenuItem from '@/modules/referral/shared/ReferralMenuItem/ReferralMenuItem.vue';
import Trackable from '@/shared/mixins/trackable';
import { isActiveRoute } from '@/shared/utils/routing';

import { getNavigationMenuText, hasSettingError } from './navigation.model';

export default {
  name: 'AppNavigationHeader',
  mixins: [Trackable],
  components: {
    ReferralMenuItem,
  },
  computed: {
    ...mapGetters({
      isImpersonating: 'user/isImpersonating',
      currentUser: 'user/currentUser',
      userFirstname: 'user/userFirstname',
      subscriptionIncomplete: 'settings/isUserSubscriptionIncomplete',
      canAccessReferralPage: 'referral/canAccessReferralPage',
    }),

    buttonText() {
      return getNavigationMenuText({
        userFirstname: this.userFirstname,
        isImpersonating: this.isImpersonating,
      });
    },

    hasSettingError() {
      return hasSettingError({
        user: this.currentUser,
        subscriptionIncomplete: this.subscriptionIncomplete,
      });
    },
  },

  methods: {
    ...mapActions({
      signOutUser: 'user/signOut',
    }),
    async signOut() {
      await this.signOutUser();
      this.$router.push({ name: 'login' });
      this.trackSignOut();
    },
    isActiveRoute,
  },
};
</script>

<style lang="scss" scoped>
:deep(.g-button__content) {
  overflow: hidden;
}
</style>
