<template>
  <span>
    <template v-for="(char, index) in text">
      <span :key="index" v-if="isHighlightChar(index)" style="background-color: yellow">{{ char }}</span>
      <template v-else>{{ char }}</template>
    </template>
  </span>
</template>

<script>
export default {
  name: 'HighlightKeywords',
  props: {
    text: { type: String, default: '' },
    highlighIndexes: { type: Array, default: () => [] },
  },
  methods: {
    isHighlightChar(index) {
      return this.highlighIndexes.includes(index);
    },
  },
};
</script>
