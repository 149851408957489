export const stateData = {
  promocode: undefined,
  canGoToPreviousPage: false,
};

const getters = {
  userPromocode: (state) => state.promocode,
  canGoToPreviousPage: (state) => state.canGoToPreviousPage,
};

const mutations = {
  setPromocode(state, { promocode }) {
    state.promocode = promocode;
  },
  setCanGoToPreviousPage(state, { canGoToPreviousPage }) {
    state.canGoToPreviousPage = canGoToPreviousPage;
  },
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
};
