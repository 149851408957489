import { DECLARATIONS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
import { getUserTaxRegime } from '@/modules/user/services/user.services';
// Services
import { refreshCurrentClosingState } from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: false,
  defaultSteps: {
    close2035: {
      title({ taxRegime }) {
        return taxRegime === 'micro' ? `Clôture de votre comptabilité` : `Clôture de la déclaration 2035`;
      },
      shortTitle: 'Clôture fiscale',
      availableOnlyForClients: true,
    },
    dspamcReplacement: {
      title: 'Recette des remplaçants',
      shortTitle: 'Remplacements',
      availableOnlyForClients: true,
    },
    dspamcCovid: {
      title: 'Traitement des aides COVID-19',
      shortTitle: 'Aides COVID',
      availableOnlyForClients: true,
    },
    incomeFromContractedActivity: {
      title: "Revenus de l'activité conventionnée",
      shortTitle: 'Activité conventionnée',
      availableOnlyForClients: true,
    },
    dspamcForfaits: {
      title: 'Rémunérations forfaitaires CPAM',
      shortTitle: 'Forfaits CPAM',
      availableOnlyForClients: true,
    },
    allowance: {
      title: 'Allocations et indemnités journalières',
      shortTitle: 'Allocations et IJ',
      availableOnlyForClients: true,
    },
    incomeFromHealthCareStructure: {
      title: 'Revenus de structures de soins du type EHPAD, ESPIC, HAD, SSIAD, CMPP…',
      shortTitle: 'Revenus de structures de soins',
      availableOnlyForClients: true,
    },
    contractedActivityFees: {
      title: "Revenus de l'activité conventionnée",
      shortTitle: 'Activité conventionnée',
      availableOnlyForClients: true,
    },
    urssafRate: {
      title: 'Taux « Urssaf »',
      availableOnlyForClients: true,
    },
    dsiReport: {
      title: 'Télédéclaration sur impots.gouv.fr',
      shortTitle: 'Télédéclaration',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
    dspamcReport: {
      title: 'Télédéclaration sur impots.gouv.fr',
      shortTitle: 'Télédéclaration',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
  socialDeclarationClosingStates: {},
  socialDeclarationYear: undefined,
  socialDeclarationTaxRegime: undefined,
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState:
    (state) =>
    ({ year }) =>
      state.socialDeclarationClosingStates[year],
  socialDeclarationClosingState: ({ socialDeclarationClosingState }) => socialDeclarationClosingState,
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setSocialDeclarationClosingState(state, { year, socialDeclarationClosingState }) {
    state.socialDeclarationClosingStates = {
      ...state.socialDeclarationClosingStates,
      [year]: socialDeclarationClosingState,
    };
  },
  setCurrentSocialDeclarationYear(state, { year }) {
    state.socialDeclarationYear = year;
  },
  setCurrentSocialDeclarationTaxRegime(state, { taxRegime }) {
    state.socialDeclarationTaxRegime = taxRegime;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, getters, dispatch }, { activity, user }) {
    await dispatch('refreshCurrent');

    const { defaultSteps, socialDeclarationYear, socialDeclarationTaxRegime } = state;

    const closingState = getters.getClosingState({ year: socialDeclarationYear });
    const closingStateSteps = get(closingState, 'steps', {});

    return generateStepsForAnActivity({
      steps: pickBy(defaultSteps, (step, key) => Object.keys(closingStateSteps).includes(key)),
      closingStateSteps,
      stepProperties: {
        closingState,
        taxRegime: socialDeclarationTaxRegime,
        fiscalPeriodId: String(closingState.declaration_year),
        minDateToClose: moment(activity.date?.minDate),
      },
      user,
      activity,
    });
  },

  refreshCurrent: async ({ commit }) => {
    commit('setLoading', true);
    const { closingState: socialDeclarationClosingState } = await refreshCurrentClosingState({
      declaration: DECLARATIONS.D_DSI.KEY,
    });
    const year = Number(socialDeclarationClosingState.declaration_year);
    const { taxRegime } = await getUserTaxRegime({ year });
    commit('setSocialDeclarationClosingState', { year, socialDeclarationClosingState });
    commit('setCurrentSocialDeclarationYear', { year });
    commit('setCurrentSocialDeclarationTaxRegime', { taxRegime });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
