import get from 'lodash/get';

export function bankAccountName({ bankAccount }) {
  return get(bankAccount, 'name');
}

export function bankName({ bankAccount }) {
  return get(bankAccount, 'bank.name', 'Banque inconnue');
}

export function getBankAccountNumberFromAccountingAccountKey({ accountingAccountKey }) {
  const match = /^n512(?<bankAccountNumber>\d{3})$/.exec(accountingAccountKey);
  return match ? parseInt(match.groups.bankAccountNumber, 10) : undefined;
}

export function hasBankAccountForBankAuthId({ bankAccounts }) {
  return ({ bankAuthId }) =>
    bankAccounts.filter(({ bank_connector }) => bank_connector.bank_auth._id === bankAuthId).length > 0;
}
