import mixpanel from 'mixpanel-browser';

import { triggerAppLaunchedEvent } from '@/shared/services/app/app.services';

export function analyticsEvent({ action, properties = {} }) {
  const category = 'app';
  try {
    if (
      Object.prototype.hasOwnProperty.call(properties, 'category') ||
      Object.prototype.hasOwnProperty.call(properties, 'action')
    ) {
      throw new Error('Property names "category" and "action" are forbidden.');
    }
    mixpanel.track(action, {
      clientAppType: 'web',
      category,
      ...properties,
    });
    if (window.dataLayer) {
      window.dataLayer.push({
        event: action,
        event_category: category,
        ...properties,
      });
    }
  } catch (err) {
    console.warn(err);
  }
}

export function analyticsSetUserProperties({ properties = {} }) {
  mixpanel.register(properties);
  mixpanel.people.set(properties);
}

export function analyticsSignUpUser({ userId }) {
  mixpanel.alias(userId);
}

export function analyticsSignInUser({ store }) {
  const user = store.getters['user/currentUser'];
  if (!user) return;

  mixpanel.identify(user._id);
  const userEmail = user.emails[0].address;

  analyticsSetUserProperties({
    properties: {
      userId: user._id,
      email: userEmail,
      $email: userEmail,
      $first_name: user.profile.firstname,
      $last_name: user.profile.name,
      withPromocode: Boolean(store.getters['referral/getPromocode']),
      signupFiscalRegime: user.signup_funnel?.fiscal_regime_key,
      signupTaxRegime: user.signup_funnel?.tax_regime,
      signupLegalForm: user.legal_form,
    },
  });
  triggerAppLaunchedEvent();
}

export function analyticsSignOutUser() {
  mixpanel.reset();
}

export function analyticsPageViewEvent({ routeName, routePath, store }) {
  try {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: routePath,
      pageTitle: routeName,
      userId: store.getters['user/userId'],
      isBookkeeping: store.getters['user/isBookkeeping'],
      fiscalRegime: store.getters['user/getUserFiscalRegime'],
      isOnboardingDone: store.getters['user/isUserOnboard'],
      userIsClient: store.getters['user/userIsClient'],
      isUserUnsupported: store.getters['user/isUserUnsupported'],
    });
  } catch (err) {
    console.warn(`window.dataLayer function not found: ${err}`);
  }
}

export function analyticsFetchDataEvent({ isBookkeeping, hasActiveSubscription, userId, planName, legalForm }) {
  try {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'fetch_data',
      info_user: {
        accounting_version: isBookkeeping ? 'freemium' : 'premium',
        accounting_plan: planName,
        accounting_configuration: legalForm,
        user_type: hasActiveSubscription ? 'client' : 'user',
        user_id: userId,
        user_status: 'logged in',
      },
    });
  } catch (err) {
    console.warn(`window.dataLayer function not found: ${err}`);
  }
}

export function analyticsLoggedOutFetchEvent() {
  try {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: 'fetch_data',
      info_user: {
        user_type: 'visiteur',
        user_status: 'logged out',
      },
    });
  } catch (err) {
    console.warn(`window.dataLayer function not found: ${err}`);
  }
}
