<template>
  <GDialog persistent :model-value="shouldShowAuthChangedModal" width="800">
    <SimpleDialog title="Vous avez changé d'utilisateur">
      <p>L'utilisateur authentifié a changé, veuillez recharger la page pour continuer.</p>

      <template #actions>
        <GButton color="primary" @click="reloadPage"> Recharger la page </GButton>
      </template>
    </SimpleDialog>
  </GDialog>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SimpleDialog from '@/shared/components/Dialog/ActionDialog.vue';

const store = useStore();
const shouldShowAuthChangedModal = computed(() => store.state.auth.shouldShowAuthChangedModal);

function reloadPage() {
  window.location.reload();
}
</script>
