import isNil from 'lodash/isNil';

const space = String.fromCharCode(160);

/**
 * May be `NaN` if value cannot be parsed to a number
 */
export function parseInteger(value) {
  return parseInt(value || 0, 10);
}

export function getCentsRounded(value) {
  return (Math.round((value || 0) * 100) / 100).toFixed(2);
}

export function getDiffAmount(input1, input2) {
  if (isNil(input1)) return '-';
  const diffAmount = input1 - (input2 || 0);
  return formatCentsToNoCents(diffAmount, { withCurrency: true });
}

export function formatCents(value, { ifNil = '0,00' } = {}) {
  if (isNil(value)) return ifNil;
  if (isNaN(Number(value))) return value;
  return (value / 100)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${space}`);
}

export function formatCentsWithDecimals(value, { ifNil = '0,00' } = {}) {
  if (isNil(value)) return ifNil;
  if (isNaN(Number(value))) return value;

  const number = value / 100;
  let [integerPart, decimalPart] = number.toFixed(4).split('.');

  // Separate thousands with a space
  integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${space}`);

  // Remove trailing zeroes and ensure at least 2 decimals
  decimalPart = decimalPart.replace(/0+$/, '').padEnd(2, '0');

  return `${integerPart},${decimalPart}`;
}

export function formatCentsBasic(amountInCents) {
  return (amountInCents / 100).toFixed(2).replace('.', ',');
}

export function formatCentsWithCurrency(amountInCents, args) {
  return `${formatCents(amountInCents, args)}${String.fromCharCode(160)}€`;
}

export function formatCentsWithDecimalsWithCurrency(amountInCents, args) {
  return `${formatCentsWithDecimals(amountInCents, args)}${String.fromCharCode(160)}€`;
}

export function formatCentsToNoCents(amountInCents, { withCurrency = false } = {}) {
  return formatCentsToNoCentsFn(amountInCents, {
    withCurrency,
  });
}

function formatCentsToNoCentsFn(amountInCents, { withCurrency = false } = {}) {
  if (!amountInCents) {
    if (withCurrency) return `0${space}€`;
    return '0';
  }

  const formattedAmount = Math.round(amountInCents / 100)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${space}`);

  if (withCurrency) return `${formattedAmount}${space}€`;

  return formattedAmount;
}

export function formatAmountNoCents(amount) {
  if (isNil(amount)) return '';
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${String.fromCharCode(160)}`);
}

export function amountInCentsWithTva(subdivision, tvaSelected) {
  if (tvaSelected === 'tva_ht') {
    return subdivision.amount_in_cents + subdivision.tva_amount_in_cents;
  }
  return subdivision.amount_in_cents;
}

export function amountAsText(amountInCents) {
  if (isNil(amountInCents)) return '-';
  return formatCentsToNoCentsFn(amountInCents, { withCurrency: true });
}

export function amountPlaceholder(amount) {
  if (amount) return amount;
  return '--,--';
}

export function formatAmount(amount) {
  if (isNil(amount)) return '';
  return Number(amount)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${space}`);
}

export function formatAmountInCentsToNoCents({ amountInCents }) {
  return Math.round(amountInCents / 100);
}
