import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';

// Contants
import { ACTIVITIES } from '@/modules/newActivities/constants/activities.constants';
import {
  isUpcomingActivity,
  setActivityLockedDescription,
  setIsLateActivity,
  setIsLockedByActivity,
  setIsOnlyForAdmin,
  setIsPendingActivity,
  setIsSoonLateActivity,
  setPeriodColor,
  setPeriodIcon,
  setPeriodText,
  todayIsBeforeDate,
} from '@/modules/newActivities/utils/activity';
// Utils
import { generateRandomId } from '@/shared/utils';

/*
When we refresh activities, we receive an object like this :
activities: {
  [activityKey]: {
    isActive: boolean,
    isActiveForAdmin?: boolean,
    closingTimeInfo?: {
      available: boolean,
      dateToClose: { type: string },
      minDate: string,
      maxDate: string,
    },
    title: string,
    declaration: string,
    estimatedDuration: string,
    period: string,
    repetition: string,
    isInEarlyClosing?: boolean,
    nextComingYearOccurences?: [{
      closingTimeInfo: object,
      period: string,
    }]
    hasRequestedHelp?: boolean,
    isHistoricalDataRecoveryNeeded?: boolean,
    isInitializedFromDocuments?: boolean,
  }
};

After formatting this activities object, we'll return this:

activities: {
  [activityKey]: {
    items: {
      current: {
        _id: string,
        key: string,
        onlyForAdmin?: boolean,
        configurable?: boolean,
        status: {
          available: boolean,
          forcedAvailability?: boolean,
          pending?: boolean,
          late?: boolean,
          soonLate?: boolean,
          lockedBy?: string,
        },
        text: {
          chip: { text: string, color: string, icon: string },
          declaration: string,
          description?: string,
          lockedDescription?: string,
          estimatedDuration: string,
          period?: string,
          repetition?: string,
          title: string,
        },
        date?: {
          minDate: string,
          maxDate: string,
        },
        other: {
          isInEarlyClosing?: boolean,
          hasRequestedHelp?: boolean,
          isHistoricalDataRecoveryNeeded?: boolean,
          isInitializedFromDocuments?: boolean,
        },
        steps,
      },
      [activityPeriodKey]: { ... },
      [activityPeriodKey]: { ... },
      [activityPeriodKey]: { ... },
    },
  },
  ...,
  };

*/

export default function generateActivityList(activities = {}, modules = {}, todaysDate = new Date()) {
  const filteredActivities = getActiveAndDefinedActivities(activities, modules);
  return Object.entries(filteredActivities).reduce(
    (list, [activityKey, activity]) => ({
      ...list,
      [activityKey]: {
        items: serializeActivity({ activityKey, activity, activities, todaysDate }),
      },
    }),
    {},
  );
}

export function generateSlicedActivities(activities = {}) {
  const MAX_NUMBER_OF_ACTIVITIES = 3;

  return Object.entries(activities).reduce((list, [activityKey, activity]) => {
    const sortedItems = sortBy(activity.items, 'date.minDate');
    const slicedItems = sortedItems.filter(
      (item, index) => index < MAX_NUMBER_OF_ACTIVITIES || isUpcomingActivity(item),
    );

    return { ...list, [activityKey]: { items: keyBy(slicedItems, 'periodKey') } };
  }, {});
}

// ----------------------------
// Helpers
// ----------------------------
function getActiveAndDefinedActivities(activities, modules) {
  return pickBy(
    activities,
    ({ isActive, isActiveForAdmin }, key) => Object.keys(modules).includes(key) && (isActive || isActiveForAdmin),
  );
}

function serializeActivity({ activityKey, activity, activities, isNextOccurrence, todaysDate } = {}) {
  const { configurable, ALWAYS_AVAILABLE } = ACTIVITIES[activityKey] || {};

  const periodKey = String(get(activity, 'periodKey', 'current'));
  const date = setActivityDates({ activity });
  const status = setActivityStatus({ activity, ALWAYS_AVAILABLE: !isNextOccurrence && ALWAYS_AVAILABLE, todaysDate });

  return {
    [periodKey]: {
      _id: generateRandomId(),
      ...(configurable && { configurable: true }),
      ...(isNextOccurrence && { isNextOccurrence: true }),
      ...(setIsOnlyForAdmin(activity) && { isOnlyForAdmin: true }),
      date,
      declaration: get(activity, 'declaration'),
      key: activityKey,
      other: setActivityOther({ activity }),
      maintenance: get(activity, 'maintenance'),
      periodKey,
      isFirstActivity: get(activity, 'isFirstActivity'),
      status,
      steps: {},
      dateToClose: get(activity, 'closingTimeInfo.dateToClose'),
      text: setActivityText({ activityKey, activity: { ...activity, status, date }, activities }),
    },
    ...setNextComingYearOccurrences({ activityKey, activity, activities }),
  };
}

function setActivityText({ activityKey, activity, activities }) {
  const chip = {
    text: setPeriodText(activity),
    color: setPeriodColor(activity),
    icon: setPeriodIcon(activity),
  };

  const { description } = ACTIVITIES[activityKey] || {};

  const lockedDescription = setActivityLockedDescription({ activity, activities });

  return {
    chip,
    description: get(activity, 'description', description),
    estimatedDuration: get(activity, 'estimatedDuration'),
    period: get(activity, 'period'),
    repetition: get(activity, 'repetition'),
    title: get(activity, 'title'),
    ...(lockedDescription && { lockedDescription }),
  };
}

function setActivityStatus({ activity, ALWAYS_AVAILABLE, todaysDate } = {}) {
  const available = get(activity, 'closingTimeInfo.available', false);
  const forcedAvailability =
    ALWAYS_AVAILABLE && todayIsBeforeDate(get(activity, 'closingTimeInfo.minDate'), todaysDate);
  const pending = setIsPendingActivity(activity);
  const late = setIsLateActivity(activity);
  const soonLate = setIsSoonLateActivity(activity);
  const lockedBy = setIsLockedByActivity(activity, { forcedAvailability });

  return {
    available: ALWAYS_AVAILABLE || available,
    ...(forcedAvailability && { forcedAvailability }),
    pending,
    late,
    soonLate,
    ...(lockedBy && { lockedBy }),
  };
}

function setActivityDates({ activity }) {
  return {
    minDate: get(activity, 'closingTimeInfo.minDate'),
    maxDate: get(activity, 'closingTimeInfo.maxDate'),
  };
}

function setActivityOther({ activity }) {
  return omit(activity, [
    'closingTimeInfo',
    'declaration',
    'estimatedDuration',
    'isActive',
    'isActiveForAdmin',
    'isFirstActivity',
    'nextComingYearOccurrences',
    'period',
    'periodKey',
    'repetition',
    'title',
    'maintenance',
  ]);
}

function setNextComingYearOccurrences({ activityKey, activity, activities }) {
  if (!activity.nextComingYearOccurrences) return [];

  return activity.nextComingYearOccurrences
    .filter((nextOccurence) => nextOccurence.periodKey)
    .reduce(
      (list, nextOccurence) => ({
        ...list,
        ...serializeActivity({
          activityKey,
          activity: {
            ...nextOccurence,
            closingTimeInfo: {
              ...nextOccurence.closingTimeInfo,
              lockedBy: {
                period: get(list, [nextOccurence.lockedBy?.periodKey, 'text', 'period'], activity.period),
                key: activityKey,
              },
            },
            ...pick(activity, ['title', 'declaration', 'estimatedDuration', 'repetition', 'maintenance']),
          },
          activities,
          isNextOccurrence: true,
        }),
      }),
      {},
    );
}
