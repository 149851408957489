import georgesApi from '@/shared/apis/georges';

export const BIC_IS_INITIALIZATION_DU_BILAN_ACTIVITY_BASE_URL = '/activities/init-bilan';

export async function getBicIsInitialisationDuBilanActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: BIC_IS_INITIALIZATION_DU_BILAN_ACTIVITY_BASE_URL,
  });

  return data;
}
