import georgesApi from '@/shared/apis/georges';

export const RETAIL_ACTIVITY_BASE_URL = '/activities/sales/retail';

export async function getRetailActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: `${RETAIL_ACTIVITY_BASE_URL}`,
  });
  return data;
}

export async function closeRetailActivity({ period }) {
  return georgesApi({
    method: 'POST',
    params: {
      period,
    },
    url: `${RETAIL_ACTIVITY_BASE_URL}/validation`,
  });
}
