export function useGlobalComponents({ app }) {
  // Get all .vue files within `src/components`
  const modules = import.meta.glob('../globalComponents/**/*.vue', { eager: true });

  for (const path in modules) {
    const splitPath = path.split('/');
    const componentName = (splitPath[splitPath?.length - 1]?.split('.') ?? '')[0];
    app.component(componentName, modules[path].default);
  }
}
