export default {
  titleTemplate: '%s',
  title: 'Indy',
  meta: [
    // Default
    {
      hid: 'description',
      name: 'description',
      content: 'Indy, une application intelligente et une équipe attentionnée pour automatiser votre comptabilité',
    },
    {
      hid: 'author',
      name: 'author',
      content: 'GeorgesTech',
    },
    // Seo
    {
      hid: 'og:type',
      name: 'og:type',
      property: 'og:type',
      content: 'website',
    },
    {
      hid: 'og:title',
      name: 'og:title',
      property: 'og:title',
      content: 'Indy',
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      property: 'og:site_name',
      content: 'Indy',
    },
    {
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: 'Indy, une application intelligente et une équipe attentionnée pour automatiser votre comptabilité',
    },
    {
      hid: 'og:image',
      name: 'og:image',
      property: 'og:image',
      content: 'https://www.indy.fr/wp-content/uploads/logo_georges_1200600.png',
    },
    {
      hid: 'og:image:height',
      name: 'og:image:height',
      property: 'og:image:height',
      content: '630',
    },
    {
      hid: 'og:image:width',
      name: 'og:image:width',
      property: 'og:image:width',
      content: '1200',
    },
    {
      hid: 'msapplication-config',
      name: 'msapplication-config',
      content: 'https://georges-public-assets.s3-eu-west-1.amazonaws.com/images/indy/ms/browserconfig.xml',
    },
  ],
  link: [
    // Prefetch DNS
    { rel: 'preconnect', href: 'https://georges-public-assets.s3-eu-west-1.amazonaws.com', crossorigin: true },
    { rel: 'preconnect', href: 'https://pro.fontawesome.com', crossorigin: true },
    { rel: 'preconnect', href: 'https://assets.calendly.com', crossorigin: true },
    // Favicon
    {
      rel: 'shortcut icon',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/favicon.ico',
      type: 'image/x-icon',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/favicon-16x16.png',
      sizes: '16x16',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/favicon-32x32.png',
      sizes: '32x32',
    },
    // iOS Touch icon
    {
      rel: 'apple-touch-icon',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/icons/indy-180x180.png',
    },
    // ------------------------------
    // iOS Splashes
    // ------------------------------
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_11__iPhone_XR_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/12.9__iPad_Pro_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/11__iPad_Pro__10.5__iPad_Pro_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/10.9__iPad_Air_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/10.5__iPad_Air_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/10.2__iPad_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/8.3__iPad_Mini_landscape.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_11__iPhone_XR_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/12.9__iPad_Pro_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/11__iPad_Pro__10.5__iPad_Pro_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/10.9__iPad_Air_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/10.5__iPad_Air_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/10.2__iPad_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png',
    },
    {
      rel: 'apple-touch-startup-image',
      media:
        'screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/pwa/splash/8.3__iPad_Mini_portrait.png',
    },
    // ------------------------------
    // Preload & Load Fonts
    // ------------------------------
    {
      rel: 'preload',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/fonts/MartinaPlantijn/MartinaPlantijn-Regular.woff',
      as: 'font',
      crossorigin: 'anonymous',
      type: 'font/woff',
    },
    {
      rel: 'preload',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/fonts/MartinaPlantijn/MartinaPlantijn-Bold.woff',
      as: 'font',
      crossorigin: 'anonymous',
      type: 'font/woff',
    },
    {
      rel: 'preload',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/fonts/SuisseIntl/SuisseIntl-Regular.woff',
      as: 'font',
      crossorigin: 'anonymous',
      type: 'font/woff',
    },
    {
      rel: 'preload',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/fonts/SuisseIntl/SuisseIntl-SemiBold.woff',
      as: 'font',
      crossorigin: 'anonymous',
      type: 'font/woff',
    },
    {
      rel: 'stylesheet',
      href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/fonts/MartinaPlantijn/fonts.css',
    },
    { rel: 'stylesheet', href: 'https://georges-public-assets.s3.eu-west-1.amazonaws.com/fonts/SuisseIntl/fonts.css' },
    // ------------------------------
    // FontAwesome
    // ------------------------------
    {
      rel: 'stylesheet',
      href: 'https://pro.fontawesome.com/releases/v5.15.4/css/all.css',
      integrity: 'sha384-rqn26AG5Pj86AF4SO72RK5fyefcQ/x32DNQfChxWvbXIyXFePlEktwD18fEz+kQU',
      crossorigin: 'anonymous',
    },
    // @TODO check if we need to cherry-pick this stylesheet
    {
      rel: 'stylesheet',
      href: 'https://assets.calendly.com/assets/external/widget.css',
      media: 'all',
    },
  ],
  script: [
    // @TODO check if we need to cherry-pick this script
    {
      src: 'https://assets.calendly.com/assets/external/widget.js',
      type: 'text/javascript',
      async: true,
      defer: true,
    },
    {
      src: 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback',
      type: 'text/javascript',
      async: true,
      defer: true,
    },
  ],
  style: [],
  htmlAttrs: { lang: 'fr' },
};
