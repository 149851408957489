import georgesApi from '@/shared/apis/georges';

const getUrl = (path) => `/activities/ca-declaration${path}`;

export async function getCaDeclarationActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl(''),
  });
  return data;
}

export async function getValidateInfoCaDeclaration() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl('/validation/info'),
  });

  return data;
}

export async function validateCaDeclarationActivity() {
  await georgesApi({
    method: 'POST',
    url: getUrl('/validation'),
  });
}

export async function estimateCotisationsSociales() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl('/estimate-cotisations-sociales'),
  });
  return data;
}
