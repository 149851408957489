import axios from 'axios';
import qs from 'qs';

import { getUserIdTokenFromFirebase, signOutWithFirebase } from '@/modules/auth/services/firebase.services';
import { getImpersonateUserId } from '@/modules/auth/services/impersonate.services';
import { $gnotify } from '@/shared/utils/notify';

import { CLIENT_VERSION_RELEASE_HEADER_NAME, RELEASE } from '../constants/version';
import { getSessionId } from '../services/logger/logger';
import { getTabId } from '../utils/getTabId';

const tabId = getTabId();

const axiosInstance = axios.create({
  baseURL: '/api',
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.data?.code === 'action_impersonation_forbidden') {
      $gnotify.warning("Vous n'avez pas la permission d'effectuer cette action en imitation.");
    }

    if (error.response.data?.code === 'auth.unknown_error') {
      await signOutWithFirebase();
    }

    return Promise.reject(error);
  },
);

export default async ({ method = 'GET', headers = {}, sudoToken, ...options }) => {
  const idToken = await getUserIdTokenFromFirebase();
  const impersonateUserId = getImpersonateUserId();
  if (!idToken) throw new Error('unauthorized');

  return axiosInstance({
    method,
    headers: {
      ...headers,
      Authorization: `Bearer ${idToken.token}`,
      // fraking axios transmit undefined header values as 'undefined' string
      ...(impersonateUserId && { 'x-user-id': impersonateUserId }),
      ...(RELEASE && { [CLIENT_VERSION_RELEASE_HEADER_NAME]: RELEASE }),
      ...(sudoToken && { 'x-sudo-token': sudoToken }),
      'x-client-app-type': 'web',
      'x-client-session-id': getSessionId(),
      'x-client-tab-id': tabId,
    },
    ...options,
  });
};
