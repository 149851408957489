import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Services
import { getVatAdvancePaymentActivity } from '@/modules/newActivities/services/vatAdvancePaymentActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    vatAdvancePaymentAmounts: {
      title: 'Indiquer les montants à déclarer',
      shortTitle: 'Montants à déclarer',
      availableOnlyForClients: true,
    },
    companyIdentity: {
      title: 'Informations relatives à votre société',
      shortTitle: 'Votre société',
    },
    vatAdvancePaymentValidateClosing: {
      title: "Valider l'acompte",
      shortTitle: 'Valider',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  closingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCurrentClosingState(state, closingState) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        fiscalPeriodId: get(closingState, 'fiscalPeriodId'),
        minDateToClose: moment(activity.date?.minDate),
      },
      user,
      activity,
    });
  },

  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const { steps, fiscalPeriodId, declarationLabel } = await getVatAdvancePaymentActivity();
    commit('setCurrentClosingState', { steps, fiscalPeriodId, declarationLabel });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
