import { COMPTE_PRO_BASE_URL_API } from '@/modules/comptePro/comptePro.constants';
import georgesApi from '@/shared/apis/georges';

const COMPTE_PRO_CARDS_BASE_URL = `${COMPTE_PRO_BASE_URL_API}/cards`;

export async function getCards() {
  const { data } = await georgesApi({
    method: 'GET',
    url: `${COMPTE_PRO_CARDS_BASE_URL}`,
  });

  return data;
}

export async function createVirtualCard({ redirectUrl }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `${COMPTE_PRO_CARDS_BASE_URL}`,
    data: { redirectUrl },
  });

  return data;
}

export async function createPhysicalCard({ redirectUrl, deliveryAddress }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `${COMPTE_PRO_BASE_URL_API}/physical-cards`,
    data: { redirectUrl, deliveryAddress },
  });

  return data;
}

export async function viewCardNumbers({ redirectUrl, cardId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `${COMPTE_PRO_BASE_URL_API}/view-card/${cardId}`,
    data: { redirectUrl },
  });

  return data;
}

export async function viewPhysicalCardCodePin({ redirectUrl, cardId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `${COMPTE_PRO_BASE_URL_API}/physical-card/${cardId}/code-pin`,
    data: { redirectUrl },
  });

  return data;
}

export async function resumePhysicalCard({ redirectUrl, cardId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `${COMPTE_PRO_BASE_URL_API}/physical-card/${cardId}/resume`,
    data: { redirectUrl },
  });

  return data;
}

export async function cancelCard({ cardId }) {
  const { data } = await georgesApi({
    method: 'DELETE',
    url: `${COMPTE_PRO_CARDS_BASE_URL}/${cardId}`,
  });

  return data;
}

export async function activatePhysicalCard({ redirectUrl, identifier }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `${COMPTE_PRO_BASE_URL_API}/physical-card/activation`,
    data: { redirectUrl, identifier },
  });

  return data;
}

export async function updateCardSpendingLimit({ redirectUrl, cardId, spendingLimitValueInCents }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `${COMPTE_PRO_CARDS_BASE_URL}/${cardId}/spending-limit`,
    data: { redirectUrl, spendingLimitValueInCents },
  });

  return data;
}
