import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';

import { fetchAndUpdateBankAuthStatus, fetchBankAuths } from '@/modules/bankConnector/bankAuth/bankAuth.services';

import { fetchBanks } from './bank/bank.services';
import { hasUnsynchronizedBankAuth } from './bankConnector.models';

export const stateData = {
  bankAuths: undefined,
  banks: undefined,
};

export const mutations = {
  setBankAuths(state, { bankAuths }) {
    state.bankAuths = bankAuths;
  },
  updateBankAuths(state, { bankAuths }) {
    state.bankAuths = unionBy(bankAuths, state.bankAuths, '_id');
  },
  setBanks(state, { banks }) {
    state.banks = banks;
  },
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const getters = {
  bankAuths: (state) => state.bankAuths,
  hasOneBankAuth: (state, getters) => !isEmpty(getters['bankAuths']),
  mostRecentBankAuth: (state, getters) => last(sortBy(getters['bankAuths'], ['created_at'])),
  hasUnsynchronizedBankAuth: (state) => hasUnsynchronizedBankAuth({ bankAuths: state.bankAuths }),
  banks: (state) => state.banks,
};

export const actions = {
  fetchBankAuths: async ({ commit }) => {
    const bankAuths = await fetchBankAuths();
    commit('setBankAuths', { bankAuths });
    return bankAuths;
  },
  fetchAndUpdateBankAuthStatus: async ({ commit }, { bankAuthIds } = {}) => {
    const bankAuths = await fetchAndUpdateBankAuthStatus({ bankAuthIds });
    commit('updateBankAuths', { bankAuths });
    return bankAuths;
  },
  fetchBanks: async ({ commit }) => {
    const banks = await fetchBanks();
    commit('setBanks', { banks });
    return banks;
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  getters,
  actions,
};
