import { FISCAL_REGIMES_DEPRECATED } from '@georges-tech/georges-lib';

import { SETTINGS_CALENDLY } from '@/shared/constants';

import { calendlyByFiscalRegimeKey, calendlyBySchools } from './calendlyButton.constants';

export function getCalendlyId({ user, context }) {
  if (context && calendlyContextToCalendlyIdMap[context]) {
    return calendlyContextToCalendlyIdMap[context]({ user });
  }
  const schoolId = user?.school?.id;
  const onboardingFiscalRegimeKey = user?.signup_funnel?.fiscal_regime_key;

  if (onboardingFiscalRegimeKey === 'BNC_2035' && calendlyBySchools[schoolId]) {
    return calendlyBySchools[schoolId];
  }

  return calendlyByFiscalRegimeKey[onboardingFiscalRegimeKey];
}

export function getCalendlyLink({ calendlyId }) {
  return SETTINGS_CALENDLY.links[calendlyId];
}

export function isUserInBnc({ user }) {
  return user.fiscal_regime ? user.fiscal_regime === FISCAL_REGIMES_DEPRECATED.BNC.KEY : true;
}

// Settings - Delete account, "Allez plus loin avec Indy" banner
export function getUpsellingCalendlyId({ user }) {
  return isUserInBnc({ user }) ? 'indyComptaUpsellingBnc' : 'indyComptaUpsellingIs';
}

// Settings - "Bien démarrer avec Indy" banner
export function getOnboardingCalendlyId() {
  return 'isOnboardingBeforeSignup';
}

// Trial over popup
export function getTrialOverCalendlyId({ user }) {
  if (isUserInBnc({ user })) {
    return 'trialOverBnc';
  }

  return 'trialOverIs';
}

// Signup - Crea banner
// Funnel - Siret info
// Support page Premium users
export function getCreaCalendlyId() {
  return 'crea';
}

// Support page Premium users
export function getCreaSciCalendlyId() {
  return 'creaSci';
}

// Funnel - Sci fiscal year not supported
export function getSciFiscalYearNotSupportedCalendlyId() {
  return 'psSci';
}

const calendlyContextToCalendlyIdMap = {
  upselling: getUpsellingCalendlyId,
  onboarding: getOnboardingCalendlyId,
  trialOver: getTrialOverCalendlyId,
  crea: getCreaCalendlyId,
  creaSci: getCreaSciCalendlyId,
  sciFiscalYearNotSupported: getSciFiscalYearNotSupportedCalendlyId,
};
