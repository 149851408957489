import { resolveStringOrFunction } from '@/shared/utils/string';

export default async function updateTitle({ to, next }, { store }) {
  const user = store.getters['user/currentUser'];

  const nearestWithTitle = [...to.matched].reverse().find((r) => r.meta && r.meta.title);
  const title = nearestWithTitle?.meta?.title;

  document.title = generateDocumentTitle({
    title: getTitle({ title, user }),
    fullName: store.getters['user/fullName'],
    isImpersonating: store.getters['user/isImpersonating'],
  });

  next();
}

function generateDocumentTitle({ title = 'Indy', fullName, isImpersonating }) {
  return isImpersonating ? `${fullName} | ${title}` : title;
}

export function getTitle({ title, user }) {
  return resolveStringOrFunction(title, { user });
}
