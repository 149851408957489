import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { COLORS_CHARTS } from '@/shared/constants';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Filler,
);

ChartJS.defaults.responsive = true;
ChartJS.defaults.layout.autoPadding = true;
ChartJS.defaults.maintainAspectRatio = false;
ChartJS.defaults.animation.easing = 'easeInOutBack';
ChartJS.defaults.animation.duration = '500';
ChartJS.defaults.plugins.legend.display = false;
ChartJS.defaults.font.family = 'SuisseIntl';
ChartJS.defaults.color = COLORS_CHARTS.defaults.color; // medium;
ChartJS.defaults.scale.grid.color = (ctx) => {
  if (!ctx.tick || ctx.tick.value === 0) return COLORS_CHARTS.defaults.gridColor.zero;
  return COLORS_CHARTS.defaults.gridColor.default;
};
ChartJS.defaults.scale.border.dash = (ctx) => {
  if (!ctx.tick || ctx.tick.value === 0) return [];
  return [4, 4];
};
ChartJS.defaults.scale.border.dashOffset = (ctx) => {
  if (!ctx.tick || ctx.tick.value === 0) return 0;
  return 4;
};
