import { GLOBALS_IN_BROWSER } from '@/shared/constants';

export function logPwaInfos({ logger, userId }) {
  if (!GLOBALS_IN_BROWSER) return;

  const displayMode = window.matchMedia('(display-mode: standalone)').matches ? 'standalone' : 'browser tab';

  logger.log({
    userId,
    msg: '[PWA] Infos',
    args: {
      displayMode,
    },
  });
}
