import {
  FISCAL_REGIMES,
  FISCAL_REGIMES_DEPRECATED,
  isFiscalRegimeIsGroup,
  LEGAL_FORMS,
  TAX_REGIMES,
} from '@georges-tech/georges-lib';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import moment from 'moment';

import { isUserOnBookkeepingProduct, userHasAccessToInvoice } from '@/modules/user/product/product.helpers.js';
import { getProductHistory } from '@/modules/user/product/product.services.js';
import { getCoupons } from '@/modules/user/services/coupon.services';
import { fetchInvoices } from '@/modules/user/services/invoice.services';
// Services
import {
  fetchUser,
  removeDemo,
  scheduleNextFiscalYearIfNeeded,
  setHasNoDDCToAdd,
} from '@/modules/user/services/user.services';
import {
  getImpersonationFailureReasonKey,
  getUserCouponMessage,
  isInScopeComptePro,
} from '@/modules/user/users.store.helpers';
// Constants
import { tvaChoicesEnum, tvaFrequenciesEnum } from '@/shared/constants/functional';
import router from '@/shared/router';
import { rebootIntercom } from '@/shared/services/intercom/intercom.services';
import { analyticsFetchDataEvent } from '@/shared/utils/analytics';
// Helpers
import { getFiscalPeriodFromDates } from '@/shared/utils/dates';
import { formatFullName } from '@/shared/utils/string';

import { isPayslipEnabled } from '../payslip';

export const stateData = {
  currentUser: undefined,
  userInvoices: undefined,
  isMoreInvoices: undefined,
  atLeastOneAddressIsMissing: false,
  areInvoicesInMaintenanceMode: false,
  showAdminInfo: false,
  userCoupons: [],
  lastAppliedCoupon: undefined,
  productHistory: [],
};

export const mutations = {
  updateCurrentUser(state, { user }) {
    state.currentUser = user;
  },
  updateUserInvoices(state, { invoices }) {
    state.userInvoices = invoices;
  },
  updateIsMoreInvoices(state, { isMoreInvoices }) {
    state.isMoreInvoices = isMoreInvoices;
  },
  updateAtLeastOneAddressIsMissing(state, { atLeastOneAddressIsMissing }) {
    state.atLeastOneAddressIsMissing = atLeastOneAddressIsMissing;
  },
  updateAreInvoicesInMaintenanceMode(state, { areInvoicesInMaintenanceMode }) {
    state.areInvoicesInMaintenanceMode = areInvoicesInMaintenanceMode;
  },
  toggleShowAdminInfo(state) {
    state.showAdminInfo = !state.showAdminInfo;
  },
  updateUserCoupons(state, { coupons, lastAppliedCoupon }) {
    state.userCoupons = coupons;
    state.lastAppliedCoupon = lastAppliedCoupon;
  },
  updateUserCompteProStatus(state, { status }) {
    state.currentUser.comptePro.userStatus.status = status;
  },
  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
  updateProductHistory(state, { history }) {
    state.productHistory = history;
  },
};

export const getters = {
  // User Roles & State
  currentUser: (state) => state.currentUser,
  fullName: ({ currentUser }) =>
    formatFullName({
      firstname: currentUser?.profile?.firstname,
      name: currentUser?.profile?.name,
    }),
  userIsDemo: (state) => get(state, 'currentUser.demo.is_demo'),
  userId: (state) => get(state, 'currentUser._id'),
  userFirstname: (state) => get(state, 'currentUser.profile.firstname'),
  getJob: (state) => get(state, 'currentUser.profile.job'),
  getOrigin: (state) => get(state, 'currentUser.origin'),
  // user is considered authenticated if she has a Firebase token and she could get her user info in backend
  // meaning the token is valid and the user is known in DB
  isAuthenticated: ({ currentUser }, getters, rootState, { 'auth/isAuthenticated': authIsAuthenticated }) =>
    authIsAuthenticated && Boolean(currentUser),
  // user is impersonating only if correctly authenticated, and she has an impersonateUserId
  isImpersonating: (state, { isAuthenticated }, rootState, { 'auth/isImpersonating': isImpersonating }) =>
    isAuthenticated && isImpersonating,
  showAdminInfo: ({ showAdminInfo }) => showAdminInfo,
  isAdmin: (state, getters, rootState, { 'auth/hasAllRequiredPermissions': hasAllRequiredPermissions }) =>
    hasAllRequiredPermissions(['admin']),
  hasActiveSubscription: (_state, { userIsClient, isChurned }) => userIsClient && !isChurned,
  isBookkeeping: (state) => isUserOnBookkeepingProduct({ user: state.currentUser }),
  isInScopeComptePro: (state) =>
    isInScopeComptePro({ compteProUserScope: get(state, 'currentUser.comptePro.userScope') }),
  hasDDCToAdd: (state) => get(state, 'currentUser.has_ddc_to_add'),
  userIsClient: (state) => Boolean(get(state, 'currentUser.stripe.subscription.plan.name')),
  planName: (state) => get(state, 'currentUser.stripe.subscription.plan.name'),
  userSubscriptionDate: (state) => get(state, 'currentUser.stripe.subscription.date'),
  nextPaymentDue: (state) => get(state, 'currentUser.stripe.subscription.payment.nextPaymentDue'),
  churnAt: (state) => get(state, 'currentUser.churn.at'),
  isChurned: (_state, { churnAt }) => Boolean(churnAt),
  currentFiscalYear: (state) => get(state.currentUser, 'current_fiscal_year'),
  email: (state) => get(state.currentUser, 'emails[0].address'),
  isLastChargeFailed: (_state, { currentUser, userIsClient }) =>
    userIsClient && get(currentUser, 'stripe.lastChargeFailed'),

  // User Vat
  userHasVat: (state) =>
    get(state, 'currentUser.profile.tva_selected')
      ? [tvaChoicesEnum.ht.key, tvaChoicesEnum.included.key].includes(get(state, 'currentUser.profile.tva_selected'))
      : undefined,
  userProfileSelectedVat: (state) => get(state, 'currentUser.profile.tva_selected'),
  isInHT: (state) => get(state, 'currentUser.profile.tva_selected') === tvaChoicesEnum.ht.key,
  isInTTC: (state) => get(state, 'currentUser.profile.tva_selected') === tvaChoicesEnum.included.key,
  userProfileVatSelectedFrequency: (state) => get(state.currentUser, 'profile.tva_frequency'),
  userHasCa3: (state, { userProfileVatSelectedFrequency }) =>
    [tvaFrequenciesEnum.quarterly.key, tvaFrequenciesEnum.monthly.key].includes(userProfileVatSelectedFrequency),
  isYearlyFrequency: (state, { userProfileVatSelectedFrequency }) =>
    userProfileVatSelectedFrequency === tvaFrequenciesEnum.yearly.key,
  isQuarterlyFrequency: (state, { userProfileVatSelectedFrequency }) =>
    userProfileVatSelectedFrequency === tvaFrequenciesEnum.quarterly.key,
  isMonthlyFrequency: (state, { userProfileVatSelectedFrequency }) =>
    userProfileVatSelectedFrequency === tvaFrequenciesEnum.monthly.key,

  // User Regime
  getUserFiscalRegimeDefaultBnc: (state) => get(state, 'currentUser.fiscal_regime', FISCAL_REGIMES_DEPRECATED.BNC.KEY),
  getUserFiscalRegime: (state) => get(state, 'currentUser.fiscal_regime'),
  accountingNotModifiableBefore: (state) => get(state, 'currentUser.accounting_not_modifiable_before'),
  getFiscalPeriodBeginning: (state) => {
    const accountingNotModifiableBefore = get(state, 'currentUser.accounting_not_modifiable_before');
    if (!accountingNotModifiableBefore) return;

    const notModifiableBefore = moment(accountingNotModifiableBefore);
    const currentFiscalYearBeginning = moment({
      year: get(state, 'currentUser.current_fiscal_period.start_date'),
    });
    return moment.max(notModifiableBefore, currentFiscalYearBeginning).toDate();
  },
  getCurrentFiscalPeriod: (state) =>
    getFiscalPeriodFromDates({
      startDate: get(state, 'currentUser.current_fiscal_period.start_date'),
      endDate: get(state, 'currentUser.current_fiscal_period.end_date'),
      fiscalYearFallback: get(state, 'currentUser.current_fiscal_year'),
    }),
  currentFiscalExerciseEndDate: (_state, { getCurrentFiscalPeriod }) => moment(getCurrentFiscalPeriod.endDate),
  currentFiscalExerciseEndYear: (_state, { currentFiscalExerciseEndDate }) =>
    currentFiscalExerciseEndDate.format('YYYY'),
  isCurrentFiscalExerciseAtEndOfYear: (_state, { currentFiscalExerciseEndDate }) =>
    currentFiscalExerciseEndDate.month() === 11 && currentFiscalExerciseEndDate.date() === 31,
  isUserInBnc: (_state, { getUserFiscalRegimeDefaultBnc }) =>
    getUserFiscalRegimeDefaultBnc === FISCAL_REGIMES_DEPRECATED.BNC.KEY,
  isUserInIsSas: (_state, { getUserFiscalRegimeDefaultBnc }) =>
    getUserFiscalRegimeDefaultBnc === FISCAL_REGIMES_DEPRECATED.IS_SAS.KEY,
  isUserInIsSarl: (_state, { getUserFiscalRegimeDefaultBnc }) =>
    getUserFiscalRegimeDefaultBnc === FISCAL_REGIMES_DEPRECATED.IS_SARL.KEY,
  // Check if currentUser.fiscal_regime is in ['is_sas', 'is_sarl'].
  isUserInBicIs: (_state, { getUserFiscalRegimeDefaultBnc }) => isFiscalRegimeIsGroup(getUserFiscalRegimeDefaultBnc),
  currentFiscalYearConfiguration: (state) => get(state, 'currentUser.currentFiscalYearConfiguration'),

  isUserInMicroBnc: (_state, { currentFiscalYearConfiguration }) =>
    get(currentFiscalYearConfiguration, 'taxRegime') === TAX_REGIMES.MICRO,

  isUserInSci: (_state, { currentUser }) => currentUser?.legal_form === LEGAL_FORMS.SCI,

  isUserInScm: (_state, { currentUser }) => currentUser?.legal_form === LEGAL_FORMS.SCM,
  isUserInEi: (_state, { currentUser }) => currentUser?.legal_form === LEGAL_FORMS.EI,
  isUserInIsPostOnboarding: (_state, { currentFiscalYearConfiguration }) => {
    return (
      [LEGAL_FORMS.EURL_SARL, LEGAL_FORMS.SASU_SAS].includes(currentFiscalYearConfiguration.legalForm) &&
      currentFiscalYearConfiguration.fiscalRegime === FISCAL_REGIMES.IS
    );
  },

  // Trial
  trialEndingDate: (_state, { currentUser }) => moment(get(currentUser, 'trial.endDate')).endOf('day'),
  daysLeftBeforeTrialEnding: (_state, { trialEndingDate }) => {
    const dateToday = moment().endOf('day');
    return trialEndingDate.diff(dateToday, 'days');
  },
  isTrialExpired: (_state, { daysLeftBeforeTrialEnding }) => daysLeftBeforeTrialEnding < 0,

  // Coupons
  userCoupons: (state) => state.userCoupons,
  lastAppliedCoupon: (state) => state.lastAppliedCoupon,
  userCouponSubscriptionText: (_state, { userCoupons, daysLeftBeforeTrialEnding }) =>
    getUserCouponMessage({
      userCoupons,
      daysLeftBeforeTrialEnding,
      labelProperty: 'subscription_text',
    }),
  userCouponButtonTooltipText: (_state, { userCoupons, daysLeftBeforeTrialEnding }) =>
    getUserCouponMessage({
      userCoupons,
      daysLeftBeforeTrialEnding,
      labelProperty: 'button_tooltip',
      appendDaysLeftBeforeTrialEnding: true,
    }),

  userInvoices: (state) => state.userInvoices,
  isMoreInvoices: (state) => state.isMoreInvoices,
  atLeastOneAddressIsMissing: (state) => state.atLeastOneAddressIsMissing,
  areInvoicesInMaintenanceMode: (state) => state.areInvoicesInMaintenanceMode,

  // Onboarding
  creationType: (_state, { currentUser }) => get(currentUser, 'signup_funnel.creation'),
  isCreatingCompany: (_state, { creationType }) => Boolean(creationType),
  isUserOnboard: (_state, { currentUser }) => currentUser?.isOnBoard,
  isUserUnsupported: (_state, { currentUser }) =>
    currentUser.isSupported === false && currentUser.isSupportedInBookkeeping === false,
  isInCompanyCreationMode: (_state, { currentUser }) => Boolean(currentUser?.signup_funnel?.creation),

  lastFiscalExerciseEndDate: (_state, { currentFiscalExerciseEndDate }) => {
    if (currentFiscalExerciseEndDate) {
      return moment(currentFiscalExerciseEndDate).subtract(1, 'year');
    }
    console.warn('User without a current_fiscal_period? :(');
  },
  lastFiscalExerciseEndYear: (_state, { lastFiscalExerciseEndDate }) => {
    return lastFiscalExerciseEndDate
      .clone()
      .subtract(1, 'day') // handle earlier timezones than France
      .format('YYYY');
  },
  nextFiscalExerciseEndDate: (_state, { currentFiscalExerciseEndDate }) => {
    if (currentFiscalExerciseEndDate) {
      return moment(currentFiscalExerciseEndDate).add(1, 'year');
    }
    console.warn('User without a current_fiscal_period? :(');
  },
  nextFiscalExerciseEndYear: (_state, { nextFiscalExerciseEndDate }) => {
    return nextFiscalExerciseEndDate
      .clone()
      .subtract(1, 'day') // handle earlier timezones than France
      .format('YYYY');
  },
  socialDeclarationYear: (_state, { currentUser }) => get(currentUser, 'social_declaration.current_year'),
  // /!\ Prefer use of 'bicIsFiscalDeclaration/isFirstFiscalExercise'
  // ie. We don't update this user's prop when a closing state gets done
  // This user's prop means: "It was user's first year of activity when it signed up with us."
  isFirstFiscalExercise: (state) => get(state.currentUser, 'is_first_fiscal_exercise', false),
  isPayslipEnabled: (state) => isPayslipEnabled({ user: state.currentUser }),
  getDashboardBannerVisibilityDismissDate: (state) => (bannerName) =>
    get(state.currentUser, ['dashboard_banners_visibility', bannerName, 'dismiss_date']),
  productHistory: (state) => get(state, 'productHistory'),
  hasBeenOnDeclaration: (state) => get(state, 'productHistory', []).some(({ product }) => product === 'declaration'),
  isSalesAccountingModeCommitment: (_state, { currentFiscalYearConfiguration }) => {
    return currentFiscalYearConfiguration?.salesAccountingMode === 'commitment';
  },
  hasChurnedInSciOrScm: (_state, { isChurned, isUserInSci, isUserInScm }) => {
    return (isUserInSci || isUserInScm) && isChurned;
  },
};

export const actions = {
  initUser: async ({ getters, dispatch }) => {
    const user = await dispatch('fetchUser');

    if (!getters.isImpersonating) {
      rebootIntercom(user);
    }

    if (user.isOnBoard) {
      dispatch('getUserCoupons');
      dispatch('newActivities/refreshActivities', undefined, { root: true });
      dispatch('transactions/getAmountOfTransactionsToCategorize', undefined, { root: true });
      await dispatch('referral/getPromocode', undefined, { root: true });
      await dispatch('settings/getProductDetails', undefined, { root: true });

      if (userHasAccessToInvoice({ user })) {
        dispatch('facturation/fetchMaltToken', undefined, { root: true });
      }
    }

    if (getters.isBookkeeping && getters.isUserOnboard) {
      await dispatch('getProductHistory');
      await dispatch('scheduleNextFiscalYear');
    }

    return user;
  },
  scheduleNextFiscalYear: async () => {
    await scheduleNextFiscalYearIfNeeded();
  },
  clearUser: async ({ dispatch }) => {
    await dispatch('resetStore', undefined, { root: true });
    rebootIntercom();
  },
  signIn: async ({ dispatch }, { email, password, turnstileToken, mfaVerifyPayload }) => {
    return await dispatch('auth/signInUser', { email, password, turnstileToken, mfaVerifyPayload }, { root: true });
  },
  signInWithAutoLoginCode: async ({ dispatch }, { autoLoginCode }) => {
    return await dispatch('auth/signInUserWithAutoLoginCode', { autoLoginCode }, { root: true });
  },
  signInWithCustomToken: async ({ dispatch }, { token }) => {
    await dispatch('auth/signInUserWithCustomToken', { token }, { root: true });
    return await dispatch('initUser');
  },
  resetPassword: async ({ dispatch }, { resetToken, password }) => {
    await dispatch('auth/resetPassword', { resetToken, password }, { root: true });
  },
  signOut: async ({ dispatch }) => {
    await dispatch('auth/signOutUser', undefined, { root: true });
    await dispatch('clearUser');
  },
  fetchUser: async ({ commit, dispatch, getters }) => {
    try {
      const { user, auth = {} } = await fetchUser();
      commit('updateCurrentUser', { user });
      commit('auth/setAuthPermissions', { permissions: auth.permissions }, { root: true });
      analyticsFetchDataEvent({
        isBookkeeping: getters.isBookkeeping,
        hasActiveSubscription: getters.hasActiveSubscription,
        userId: getters.userId,
        planName: getters.planName,
        legalForm: user.legal_form,
      });
      return user;
    } catch (err) {
      if (err?.response?.status === 401) {
        // backend refused authentication, signOut & redirect to login
        await dispatch('signOut');
        router.push({ name: 'login' });
        throw new Error("Une erreur est survenue lors de l'authentification");
      }
      if (err?.response?.status === 403) {
        // backend refused impersonation, reset impersonateUserId
        await dispatch('auth/clearImpersonateUserId', undefined, { root: true });
        // reload page without impersonate query
        const impersonationFailedReason = getImpersonationFailureReasonKey({ responseCode: err?.response?.data?.code });

        window.location = window.location.pathname.startsWith('/imiter')
          ? `/transactions?${impersonationFailedReason}=true`
          : window.location.pathname + `?${impersonationFailedReason}=true`;
      }
    }
  },
  async getUserCoupons({ commit }) {
    try {
      const { results: coupons, lastAppliedCoupon } = await getCoupons();
      commit('updateUserCoupons', { coupons, lastAppliedCoupon });
    } catch (err) {
      console.error(err);
    }
  },
  async getUserInvoices({ state, commit }, { invoiceNumberLessThan, limit }) {
    const { invoices, isMoreInvoices, atLeastOneAddressIsMissing, areInvoicesInMaintenanceMode } = await fetchInvoices({
      invoiceNumberLessThan,
      limit,
    });
    const allInvoices = unionBy(invoices, state.userInvoices || [], (invoice) => invoice._id);
    commit('updateUserInvoices', { invoices: allInvoices });
    commit('updateIsMoreInvoices', { isMoreInvoices });
    commit('updateAtLeastOneAddressIsMissing', { atLeastOneAddressIsMissing });
    commit('updateAreInvoicesInMaintenanceMode', { areInvoicesInMaintenanceMode });
  },
  async removeDemo() {
    await removeDemo();
    location.reload();
  },
  async setHasNoDDCToAdd({ dispatch }) {
    await setHasNoDDCToAdd();
    dispatch('fetchUser');
  },
  async getProductHistory({ commit }) {
    const { history } = await getProductHistory();

    commit('updateProductHistory', { history });
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  getters,
  actions,
};
