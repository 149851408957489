import flatMapDeep from 'lodash/flatMapDeep';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';

import {
  isAdminOnlyActivity,
  isAnticipatedActivity,
  isAvailableActivity,
  isUpcomingActivity,
} from '@/modules/newActivities/utils/activity';

export { getActivitiesToDoCount, getAdminOnlyActivities, getAvailableActivities, getUpcomingActivities };

function getActivitiesToDoCount(activities) {
  const filteredActivities = pickBy(
    transformActivitiesToList(activities),
    (activity) => isAvailableActivity(activity) && !isAdminOnlyActivity(activity) && !isAnticipatedActivity(activity),
  );

  return Object.values(filteredActivities).length;
}

function getAvailableActivities(activities) {
  const filteredActivities = pickBy(
    transformActivitiesToList(activities),
    (activity) => isAvailableActivity(activity) && !isAdminOnlyActivity(activity),
  );

  return orderBy(
    Object.values(filteredActivities),
    [
      ({ dateToClose }) => dateToClose?.type === 'firstActivityToBeDone',
      ({ date }) => !date.maxDate,
      'status.late',
      'status.soonLate',
      'status.pending',
      'date.maxDate',
      'status.lockedBy',
    ],
    ['desc', 'desc', 'desc', 'desc', 'asc', 'asc', 'desc'],
  );
}

function getUpcomingActivities(activities) {
  const filteredActivities = pickBy(
    transformActivitiesToList(activities),
    (activity) => isUpcomingActivity(activity) && !isAdminOnlyActivity(activity),
  );
  return orderBy(Object.values(filteredActivities), ['date.minDate', 'date.maxDate'], ['asc', 'asc']);
}

function getAdminOnlyActivities(activities) {
  const filteredActivities = pickBy(transformActivitiesToList(activities), (activity) => isAdminOnlyActivity(activity));
  return orderBy(Object.values(filteredActivities), ['date.maxDate'], ['asc']);
}

function transformActivitiesToList(activities) {
  return orderBy(
    flatMapDeep(Object.values(activities), ({ items }) => Object.values(items)),
    ['date.minDate'],
    ['asc'],
  );
}
