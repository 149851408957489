export function clientInitials({ client }) {
  if (client.type === 'ENTITY') {
    return client.companyName[0].toUpperCase();
  } else if (client.type === 'INDIVIDUAL') {
    return `${client.firstName[0].toUpperCase()}${client.lastName[0].toUpperCase()}`;
  }

  throw new Error('unsupported client type');
}

export function clientName({ client }) {
  if (client.type === 'ENTITY') {
    return client.companyName;
  } else if (client.type === 'INDIVIDUAL') {
    return `${client.firstName || ''} ${client.lastName || ''}`.trim();
  }

  throw new Error('unsupported client type');
}
