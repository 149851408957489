<template>
  <GListItem :to="{ name: 'referral' }" :active="isActiveRoute('/parrainage')" :title="title">
    <template #prepend>
      <GIcon name="fa fa-heart" class="red-heart" size="20" color="primary" />
    </template>
  </GListItem>
</template>

<script>
import { mapGetters } from 'vuex';

import { isActiveRoute } from '@/shared/utils/routing';

export default {
  name: 'ReferralMenuItem',
  computed: {
    ...mapGetters({
      isTemporaryCampaignActive: 'referral/isTemporaryCampaignActive',
      hasActiveSubscription: 'user/hasActiveSubscription',
    }),
    title() {
      if (this.isTemporaryCampaignActive) {
        return '3 mois offerts';
      }
      if (this.hasActiveSubscription) {
        return '1 mois offert';
      }
      return 'Parrainage';
    },
  },
  methods: {
    isActiveRoute,
  },
};
</script>
<style lang="scss" scoped>
.red-heart {
  color: rgb(var(--g-theme-error));
  animation-name: heart-bump;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
</style>
