import get from 'lodash/get';
import moment from 'moment';

import { getSoldeIsActivity } from '@/modules/closing/sciIsSoldeIsActivity/soldeIsActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    soldeIsAdvancePayments: {
      title: 'Renseigner les acomptes & remboursements versés au titre de l’impôt sur les sociétés',
      shortTitle: 'Impôt sur les sociétés',
      availableOnlyForClients: true,
    },
    crlAdvancePayments: {
      title: 'Renseigner les acomptes & remboursements versés au titre de la Contribution sur les Revenus Locatifs',
      shortTitle: 'Contribution sur les revenus locatifs',
      availableOnlyForClients: true,
    },
    validateSoldeIsDeclaration: {
      title: "Télédéclaration et paiement du solde d'IS",
      shortTitle: 'Télédéclaration et paiement',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];
    const showAdminInfo = rootGetters['user/showAdminInfo'];

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        minDateToClose: moment(activity.date?.minDate),
        fiscalPeriodId: String(userCurrentFiscalYear),
        isInEarlyClosing: showAdminInfo,
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getSoldeIsActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
