import * as storage from '@/shared/utils/storage';

// @TODO guard these routes !
export default [
  {
    path: '/connexion-banque',
    name: 'frameReceivingItem',
    component: () => import('@/modules/bankin/components/FrameReceivingItem.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/attente-bankin/',
    name: 'waitBankinFunnelOpening',
    component: () => import('@/modules/bankin/components/BankinFunnelOpeningMessage.vue'),
    beforeEnter: (_to, _from, next) => {
      if (!storage.loadString('bankinFunnelUrl')) {
        return next({ name: 'home' });
      }

      next();
    },
    meta: {
      requiresAuth: true,
    },
  },
];
