import get from 'lodash/get';
import moment from 'moment';

import { getFiscalDeclarationActivity } from '@/modules/closing/sciIsFiscalDeclarationActivity/fiscalDeclarationActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
import { isFeatureEnabled } from '@/modules/user/features/features.helpers';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    categorizeAll: {
      title: 'Catégoriser les transactions',
      availableOnlyForClients: false,
      availableBeforeEndYear: true,
    },
    realEstateAssetsWithAmortization: {
      title: 'Biens immobiliers',
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    realEstateAssetSales: {
      title: 'Ventes immobilières',
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    assignRentalIncome: {
      title: 'Revenus locatifs',
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    loanBreakdown: {
      title: "Ventiler les échéances d'emprunts",
      shortTitle: "Échéances d'emprunt",
      availableOnlyForClients: false,
      availableBeforeEndYear: true,
    },
    assignAssociatesAccounts: {
      title: 'Comptes courants des associés',
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    checkAssets: {
      title: 'Vérifier les immobilisations',
      shortTitle: 'Immobilisations',
      availableOnlyForClients: true,
    },
    checkFinancialBalances: {
      title: 'Vérifier les soldes bancaires',
      shortTitle: 'Soldes bancaires',
      availableOnlyForClients: true,
    },
    loyersEnAttente: {
      title: 'Préciser les loyers en attente',
      shortTitle: 'Loyers en attente',
      availableOnlyForClients: true,
    },
    creancesEnAttente: {
      title: 'Préciser les revenus en attente',
      shortTitle: 'Revenus en attente',
      availableOnlyForClients: true,
    },
    advanceRentalIncome: {
      title: 'Précisez le montant des loyers perçus en avance',
      shortTitle: 'Loyers perçus en avance',
      availableOnlyForClients: true,
    },
    revenusPercusEnAvance: {
      title: 'Précisez le montant des revenus perçus en avance',
      shortTitle: 'Revenus perçus en avance',
      availableOnlyForClients: true,
    },
    dettesFournisseurs: {
      title: 'Dettes fournisseurs',
      availableOnlyForClients: true,
    },
    commandesEnCours: {
      title: 'Commandes en cours',
      availableOnlyForClients: true,
    },
    assignPreviousFiscalYearResultIs: {
      title: 'Affectation du résultat N-1',
      availableOnlyForClients: true,
    },
    sciInformationUpdate: {
      title: 'Mise à jour des informations sur votre SCI',
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: 'Valider et envoyer la déclaration aux impôts',
      shortTitle: 'Valider la déclaration',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];
    const currentUser = rootGetters['user/currentUser'];
    const showAdminInfo = rootGetters['user/showAdminInfo'];

    const isCurrentFiscalYearAvailableForClosing = isFeatureEnabled({
      user: currentUser,
      feature: 'sciIsClosingActivity',
    });

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState: state.closingState,
        minDateToClose: isCurrentFiscalYearAvailableForClosing
          ? moment(activity.date?.minDate)
          : moment.max(moment('2024-02-16'), moment(activity.date?.minDate)), // TODO: revert this line next week (on 2024-02-14)
        fiscalPeriodId: String(userCurrentFiscalYear),
        isInEarlyClosing: showAdminInfo,
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getFiscalDeclarationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
