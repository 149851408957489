import pickBy from 'lodash/pickBy';

export {
  everyAvailableStepDone,
  everyStepsDisabled,
  everyStepsDone,
  filterAvailableSteps,
  hasOneMoreStepToDo,
  hasStartedSteps,
};

function filterVisibleSteps({ steps }) {
  return pickBy(steps, ({ isVisible = true }) => isVisible);
}

function filterAvailableSteps({ steps }) {
  return pickBy(steps, ({ disabled }) => !disabled);
}

function everyStepsDone({ steps } = {}) {
  const visibleSteps = filterVisibleSteps({ steps });
  const visibleStepsCount = Object.keys(visibleSteps).length ?? 0;
  return visibleStepsCount > 0 && Object.values(visibleSteps).every(({ status }) => status === 'done');
}

function everyAvailableStepDone({ steps } = {}) {
  const availableSteps = filterAvailableSteps({ steps });
  return everyStepsDone({ steps: availableSteps });
}

function everyStepsDisabled({ steps } = {}) {
  const visibleSteps = filterVisibleSteps({ steps });
  return Object.values(visibleSteps).every(({ disabled }) => disabled);
}

function hasStartedSteps({ steps } = {}) {
  const visibleSteps = filterVisibleSteps({ steps });
  return Object.values(visibleSteps).some(({ status }) => status === 'done');
}

function hasOneMoreStepToDo({ steps } = {}) {
  const visibleSteps = filterVisibleSteps({ steps });
  return Object.values(visibleSteps).filter(({ status }) => status !== 'done').length === 1;
}
