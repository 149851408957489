const mobileWebViewRoutes = [
  {
    path: '/mobile-webview/intercom-conversations',
    name: 'mobileWebViewIntercomConversations',
    component: () => import('./IntercomMobileConversation.vue'),
    meta: {
      title: 'Intercom',
    },
  },
];

export { mobileWebViewRoutes };
