<template>
  <GSheet :color="color" class="space-y-12 pa-8">
    <div v-for="{ feature, available } in features" :key="feature" class="d-flex space-x-8">
      <template v-if="available">
        <GIcon class="m-t-4" left name="fal fa-check" />

        <GText variant="bodyS">{{ feature }}</GText>
      </template>

      <template v-else>
        <GIcon class="m-t-4" left color="error" name="fal fa-times" />

        <GText variant="bodyS" emphasis="medium">{{ feature }}</GText>
      </template>
    </div>
  </GSheet>
</template>

<script>
export default {
  name: 'FeaturesByLegalForm',
  props: {
    color: { type: String, default: 'background' },
    featuresList: {
      type: Array,
      required: true,
      default: () => [],
    },
    unavailable: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    features() {
      return [
        ...this.featuresList.map((feature) => ({ feature, available: true })),
        ...this.unavailable.map((feature) => ({ feature, available: false })),
      ];
    },
  },
};
</script>
