import { getUserFiscalPlan } from '@georges-tech/georges-lib';
import deburr from 'lodash/deburr';
import escapeRegExp from 'lodash/escapeRegExp';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';
import moment from 'moment';

// Constants
import {
  AA_EXCLUDED_FROM_TOTAL_TRANSACTION,
  ACCOUNT_OR_FROM_CHOICES,
} from '@/modules/transactions/constants/transactions.constants';

export {
  filtersTransactions, // only for tests
  insertTransactionAndRefreshList,
  mergeTransactionsAndRefreshList,
  updateTransactionAndRefreshList,
};

function mergeTransactionsAndRefreshList({ oldTransactions, newTransactions, params, fiscalConfiguration }) {
  const listUpdated = Object.assign({}, oldTransactions, newTransactions);

  return filtersTransactions({
    transactions: listUpdated,
    params,
    fiscalConfiguration,
  });
}

function updateTransactionAndRefreshList({ transaction, transactions, params, fiscalConfiguration }) {
  transactions[transaction._id] = transaction;

  return filtersTransactions({
    transactions,
    params,
    fiscalConfiguration,
  });
}

function insertTransactionAndRefreshList({ transaction, transactions, params, fiscalConfiguration }) {
  return filtersTransactions({
    transactions: { ...transactions, [transaction._id]: transaction },
    params,
    fiscalConfiguration,
  });
}

// If anything changes here, you need to update filters in server too.
// Path: /packages/server/src/modules/transactionsUI/_transactionsUI/functions
function filtersTransactions({ transactions = {}, params, fiscalConfiguration }) {
  const {
    search,
    selectedDates = {},
    selectedAccounts = [],
    selectedCategories = [],
    displayOnlyVatToBeDefined,
    deleted,
    displayAttachments,
    displayIngressOutflow,
    displayAmounts = {},
  } = params;

  return pickBy(transactions, (transaction) => {
    return (
      isValidSearch(transaction, search, fiscalConfiguration) &&
      isValidDate(transaction, selectedDates) &&
      isValidBankAccount(transaction, selectedAccounts) &&
      isValidCategory(transaction, selectedCategories) &&
      hasVatToBeDefined(transaction, displayOnlyVatToBeDefined) &&
      hasReceipt(transaction, displayAttachments) &&
      isValidAmounts(transaction, { displayIngressOutflow, ...displayAmounts }) &&
      isDeleted(transaction, deleted)
    );
  });
}

// -------------------------------
// Filters
// -------------------------------

function isValidAccountingAccount({ transaction, searchRegex, fiscalConfiguration }) {
  // Retrieves all accounting account numbers for a specific regime
  const allUserAccountingAccounts = getUserFiscalPlan({ fiscalConfiguration });
  const matchedAccounts = flatMap(
    Object.values(allUserAccountingAccounts).filter((account) => searchRegex.test(account.searchable_name)),
    'number',
  );

  // Retrieves the usable accounting account numbers of the transaction
  const subdivisionsAccountingAccounts = flatMap(transaction.subdivisions, (subdiv) => {
    if (subdiv.modifiable === false || subdiv.is_tva) return [];
    return subdiv.accounting_account.number;
  });

  const accountingAccountsFound = intersection(matchedAccounts, subdivisionsAccountingAccounts);
  return !isEmpty(accountingAccountsFound);
}

function isValidSearchAmount({ transaction, search }) {
  const amountInSearch = search.replace(',', '.');

  if (isNaN(amountInSearch)) return true;

  const searchableAmountInCents = Math.abs(Math.round(parseFloat(amountInSearch) * 100));

  const amountsInTransaction = map(transaction.subdivisions, (subdivision) => Math.abs(subdivision.amount_in_cents));
  return amountsInTransaction.includes(searchableAmountInCents);
}

// -- Search
function isValidSearch(transaction, search, fiscalConfiguration) {
  if (isEmpty(search)) return transaction;

  const searchText = escapeRegExp(deburr(search)).trim();
  const searchRegex = new RegExp(searchText, 'i');

  // Detect if search is in description / annotation / accounting accounts
  const hasFoundAnAccountingAccount = isValidAccountingAccount({ transaction, searchRegex, fiscalConfiguration });
  const isFoundInDescription = searchRegex.test(transaction.searchable_description);
  const isFoundInAnnotation = searchRegex.test(transaction.searchable_annotation);
  const hasFoundAmount = isValidSearchAmount({ transaction, search });

  return hasFoundAnAccountingAccount || isFoundInDescription || isFoundInAnnotation || hasFoundAmount;
}

// -- Date
function isValidDate(transaction, { start, end }) {
  if (!start || !end) return transaction;
  const transactionDate = transaction.isodate || transaction.date;
  return (
    moment(transactionDate).isSameOrAfter(moment(start).startOf('day')) &&
    moment(transactionDate).isSameOrBefore(moment(end).endOf('day'))
  );
}

// -- BankAccount or Choices
function isValidBankAccount(transaction, accounts) {
  if (isEmpty(accounts)) return transaction;

  const accountsWithoutBankAccountId = Object.keys(omit(ACCOUNT_OR_FROM_CHOICES, 'BANK_ACCOUNT_CHOICE'));

  for (const account of accounts) {
    const accountFrom = get(ACCOUNT_OR_FROM_CHOICES, [account.id], ACCOUNT_OR_FROM_CHOICES.BANK_ACCOUNT_CHOICE);
    const accountId = accountsWithoutBankAccountId.includes(account.id) ? undefined : account.id;
    const isValidFrom = accountFrom.includes(transaction.from);
    const isValidBankAccountId = accountId === transaction.id_bank_account;

    if (!accountId && isValidFrom) return true;
    if (accountId && isValidFrom && isValidBankAccountId) return true;
    continue;
  }
}

// - Categories
function isValidCategory(transaction, categories) {
  if (isEmpty(categories)) return transaction;

  const subdivisionsAccountAccounts = map(transaction.subdivisions, 'accounting_account.number');
  const categoriesAccountAccounts = map(categories, 'id');

  return !isEmpty(intersection(subdivisionsAccountAccounts, categoriesAccountAccounts));
}

// -- Only VAT to defined
function hasVatToBeDefined(transaction, displayOnlyVatToBeDefined) {
  if (!displayOnlyVatToBeDefined) return transaction;

  return some(map(transaction.subdivisions, 'tva_has_to_be_defined'));
}

// -- With receipt
/**
 * @param transaction
 * @param displayAttachments {(undefined|'with'|'without')}
 * @returns {boolean|*}
 */
function hasReceipt(transaction, displayAttachments) {
  if (!displayAttachments) return transaction;
  if (displayAttachments === 'with') {
    return transaction?.receipts?.length > 0;
  }
  return isEmpty(transaction.receipts);
}

// -- Valid amounts
function isValidAmounts(transaction, { displayIngressOutflow, from: aboveAmount, to: belowAmount }) {
  if (!displayIngressOutflow && !aboveAmount && !belowAmount) return transaction;

  const transactionAmount =
    -1 *
    sumBy(
      transaction.subdivisions.filter(
        (subdiv) =>
          subdiv.modifiable === false && subdiv.accounting_account.number !== AA_EXCLUDED_FROM_TOTAL_TRANSACTION,
      ),
      (subdiv) => subdiv.amount_in_cents / 100,
    );

  const ingress = transactionAmount >= 0;
  const outgress = transactionAmount < 0;

  const isAboveValid = isNil(aboveAmount) || Math.abs(transactionAmount) >= aboveAmount;
  const isBelowValid = isNil(belowAmount) || Math.abs(transactionAmount) <= belowAmount;

  if (displayIngressOutflow === 'ingress') return ingress && isAboveValid && isBelowValid;
  if (displayIngressOutflow === 'outflow') return outgress && isAboveValid && isBelowValid;
  return isAboveValid && isBelowValid;
}

// -- deleted
function isDeleted(transaction, deleted) {
  if (deleted === 'all') return true;
  const isTransactionDeleted = Boolean(transaction.is_deleted);
  if (deleted === 'yes') return isTransactionDeleted;
  return !isTransactionDeleted;
}
