<template>
  <GPanel v-model="isActive" :priority="-10" @after-leave="reset" @submit="onSubmit">
    <template #prepend="{ isAnimating }">
      <div class="p-relative d-flex flex-fill align-center justify-space-between space-x-16 pa-12">
        <GSkeletonLoader :loading="isAnimating" type="input" height="40" class="flex-1">
          <template #default>
            <CategoriesMobileSearch
              v-model="searchText"
              :subpage-title="subpageTitle"
              :is-subpage-visible="currentPage !== PAGES.categoriesList"
              :show-page="showPage"
              :is-animating="isAnimating"
              @update:model-value="updateSearch"
            />
          </template>
        </GSkeletonLoader>

        <GButton variant="text" :color="isSearchDirty ? 'primary' : ''" @click="isActive = false">
          {{ isSearchDirty ? 'Annuler' : 'Fermer' }}
        </GButton>
      </div>
    </template>

    <div class="p-relative">
      <!-- Default page: categoriesList -->
      <transition name="defaultPage">
        <div v-if="currentPage === PAGES.categoriesList" class="panel-content__page panel-content__page-default">
          <div
            v-if="matchedCategoriesFromSearch.length === 0 && searchText.length > 2"
            class="d-flex align-center pa-24"
          >
            <GText tag="h3">Aucun résultat</GText>
          </div>

          <GList class="pa-0">
            <!-- Not enough char to search -->
            <GListItem
              v-if="matchedCategoriesFromSearch.length === 0 && searchText.length !== 0 && searchText.length <= 2"
            >
              <GListItemIcon><GIcon name="fal fa-info-circle" /></GListItemIcon>
              <GListItemContent class="my-4">Saisissez au minumum 3 caractères</GListItemContent>
            </GListItem>

            <!-- Search result -->
            <template
              v-for="(matchedCategoryFromSearch, index) in matchedCategoriesFromSearch"
              :key="`${matchedCategoryFromSearch.number}-${index}`"
            >
              <GListItem
                class="d-flex flex-column align-start py-4 space-y-4"
                :class="{
                  [`background-${matchedCategoryFromSearch.decoration.category} background-opacity-10 border-${matchedCategoryFromSearch.decoration.category} border-l-2`]:
                    matchedCategoryFromSearch.number === accountingAccountNumber,
                }"
                @click="onAccountingAccountClick(matchedCategoryFromSearch)"
              >
                <span>{{ matchedCategoryFromSearch.category_name }}</span>
                <div v-if="matchedCategoryFromSearch.shouldExpandKeywords">
                  <HighlightKeywords
                    :text="matchedCategoryFromSearch.categorisation_modal.keywords"
                    :highligh-indexes="matchedCategoryFromSearch.highlightedIndexes"
                    class="text-bodyS text-medium"
                  />
                </div>
              </GListItem>
              <GDivider />
            </template>

            <!-- Show all categories -->
            <GListItem v-if="searchText.length === 0" @click="showPage(PAGES.categoriesFull)">
              <GListItemIcon><GIcon name="fal fa-long-arrow-right" /></GListItemIcon>
              <GListItemContent class="my-4">Parcourir toutes les catégories</GListItemContent>
              <GListItemAction><GIcon name="fal fa-chevron-right" /></GListItemAction>
            </GListItem>

            <!-- Categories -->
            <GListItem density="compact" class="background-background">
              <span class="text-medium">Suggestions de recherche</span>
            </GListItem>

            <template v-for="categoryGroup in categoryGroups" :key="categoryGroup.key">
              <GListItem @click="showCategoryList(categoryGroup)">
                <GListItemIcon>
                  <GBadge :color="categoryGroup.key" inline dot-large />
                </GListItemIcon>
                <GListItemContent class="my-4">{{ categoryGroup.name }}</GListItemContent>
                <GListItemAction><GIcon name="fal fa-chevron-right" /></GListItemAction>
              </GListItem>

              <GDivider />
            </template>
          </GList>
        </div>
      </transition>

      <!-- Full categories: categoriesFull -->
      <transition name="detailPage">
        <div v-if="currentPage === PAGES.categoriesFull" class="panel-content__page panel-content__page-details">
          <template v-for="categoryGroup in categoryGroups" :key="categoryGroup.key">
            <CategoriesMobileList
              :category-group="categoryGroup"
              :accounting-account-number="accountingAccountNumber"
              @change="onAccountingAccountClick"
            />
          </template>
        </div>
      </transition>

      <!-- Details: categoryList -->
      <transition name="detailPage">
        <div v-if="currentPage === PAGES.categoryList" class="panel-content__page panel-content__page-details">
          <CategoriesMobileList
            :category-group="selectedCategoryGroup"
            :accounting-account-number="accountingAccountNumber"
            @change="onAccountingAccountClick"
          />
        </div>
      </transition>
    </div>
  </GPanel>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions, mapGetters } from 'vuex';

import CategoriesMobileList from '@/modules/categories/components/CategoriesMobileList.vue';
import CategoriesMobileSearch from '@/modules/categories/components/CategoriesMobileSearch.vue';
// Components
import HighlightKeywords from '@/shared/components/HighlightKeywords/HighlightKeywords.vue';
import Trackable from '@/shared/mixins/trackable';

const PAGES = {
  categoriesList: 'categoriesList', // Default page
  categoriesFull: 'categoriesFull', // Show all categoryGroups with their categories
  categoryList: 'categoryList', // Show a selected categoryGroup with its categories
};

export default {
  name: 'CategoriesMobile',
  emits: ['close', 'change'],
  mixins: [Trackable],

  components: {
    HighlightKeywords,
    CategoriesMobileSearch,
    CategoriesMobileList,
  },

  props: {
    opened: Boolean,
    accountingAccountNumber: { type: String, default: '' },
  },

  data: () => ({
    changePageTimeout: undefined,
    currentPage: PAGES.categoriesList,
    PAGES,
    searchText: '',
    selectedCategoryGroup: {},
  }),

  computed: {
    ...mapGetters({
      categoryGroups: 'categories/categoryGroups',
      matchedCategoriesFromSearch: 'categories/getMatchedCategoriesFromSearch',
    }),
    isActive: {
      get() {
        return this.opened;
      },
      set(value) {
        this.$emit('close', value);
      },
    },
    isSearchDirty() {
      return this.searchText.length !== 0;
    },
    subpageTitle() {
      if (this.currentPage === PAGES.categoriesList) return;
      if (this.currentPage === PAGES.categoriesFull) return 'Parcourir les catégories';
      return this.selectedCategoryGroup?.name;
    },
  },
  beforeUnmount() {
    this.reset();
  },

  methods: {
    ...mapActions({
      highlightCategories: 'categories/highlightCategories',
    }),
    updateSearch(value) {
      if (value && value.length <= 2) return;
      this.highlightCategories({ searchTerm: value });
      this.debouncedSendAnalytics();
    },
    debouncedSendAnalytics: debounce(function () {
      this.trackEvent({
        action: 'search for transaction category',
        properties: {
          isMobile: true,
          currentCategory: this.searchText,
        },
      });
    }, 2000),
    onSubmit() {
      this.updateSearch(this.searchText);
    },
    showPage(name) {
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf('android') > -1;
      const delay = isAndroid ? 250 : 0;

      clearTimeout(this.changePageTimeout);
      this.changePageTimeout = setTimeout(() => {
        this.currentPage = name;
      }, delay);
    },
    showCategoryList(categoryGroup) {
      this.selectedCategoryGroup = categoryGroup;
      this.searchText = '';
      this.showPage(PAGES.categoryList);
    },
    onAccountingAccountClick(accountingAccountNumber) {
      if (accountingAccountNumber === this.accountingAccountNumber) return;
      this.$emit('change', accountingAccountNumber);
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-content {
  &__page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 0;

    &-details {
      left: 100%;
      transform: translate3d(-100%, 0, 0);
      z-index: 1;
    }

    // CF https://medium.com/@draganeror/iphone-x-layout-features-with-css-environment-variables-d57423433dec
    // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      padding-bottom: var(--safe-area-inset-bottom);
    }

    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      padding-bottom: var(--safe-area-inset-bottom);
    }
  }
}

// -- Transitions
.defaultPage-enter-active,
.defaultPage-leave-active,
.detailPage-enter-active,
.detailPage-leave-active {
  transition: transform 0.28s ease-in-out;
}
.defaultPage-enter-from,
.defaultPage-leave-to,
.detailPage-leave-from,
.detailPage-enter-to {
  transform: translate3d(-100%, 0, 0);
}
.defaultPage-leave-from,
.defaultPage-enter-to,
.detailPage-enter-from,
.detailPage-leave-to {
  transform: translate3d(0, 0, 0);
}
</style>
