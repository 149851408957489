import { transformParamsForAccountingApi } from '@/modules/transactions/models/accounting.models';
import georgesApi from '@/shared/apis/georges';

export { getTransactionsSummary };

async function getTransactionsSummary(params) {
  const { data } = await georgesApi({
    method: 'POST',
    url: '/accounting/transactions/summary',
    data: transformParamsForAccountingApi(params),
  });
  return data;
}
