import { v4 as uuidv4 } from 'uuid';

export const getTabId = () => {
  const tabId = sessionStorage.getItem('indy:tabId');
  if (!tabId) {
    const newTabId = uuidv4();
    sessionStorage.setItem('indy:tabId', newTabId);
    return newTabId;
  }
  return tabId;
};
