<template>
  <GCard>
    <GCardTitle>Votre dernier paiement semble avoir échoué</GCardTitle>
    <GDivider />
    <GCardText>
      <div class="space-y-16">
        <GText variant="bodyS">Vous n'avez plus accès à ces fonctionnalités :</GText>
        <FeaturesByLegalForm :features-list="featuresList" />
        <GText variant="bodyS">
          Pour qu'Indy puisse de nouveau automatiser votre comptabilité, vous devez mettre à jour votre moyen de
          paiement.
        </GText>

        <div class="d-flex flex-column">
          <GButton :to="{ name: 'userSettingsStripe' }" color="primary" size="large">
            Modifier mon moyen de paiement
          </GButton>
        </div>

        <GDivider class="border-dashed my-16" />

        <GText variant="bodyS" emphasis="medium">
          Votre historique est conservé temporairement : si vous mettez votre moyen de paiement à jour, vos données
          seront de nouveau disponibles, et les opérations effectuées intactes. Attention : sans action de votre part,
          vos données seront définitivement supprimées au bout d'un an.
        </GText>
      </div>
    </GCardText>
  </GCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import FeaturesByLegalForm from '@/modules/core/subscriptionModals/FeaturesByLegalForm.vue';

export default {
  name: 'LastChargeFailModal',
  components: { FeaturesByLegalForm },
  computed: {
    ...mapGetters({
      featuresList: 'settings/featuresSubscriptionModalsList',
    }),
  },

  created() {
    this.getSubscriptionData();
  },

  methods: {
    ...mapActions({
      getSubscriptionData: 'settings/getSubscriptionData',
    }),
  },
};
</script>
