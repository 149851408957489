<template>
  <GTable variant="filled" tile text-align="mixed">
    <slot />
  </GTable>
</template>

<script>
/*
  This table is pretty simple without border, background.
  Should be used inside GExpansionPanel
*/
export default {
  name: 'AppTableTile',
};
</script>
