import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Services
import { refreshCurrentClosingState } from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: false,
  closingState: {},
  defaultSteps: {
    vatInitialization: {
      title: 'Report des montants de TVA payée de votre dernière déclaration',
      availableOnlyForClients: false,
    },
    vatRegularisationExercicePrecedent: {
      title: 'Régularisation de TVA sur l’exercice précédent',
      availableOnlyForClients: false,
    },
    bncInitializationValidateClosing: {
      title: "Valider l'initialisation",
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        fiscalPeriodId: String(get(closingState, 'fiscal_year', '')),
        minDateToClose: moment(activity.date?.minDate),
      },
      user,
      activity,
    });
  },

  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const { closingState } = await refreshCurrentClosingState({
      declaration: 'BNC_INITIALIZATION',
    });
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
