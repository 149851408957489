<template>
  <GSheet :color="bg" :style="styles" tile>
    <slot />
  </GSheet>
</template>

<script setup>
import { useLayout } from '@georgestech/georges-ui-kit';
import { computed } from 'vue';

const { mainRect } = useLayout();

const props = defineProps({
  bg: { type: String, default: 'transparent' },
  fitContainerWidth: Boolean,
});

const styles = computed(() => ({
  height: `calc(100vh - ${mainRect.value.top}px - ${mainRect.value.bottom}px)`,
  width: props.fitContainerWidth ? '100%' : `calc(100vw - ${mainRect.value.left}px - ${mainRect.value.right}px)`,
}));
</script>
