import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import moment from 'moment';

const getFormattedDate = (date) => (date instanceof Date ? moment(date).format('YYYY-MM-DD') : date);

export { transformParamsForAccountingApi };
function transformParamsForAccountingApi({
  displayAmounts = {},
  displayAttachments,
  displayIngressOutflow,
  deleted,
  displayOnlyVatToBeDefined,
  search,
  selectedAccounts = [],
  selectedCategories = [],
  selectedDates = {},
}) {
  return omitBy(
    {
      period: omitBy(
        {
          startDate: getFormattedDate(selectedDates.start),
          endDate: getFormattedDate(selectedDates.end),
        },
        isNil,
      ),
      accountingAccountNumber: flatMap(selectedCategories, 'id'),
      hasReceipts: get({ with: 'yes', without: 'no' }, displayAttachments),
      vatToBeDefined: displayOnlyVatToBeDefined ? 'yes' : undefined,
      amountInCentsRange: omitBy(
        {
          above: isNil(displayAmounts.from) ? undefined : Math.round(parseFloat(displayAmounts.from) * 100),
          under: isNil(displayAmounts.to) ? undefined : Math.round(parseFloat(displayAmounts.to) * 100),
          displayIncomeExpense: get({ ingress: 'income', outflow: 'expense' }, displayIngressOutflow),
        },
        isNil,
      ),
      fullText: search,
      uiTransactionType: flatMap(selectedAccounts, 'id'),
      transactionIsDeleted: get({ all: 'both', yes: 'yes' }, deleted),
    },
    isEmpty,
  );
}
