import get from 'lodash/get';

import { ONBOARDING_CREATION_QUERY_PARAMS } from '../../onboarding.constants';

export function getCreation({ queryParams } = {}) {
  const creation = get(queryParams, ONBOARDING_CREATION_QUERY_PARAMS.creation.paramName);
  const validValues = Object.values(ONBOARDING_CREATION_QUERY_PARAMS.creation.validValues);
  return validValues.includes(creation) ? creation : undefined;
}

export function getZipCode({ queryParams } = {}) {
  return get(queryParams, ONBOARDING_CREATION_QUERY_PARAMS.zipCode.paramName);
}

export function getCapitalDeposit({ queryParams } = {}) {
  return get(queryParams, ONBOARDING_CREATION_QUERY_PARAMS.capitalDeposit.paramName, false) === 'true';
}
