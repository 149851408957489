const ENTITIES = {
  INVOICE: 'invoice',
  QUOTATION_DRAFT: 'quotation_draft',
  INVOICE_SOLDE_DRAFT: 'invoice_solde_draft',
  IMPORTED_INVOICE: 'imported_invoice',
};

export const FACTURATION = {
  ENTITIES,
};

export const FACTURATION_TAB_ITEMS = {
  INVOICE: {
    value: 'facturation.invoices',
    title: 'Factures',
    to: { name: 'facturation.invoices' },
  },
  QUOTATION: {
    value: 'facturation.quotations',
    title: 'Devis',
    to: { name: 'facturation.quotations' },
  },
  MONTHLY_RETAIL_REPORT: {
    value: 'facturation.monthlyRetailReport',
    title: 'Bilans mensuels',
    to: {
      name: 'facturation.monthlyRetailReport',
    },
  },
};

export const CUSTOMIZATION_FONTS = [
  { value: 'INDY', text: 'Indy' },
  { value: 'ROBOTO', text: 'Roboto' },
  { value: 'PT_SERIF', text: 'PT Serif' },
  { value: 'MONTSERRAT', text: 'Montserrat' },
  { value: 'LORA', text: 'Lora' },
  { value: 'FARSAN', text: 'Farsan' },
  { value: 'CIRCULARSTD', text: 'CircularStd' },
];

export const FACTURATION_PAGE_SIZE = 20;
