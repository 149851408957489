import {
  transformApiFiltersForGeorges,
  transformParamsForApi,
} from '@/modules/transactions/models/transactions.models';
import georgesApi from '@/shared/apis/georges.js';

export {
  changeSubdivisionVat,
  changeTransactionDate,
  getAmountOfTransactionsToCategorize,
  getManualTransactionTypes,
  getMatchingInvoices,
  getMatchingReceipts,
  getTransactionHistory,
  getTransactionsPaginated,
  getTransactionsParams,
  markTransactionAsDeleted,
  patchAccountingLines,
  safelyGetMatchingInvoices,
  safelyGetMatchingReceipts,
  unmarkTransactionAsDeleted,
  updateTransactionAnnotation,
};

async function getTransactionsParams(params) {
  const { data } = await georgesApi({
    url: '/transactions/transactions-list-filters',
    params,
  });
  return { ...data, filters: transformApiFiltersForGeorges(data.filters) };
}

async function safelyGetMatchingInvoices({ transactionIds }) {
  try {
    return await getMatchingInvoices({ transactionIds });
  } catch (error) {
    console.error(error);

    return {
      transactions: [],
      invoices: [],
    };
  }
}

async function getMatchingInvoices(params) {
  const { data } = await georgesApi({
    url: '/transactions/matching-invoices',
    params,
  });
  return data;
}

async function safelyGetMatchingReceipts({ transactionIds }) {
  try {
    return await getMatchingReceipts({ transactionIds });
  } catch (error) {
    console.error(error);

    return {
      transactions: [],
      receipts: [],
    };
  }
}

async function getMatchingReceipts(params) {
  const { data } = await georgesApi({
    url: '/transactions/matching-receipts',
    params,
  });
  return data;
}

async function getAmountOfTransactionsToCategorize(params) {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/transactions/to-categorize-count',
    params,
  });
  return data;
}

async function getTransactionsPaginated(params, page = 1) {
  const { data } = await georgesApi({
    url: '/transactions/transactions-list',
    params: { ...transformParamsForApi(params), page },
  });
  return data;
}

async function changeSubdivisionVat({ transactionId, subdivisionId, tvaRate, tvaIntracom, tvaToBeDefined }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/transactions/${transactionId}/vat`,
    data: {
      subdivisionId,
      tvaRate,
      tvaIntracom,
      tvaToBeDefined,
    },
  });
  return data;
}

async function updateTransactionAnnotation({ transactionId, ...rest }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/transactions/${transactionId}/annotation`,
    data: rest,
  });
  return data;
}

async function markTransactionAsDeleted({ transactionId }) {
  const { data } = await georgesApi({
    method: 'DELETE',
    url: `/transactions/${transactionId}`,
  });
  return data;
}

async function unmarkTransactionAsDeleted({ transactionId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/transactions/${transactionId}/resurrect`,
  });
  return data;
}

async function changeTransactionDate({ transactionId, newDate }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/transactions/${transactionId}/date`,
    data: {
      newDate,
    },
  });
  return data;
}

async function patchAccountingLines({ transactionId }) {
  return georgesApi({
    method: 'POST',
    url: `/transactions/${transactionId}/actions/patch-acls`,
  });
}

async function getManualTransactionTypes() {
  const { data } = await georgesApi({
    url: '/transactions/manual-transaction-types',
  });
  return data;
}

async function getTransactionHistory({ transactionId }) {
  const { data } = await georgesApi({
    url: `/transactions/${transactionId}/history`,
  });
  return data;
}
