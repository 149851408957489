import get from 'lodash/get';
import moment from 'moment';

import { getRetailActivity } from '@/modules/closing/retailActivity/retailActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    monthlySales: {
      title: 'Ventes réalisées sur le mois',
      shortTitle: 'Ventes',
      availableOnlyForClients: false,
    },
    salesCollection: {
      title: 'Encaissements des ventes',
      shortTitle: 'Encaissements',
      availableOnlyForClients: false,
    },
    billedCommissions: {
      title: 'Commissions facturées',
      shortTitle: 'Commissions',
      availableOnlyForClients: false,
    },
    failedSales: {
      title: 'Remboursements, annulations et impayés',
      shortTitle: 'Remboursements',
      availableOnlyForClients: false,
    },
    validateActivity: {
      title: 'Valider le bilan',
      shortTitle: 'Valider',
      availableOnlyForClients: false,
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        period: get(closingState, 'period', ''),
        minDateToClose: moment(activity.date?.minDate),
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getRetailActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
