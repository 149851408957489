import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { CARDS_MODAL_SCREENS } from '@/modules/comptePro/components/Cards/cards.constants';
import { getCards } from '@/modules/comptePro/components/Cards/cards.service';
import {
  getMaintenanceMessage,
  isCompteProDisabled,
  isCompteProInMaintenanceMode,
} from '@/modules/comptePro/comptePro.models';
import { userCanSeeComptePro } from '@/modules/user/product/product.helpers';

export const defaultStateData = {
  lastScaSuccessfulAction: undefined,

  cards: {
    isFetchingCards: true,
    hasFetchingCardsError: false,
    cardsList: [],
    currentModalScreen: {
      name: CARDS_MODAL_SCREENS.CARDS_LIST,
      selectedCard: undefined,
    },
  },
};

export const mutations = {
  setFetchingCards(state, isFetchingCards) {
    state.cards.isFetchingCards = isFetchingCards;
  },
  setFetchingCardsError(state, hasFetchingCardsError) {
    state.cards.hasFetchingCardsError = hasFetchingCardsError;
  },
  setCardsList(state, cardsList) {
    state.cards.cardsList = cardsList;
  },
  setCurrentCardModalScreen(state, { name, selectedCard }) {
    state.cards.currentModalScreen = {
      name,
      selectedCard,
    };
  },
  setLastScaSuccessfulAction(state, lastScaSuccessfulAction) {
    state.lastScaSuccessfulAction = lastScaSuccessfulAction;
  },
  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, defaultStateData);
  },
  resetCardsState(state) {
    state.cards = cloneDeep(defaultStateData.cards);
  },
};

export const getters = {
  hasStartedComptePro: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/currentUser'];

    return get(user, 'comptePro.hasStarted');
  },
  hasStartedCompteProOnboarding: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/currentUser'];

    return get(user, 'comptePro.hasStartedOnboarding');
  },
  canSeeComptePro: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/currentUser'];

    return userCanSeeComptePro({ user });
  },
  isDisabled: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/currentUser'];
    const userStatus = get(user, 'comptePro.userStatus');

    return isCompteProDisabled({
      userStatus: userStatus?.status,
      maintenanceLevel: userStatus?.maintenance?.level,
    });
  },
  isInMaintenanceMode: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/currentUser'];
    const userStatus = get(user, 'comptePro.userStatus');

    return isCompteProInMaintenanceMode({
      maintenanceLevel: userStatus?.maintenance?.level,
    });
  },
  maintenanceMessage: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/currentUser'];
    const userStatus = get(user, 'comptePro.userStatus');

    return getMaintenanceMessage({ maintenance: userStatus?.maintenance });
  },

  isFetchingCards: ({ cards }) => cards.isFetchingCards,
  hasFetchingCardsError: ({ cards }) => cards.hasFetchingCardsError,
  cardsList: ({ cards }) => cards.cardsList,
  hasCard: (_, { cardsList }) => cardsList.length > 0,
  currentCardModalScreenName: ({ cards }) => cards.currentModalScreen.name,
  currentCardModalScreenSelectedCard: ({ cards }) => cards.currentModalScreen.selectedCard,
  lastScaSuccessfulAction: ({ lastScaSuccessfulAction }) => lastScaSuccessfulAction,
  userCompteProStatus: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/currentUser'];

    return get(user, 'comptePro.userStatus.status');
  },
};

export const actions = {
  async fetchCards({ commit }) {
    commit('setFetchingCards', true);

    try {
      const { cards } = await getCards();
      commit('setCardsList', cards);

      commit('setFetchingCardsError', false);
    } catch (error) {
      commit('setFetchingCardsError', true);
    } finally {
      commit('setFetchingCards', false);
    }
  },
  goToFirstCardModalScreen({ commit }, { lastScaSuccessfulAction } = {}) {
    commit('setCurrentCardModalScreen', {
      name: CARDS_MODAL_SCREENS.CARDS_LIST,
      selectedCard: undefined,
    });

    if (lastScaSuccessfulAction) {
      commit('setLastScaSuccessfulAction', lastScaSuccessfulAction);
    }
  },
};

export default {
  namespaced: true,
  state: defaultStateData,
  mutations,
  getters,
  actions,
};
