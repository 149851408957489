import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';
import get from 'lodash/get';

import { RELEASE } from '@/shared/constants/version';

const { config, services } = get(window, 'GEORGES_SETTINGS.datadog', {});

const loggerFactory = () => {
  let logger = console;
  if (services?.logs?.enable && services?.logs?.config) {
    datadogLogs.init({
      ...config,
      ...services.logs.config,
      version: RELEASE,
      beforeSend: (log) => {
        if (log.err) {
          delete log.err.config;
          delete log.err.request;
          delete log.err.response;
          delete log.err.toJSON;
        }
        if (log.error) {
          delete log.error.config;
          delete log.error.request;
          delete log.error.response;
          delete log.error.toJSON;
        }
        return true;
      },
    });
    logger = datadogLogs.logger;
  }

  if (services?.rum?.enable && services?.rum?.config) {
    datadogRum.init({
      ...config,
      ...services?.rum?.config,
      version: RELEASE,
    });
  }

  return Object.fromEntries(
    ['log', 'info', 'warn', 'error'].map((level) => [
      level,
      ({ userId, msg, args }) => {
        try {
          logger[level](msg, {
            userId,
            ...args,
          });
        } catch (err) {
          console.warn('Could not log...', args, err);
        }
      },
    ]),
  );
};

export const logger = loggerFactory();

export const routerLogMiddleware = async ({ to, from, next }, { store }) => {
  if (!store.state.auth.authUser) {
    next();
    return;
  }
  const { _id: userId } = store.state.user.currentUser || (await store.dispatch('user/fetchUser'));
  try {
    logger.log({
      userId,
      msg: '[router] entering route',
      args: {
        to: { name: get(to, 'name'), path: get(to, 'path') },
        from: { name: get(from, 'name'), path: get(from, 'path') },
      },
    });
  } catch (error) {
    console.error('router log', error);
  }
  next();
};

export function onUserLogin({ userId, authUserId }) {
  try {
    setGlobalContextProperty('authUserId', authUserId);
    setGlobalContextProperty('userId', userId);
  } catch {}
}

export function onUserLogout() {
  try {
    removeGlobalContextProperty('authUserId');
    removeGlobalContextProperty('userId');
  } catch {}
}

function setGlobalContextProperty(property, value) {
  datadogLogs && services?.logs?.enable && datadogLogs.setGlobalContextProperty(property, value);
  datadogRum && services?.rum?.enable && datadogRum.setGlobalContextProperty(property, value);
}

function removeGlobalContextProperty(property) {
  datadogLogs && services?.logs?.enable && datadogLogs.removeGlobalContextProperty(property);
  datadogRum && services?.rum?.enable && datadogRum.removeGlobalContextProperty(property);
}

export function getSessionId() {
  try {
    return datadogLogs.getInternalContext().session_id;
  } catch (err) {
    return;
  }
}
