<template>
  <GBottomNavigation :active="visible" variant="outlined">
    <AppMobileNavigationLink
      v-for="link in links"
      :key="link.name"
      :disabled="link.disabled"
      :href="link.href"
      :icon="link.icon"
      :to="link.path"
      :badge="link.badge?.(badgesContext)"
      :is-active="isActiveRoutes(link.isActiveRouteConditions)"
      :on-display="link.onDisplay"
    />
  </GBottomNavigation>
</template>

<script>
import { mapGetters } from 'vuex';

import { isInMobileWebView } from '@/shared/utils/device';
import { isActiveRoute } from '@/shared/utils/routing';

import AppMobileNavigationLink from './AppMobileNavigationLink.vue';
import { generateNavigationLinks, getNavigationBadges } from './navigation.model';

export default {
  name: 'AppMobileNavigation',
  components: {
    AppMobileNavigationLink,
  },

  computed: {
    isMobile() {
      return this.$georgesuikit.breakpoint.isMobile;
    },

    ...mapGetters({
      currentUser: 'user/currentUser',
      userIsClient: 'user/userIsClient',
      activitiesToDoCount: 'newActivities/activitiesToDoCount',
      transactionsToCategorizeCount: 'transactions/transactionsToCategorizeCount',
      isTemporaryCampaignActive: 'referral/isTemporaryCampaignActive',
    }),

    visible() {
      return this.isMobile && !isInMobileWebView();
    },

    links() {
      return generateNavigationLinks({
        isMobile: this.isMobile,
        user: this.currentUser,
      });
    },

    badgesContext() {
      return getNavigationBadges({
        transactionsToCategorizeCount: this.transactionsToCategorizeCount,
        activitiesToDoCount: this.activitiesToDoCount,
        shouldShowNotificationBadge: this.shouldShowNotificationBadge,
        user: this.currentUser,
      });
    },

    shouldShowNotificationBadge() {
      if (this.isActiveRoute('/votre-compte')) return false;

      const shouldShowSubscriptionNotif = !this.userIsClient;
      const shouldShowCampaignNotif = this.userIsClient && this.isTemporaryCampaignActive;
      return shouldShowSubscriptionNotif || shouldShowCampaignNotif;
    },
  },
  methods: {
    isActiveRoutes(conditions) {
      if (!conditions) return false;
      return conditions.some(({ path, options }) => this.isActiveRoute(path, options));
    },
    isActiveRoute,
  },
};
</script>
