import get from 'lodash/get';

import { getSciIsAccountingInitialisationActivity } from '@/modules/closing/sciIsAccountingInitialisationActivity/sciIsAccountingInitialisationActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
import { getDecoratedClosingSteps } from '@/modules/newActivities/models/sciIsAccountingInitialisationActivity/sciIsAccountingInitialisationDecoration';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    uploadLastFiscalYearDocuments: {
      title: 'Télécharger les documents de la dernière clôture',
      shortTitle: 'Télécharger vos documents',
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    sciInformation: {
      title: 'Informations sur votre SCI',
      shortTitle: 'Votre SCI',
      availableOnlyForClients: true,
    },
    initRealEstateAssetsWithAmortization: {
      title: 'Informations sur vos biens',
      shortTitle: 'Vos biens',
      availableOnlyForClients: true,
    },
    initTravauxEtAmortissements: {
      title: 'Installations, aménagements et travaux immobilisés',
      shortTitle: 'Travaux immobilisés',
      availableOnlyForClients: true,
    },
    initAutresImmobilisations: {
      title: 'Informations sur vos immobilisations autres que vos biens immobiliers & travaux',
      shortTitle: 'Autres immobilisations',
      availableOnlyForClients: true,
    },
    initCapitauxPropresIs: {
      title: 'Indiquer les capitaux propres',
      shortTitle: 'Capitaux propres',
      availableOnlyForClients: true,
    },
    associatesInformation: {
      title: 'Informations sur les associés',
      shortTitle: 'Vos associés',
      availableOnlyForClients: true,
    },
    associatesInformationWithCca: {
      title: `Comptes courants des associés`,
      shortTitle: 'Vos associés',
      availableOnlyForClients: true,
    },
    initComptesFinanciers: {
      title: 'Initialiser les comptes financiers',
      shortTitle: 'Soldes financiers',
      availableOnlyForClients: true,
    },
    initEmpruntsEnCours: {
      title: 'Préciser les emprunts en cours',
      shortTitle: 'Emprunts en cours',
      availableOnlyForClients: true,
    },
    initLoyersEnAttente: {
      title: 'Préciser les loyers en attente',
      shortTitle: 'Loyers en attente',
      availableOnlyForClients: true,
    },
    initCreancesEnAttente: {
      title: 'Préciser les revenus en attente',
      shortTitle: 'Revenus en attente',
      availableOnlyForClients: true,
    },
    initAdvanceRentalIncome: {
      title: 'Préciser le montant des loyers perçus en avance',
      shortTitle: 'Loyers perçus en avance',
      availableOnlyForClients: true,
    },
    initRevenusPercusEnAvance: {
      title: 'Préciser le montant des revenus perçus en avance',
      shortTitle: 'Revenus perçus en avance',
      availableOnlyForClients: true,
    },
    initDettesFournisseursIs: {
      title: 'Préciser les dettes envers vos fournisseurs',
      shortTitle: 'Dettes fournisseurs',
      availableOnlyForClients: true,
    },
    initCommandesEnCours: {
      title: 'Préciser les commandes en cours',
      shortTitle: 'Commandes en cours',
      availableOnlyForClients: true,
    },
    initAutresCreancesDettesIs: {
      title: 'Indiquer les autres créances et dettes',
      shortTitle: 'Autres créances et dettes',
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: `Valider l'initialisation`,
      shortTitle: `Valider`,
      availableOnlyForClients: true,
    },
    validationDuBilan: {
      title: `Valider l'initialisation de votre bilan`,
      shortTitle: 'Valider le bilan',
      availableOnlyForClients: true,
      isStepActivatedForAdmin: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
  isInitializedFromDocuments: ({ closingState }) => closingState.isInitializedFromDocuments,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const hasAccountingInitialisationInternalizedFlag = get(
  window,
  'GEORGES_SETTINGS.featureFlags.sci.accountingInitialisationInternalizedFlag',
  false,
);

const actions = {
  async getClosingStateSteps({ state, dispatch, rootGetters }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    const showAdminInfo = rootGetters['user/showAdminInfo'];
    const { isFirstFiscalYearOfActivity } = rootGetters['user/currentFiscalYearConfiguration'];

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: hasAccountingInitialisationInternalizedFlag
        ? getDecoratedClosingSteps({
            steps: get(closingState, 'steps', {}),
            showAdminInfo,
            isFirstFiscalYearOfActivity,
            isInitializedFromDocuments: closingState.isInitializedFromDocuments,
            isActivityDone: Boolean(closingState.doneAt),
          })
        : get(closingState, 'steps', {}),
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getSciIsAccountingInitialisationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
