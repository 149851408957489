import { aa } from '@georges-tech/georges-lib';

import { formatCents } from '@/shared/utils/amounts';

const BALANCE_ACCOUNTS_DESCRIPTIONS = {
  notDeclaredEnough: (title, amount) => {
    if (amount < 0) {
      return `Vous n'avez pas assez déclaré de ${title} sur vos déclarations aux impôts.
          Une régularisation de ${formatCents(Math.abs(amount))}€ sera réintégrée sur
          votre prochaine déclaration de TVA.`;
    }
    if (amount > 0) {
      return `Vous avez déclaré trop de ${title} sur vos déclarations aux impôts.
          Une régularisation de ${formatCents(amount)}€ sera appliquée en déduction sur
          votre prochaine déclaration de TVA.`;
    }
    return `Parfait, votre compte de ${title} est à l'équilibre !`;
  },
  tooMuchDeclared: (title, amount) => {
    if (amount < 0) {
      return `Vous avez déduit trop de ${title} sur vos déclarations aux impôts.
          Une régularisation de ${formatCents(Math.abs(amount))}€ sera réintégrée
          sur votre prochaine déclaration de TVA.`;
    }
    if (amount > 0) {
      return `Vous n'avez pas assez déduit de ${title} sur vos déclarations aux impôts.
          Une régularisation de ${formatCents(amount)}€ sera appliquée en déduction
          sur votre prochaine déclaration de TVA.`;
    }
    return `Parfait, votre compte de ${title} est à l'équilibre !`;
  },
};

export const BALANCE_ACCOUNTS = {
  [aa.q_445710_tva_collectee]: {
    accountingAccount: aa.q_445710_tva_collectee,
    title: 'TVA collectée',
    order: 1,
    style: 'warning',
    icon: 'fal fa-balance-scale-right',
    descriptionFunction: BALANCE_ACCOUNTS_DESCRIPTIONS.notDeclaredEnough,
  },
  [aa.q_445610_tva_deductible]: {
    accountingAccount: aa.q_445610_tva_deductible,
    title: 'TVA déductible',
    order: 2,
    style: 'error',
    icon: 'fal fa-balance-scale-left',
    descriptionFunction: BALANCE_ACCOUNTS_DESCRIPTIONS.tooMuchDeclared,
  },
  [aa.q_445620_tva_sur_immobilisation]: {
    accountingAccount: aa.q_445620_tva_sur_immobilisation,
    title: 'TVA sur immobilisation',
    order: 3,
    style: 'primary',
    icon: 'far fa-building',
    descriptionFunction: BALANCE_ACCOUNTS_DESCRIPTIONS.tooMuchDeclared,
  },
  [aa.q_445775_tva_sur_cession_d_immo]: {
    accountingAccount: aa.q_445775_tva_sur_cession_d_immo,
    title: "TVA sur cession d'immobilisation",
    order: 4,
    style: 'dark',
    icon: 'fas fa-hand-holding-usd',
    descriptionFunction: BALANCE_ACCOUNTS_DESCRIPTIONS.notDeclaredEnough,
  },
  [aa.q_445210_tva_intracom_ue]: {
    accountingAccount: aa.q_445210_tva_intracom_ue,
    title: 'TVA intracommunautaire UE',
    order: 5,
    style: 'success',
    icon: 'fas fa-euro-sign',
    descriptionFunction: BALANCE_ACCOUNTS_DESCRIPTIONS.notDeclaredEnough,
  },
  [aa.q_445220_tva_intracom_hors_ue]: {
    accountingAccount: aa.q_445220_tva_intracom_hors_ue,
    title: 'TVA hors UE',
    order: 6,
    style: 'primary background-opacity-50',
    icon: 'fas fa-globe-americas',
    descriptionFunction: BALANCE_ACCOUNTS_DESCRIPTIONS.notDeclaredEnough,
  },
};

export const CLOSING_STEP_KEY = 'vatCompliance';

export const VAT_REGULARISATION_STEP_STATUS = {
  ALL_DECLARATIONS_DONE: 'ALL_DECLARATIONS_DONE',
  STEP_SHOULD_NOT_BE_DISPLAYED: 'STEP_SHOULD_NOT_BE_DISPLAYED',
  MISSING_LAST_EXERCISE_DECLARATIONS: 'MISSING_LAST_EXERCISE_DECLARATIONS',
  MISSING_FRANCHISE_CONFIGURATION_ON_EXERCISE_BEGINNING: 'MISSING_FRANCHISE_CONFIGURATION_ON_EXERCISE_BEGINNING',
  BAD_EXERCISE_FISCAL_DATE: 'BAD_EXERCISE_FISCAL_DATE',
  INCORRECT_OD: 'INCORRECT_OD',
  UNPROCESSABLE: 'UNPROCESSABLE',
};
