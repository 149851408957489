export function isRecordLikeObject(source) {
  return typeof source === 'object' && !Array.isArray(source) && source !== null;
}

export function mapObjectValues(source, fct, recursive = true) {
  return Object.fromEntries(
    Object.entries(source).map(([key, value]) => {
      if (isRecordLikeObject(value)) {
        if (recursive) {
          value = mapObjectValues(value, fct, recursive);
        }
      } else {
        value = fct(value);
      }
      return [key, value];
    }),
  );
}

export function replaceUndefinedByNull(obj) {
  return replaceBy(obj, undefined, null);
}

export function replaceBy(target, from, to) {
  //TODO: should be typed
  if (target === from) {
    return to;
  }

  if (target === null || typeof target !== 'object') {
    return target;
  }

  const entries = Object.entries(target);
  if (entries.length === 0) {
    return target;
  }

  return entries.reduce((acc, [key, value]) => {
    if (value === from) {
      return { ...acc, [key]: to };
    }

    if (Array.isArray(value)) {
      return {
        ...acc,
        [key]: value.map((value) => replaceBy(value, from, to)),
      };
    }

    if (typeof value === 'object') {
      return { ...acc, [key]: replaceBy(value, from, to) };
    }

    return { ...acc, [key]: value };
  }, {});
}
