<template>
  <GBannerHeader color="warning" variant="tonal" @click:close="hideBanner" v-if="isVisible" @click="learnMore">
    <div class="d-flex space-x-8">
      <div v-if="!isMobile">
        <GLazyImg src="/img/banner/Stars.svg" width="24"></GLazyImg>
      </div>
      <div>
        <span
          ><b>Parrainage boosté - {{ remainingDaysWording }}</b></span
        ><br v-if="isMobile" /><span v-else>&nbsp;</span>
        <span><u>3 mois </u>offerts pour vous et votre filleul !</span>
      </div>
    </div>
  </GBannerHeader>
</template>

<script setup>
// Services
import { useDisplay } from '@georgestech/georges-ui-kit';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { REFERRAL_CAMPAIGN_TYPES } from '@/modules/referral/constants/referral.constants';
import { trackReferralEvent } from '@/modules/referral/referral.composable';
import { getRemainingBoostedReferralDays } from '@/modules/referral/shared/ReferralBoostedBanner/referralBoostedBanner.model';

const router = useRouter();
const store = useStore();
const { isMobile } = useDisplay();
const isDismissed = ref(false);

const hasActiveSubscription = computed(() => store.getters['user/hasActiveSubscription']);
const currentReferralCampaignType = computed(() => store.getters['referral/currentReferralCampaignType']);
const promocode = computed(() => store.getters['referral/promocode']);

const remainingDaysWording = computed(() => {
  if (!promocode.value) return;

  return getRemainingBoostedReferralDays({ promocode: promocode.value });
});

const isVisible = computed(() => {
  return (
    hasActiveSubscription.value &&
    !isDismissed.value &&
    currentReferralCampaignType.value === REFERRAL_CAMPAIGN_TYPES.boosted
  );
});

const hideBanner = () => {
  isDismissed.value = true;
};

const learnMore = () => {
  router.push({ name: 'referral' });
  trackReferralEvent({ action: 'Click go to referral page (from boosted referral banner)' });
};
</script>
