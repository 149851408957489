export default [
  {
    path: '/accompagnement',
    name: 'support',
    component: () => import('@/modules/support/Support.vue'),
    meta: {
      title: 'Accompagnement',
      requiresAuth: true,
      parent: {
        title: 'Paramètres',
        link: 'userSettingsNav',
      },
    },
  },
];
