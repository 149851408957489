export default [
  {
    path: '/connexion',
    name: 'login',
    component: () => import('@/modules/login/components/Login.vue'),
    meta: {
      title: 'Connexion',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('@/modules/login/components/ForgotPassword.vue'),
    meta: {
      title: 'Mot de passe oublié',
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('@/modules/login/components/ResetPassword.vue'),
    meta: {
      title: 'Reset du mot de passe',
    },
  },
  {
    path: '/mobile-webview-login',
    name: 'mobileWebviewLogin',
    component: () => import('@/modules/login/components/MobileWebviewLogin.vue'),
    meta: {
      title: 'Authentification en cours...',
    },
  },
];
