import georgesApi from '@/shared/apis/georges.js';

export { getCoupons };

async function getCoupons() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/users/me/coupons',
  });
  return data.coupons;
}
