<template>
  <GCard variant="outlined" :bg="bg" class="flex-1 py-24 px-24 space-y-16">
    <div class="space-y-24">
      <GText variant="headlineS">{{ title }}</GText>

      <div class="space-y-8">
        <!-- Price -->
        <div class="d-flex space-x-4">
          <GText weight="bold" style="font-size: 40px; line-height: 32px">{{ priceHt }}</GText>
          <GText variant="bodyL">€ HT/mois</GText>
        </div>
        <GText variant="labelL">{{ planShortDescription }}</GText>
      </div>
    </div>

    <GButton
      v-if="showButton"
      block
      :variant="buttonVariant"
      :color="buttonColor"
      @click="handleButtonClick"
      :loading="isButtonLoading"
    >
      {{ buttonText }}
    </GButton>
    <slot name="extra-info" />

    <div class="space-y-16">
      <GText variant="bodyS" weight="bold">{{ featuresTitle }}&nbsp;:</GText>

      <FeaturesByLegalForm :color="bg" :features-list="availableFeatures" :unavailable="unavailableFeatures" />
    </div>
  </GCard>
</template>

<script>
import FeaturesByLegalForm from '@/modules/core/subscriptionModals/FeaturesByLegalForm.vue';

export default {
  name: 'PlanCard',
  components: {
    FeaturesByLegalForm,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    priceHt: {
      type: String,
      default: undefined,
    },
    bg: {
      type: String,
      default: 'surface',
    },
    buttonText: {
      type: String,
      default: 'Démarrer',
    },
    buttonColor: {
      type: String,
      optional: true,
    },
    buttonVariant: {
      type: String,
    },
    buttonAction: {
      type: Function,
      optional: true,
    },
    planPriceHT: {
      type: Number,
      default: undefined,
    },
    planShortDescription: {
      type: String,
    },
    featuresTitle: {
      type: String,
      default: 'Inclus',
    },
    availableFeatures: {
      type: Array,
      default: () => [],
    },
    unavailableFeatures: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isButtonLoading: false,
  }),
  computed: {
    showButton() {
      return this.buttonAction !== undefined && this.buttonText !== undefined;
    },
  },
  methods: {
    async handleButtonClick() {
      this.isButtonLoading = true;

      try {
        await this.buttonAction();
      } catch (error) {}

      this.isButtonLoading = false;
    },
  },
};
</script>
