import get from 'lodash/get';
import moment from 'moment';

import { getIsProcesVerbalAssembleeGeneraleActivity } from '@/modules/closing/sciIsProcesVerbalAssembleeGeneraleActivity/sciIsProcesVerbalAssembleeGeneraleActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    agInformation: {
      title: `Information sur l'AG`,
      shortTitle: `Information sur l'AG`,
      availableOnlyForClients: true,
    },
    assignResultIs: {
      title: ({ fiscalPeriodId }) => `Affectation du résultat de l'exercice ${Number(fiscalPeriodId) - 1}`,
      shortTitle: 'Affectation du résultat',
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: 'Valider et télécharger les documents',
      shortTitle: 'Valider le procès verbal',
      availableOnlyForClients: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];
    const showAdminInfo = rootGetters['user/showAdminInfo'];

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        minDateToClose: moment(activity.date?.minDate),
        fiscalPeriodId: String(userCurrentFiscalYear),
        isInEarlyClosing: showAdminInfo,
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getIsProcesVerbalAssembleeGeneraleActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
