import omit from 'lodash/omit';

export default function ({ to, next }, { store }) {
  if (to.query.mode === 'verifyEmail') {
    store.dispatch('auth/verifyEmail', { oobCode: to.query.oobCode }, { root: true });
    next({ ...to, query: omit(to.query, ['mode', 'oobCode']) });
  }

  next();
}
