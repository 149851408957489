import georgesPublicApi from '@/shared/apis/georges.public.js';

import { signInWithFirebaseWithCustomToken } from './firebase.services.js';
import { hashPassword } from './password.helpers.js';

export async function signInWithIndy({ email, password, turnstileToken, mfaVerifyPayload }) {
  // Password is already hashed and safely stored by Firebase,
  // but this client side hash is made for retro compatibility
  // purpose with our old authentification system
  const hashedPassword = hashPassword({ password });
  const { token } = await authenticateOnIndy({ email, hashedPassword, turnstileToken, mfaVerifyPayload });
  await signInWithFirebaseWithCustomToken({ token });
}

/**
 * @private
 *
 * Verify user credentials through our backend for more controls like 2FA
 * Returns a custom token to be used with Firebase
 */
async function authenticateOnIndy({ email, hashedPassword, turnstileToken, mfaVerifyPayload }) {
  const { data } = await georgesPublicApi({
    method: 'POST',
    url: '/auth/login',
    data: {
      email,
      password: hashedPassword,
      turnstileToken,
      mfaVerifyPayload,
    },
  });

  return data;
}

export async function getCustomTokenFromAutoLoginCode({ autoLoginCode }) {
  const { data } = await georgesPublicApi({
    method: 'POST',
    url: '/auth/verify-auto-login-code',
    data: {
      code: autoLoginCode,
    },
  });

  return data.token;
}
