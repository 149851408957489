<template>
  <GCard>
    <GCardTitle class="text-break">{{ title }}</GCardTitle>
    <GDivider />
    <GCardContent>
      <slot />
    </GCardContent>
    <GDivider />
    <GCardActions>
      <GSpacer />
      <template v-if="!hasActionsSlot">
        <GButton variant="text" @click="$emit('close')">{{ cancelButtonText }}</GButton>
        <GButton color="primary" :loading="validateButtonLoading" @click="$emit('validate')">
          {{ validateButtonText }}
        </GButton>
      </template>

      <slot v-if="hasActionsSlot" name="actions" />
    </GCardActions>
  </GCard>
</template>

<script>
export default {
  name: 'ActionDialog',
  emits: ['close', 'validate'],
  props: {
    title: { type: String, default: '' },
    cancelButtonText: { type: String, default: 'Annuler' },
    validateButtonText: { type: String, default: 'Valider' },
    validateButtonLoading: { type: Boolean },
  },
  computed: {
    hasActionsSlot() {
      return Boolean(this.$slots.actions);
    },
  },
};
</script>
