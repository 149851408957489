import fuzzysort from 'fuzzysort';
import deburr from 'lodash/deburr';
import isEmpty from 'lodash/isEmpty';

// Closer to 0 is a better match
// -200000 is very permissive
// -10000 is very restrictive
const MATCH_THRESHOLD = -100000;

export function accountingAccountMatchesSearch(accountingAccountKeywords, searchTerm) {
  if (!accountingAccountKeywords || !searchTerm || searchTerm.length <= 1) return false;

  // --- Look for exact match
  const indexesWithExactResult = searchForExactMatch(accountingAccountKeywords, searchTerm);
  if (indexesWithExactResult) {
    return {
      match: true,
      exact: true,
      highlightedIndexes: indexesWithExactResult,
    };
  }

  // --- Look for fuzzy match
  const indexesWithFuzzy = searchWithFuzzy(accountingAccountKeywords, searchTerm);
  if (!indexesWithFuzzy) return;

  return {
    match: true,
    highlightedIndexes: indexesWithFuzzy,
  };
}

function searchForExactMatch(accountingAccountKeywords, searchTerm) {
  const searchableTerm = makeSearchable(searchTerm);
  const indexes = getMatchIndexes(accountingAccountKeywords, searchableTerm);

  if (isEmpty(indexes)) return false;

  return indexes;
}

function searchWithFuzzy(accountingAccountKeywords, searchTerm) {
  const result = fuzzysort.single(makeSearchable(searchTerm), accountingAccountKeywords);
  const hasResult = result && result.score > MATCH_THRESHOLD;

  if (!hasResult) return false;
  return fuzzysort.indexes(result);
}

function getMatchIndexes(text, searchTerm) {
  const indexes = [];
  let match;
  let i = 0;

  while ((match = makeSearchable(text).indexOf(searchTerm, i)) > -1) {
    for (let j = match; j < match + searchTerm.length; j++) {
      indexes.push(j);
    }
    i = match + searchTerm.length;
  }

  return indexes;
}

function makeSearchable(string = '') {
  return deburr(string.toLowerCase());
}
