import cloneDeep from 'lodash/cloneDeep';
import { createStore } from 'vuex';

// Stores
import auth from '@/modules/auth/auth.store';
import bankAccount from '@/modules/bankConnector/bankAccount/bankAccount.store';
import bankConnector from '@/modules/bankConnector/bankConnector.store';
import categories from '@/modules/categories/categories.store';
import regularisationTva from '@/modules/closing/shared/sharedRegularisationTva/regularisationTva.store';
import comptePro from '@/modules/comptePro/comptePro.store';
import dashboard from '@/modules/dashboard/dashboard.store';
import documents from '@/modules/documents/documents.store';
import facturation from '@/modules/facturation/facturation.store';
import newActivities from '@/modules/newActivities/stores';
import onboarding from '@/modules/onboarding/onboarding.store';
import referral from '@/modules/referral/referral.store';
import settings from '@/modules/settings/settings.store';
import transactions from '@/modules/transactions/transactions.store';
import user from '@/modules/user/user.store';
import layout from '@/shared/store/layout.store';
import { APP_STATES } from '@/shared/store/store.constants';

// Vuex mutates the store options object we give him when instanciating a new Store.
// Therefore, we cannot re-use the same object when instanciating multiple Stores,
// which notably occurs in tests. Hence, this factory with cloneDeep.
export const getStoreOptions = () =>
  cloneDeep({
    state: {
      app: {
        isInitialized: false,
        status: "Initialisation de l'application",
      },
    },
    mutations: {
      updateAppState(state, { isInitialized = false, status } = {}) {
        state.app = {
          ...state.app,
          isInitialized,
          status,
        };
      },
    },
    getters: {
      isInitialized(state) {
        return state.app.isInitialized;
      },
      currentAppStatus(state) {
        return state.app.status;
      },
    },
    actions: {
      async initUserContext({ commit, dispatch }, routeContext) {
        commit('updateAppState', APP_STATES.fetchAuthUser);
        await dispatch('auth/init', routeContext);

        try {
          commit('updateAppState', APP_STATES.fetchUserData);
          await dispatch('user/initUser');
        } catch (error) {}

        commit('updateAppState', APP_STATES.initialized);
      },
      /**
       * Reset all modules to default
       * When imitating someone, modules should be reset
       */
      resetStore({ commit, dispatch }) {
        dispatch('newActivities/resetStateData');

        commit('bankAccount/resetStateData');
        commit('bankConnector/resetStateData');
        commit('categories/resetStateData');
        commit('dashboard/resetStateData');
        commit('documents/resetStateData');
        commit('layout/resetStateData');
        commit('onboarding/resetStateData');
        commit('settings/resetStateData');
        commit('transactions/resetStateData');
        commit('user/resetStateData');
        commit('facturation/resetStateData');
        commit('referral/resetStateData');
        commit('comptePro/resetStateData');
      },
    },
    modules: {
      auth,
      bankAccount,
      bankConnector,
      categories,
      dashboard,
      documents,
      layout,
      newActivities,
      onboarding,
      regularisationTva,
      settings,
      transactions,
      user,
      facturation,
      referral,
      comptePro,
    },
  });

const store = createStore(getStoreOptions());

export function useVuex({ app }) {
  app.use(store);
  return store;
}

export default store;
