<template>
  <GTable variant="outlined" with-bg-header bordered text-align="left">
    <slot />
  </GTable>
</template>

<script>
/*
  This table is mainly used in our document pages (OGBNC, VAT, DAS2, ....)
*/
export default {
  name: 'AppTableBordered',
};
</script>
