import get from 'lodash/get';

import { getAccountingInitialisationActivity } from '@/modules/closing/scmAccountingInitialisationActivity/accountingInitialisationActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    scmAssociatesInformation: {
      title: 'Informations sur les associés',
      shortTitle: 'Vos associés',
      availableOnlyForClients: false,
    },
    scmSharesHistory: {
      title: 'Parts de la SCM',
      shortTitle: 'Parts de la SCM',
      availableOnlyForClients: false,
    },
    associatesRepartitionRule: {
      title: 'Règle de répartition des charges',
      shortTitle: 'Règle de répartition',
      availableOnlyForClients: false,
    },
    validateScmAccountingInitialisation: {
      title: 'Validation des informations',
      shortTitle: 'Validation',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getAccountingInitialisationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
