import get from 'lodash/get';

export const REFERRAL_CAMPAIGN_TYPES = {
  classic: 'classic',
  boosted: 'boosted',
  temporary: 'temporary',
  ambassador: 'ambassador',
  freemium1: 'freemium1',
  freemium2: 'freemium2',
  freemium3: 'freemium3',
};

export const REFERRAL_CAMPAIGN_BY_TAG = {
  ref: REFERRAL_CAMPAIGN_TYPES.classic,
  'new-client-referral-boost-campaign': REFERRAL_CAMPAIGN_TYPES.boosted,
  ambassador: REFERRAL_CAMPAIGN_TYPES.ambassador,
  FREEMIUM_REFERRAL_CAMPAIGN_1: REFERRAL_CAMPAIGN_TYPES.freemium1,
  FREEMIUM_REFERRAL_CAMPAIGN_2: REFERRAL_CAMPAIGN_TYPES.freemium2,
  FREEMIUM_REFERRAL_CAMPAIGN_3: REFERRAL_CAMPAIGN_TYPES.freemium3,
};

export const REFERRAL_CGU_URL = get(
  window,
  'GEORGES_SETTINGS.referral.cguUrl.classic',
  'https://georges-public-assets.s3.eu-west-1.amazonaws.com/cgu/Conditions%20ge%CC%81ne%CC%81rales_CG%20Parrainage.pdf',
);

export const AMBASSADOR_ELIGIBILITY_STATUS = {
  eligible: 'eligible',
  notEligible: 'not_eligible',
  alreadyAmbassador: 'already_ambassador',
};

export const AMBASSADOR_CGU_URL = get(
  window,
  'GEORGES_SETTINGS.referral.cguUrl.ambassador',
  'https://georges-public-assets.s3.eu-west-1.amazonaws.com/cgu/Conditions%20ge%CC%81ne%CC%81rales_CG%20Ambassadeur.pdf',
);

export const AMBASSADOR_PROGRAM_ENABLED = get(
  window,
  'GEORGES_SETTINGS.featureFlags.ambassador.isAmbassadorProgramEnabled',
  false,
);
