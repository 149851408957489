export const stateData = {
  opened: false,
  activity: undefined,
  selectedStepKey: undefined,
  shouldTriggerReviewChecks: false,
  helpKey: undefined,
};

export const getters = {
  opened: ({ opened }) => opened,
  activity: ({ activity }) => activity,
  selectedStepKey: ({ selectedStepKey }) => selectedStepKey,
  shouldTriggerReviewChecks: ({ shouldTriggerReviewChecks }) => shouldTriggerReviewChecks,
  helpKey: ({ helpKey }) => helpKey,
};

export const mutations = {
  setOpened: (state, opened) => {
    state.opened = opened;
  },
  setActivity: (state, activity) => {
    state.activity = activity;
  },
  setSelectedStepKey: (state, selectedStepKey) => {
    state.selectedStepKey = selectedStepKey;
  },
  resetStateData(state) {
    Object.assign(state, stateData);
  },
  setShouldTriggerReviewChecks: (state, { shouldTriggerReviewChecks }) => {
    state.shouldTriggerReviewChecks = shouldTriggerReviewChecks;
  },
  setHelpKey: (state, { helpKey }) => {
    state.helpKey = helpKey;
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
};
