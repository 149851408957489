export const VAT_SYNC_STATE = {
  SYNCED: 'SYNCED',
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING',
  NOT_STARTED: 'NOT_STARTED',
  SYNC_SKIP_AVAILABLE: 'SYNC_SKIP_AVAILABLE',
};

export const VAT_SYNC_ERROR_CODES = {
  WRONG_SIREN: 'WRONG_SIREN',
  MISSING_FISCAL_ACCOUNT_ERROR: 'MISSING_FISCAL_ACCOUNT_ERROR',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  UNKNOWN: 'UNKNOWN',
};
