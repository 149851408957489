export const TRANSACTIONS_MAX_TO_SHOW = 800;

export const FILTERS_KEY = {
  date: 'date',
  deleted: 'deleted',
  displayAmounts: 'displayAmounts',
  displayAttachments: 'displayAttachments',
  displayIngressOutflow: 'displayIngressOutflow',
  displayOnlyVatToBeDefined: 'displayOnlyVatToBeDefined',
  search: 'search',
  selectedCategories: 'selectedCategories',
};

export const TRANSACTIONS_PARAMS_QUERY = {
  displayAmounts: { from: undefined, to: undefined },
  displayAttachments: undefined, // undefined / 'with' / 'without'
  displayIngressOutflow: undefined,
  deleted: undefined,
  displayOnlyVatToBeDefined: false,
  focus: undefined,
  search: '',
  selectedAccounts: [],
  selectedCategories: [],
  selectedDates: undefined,
};

export const TRANSACTIONS_FILTERS = {
  accountOrFroms: [],
  categories: [],
  allowedDates: {},
};

export const ADD_NEW_TRANSACTION_BUTTON_TITLE_MAP = {
  cashIncome: 'Recette en espèces',
  cashExpense: 'Dépense en espèces',
  professionalIncome: 'Autre recette professionnelle',
  professionalExpense: 'Autre dépense professionnelle',
  recetteSurComptePersonnel: 'Recette sur compte personnel',
  noteDeFrais: 'Note de frais',
};

export const TRANSACTION_CREATE_FORM_TITLE_MAP = {
  cashIncome: `Ajout d'une recette en espèces`,
  cashExpense: `Ajout d'une dépense en espèces`,
  professionalIncome: `Ajout d'une recette professionnelle`,
  professionalExpense: `Ajout d'une dépense professionnelle`,
  recetteSurComptePersonnel: `Ajout d'une recette sur compte personnel`,
  noteDeFrais: `Ajout d'une note de frais`,
};

export const TRANSACTION_EDIT_FORM_TITLE_MAP = {
  cashIncome: `Modification d'une recette en espèces`,
  cashExpense: `Modification d'une dépense en espèces`,
  professionalIncome: `Modification d'une recette professionnelle`,
  professionalExpense: `Modification d'une dépense professionnelle`,
  recetteSurComptePersonnel: `Modification d'une recette sur compte personnel`,
  noteDeFrais: `Modification d'une note de frais`,
};

export const CLOSING_STEPS_FROMS = [
  'closingStepMakeAssetAccounting.amortization', // Closing Step 2035
  'closingStepMakeAssetAccounting.somptuaryReintegration', // Closing Step 2035
  'closingStepMakeAssetAccounting.personalReintegration', // Closing Step 2035
  'closingStepMakeAssetAccounting.kilometerOptionReintegration', // Closing Step 2035
  'closingStepMakeAssetAccounting.claimOnAssetSold', // Closing Step 2035
  'closingStepMakeAssetAccounting.assetExit', // Closing Step 2035
  'closingStepMakeAssetAccounting.pvltPersonalReintegration', // Closing Step 2035
  'closingStepMakeAssetAccounting.pvltExemption', // Closing Step 2035
  'closingStepMakeAssetAccounting.pvctPersonalReintegration', // Closing Step 2035
  'closingStepMakeAssetAccounting.pvctExemption', // Closing Step 2035
  'closingStepMakeAssetAccounting.mvltPersonalReintegration', // Closing Step 2035
  'closingStepMakeAssetAccounting.mvctPersonalReintegration', // Closing Step 2035
  'closingStepMakeAssetAccounting.gainLossAffectation', // Closing Step 2035
  'closingStep2036', // Closing Step 2035
  'closingStepContribution', // Closing Step 2035
  'closingStepLateCheques', // Closing Step 2035
  'closingStepKilometerOption', // Closing Step 2035
  'closingStepUrssaf', // Closing Step 2035
  'closingStepLaundry', // Closing Step 2035
  'closingStepInitializeFinancialAccounts', // Closing Step 2035
  'closingStepExemptions', // Closing Step 2035
  'closingStepCashVariation', // Closing Step 2035
  'closingStepAccountingCosts', // Closing Step 2035
  'vatCreditRefundDemand', // Closing Step CA3 / CA12
  'vatSumsToAdd', // Closing Step CA3 / CA12
  'closingStepValidationDuBilan', // Closing Step BIC IS Initialisation du Bilan
  'closingStepValidationDuBilan.extourne', // Closing Step BIC IS Initialisation du Bilan
  'closingStepCapitauxPropres', // Closing Step BIC IS Fiscal Declaration
  'closingStepImpotsEtTaxes', // Closing Step BIC IS Fiscal Declaration
  'closingStepCreancesClients', // Closing Step BIC IS Fiscal Declaration
  'closingStepDettesFournisseurs', // Closing Step BIC IS Fiscal Declaration
  'closingStepCommandesEnCours', // Closing Step BIC IS Fiscal Declaration
  'closingStepPrestationsEnCours', // Closing Step BIC IS Fiscal Declaration
  'closingStepRemunerationsEtCotisationsGerant', // Closing Step BIC IS Fiscal Declaration
  'closingStepRemunerationsEtCotisationsGerant.remunerations', // Closing Step BIC IS Fiscal Declaration
  'closingStepRemunerationsEtCotisationsGerant.cotisations', // Closing Step BIC IS Fiscal Declaration
  'closingStepRemunerationsEtCotisationsSalaries', // Closing Step BIC IS Fiscal Declaration
  'closingStepValidationClotureBic.impot', // Closing Step BIC IS Fiscal Declaration
  'closingStepValidationClotureBic.resultat', // Closing Step BIC IS Fiscal Declaration
  'closingStepValidationClotureBic.extourneResultat', // Closing Step BIC IS Fiscal Declaration
  'closingStepValidationClotureBic.extourne', // Closing Step BIC IS Fiscal Declaration
  'taxClosing', // fiscal declaration activity
  'taxClosing.aNouveau', // fiscal declaration a nouveau
  'taxClosing.extourne', // fiscal declaration activity extourne
  'taxClosing.step.lateChequesPreviousYear', // Late cheque previous year
  'taxClosing.step.lateChequesThisYear', // Late cheque this year
];

export const ACCOUNT_OR_FROM_CHOICES = {
  BANK_ACCOUNT_CHOICE: ['PDFUpload', 'JSONUpload', 'bankConnector', 'bankAccountSuspension'],
  caisse: ['cashIncome', 'cashExpense'],
  manuel: ['professionalIncome', 'professionalExpense'],
  noteDeFrais: ['professionalExpense'],
  recetteSurComptePersonnel: ['professionalIncome'],
  divers: ['addAsset', 'ANouveaux', ...CLOSING_STEPS_FROMS],
};

export const AA_EXCLUDED_FROM_TOTAL_TRANSACTION = ['513000'];

export const DEFAULT_DESCRIPTION = 'Opération';
