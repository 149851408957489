import georgesApi from '@/shared/apis/georges.js';

export { fetchInvoice, fetchInvoices };

async function fetchInvoices({ invoiceNumberLessThan, limit }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/invoices',
    params: {
      invoiceNumberLessThan,
      limit,
    },
  });
  return data;
}

async function fetchInvoice({ displayNumber }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/invoices/${displayNumber}`,
  });
  return data.invoice;
}
