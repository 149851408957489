// Transform JS object of data in uri parameters
// in: { 'first name': 'George', 'last name': 'Jetson', 'age': 110 };
// out: ?first%20name=George&last%20name=Jetson&age=110

/**
 * Transform JS object of data in uri parameters from
 * ```js
 * { 'first name': 'George', 'last name': 'Jetson', 'age': 110 }`
 * ```
 * to `?first%20name=George&last%20name=Jetson&age=110`
 */
export function encodeQueryData(data) {
  if (!data || !Object.keys(data) || Object.keys(data).length === 0) return '';

  const ret = [];
  for (const key in data) { // eslint-disable-line
    if (key && data[key]) {
      ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
    }
  }

  if (ret.length === 0) return '';

  return `?${ret.join('&')}`;
}

/**
 * Return the URL without query parameters
 * @param {string} url URL to parse, default to window.location.href
 * @returns {(string|undefined)} URL without query parameters or undefined if the URL is not a string
 */
export function getUrlWithoutQueryParams(url = window.location.href) {
  if (!url || typeof url !== 'string') {
    return undefined;
  }

  return url.split('?')[0];
}

/**
 * Redirect to URL
 *
 * @param {string} url URL to redirect to
 */
export function goToUrl(url) {
  // TODO Supprimer ce check lors de la conversion en TS
  if (!url || typeof url !== 'string') {
    return;
  }

  window.location.href = url;
}

/**
 * @param {string} url
 */
export function openUrlInNewTab(url) {
  // TODO Supprimer ce check lors de la conversion en TS
  if (!url || typeof url !== 'string') {
    return;
  }

  window.open(url, '_blank');
}
