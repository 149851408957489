import { defineAsyncComponent, markRaw } from 'vue';

import { getTransactionsTabName } from '@/modules/user/product/product.helpers';

export default [
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/modules/transactions/Transactions.vue'),
    meta: {
      title: ({ user }) => getTransactionsTabName({ user }),
      requiresAuth: true,
      updateIntercom: true,
      searchBar: markRaw(
        defineAsyncComponent(() =>
          import('@/modules/transactions/components/TransactionsSearch/TransactionsSearch.vue'),
        ),
      ),
      actions: {
        search: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/transactions/components/ShowTransactionsFiltersMobile/ShowTransactionsFiltersMobile.vue'),
          ),
        ),
        add: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/transactions/components/AddNewTransactionButton/AddNewTransactionButton.vue'),
          ),
        ),
      },
    },
  },
];
