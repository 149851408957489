import georgesApi from '@/shared/apis/georges';

/**
 * @return {
 *   _id: string;
 *   bank_connector?: {
 *     id: string;
 *     type: string;
 *     bank_auth: { _id: string };
 *   };
 *   bank: {
 *     _id: string;
 *     name: string;
 *     logoUrl?: string;
 *   };
 *   connector_user_account?: { _id: string };
 *   name: string;
 *   balance_in_cents: number;
 *   deferred_debit_card_account?: boolean;
 *   number: number;
 *   suspended?: boolean;
 *   created_at?: Date;
 *   isSynchronizationAllowed: boolean;
 * }[]
 *
 * Le point d'API retourne des objets du type `ApiFormattedBankAccount`, défini côté serveur.
 * Le type décrit ici est un sous-type correspondant aux données utilisées sur le client (au 03/06/2024)
 */
async function fetchBankAccounts({ synchronized } = {}) {
  const { data } = await georgesApi({
    url: '/bank-connector/bank-accounts',
    params: { synchronized, withAvailableBalanceInCents: true },
  });

  return data.bankAccounts;
}

async function getConnectorBankAccounts({ includeDDCs } = {}) {
  const { data } = await georgesApi({
    url: '/bank-connector/bank-accounts/connector-bank-accounts',
    params: { includeDDCs },
  });

  return data.connectorBankAccounts;
}

function deleteBankAccount({ bankAccountId }) {
  return georgesApi({
    method: 'DELETE',
    url: `/bank-connector/bank-accounts/${bankAccountId}`,
  });
}

async function addBankAccount({ connectorType, bankAccountConnectorId, isDDC, connectorUserAccountId }) {
  const { data } = await georgesApi({
    method: 'POST',
    data: {
      connectorType,
      bankAccountConnectorId,
      isDDC,
      connectorUserAccountId,
    },
    url: '/bank-connector/bank-accounts',
  });
  return data;
}

async function fetchBankAccountTransactionsCount({ bankAccountId }) {
  const { data } = await georgesApi({
    url: `/bank-connector/bank-accounts/${bankAccountId}/transactions-count`,
  });

  return data.transactionsCount;
}

async function suspendBankAccount({ bankAccountId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/bank-connector/bank-accounts/${bankAccountId}/suspend`,
  });

  return data.bankAccount;
}

async function restoreBankAccount({ bankAccountId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/bank-connector/bank-accounts/${bankAccountId}/restore`,
  });

  return data.bankAccount;
}

function replaceDDC({ connectorType, bankAccountConnectorId, connectorUserAccountId }) {
  return georgesApi({
    method: 'POST',
    url: `/bank-connector/bank-accounts/ddc`,
    data: {
      connectorType,
      bankAccountConnectorId,
      connectorUserAccountId,
    },
  });
}

export {
  addBankAccount,
  deleteBankAccount,
  fetchBankAccounts,
  fetchBankAccountTransactionsCount,
  getConnectorBankAccounts,
  replaceDDC,
  restoreBankAccount,
  suspendBankAccount,
};
