import georgesApi from '@/shared/apis/georges.js';

export async function isAllowedToEnableFacturation() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/facturation-invoices/is-allowed-to-enable',
  });
  return data.isAllowedToEnable;
}

export async function getCaDeclarationSettingsOptions() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/users/me/settings-options/ca-declaration',
  });
  return data;
}
