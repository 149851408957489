import get from 'lodash/get';
import moment from 'moment';

import { getBicIsInitialisationDuBilanActivity } from '@/modules/closing/bicIsInitialisationDuBilanActivity/bicIsInitialisationDuBilanActivity.service';
import { getDecoratedClosingSteps } from '@/modules/newActivities/models/bicIsInitialisationDuBilanActivity/initBilanDecoration';
// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

export const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    initTelechargerDocumentsDerniereCloture: {
      title: 'Télécharger les documents de la dernière clôture',
      shortTitle: 'Télécharger vos documents',
      availableOnlyForClients: true,
    },
    initImmobilisationsEtCautions: {
      title: 'Indiquer les immobilisations et les cautions',
      shortTitle: 'Immobilisations et cautions',
      availableOnlyForClients: true,
    },
    initCapitauxPropres: {
      title: 'Indiquer les capitaux propres',
      shortTitle: 'Capitaux propres',
      availableOnlyForClients: true,
    },
    initEmpruntsEnCours: {
      title: 'Préciser les emprunts en cours',
      shortTitle: 'Emprunts en cours',
      availableOnlyForClients: true,
    },
    initSoldeCompteCourantAssocie: {
      title: "Comptes d'associés",
      shortTitle: "Comptes d'associés",
      availableOnlyForClients: true,
    },
    initComptesFinanciers: {
      title: 'Initialiser les comptes financiers',
      shortTitle: 'Soldes financiers',
      availableOnlyForClients: true,
    },
    initRemunerationsDues: {
      title: 'Indiquer les rémunérations et cotisations sociales en attente',
      shortTitle: 'Rémunérations et cotisations sociales en attente',
      availableOnlyForClients: true,
    },
    initCreancesDettesEnversEtat: {
      title: 'Indiquer les autres créances et dettes',
      shortTitle: 'Autres créances et dettes',
      availableOnlyForClients: true,
    },
    initSalesAndReceivables: {
      title: 'Ventes et créances',
      shortTitle: 'Ventes et créances',
      availableOnlyForClients: true,
    },
    initStocks: {
      title: 'Inventaire des stocks',
      shortTitle: 'Stocks',
      availableOnlyForClients: true,
    },
    initCreancesClients: {
      title: 'Préciser les créances clients',
      shortTitle: 'Créances clients',
      availableOnlyForClients: true,
    },
    initPrestationsEnCours: {
      title: 'Préciser les prestations en cours',
      shortTitle: 'Prestations en cours',
      availableOnlyForClients: true,
    },
    initDettesEnversFournisseurs: {
      title: 'Préciser les dettes envers vos fournisseurs',
      shortTitle: 'Dettes fournisseurs',
      availableOnlyForClients: true,
    },
    initCommandesEnCours: {
      title: 'Préciser les commandes en cours',
      shortTitle: 'Commandes en cours',
      availableOnlyForClients: true,
    },
    validationDuBilan: {
      title: "Valider la balance d'ouverture",
      shortTitle: 'Balance d’ouverture',
      availableOnlyForClients: true,
      isValidatingStep: true,
      isStepActivatedForAdmin: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    const showAdminInfo = rootGetters['user/showAdminInfo'];
    const isInitializedFromDocuments = activity.other?.isInitializedFromDocuments === true;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: getDecoratedClosingSteps({
        steps: get(closingState, 'steps', {}),
        showAdminInfo,
        isInitializedFromDocuments,
      }),
      stepProperties: {
        closingState,
        isInitializedFromDocuments,
        minDateToClose: moment(activity.date?.minDate),
        fiscalPeriodId: String(userCurrentFiscalYear),
      },
      user,
      activity,
    });
  },

  async refreshCurrent({ commit }) {
    commit('setLoading', true);

    const { closingState } = await getBicIsInitialisationDuBilanActivity();

    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
