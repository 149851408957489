<template>
  <div class="preloader-layout">
    <div class="preloader-layout__wrapper">
      <div class="preloader-layout__logo-wrapper">
        <GLazyImg
          src="https://georges-public-assets.s3-eu-west-1.amazonaws.com/images/indy/logo.svg"
          alt="logo"
          :max-width="180"
          :max-height="180"
          contain
        />
      </div>
      <div class="preloader-layout__status-wrapper">
        <p class="preloader-layout__status">... {{ currentAppStatus }} ...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Preloader',
  computed: mapGetters({
    currentAppStatus: 'currentAppStatus',
  }),
};
</script>

<style scoped lang="scss">
.preloader-layout {
  position: fixed;
  background-color: rgb(var(--g-theme-surface));
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__wrapper {
    position: relative;

    height: 100%;
    width: 100%;

    display: grid;
    grid-template-areas: '. . .' '. logo .' '. status .' '. . .';
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr 150px 80px 1fr;
    grid-gap: $gutter * 2 0;

    @include mq('md') {
      grid-template-rows: 1fr 207px 120px 1fr;
    }
  }

  &__logo {
    display: flex;
    stroke: currentColor;
    stroke-width: 2px;
    fill: transparent;

    &-wrapper {
      position: relative;
      grid-area: logo;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }

  &__status {
    font-weight: bold;
    font-size: 1em;
    text-align: center;

    @include mq('md') {
      font-size: 1.5em;
    }

    &-wrapper {
      grid-area: status;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
}
</style>
