import georgesApi from '@/shared/apis/georges';

const getUrl = (path) => `/activities/das2${path}`;

export async function getDas2DeclarationActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl(''),
  });
  return data;
}

export async function validateDas2DeclarationActivity() {
  await georgesApi({
    method: 'POST',
    url: getUrl('/validation'),
  });
}
