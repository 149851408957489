// import omitBy from 'lodash/omitBy';
import { DECLARATIONS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Services
import {
  fetchClosingState,
  refreshClosingState,
  refreshCurrentClosingState,
} from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: true,
  closingStates: {},
  defaultSteps: {
    categorizeAll: {
      title: 'Catégoriser toutes les transactions',
      shortTitle: 'Catégoriser les transactions',
      availableBeforeEndYear: true,
    },
    bicIsCarFees: {
      title: 'Retraiter les frais de véhicule',
      shortTitle: 'Frais de véhicule',
      availableBeforeEndYear: true,
    },
    checkAssets: {
      title: `Vérifier le registre d'immobilisations`,
      shortTitle: 'Immobilisations',
      availableBeforeEndYear: true,
    },
    checkEcheanceEmprunts: {
      title: `Vérifier les échéances d'emprunt`,
      shortTitle: 'Échéances d’emprunt',
      availableBeforeEndYear: true,
    },
    capital: {
      title: `Capital social`,
      shortTitle: 'Capital',
      availableBeforeEndYear: true,
    },
    kilometerOption: {
      title: `Déduire les indemnités kilométriques`,
      shortTitle: 'Indemnités kilométriques',
      availableOnlyForClients: true,
      description: `Vous avez opté pour le barème kilométrique. Les kilomètres réalisés à titre professionnel ouvrent droit à une déduction forfaitaire selon le barème publié par l'Etat. Complétez les informations ci-dessous permettant de calculer vos indemnités kilométriques :`,
      declarationForKilometricPackageNotPublishedAlert: 'liasse fiscale',
    },
    checkFinancialBalance: {
      title: `Vérifier les soldes financiers`,
      shortTitle: 'Soldes financiers',
      availableOnlyForClients: true,
    },
    cashRegister: {
      title: 'Inventaire de caisse',
      shortTitle: 'Caisse',
      availableOnlyForClients: true,
    },
    checkOnlineSalesBalance: {
      title: `Solde des plateformes e-commerce`,
      shortTitle: `Solde e-commerce`,
      availableOnlyForClients: true,
    },
    stocks: {
      title: `Faire l'inventaire des stocks`,
      shortTitle: 'Stocks',
      availableOnlyForClients: true,
    },
    listCreancesClients: {
      title: `Faire l'inventaire des créances clients`,
      shortTitle: 'Créances clients',
      availableOnlyForClients: true,
    },
    listPrestationsEnCours: {
      title: `Préciser les prestations en cours`,
      shortTitle: 'Prestations en cours',
      availableOnlyForClients: true,
    },
    listDettesFournisseurs: {
      title: `Faire l'inventaire des dettes fournisseurs`,
      shortTitle: 'Dettes fournisseurs',
      availableOnlyForClients: true,
    },
    listCommandesEnCours: {
      title: `Préciser les commandes en cours`,
      shortTitle: 'Commandes en cours',
      availableOnlyForClients: true,
    },
    verifierCapitauxPropres: {
      title: `Affectation du résultat de l'exercice précédent`,
      shortTitle: ({ fiscalPeriodId }) => `Affectation du résultat ${Number(fiscalPeriodId) - 1}`,
      availableOnlyForClients: true,
    },
    verifierRemunerationsEtCotisationsSocialesGerant: {
      title: `Vérifier les rémunérations et les cotisations sociales du gérant`,
      shortTitle: 'Rémunération du gérant',
      availableOnlyForClients: true,
    },
    verifierRemunerationsEtCotisationsSocialesSalaries: {
      title({ user: { isUserInIsSas } }) {
        return isUserInIsSas
          ? `Vérifier les rémunérations et les cotisations sociales`
          : `Vérifier les rémunérations et les cotisations sociales des salariés`;
      },
      shortTitle: 'Rémunération des salariés',
      availableOnlyForClients: true,
    },
    fiscalExemptions: {
      title: `Exonérations fiscales et crédits d'impôt`,
      shortTitle: 'Exonérations et crédits d’impôts',
      availableOnlyForClients: true,
    },
    verifierImpotsEtTaxes: {
      title: `Vérifier les impôts et les taxes divers`,
      shortTitle: 'Impôts et taxes divers',
      availableOnlyForClients: true,
    },
    vatCompliance: {
      title: 'Régularisation de TVA',
      shortTitle: 'Régularisation de TVA',
      availableOnlyForClients: true,
    },
    companyIdentity: {
      title: `Informations relatives à votre société`,
      shortTitle: 'Votre société',
      availableOnlyForClients: true,
    },
    // ici l'etape existe deja (pas besoin d'en creer une)
    validateFiscalDeclaration: {
      title: 'Vérifier et valider la clôture comptable et fiscale',
      shortTitle: 'Valider la clôture',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState:
    ({ closingStates }) =>
    ({ year }) =>
      closingStates[year],
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingStates(state, { year, closingState }) {
    state.closingStates = {
      ...state.closingStates,
      [year]: closingState,
    };
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, getters, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];

    const closingState = getters.getClosingState({ year: userCurrentFiscalYear });

    return generateStepsForAnActivity({
      steps: {
        ...defaultSteps,
      },
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        minDateToClose: moment(activity.date?.minDate),
        fiscalPeriodId: String(userCurrentFiscalYear),
        isInEarlyClosing: get(closingState, 'is_in_early_closing', false),
      },
      user,
      activity,
    });
  },

  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const { closingState } = await refreshCurrentClosingState({
      declaration: DECLARATIONS.D_BIC_IS_FISCAL.KEY,
    });
    commit('setClosingStates', { closingState, year: closingState.fiscal_year });
    commit('setLoading', false);
  },

  async refresh({ commit }, { year }) {
    commit('setLoading', true);
    const { closingState } = await refreshClosingState({
      declaration: DECLARATIONS.D_BIC_IS_FISCAL.KEY,
      fiscalPeriodId: year,
    });
    commit('setClosingStates', { year, closingState });
    commit('setLoading', false);
  },

  async fetch({ commit }, { year }) {
    commit('setLoading', true);
    try {
      const { closingState } = await fetchClosingState({
        declaration: DECLARATIONS.D_BIC_IS_FISCAL.KEY,
        fiscalPeriodId: year,
      });
      commit('setClosingStates', { closingState, year });
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
