import { getDashboardArrangement, getDashboardData } from '@/modules/dashboard/api/dashboard.api';

export const defaultStateData = {
  loading: false,
  resizing: false,
  // -- Period picker
  availablePeriodChoices: {},
  selectedPeriodChoiceId: 'default',
  // -- Active dashboard
  arrangement: undefined,
  // -- Panels shown
  panels: {},
};

export const mutations = {
  setSelectedPeriodChoiceId(state, { selectedPeriodChoiceId }) {
    state.selectedPeriodChoiceId = selectedPeriodChoiceId;
  },
  setDashboardData(state, { availablePeriodChoices, selectedPeriodChoiceId, panels }) {
    state.availablePeriodChoices = availablePeriodChoices;
    state.selectedPeriodChoiceId = selectedPeriodChoiceId;
    state.panels = panels;
  },
  setResizing(state, value) {
    state.resizing = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setDashboardArrangement(state, { arrangement }) {
    state.arrangement = arrangement;
  },
  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, defaultStateData);
  },
};

export const getters = {
  isLoading: ({ loading }) => loading,
  isResizing: ({ resizing }) => resizing,
  // -- Period picker
  getAvailablePeriodChoices: ({ availablePeriodChoices }) => availablePeriodChoices,
  getSelectedPeriodChoiceId: ({ selectedPeriodChoiceId }) => selectedPeriodChoiceId,
  getSelectedPeriodChoice: ({ availablePeriodChoices, selectedPeriodChoiceId }) =>
    availablePeriodChoices[selectedPeriodChoiceId] ?? {},
  // -- Active dashboard
  getArrangement: ({ arrangement }) => arrangement,
  // -- Panels shown
  getPanels: ({ panels }) => panels,
  isActivePanel:
    (state) =>
    ({ panelKey }) =>
      Object.keys(state.panels).includes(panelKey) ?? false,
};

const actions = {
  async initDashboardArrangement({ state, commit }) {
    const { selectedPeriodChoiceId } = state;
    const dashboardArrangement = await getDashboardArrangement({ selectedPeriodChoiceId });
    commit('setDashboardArrangement', { arrangement: dashboardArrangement });
  },
  async initDashboardData({ state, commit }) {
    commit('setLoading', true);
    const { selectedPeriodChoiceId } = state;
    const dashboardData = await getDashboardData({ selectedPeriodChoiceId });
    commit('setDashboardData', dashboardData);
    commit('setLoading', false);
  },
  async setSelectedPeriodChoiceId({ commit }, { selectedPeriodChoiceId }) {
    commit('setLoading', true);
    commit('setSelectedPeriodChoiceId', { selectedPeriodChoiceId });
    const dashboardArrangement = await getDashboardArrangement({ selectedPeriodChoiceId });
    commit('setDashboardArrangement', { arrangement: dashboardArrangement });
    const dashboardData = await getDashboardData({ selectedPeriodChoiceId });
    commit('setDashboardData', dashboardData);
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...defaultStateData },
  mutations,
  getters,
  actions,
};
