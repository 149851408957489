import { prepareDashboardPanels } from '@/modules/dashboard/models/dashboard.panels.model';
import georgesApi from '@/shared/apis/georges.js';

export async function getDashboardArrangement({ selectedPeriodChoiceId }) {
  const { data } = await georgesApi({
    url: `/dashboard/periods/${selectedPeriodChoiceId}/arrangement`,
  });
  return data;
}

export async function getDashboardData({ selectedPeriodChoiceId }) {
  const { data } = await georgesApi({
    url: `/dashboard/periods/${selectedPeriodChoiceId}`,
  });
  return prepareDashboardPanels(data);
}
