<template>
  <div class="d-grid lt-xl:grid-cols-3 xl:grid-flow-col gap-x-8 gap-y-24">
    <div v-for="categoryGroup in computedCategoriesGroups" :key="categoryGroup.key">
      <div class="d-flex align-center space-x-8">
        <GBadge :color="categoryGroup.key" inline dot-large />
        <span class="text-bodyS text-medium">{{ categoryGroup.name }}</span>
      </div>

      <GList>
        <CategoriesLink
          v-for="accountingAccount in categoryGroup.accountingAccounts"
          :key="accountingAccount.number"
          :category-group="categoryGroup"
          :accounting-account="accountingAccount"
          :is-selected="accountingAccount.number === accountingAccountNumber"
          @change-accounting-account="$emit('change', $event)"
        />
      </GList>
    </div>
  </div>
</template>

<script>
import omitBy from 'lodash/omitBy';
import { mapGetters } from 'vuex';

// Components
import CategoriesLink from '@/modules/categories/components/CategoriesLink.vue';

export default {
  name: 'CategoriesList',
  emits: ['change'],
  components: { CategoriesLink },
  props: {
    accountingAccountNumber: { type: String, default: undefined },
  },
  computed: {
    ...mapGetters({
      categoryGroupsWithHighlight: 'categories/categoryGroupsWithHighlight',
      showAdminInfo: 'user/showAdminInfo',
    }),
    computedCategoriesGroups() {
      const { showAdminInfo, categoryGroupsWithHighlight } = this;
      return omitBy(categoryGroupsWithHighlight, ({ onlyAdmin }) => onlyAdmin && !showAdminInfo);
    },
  },
};
</script>
