import get from 'lodash/get';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
import {
  generateStepShortTitleForBankAccount,
  generateStepTitleForBankAccount,
} from '@/modules/newActivities/models/initBankAccountsBalancesActivity/generateStepTitleForBankAccount';
// Services
import { refreshCurrentClosingState } from '@/shared/services/closingStates/closingStates.services';

const DECLARATION = 'initBankAccountsBalances';

export const stateData = {
  loading: true,
  closingState: undefined,
  defaultSteps: {
    initBankAccountsBalancesValidateClosing: {
      title: "Valider l'historique bancaire",
      shortTitle: 'Valider',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  activityState: (_state, _getters, _rootState, { 'newActivities/getActivity': getActivity }) =>
    getActivity({ key: 'initBankAccountsBalancesActivity' }),
  isHistoricalDataRecoveryNeeded: (_state, { activityState }) =>
    get(activityState, 'other.isHistoricalDataRecoveryNeeded', false),
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setDefaultSteps(state, { defaultSteps }) {
    state.defaultSteps = merge({}, defaultSteps, state.defaultSteps);
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
      },
      user,
      activity,
    });
  },

  // -- Refresh
  async refreshCurrent({ commit }) {
    commit('setLoading', true);

    const { closingState } = await refreshCurrentClosingState({
      declaration: DECLARATION,
    });

    const closingSteps = omitBy(get(closingState, 'steps'), (step) => step.status === 'notApplicable');
    const defaultSteps = Object.keys(closingSteps).reduce(
      (steps, stepKey) => ({
        ...steps,
        [`${stepKey}`]: {
          title: generateStepTitleForBankAccount({ closingStepKey: stepKey }),
          shortTitle: generateStepShortTitleForBankAccount({ closingStepKey: stepKey }),
          stepKey,
          stepPathName: 'initBankAccountBalance',
          availableOnlyForClients: true,
        },
      }),
      {},
    );

    commit('setDefaultSteps', { defaultSteps });
    commit('setClosingState', { closingState: { ...closingState, steps: closingSteps } });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
