<template>
  <GResponsive class="not-found">
    <GContainer class="fill-height">
      <GRow>
        <GCol align="center" justify="center">
          <GLazyImg
            src="https://georges-public-assets.s3-eu-west-1.amazonaws.com/images/indy/logo.svg"
            alt="logo"
            :max-width="144"
            :max-height="66"
            contain
          />
          <h1>404</h1>
          <h2>Page introuvable !</h2>
          <p class="text-labelL">Vous pouvez retourner à la page principale</p>
          <GButton :to="goToButton.link" color="primary" size="large" variant="outlined" class="m-t-24">
            {{ goToButton.text }}
            <GIcon name="fal fa-long-arrow-right" right />
          </GButton>
        </GCol>
      </GRow>
    </GContainer>
  </GResponsive>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotFound',
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
    }),
    goToButton() {
      if (!this.currentUser) {
        return {
          text: 'Se connecter',
          link: { name: 'login' },
        };
      }
      return {
        text: 'PILOTAGE',
        link: { name: 'home' },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  background-color: rgba(var(--g-theme-surface), 1);

  h1 {
    font-size: 10em;
    font-weight: bold;
    line-height: 1;
    color: transparent;
    -webkit-text-stroke: 2px rgba(var(--g-theme-on-background), 1);
  }
}
</style>
