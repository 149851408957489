import { getCreation } from '@/modules/onboarding/components/signup/contactDetails.models';

export { userNeedsToBeDisconnectedToCreateCompany };

function userNeedsToBeDisconnectedToCreateCompany({ queryParams, isAlreadyOnCreation }) {
  const creation = getCreation({ queryParams });
  const userWantsToCreateCompany = Boolean(creation);

  return userWantsToCreateCompany && !isAlreadyOnCreation;
}
