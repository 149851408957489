import mapValues from 'lodash/mapValues';

export const getDecoratedClosingSteps = ({ steps = {}, showAdminInfo = false, isInitializedFromDocuments = false }) => {
  const { initTelechargerDocumentsDerniereCloture, ...allStepsButinitTelechargerDocumentsDerniereCloture } = steps;
  return {
    initTelechargerDocumentsDerniereCloture,
    ...mapValues(allStepsButinitTelechargerDocumentsDerniereCloture, (stepProps) => ({
      ...stepProps,
      isVisible:
        stepProps.isVisible !== undefined
          ? stepProps.isVisible && (showAdminInfo || isInitializedFromDocuments)
          : showAdminInfo || isInitializedFromDocuments,
    })),
  };
};
