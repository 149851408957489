export default [
  {
    path: '/banques',
    name: 'bankConnector',
    component: () => import('@/shared/layouts/BlankLayout/BlankLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'shine/redirection',
        name: 'shineRedirect',
        component: () => import('./synchro/shine/ShineRedirection.vue'),
        props: ({ query }) => ({ shineState: query.state, shineCode: query.code }),
      },
      {
        path: 'qonto/redirection',
        name: 'qontoRedirect',
        component: () => import('./synchro/qonto/QontoRedirection.vue'),
        props: ({ query }) => ({ qontoState: query.state, qontoCode: query.code }),
      },
      {
        path: 'swan/redirection',
        name: 'swanRedirect',
        component: () => import('./synchro/swan/SwanRedirection.vue'),
        props: ({ query }) => ({ swanState: query.state, swanCode: query.code }),
      },
      {
        path: '/attente-redirection',
        name: 'waitBankSynchroRedirection',
        component: () => import('./synchro/BankSynchroRedirection.vue'),
        props: ({ query }) => ({
          bankId: query.bankId,
          context: query.context,
          connectorUserAccountId: query.connectorUserAccountId,
        }),
      },
    ],
  },
];
