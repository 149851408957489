import { defineAsyncComponent, markRaw } from 'vue';

export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/modules/dashboard/Dashboard.vue'),
    meta: {
      title: 'Pilotage',
      requiresAuth: true,
      updateIntercom: true,
      actions: {
        datePicker: markRaw(
          defineAsyncComponent(() => import('@/modules/dashboard/components/shared/DashboardPeriodPicker.vue')),
        ),
      },
    },
  },
];
