<template>
  <BaseSubscriptionModal
    :title="title"
    sub-title="Abonnez-vous pour automatiser votre comptabilité et vos déclarations avec Indy."
    :features-list="featuresList"
    :show-freemium="false"
    freemium-button-text="Démarrer"
    @close="$emit('close')"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseSubscriptionModal from '@/modules/core/subscriptionModals/baseSubscriptionModal/BaseSubscriptionModal.vue';

export default {
  name: 'ChurnedSciScmModal',
  emits: ['close'],
  components: { BaseSubscriptionModal },
  computed: {
    ...mapGetters({
      featuresList: 'settings/featuresSubscriptionModalsList',
    }),
    title() {
      return `Vous n'êtes plus abonné à Indy`;
    },
  },

  created() {
    this.getSubscriptionData();
  },

  methods: {
    ...mapActions({
      getSubscriptionData: 'settings/getSubscriptionData',
    }),
  },
};
</script>
