import merge from 'lodash/merge';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';

import * as routingService from './routing/routing.service';

export {
  getFirstUncompletedStepKey,
  getPreviousStepKey,
  goToFirstUncompletedStep,
  goToNextStep,
  isAllowedOnboardingRoute,
};

async function getPreviousStepKey({ stepKey }) {
  return routingService.getPreviousStepKey({ stepKey });
}

async function goToNextStep(params = {}) {
  const userIsDemo = this.$store.getters['user/userIsDemo'];
  const userIsOnBoard = this.$store.getters['user/isUserOnboard'];
  if (userIsDemo || userIsOnBoard) {
    this.$router.push({ name: 'home' });
    return;
  }

  const currentRouteName = this.$route.name;
  const nextStepKey = await routingService.getNextStepKey({ stepKey: currentRouteName });

  try {
    await this.$router.push(merge(params, { name: nextStepKey }));
  } catch (err) {
    if (isNavigationFailure(err, NavigationFailureType.redirected)) {
      // Expected, route guard redirects us to next step
    } else {
      throw err;
    }
  }
}

async function isAllowedOnboardingRoute({ stepKey, store }) {
  const userIsAuthenticated = store.getters['user/isAuthenticated'];
  if (!userIsAuthenticated) {
    return stepKey === 'contactDetails';
  }

  const userIsDemo = store.getters['user/userIsDemo'];
  const userIsOnBoard = store.getters['user/isUserOnboard'];
  if (userIsDemo || userIsOnBoard) return false;

  return routingService.isAccessToStepAllowed({ stepKey: stepKey });
}

async function goToFirstUncompletedStep() {
  const stepKey = await getFirstUncompletedStepKey({ store: this.$store });

  try {
    await this.$router.push({ name: stepKey });
  } catch (err) {
    if (isNavigationFailure(err, NavigationFailureType.redirected)) {
      // Expected, route guard redirects us to next step
    } else {
      throw err;
    }
  }
}

async function getFirstUncompletedStepKey({ store }) {
  const userIsAuthenticated = store.getters['user/isAuthenticated'];
  if (!userIsAuthenticated) {
    return 'contactDetails';
  }

  const userIsDemo = store.getters['user/userIsDemo'];
  const userIsOnBoard = store.getters['user/isUserOnboard'];
  if (userIsDemo || userIsOnBoard) return 'home';

  return routingService.getFirstUncompletedStep();
}
