import isFunction from 'lodash/isFunction';

export function capitalizeFirstLetter(text) {
  if (!text) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.substr(1);
}

export function formatFullName({ firstname, name }) {
  return (
    [firstname, name]
      .filter((element) => typeof element === 'string')
      .join(' ')
      .trim() || undefined
  );
}

export const NON_BREAKING_SPACE = String.fromCharCode(160);

export function resolveStringOrFunction(stringOrFunction, props) {
  return isFunction(stringOrFunction) ? stringOrFunction({ ...props }) : stringOrFunction;
}
