export function generateStepTitleForBankAccount({ closingStepKey }) {
  if (closingStepKey === 'n512500') return 'Télécharger les relevés de votre carte à débit différé';

  const bankAccountNumber = getBankAccountNumberFromAccountingAccountKey({
    accountingAccountKey: closingStepKey,
  });

  return `Télécharger les relevés de votre compte bancaire n°${bankAccountNumber}`;
}

function getBankAccountNumberFromAccountingAccountKey({ accountingAccountKey }) {
  const match = /^n512(?<bankAccountNumber>\d{3})$/.exec(accountingAccountKey);
  return match ? parseInt(match.groups.bankAccountNumber, 10) : undefined;
}

export function generateStepShortTitleForBankAccount({ closingStepKey }) {
  if (closingStepKey === 'n512500') return 'Carte à débit différé';

  const bankAccountNumber = getBankAccountNumberFromAccountingAccountKey({
    accountingAccountKey: closingStepKey,
  });

  if (!bankAccountNumber) {
    return 'Compte bancaire';
  }

  return `Compte bancaire N°${bankAccountNumber}`;
}
