<template>
  <GList class="pa-0 rounded-0">
    <GListItem density="compact" class="space-x-16 background-background">
      <GBadge :color="categoryGroup.key" inline dot-large />
      <span class="text-medium">{{ categoryGroup.name }}</span>
    </GListItem>

    <template v-for="accountingAccount in categoryGroup.accountingAccounts" :key="accountingAccount.number">
      <GListItem
        :class="linkClasses(accountingAccount.number, categoryGroup.key)"
        @click="$emit('change', accountingAccount)"
      >
        <GListItemContent class="my-4">{{ accountingAccount.category_name }}</GListItemContent>
      </GListItem>

      <GDivider />
    </template>
  </GList>
</template>

<script>
export default {
  name: 'CategoriesMobileList',
  emits: ['change'],
  props: {
    categoryGroup: { type: Object, required: true },
    accountingAccountNumber: String,
  },
  methods: {
    linkClasses(number, key) {
      const isSelected = number === this.accountingAccountNumber;
      return {
        [`background-${key} background-opacity-10 border-${key} border-l-2`]: isSelected,
      };
    },
  },
};
</script>
