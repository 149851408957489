import { isIntercomInitialized, updateIntercom } from '@/shared/services/intercom/intercom.services';

export default async function updateIntercomData({ to, next }) {
  const shouldUpdateIntercom = to.matched.some((record) => record.meta.updateIntercom);

  if (!shouldUpdateIntercom || !isIntercomInitialized()) {
    return next();
  }

  updateIntercom();
  next();
}
