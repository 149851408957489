<template>
  <div class="d-flex flex-column px-16 p-b-24 space-y-12 text-center">
    <!-- Bookkeeping -->
    <BookkeepingUpsellingLink v-if="isBookkeeping && !isReverseTrialRemovalEnabled" />

    <!-- Demo user -->
    <template v-else-if="userIsDemo">
      <GButton color="primary" variant="outlined" @click="removeDemo"> Revenir à l'essai gratuit </GButton>
      <p class="text-bodyS text-warning">Aperçu temporaire avec des données factices qui ne sont pas sauvegardées.</p>
    </template>
    <!-- Show Restore/Subscribe for user who's not subscribed -->
    <template v-else-if="!isActiveRoute('/compte/abonnement')">
      <GButton v-if="userIsChurned" color="primary" :to="{ name: 'userSettingsStripe' }">
        Réactiver&nbsp;<span class="hidden-sm-and-down">l'abonnement</span>
      </GButton>

      <GButton
        v-else-if="!userIsClient && currentUser?.isSupported"
        :to="{ name: 'userSettingsStripe' }"
        color="primary"
        size="large"
      >
        Je m'abonne
      </GButton>
    </template>

    <ReferralPageLink v-if="canAccessReferralPage" />

    <!-- Show discount message if user has not subscribed -->
    <p v-if="!isBookkeeping && !userIsDemo && !userIsClient && !isTrialExpired" class="text-bodyS">
      {{ discountMessage }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ReferralPageLink from '@/modules/referral/shared/ReferralPageLink/ReferralPageLink.vue';
import BookkeepingUpsellingLink from '@/modules/user/components/BookkeepingUpsellingLink/BookkeepingUpsellingLink.vue';
import { isActiveRoute } from '@/shared/utils/routing';

import { getDiscountMessage } from './navigation.model';

export default {
  name: 'AppNavigationFooter',
  components: {
    ReferralPageLink,
    BookkeepingUpsellingLink,
  },

  computed: {
    ...mapGetters({
      daysLeftBeforeTrialEnds: 'user/daysLeftBeforeTrialEnding',
      isBookkeeping: 'user/isBookkeeping',
      isTrialExpired: 'user/isTrialExpired',
      userCouponButtonTooltipText: 'user/userCouponButtonTooltipText',
      userCoupons: 'user/userCoupons',
      userIsChurned: 'user/isChurned',
      userIsClient: 'user/userIsClient',
      userIsDemo: 'user/userIsDemo',
      canAccessReferralPage: 'referral/canAccessReferralPage',
      currentUser: 'user/currentUser',
    }),
    isReverseTrialRemovalEnabled() {
      return window.GEORGES_SETTINGS.featureFlags.enableReverseTrialRemoval;
    },
    discountMessage() {
      return getDiscountMessage({
        userCoupons: this.userCoupons,
        userCouponButtonTooltipText: this.userCouponButtonTooltipText,
        daysLeftBeforeTrialEnds: this.daysLeftBeforeTrialEnds,
      });
    },
  },

  methods: {
    ...mapActions({
      removeDemo: 'user/removeDemo',
    }),
    isActiveRoute,
  },
};
</script>
