import { useStore } from 'vuex';

import { isUserOnBookkeepingProduct } from '@/modules/user/product/product.helpers';

import { ACTIVITIES_PREVIEW_ROUTE_NAME, ACTIVITIES_ROUTE_NAME } from '../constants/activities.constants';

export async function newActivitiesGuard({ to = {}, next }) {
  const store = useStore();

  const user = store.getters['user/currentUser'];
  const canUserSeeActivitiesMenuWhileFreemium = user.isSupported && isUserOnBookkeepingProduct({ user });

  if (canUserSeeActivitiesMenuWhileFreemium && to.name !== ACTIVITIES_PREVIEW_ROUTE_NAME) {
    return next({ name: ACTIVITIES_PREVIEW_ROUTE_NAME, replace: true });
  } else if (!canUserSeeActivitiesMenuWhileFreemium && to.name === ACTIVITIES_PREVIEW_ROUTE_NAME) {
    return next({ name: ACTIVITIES_ROUTE_NAME, replace: true });
  }
  return next();
}
