import { defineAsyncComponent, markRaw } from 'vue';

import { ACTIVITIES_PREVIEW_ROUTE_NAME, ACTIVITIES_ROUTE_NAME } from './constants/activities.constants';
import { newActivitiesGuard } from './middlewares/activities.guard';

const newActivitiesRoutes = [
  {
    path: '/activities',
    name: ACTIVITIES_ROUTE_NAME,
    component: () => import('@/modules/newActivities/NewActivities.vue'),
    meta: {
      title: 'À faire',
      requiresAuth: true,
      updateIntercom: true,
      actions: {
        calendarButton: markRaw(
          defineAsyncComponent(() => import('@/modules/newActivities/components/layout/ToggleCalendarOverview.vue')),
        ),
        upcomingButton: markRaw(
          defineAsyncComponent(() => import('@/modules/newActivities/components/layout/ToggleUpcomingPanel.vue')),
        ),
      },
    },
    children: [
      {
        path: ':activityKey',
        component: () => import('@/modules/newActivities/pages/Activity.vue'),
        children: [
          {
            path: '',
            component: () => import('@/modules/newActivities/pages/ActivitySummary.vue'),
          },
          {
            path: ':stepKey',
            component: () => import('@/modules/newActivities/pages/ActivityStep.vue'),
          },
        ],
      },
    ],
    beforeEnter: (to, _, next) => newActivitiesGuard({ to, next }),
  },
  {
    path: '/presentation-activites',
    name: ACTIVITIES_PREVIEW_ROUTE_NAME,
    component: () => import('@/modules/newActivities/ActivitiesPreview.vue'),
    meta: {
      title: 'À faire',
      requiresAuth: true,
      updateIntercom: true,
    },
    beforeEnter: (to, _, next) => newActivitiesGuard({ to, next }),
  },
];

export default newActivitiesRoutes;
