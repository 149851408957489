<template>
  <transition name="slide-in-out-transition" mode="out-in">
    <GButton :disabled="disabled" variant="outlined" @click="learnMore" prepend-icon="fal fa-ticket-alt">
      Parrainage
    </GButton>
  </transition>
</template>

<script>
import { useRouter } from 'vue-router';

import { trackReferralEvent } from '@/modules/referral/referral.composable';
// Constants
import { isActiveRoute } from '@/shared/utils/routing';

export default {
  name: 'ReferralPageLink',
  setup() {
    const router = useRouter();

    const learnMore = () => {
      router.push({ name: 'referral' });
      trackReferralEvent({ action: 'Click go to referral page' });
    };

    return {
      learnMore,
    };
  },
  computed: {
    disabled() {
      return isActiveRoute.call(this, '/parrainage');
    },
  },
};
</script>
