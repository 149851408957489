import Intercom from '@mathieustan/intercom';

import { INTERCOM_SETTINGS } from '@/shared/services/intercom/config';
import { formatFullName } from '@/shared/utils/string';

import { analyticsEvent } from '../../utils/analytics.js';

const { appId, disable } = INTERCOM_SETTINGS;

export {
  hideIntercom,
  isIntercomInitialized,
  isIntercomLoaded,
  isIntercomVisible,
  listenOnShow,
  rebootIntercom,
  showIntercom,
  showNewMessageIntercom,
  shutdownIntercom,
  trackEventIntercom,
  updateIntercom,
};

const fakeIntercomService = {
  ready: false,
  isBooted: false,
  visible: false,
  boot: () => ({}),
  show: () => ({}),
  showNewMessageIntercom: () => ({}),
  shutdown: () => ({}),
  hide: () => ({}),
  update: () => ({}),
  trackEvent: () => ({}),
  once: () => ({}),
  callIntercom: () => ({}),
};

const intercom = initIntercomService();

// -- Load Intercom script && Init Service
function initIntercomService() {
  if (!appId || disable) return fakeIntercomService;
  return new Intercom({ appId });
}

// -- Getters
function isIntercomLoaded() {
  return intercom.ready;
}
function isIntercomInitialized() {
  return intercom.isBooted;
}
function isIntercomVisible() {
  return intercom.visible;
}

// -- Actions
function rebootIntercom(user) {
  try {
    shutdownIntercom();

    if (isIntercomInitialized()) return;

    if (!isIntercomLoaded()) {
      intercom.once('ready', () => {
        intercom.boot(generateUserInfos(user));
      });
    } else {
      intercom.boot(generateUserInfos(user));
    }
    listenOnShow();
  } catch (error) {
    console.error('initIntercom', error);
  }
}

function shutdownIntercom() {
  intercom.shutdown();
}

function showIntercom() {
  intercom.show();
}

function showNewMessageIntercom(content) {
  intercom.showNewMessage(content);
}

function hideIntercom() {
  intercom.hide();
}

function updateIntercom(...args) {
  intercom.update(...args);
}

function trackEventIntercom(...args) {
  try {
    if (!isIntercomLoaded()) {
      intercom.once('ready', () => intercom.trackEvent(...args));
    } else {
      intercom.trackEvent(...args);
    }
  } catch (err) {
    console.warn(err);
  }
}

// -- Helpers
function generateUserInfos(user) {
  if (!user) return { anonymous: true };

  const { profile, emails, _id: userId, user_hash: userHash } = user;

  return {
    name: formatFullName({ firstname: profile.firstname, name: profile.name }),
    email: emails[0].address,
    user_id: userId,
    user_hash: userHash,
  };
}

function listenOnShow() {
  intercom.callIntercom('onShow', () => {
    analyticsEvent({
      action: 'show intercom',
      properties: {
        url: window.location.pathname,
      },
    });
  });
  intercom.callIntercom('onHide', () => {
    analyticsEvent({
      action: 'hide intercom',
      properties: {
        url: window.location.pathname,
      },
    });
  });
}
