import isNil from 'lodash/isNil';
import moment from 'moment';

import { formatAmount, formatCents, formatCentsToNoCents } from '@/shared/utils/amounts';

const space = String.fromCharCode(160);

function formatAmountMaybeNoCentsFn(amount) {
  if (isNil(amount)) return '';
  if (Number.isInteger(amount)) {
    return String(Math.round(amount)).replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${space}`);
  }
  return amount
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${space}`);
}

export const filters = {
  // --------------------
  // Date Filters
  // --------------------
  formatDate: (value, wantedFormat = 'DD/MM/YYYY') => {
    if (!value) return '';

    const date = moment(value);
    if (!date.isValid()) return value;
    return moment(value).format(wantedFormat);
  },
  // formatMonth: timestamp => {
  //   if (timestamp) {
  //     return MONTHS[parseInt(moment(timestamp).format('MM'), 10) - 1];
  //   }
  //   return '';
  // },

  // --------------------
  // Amount/Money Filters
  // --------------------

  toFixed: (a, b) => a.toFixed(b),

  formatAmountWithCurrency: (amountInCents, args = {}) => `${formatAmount(amountInCents, args)}${space}€`,

  formatCentsMaybeNoCents: (amountInCents) => {
    if (isNil(amountInCents)) return '';
    const amount = amountInCents / 100;
    return formatAmountMaybeNoCentsFn(amount);
  },

  formatCentsWithoutTrailingDecimalZeros: (amountInCents) => {
    const amountInCentsRounded = Math.round(amountInCents);
    const hasCents = amountInCentsRounded % 100 !== 0;
    return hasCents ? formatCents(amountInCentsRounded) : formatCentsToNoCents(amountInCentsRounded);
  },

  formatCentsForInput: (amountInCents) => {
    if (typeof amountInCents === 'number' && !isNaN(amountInCents)) {
      return (amountInCents / 100).toFixed(2);
    }
    return '';
  },

  // --------------------
  // TVA Tag filters
  // --------------------
  // getTransactionAmount: TransactionsAPI.getTransactionAmountInCents,

  // --------------------
  // Others Filters
  // --------------------
  selected: (option, key) => (option === key ? { selected: 'selected' } : ''),
  sum: (nb1, nb2) => nb1 + nb2,
  minus: (amount) => -1 * amount,
  subtract: (nb1, nb2) => nb1 - nb2,
  and: (condition1, condition2) => condition1 && condition2,
  or: (condition1, condition2) => condition1 || condition2,
};
