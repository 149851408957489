export const stateData = {
  minifyLeftBanner: false,
};

export const getters = {
  minifyLeftBanner: (state) => state.minifyLeftBanner,
};

export const mutations = {
  resetStateData: (state) => {
    Object.assign(state, stateData);
  },
  updateMinifyLeftBanner(state, { minifyLeftBanner }) {
    state.minifyLeftBanner = minifyLeftBanner;
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
};
