import { onboardingGuard } from '@/modules/onboarding/middlewares/onboarding.guard';
import { ONBOARDING_PROGRESS_STEPS } from '@/modules/onboarding/onboarding.constants';

export default [
  {
    path: '/inscription',
    name: 'onboarding',
    component: () => import('@/modules/onboarding/Onboarding.vue'),
    beforeEnter: (to, _from, next) => onboardingGuard({ to, next }),
    children: [
      {
        path: 'vos-identifiants',
        name: 'contactDetails',
        components: {
          left: () => import('@/modules/login/components/LoginSidebar.vue'),
          right: () => import('@/modules/onboarding/components/signup/Signup.vue'),
        },
        meta: {
          hideProgressBar: true,
        },
      },
      {
        path: 'paiement-creation-societe',
        name: 'upfrontPayment',
        components: {
          right: () => import('@/modules/onboarding/components/upfrontPayment/UpfrontPayment.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.info,
        },
      },
      {
        path: 'creation-societe',
        name: 'redirectToLegalPlace',
        components: {
          right: () => import('@/modules/onboarding/components/redirectToLegalPlace/RedirectToLegalPlace.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.info,
        },
      },
      {
        path: 'creation-ae',
        name: 'createAeInfo',
        components: {
          right: () => import('@/modules/onboarding/components/createAeInfo/CreateAeInfo.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'depot-de-capital',
        name: 'capitalDeposit',
        components: {
          right: () => import('@/modules/onboarding/components/capitalDeposit/CapitalDeposit.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.info,
        },
      },
      {
        path: 'votre-besoin',
        name: 'chooseWantedFeatures',
        components: {
          right: () => import('@/modules/onboarding/components/chooseWantedFeatures/ChooseWantedFeatures.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.info,
        },
      },
      {
        path: 'informations-personnelles',
        name: 'personalInfo',
        components: {
          right: () => import('@/modules/onboarding/components/personalInfo/PersonalInfo.vue'),
        },
        meta: {
          persistentLeftPart: true,
          progressStep: ONBOARDING_PROGRESS_STEPS.info,
        },
      },
      {
        path: 'entreprise-creee',
        name: 'companyCreationStatus',
        components: {
          right: () => import('@/modules/onboarding/components/companyCreationStatus/CompanyCreationStatus.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.info,
        },
      },
      {
        path: 'votre-siret',
        name: 'siretInput',
        components: {
          left: () => import('@/modules/onboarding/components/siretInput/SiretInputReassurance.vue'),
          right: () => import('@/modules/onboarding/components/siretInput/SiretInput.vue'),
        },
        meta: {
          persistentLeftPart: true,
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'votre-activite',
        name: 'siretInfo',
        components: {
          right: () => import('@/modules/onboarding/components/siretInfo/SiretInfo.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'faire-un-depot-de-capital',
        name: 'chooseCapitalDeposit',
        components: {
          right: () => import('@/modules/onboarding/components/chooseCapitalDeposit/ChooseCapitalDeposit.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'votre-metier',
        name: 'chooseJob',
        components: {
          right: () => import('@/modules/onboarding/components/chooseJob/ChooseJob.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'nature-activite',
        name: 'activityType',
        components: {
          right: () => import('@/modules/onboarding/components/activityType/ActivityType.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'configuration-scm',
        name: 'scmConfiguration',
        components: {
          right: () => import('@/modules/onboarding/components/scmConfiguration/ScmConfiguration.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'informations-complementaires',
        name: 'needMoreInfos',
        components: {
          right: () => import('@/modules/onboarding/components/needMoreInfos/NeedMoreInfos.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'informations-complementaires-sci',
        name: 'sciMoreInfo',
        components: {
          right: () => import('@/modules/onboarding/components/sciMoreInfo/SciMoreInfo.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'pas-adapte-bic-ei',
        name: 'notSupportedBicEi',
        components: {
          right: () => import('@/modules/onboarding/components/notSupported/NotSupported.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'pas-adapte-sci-ir',
        name: 'notSupportedSciIr',
        components: {
          right: () => import('@/modules/onboarding/components/notSupported/NotSupported.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'pas-adapte',
        name: 'notSupported',
        components: {
          right: () => import('@/modules/onboarding/components/notSupported/NotSupported.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'ecole',
        name: 'chooseSchool',
        components: {
          right: () => import('@/modules/onboarding/components/chooseSchool/ChooseSchool.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'offre-premiere-annee',
        name: 'schoolsPrepayment',
        components: {
          right: () => import('@/modules/onboarding/components/schoolsPrepayment/SchoolsPrepayment.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'siret-offre-premiere-annee',
        name: 'schoolsSiretInput',
        components: {
          right: () => import('@/modules/onboarding/components/schoolsSiretInput/SchoolsSiretInput.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.config,
        },
      },
      {
        path: 'dernieres-infos-bnc',
        name: 'accountConfigurationBnc',
        components: {
          right: () => import('@/modules/onboarding/components/accountConfigurationBnc/AccountConfigurationBnc.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'dernieres-infos-bic',
        name: 'accountConfigurationBic',
        components: {
          right: () => import('@/modules/onboarding/components/accountConfigurationBic/AccountConfigurationBic.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'dernieres-infos-societe',
        name: 'accountConfigurationWithFiscalYearShift',
        components: {
          right: () =>
            import(
              '@/modules/onboarding/components/accountConfigurationWithFiscalYearShift/AccountConfigurationWithFiscalYearShift.vue'
            ),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'warning-sci-is-fiscal-year-not-supported',
        name: 'warningSciIsFiscalYearNotSupported',
        components: {
          right: () =>
            import(
              '@/modules/onboarding/components/warningSciIsFiscalYearNotSupported/WarningSciIsFiscalYearNotSupported.vue'
            ),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'tva',
        name: 'vatConfiguration',
        components: {
          right: () => import('@/modules/onboarding/components/vatConfiguration/VatConfiguration.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'pas-adapte-tva',
        name: 'notSupportedFiscalPeriod',
        components: {
          right: () => import('@/modules/onboarding/components/notSupported/NotSupported.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.entreprise,
        },
      },
      {
        path: 'paie',
        name: 'payslipConfiguration',
        components: {
          right: () => import('@/modules/onboarding/components/payslipConfiguration/PayslipConfiguration.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.compte,
        },
      },
      {
        path: 'synchronisation',
        name: 'bankSync',
        components: {
          left: () => import('@/modules/onboarding/components/bankSync/BankSyncReassurance.vue'),
          right: () => import('@/modules/onboarding/components/bankSync/BankSync.vue'),
        },
        meta: {
          persistentLeftPart: true,
          progressStep: ONBOARDING_PROGRESS_STEPS.compte,
        },
      },
      {
        path: 'finalisation-compte-pro',
        name: 'bankSyncComptePro',
        components: {
          right: () => import('@/modules/onboarding/components/bankSyncComptePro/BankSyncComptePro.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.compte,
        },
      },
      {
        path: 'creation-compte-pro',
        name: 'creationComptePro',
        components: {
          right: () => import('@/modules/onboarding/components/creationComptePro/CreationComptePro.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.compte,
        },
      },
      {
        path: 'attente-synchronisation',
        name: 'waitBankSync',
        components: {
          right: () => import('@/modules/onboarding/components/waitBankSync/WaitBankSync.vue'),
        },
        props: {
          right: { context: 'onboarding' },
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.compte,
        },
      },
      {
        path: 'votre-compte-bancaire',
        name: 'chooseBankAccount',
        components: {
          right: () => import('@/modules/onboarding/components/chooseBankAccount/ChooseBankAccount.vue'),
        },
        meta: {
          progressStep: ONBOARDING_PROGRESS_STEPS.compte,
        },
      },

      {
        path: '*',
        name: 'onboarding-not-found',
      },
    ],
  },
];
