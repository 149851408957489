import georgesApi from '@/shared/apis/georges.js';

async function fetchBanks() {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/bank-connector/banks`,
  });

  return data.banks;
}

async function fetchBankSynchroUrl({ bankId, baseUrl, context }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/bank-connector/banks/${bankId}/synchro-url`,
    params: {
      baseUrl,
      context,
    },
  });

  return data.synchroUrl;
}

export { fetchBanks, fetchBankSynchroUrl };
