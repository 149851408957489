<template>
  <component
    :is="activeComponent"
    :opened="opened"
    :description="changeCategoryProps.transactionDescription"
    :accounting-account-number="changeCategoryProps.accountingAccountNumber"
    @close="close"
    @change="callOnChangeFn"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

// Components
import CategoriesDesktop from '@/modules/categories/layouts/CategoriesDesktop.vue';
import CategoriesMobile from '@/modules/categories/layouts/CategoriesMobile.vue';
import Trackable from '@/shared/mixins/trackable';

export default {
  name: 'Categories',
  mixins: [Trackable],
  computed: {
    ...mapGetters({
      opened: 'categories/opened',
      changeCategoryProps: 'categories/changeCategoryProps',
      onChange: 'categories/onChange',
    }),
    activeComponent() {
      const { isMobile } = this.$georgesuikit.breakpoint;
      if (isMobile) return CategoriesMobile;
      return CategoriesDesktop;
    },
  },
  watch: {
    opened(isOpen) {
      if (isOpen) {
        this.trackEvent({
          action: 'Open recategorization modal',
          properties: {
            currentCategory: this.changeCategoryProps.accountingAccountNumber,
          },
        });
      }
    },
  },
  created() {
    this.initCategoryGroups();
  },
  methods: {
    ...mapMutations({
      updateVisibility: 'categories/updateVisibility',
      resetCategoryGroupsHighlighted: 'categories/resetCategoryGroupsHighlighted',
    }),
    ...mapActions({
      initCategoryGroups: 'categories/initCategoryGroups',
    }),
    close({ newCategorySubmitted }) {
      this.updateVisibility({ opened: false, changeCategoryProps: {} });
      this.resetCategoryGroupsHighlighted();
      if (!newCategorySubmitted) {
        this.trackEvent({
          action: 'cancel recategorization modal',
        });
      }
    },
    async callOnChangeFn(value) {
      try {
        await this.onChange(value);
        this.close({ newCategorySubmitted: true });
        this.trackEvent({
          action: 'submit new category',
          properties: {
            newCategory: value.number,
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
