import { defineAsyncComponent, markRaw } from 'vue';
const documentsRoutes = [
  {
    path: '/document/declaration-2035',
    name: 'documentDeclaration2035',
    component: () => import('@/modules/exports/declaration2035/Declaration2035.vue'),
    meta: {
      title: 'Déclaration 2035',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/D2035/ExportDeclaration2035.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2035' },
        },
      },
    },
  },
  {
    path: '/document/resultat-micro',
    name: 'documentResultatMicro',
    component: () => import('@/modules/exports/declaration2035/Declaration2035.vue'),
    meta: {
      title: 'Résultat comptable',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/D2035/ExportDeclaration2035.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2035' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2042-c-pro',
    name: 'documentDeclaration2042CPro',
    component: () => import('@/modules/exports/declaration2042CPro/Declaration2042CPro.vue'),
    meta: {
      title: 'Déclaration 2042 C-PRO',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2042' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2033',
    name: 'documentDeclaration2033',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2033' },
    meta: {
      title: 'Déclaration 2033',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/documents/components/LiasseFiscaleIs/ExportDeclaration2033.vue'),
          ),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2033' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2065',
    name: 'documentDeclaration2065',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2065' },
    meta: {
      title: 'Déclaration 2065',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/documents/components/LiasseFiscaleIs/ExportDeclaration2065.vue'),
          ),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2065' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2572',
    name: 'documentDeclaration2572',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2572' },
    meta: {
      title: `Solde d'IS`,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/D2572/ExportDeclaration2572.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2572' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2571/:periodId',
    name: 'documentDeclaration2571',
    component: () => import('@/modules/documents/components/D2571/D2571PdfViewer.vue'),
    props: true,
    meta: {
      title: `Acompte d'IS`,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/D2571/ExportD2571Button.vue')),
        ),
        datePicker: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/D2571/D2571PeriodsDatePicker.vue')),
        ),
      },
    },
  },
  {
    path: '/document/pv-ag',
    name: 'documentPvOfAg',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'pvOfAg' },
    meta: {
      title: `PV d'AG`,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/PvOfAg/ExportDeclarationPvOfAg.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'pvOfAg' },
        },
      },
    },
  },
  {
    path: '/document/registre-immobilisations',
    name: 'documentRegistreImmobilisations',
    component: () => import('@/modules/exports/assetRegister/AssetRegister.vue'),
    meta: {
      title: 'Registre des immobilisations',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/assetRegister/ExportAssetRegister.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'assetRegister' },
        },
      },
    },
  },
  {
    path: '/document/das2',
    name: 'documentDAS2',
    component: () => import('@/modules/exports/DAS2/DAS2.vue'),
    meta: {
      title: 'DAS2',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/DAS2/ExportDeclarationDAS2.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'das2' },
        },
      },
    },
  },
  {
    path: '/document/tableaux-oga',
    name: 'documentTableauxOga',
    component: () => import('@/modules/documents/components/Ogbnc/Ogbnc.vue'),
    meta: {
      title: 'Tableaux OGA',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/Ogbnc/ExportOgbnc.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'ogbnc' },
        },
      },
    },
  },
  {
    path: '/document/grand-livre',
    name: 'documentGrandLivre',
    component: () => import('@/modules/exports/accountingBook/AccountingBook.vue'),
    meta: {
      title: 'Grand Livre',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/accountingBook/ExportAccountingBook.vue')),
        ),
        datePicker: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
        ),
      },
    },
  },
  {
    path: '/document/bilan',
    name: 'documentBilan',
    component: () => import('@/modules/exports/bilan/Bilan.vue'),
    meta: {
      title: 'Bilan',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/Bilan/ExportBilan.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'bilan' },
        },
      },
    },
  },
  {
    path: '/document/balance',
    name: 'documentBalance',
    component: () => import('@/modules/exports/balance/GeneralBalance.vue'),
    meta: {
      title: 'Balance',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/Balance/ExportGeneralBalance.vue')),
        ),
        datePicker: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
        ),
      },
    },
  },
  {
    path: '/document/compte-de-resultat',
    name: 'documentCompteDeResultat',
    component: () => import('@/modules/documents/components/CompteDeResultat/CompteDeResultat.vue'),
    meta: {
      title: 'Compte de résultat',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/documents/components/CompteDeResultat/ExportCompteDeResultat.vue'),
          ),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'compteDeResultat' },
        },
      },
    },
  },
  {
    path: '/document/journaux-comptables',
    name: 'documentFec',
    component: () => import('@/modules/exports/fec/AccountingLines.vue'),
    meta: {
      title: 'FEC',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/Fec/ExportAccountingLines.vue')),
        ),
        datePicker: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
        ),
      },
    },
  },
  // --- VAT
  {
    path: '/document/tva/:vatTag?', // '?' to handle redirection from 'oldRoutes'
    name: 'vatDocument',
    component: () => import('@/modules/exports/vat/Vat.vue'),
    props: true,
    // beforeEnter: multiguard([mustBeAuth, updateIntercomData]),
    meta: {
      title: 'TVA',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/Vat/shared/ExportTvaActionButton.vue')),
        ),
        datePicker: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/VatPeriodsDatepicker.vue')),
        ),
      },
    },
  },
  {
    path: '/document/acompte-tva/:fiscalPeriodId',
    name: 'advancePaymentDeclarationDocument',
    component: () => import('@/modules/exports/vat/advancePayment/AdvancePaymentDeclaration.vue'),
    props: true,
    meta: {
      title: 'Acompte de TVA',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      // actions: {
      //   datePicker: VatPeriodsDatepicker,
      // },
    },
  },
  {
    path: '/document/payslip/:fiscalPeriodId?',
    name: 'payslipDocument',
    component: () => import('@/modules/exports/payslip/Payslip.vue'),
    props: true,
    meta: {
      title: 'Fiche de paie',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/documents/components/Payslip/shared/ExportPayslipActionButton.vue'),
          ),
        ),
        datePicker: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/documents/components/Payslip/PayslipPeriodsDatepicker/PayslipPeriodsDatepicker.vue'),
          ),
        ),
      },
    },
  },

  // -- DECLARATION SOCIALE
  {
    path: '/document/declaration-sociale',
    name: 'socialDeclarationDocument',
    component: () => import('@/modules/documents/components/SocialDeclaration/SocialDeclarationPreview.vue'),
    // beforeEnter: multiguard([mustBeAuth, updateIntercomData]),
    meta: {
      title: 'Déclaration sociale PAMC',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'socialDeclaration' },
        },
      },
    },
  },

  {
    path: '/document/declaration-cfe',
    name: 'documentDeclarationCfe',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'cfe' },
    meta: {
      title: 'Déclaration CFE',
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      requiresAuth: true,
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/Cfe/ExportDeclarationCfe.vue')),
        ),
      },
    },
  },

  {
    path: '/document/receipt/:receiptId',
    name: 'receiptPreview',
    component: () => import('@/modules/receipts/components/ReceiptPreview.vue'),
    props: true,
    meta: {
      title: 'Justificatif en attente',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
    },
  },

  // --- Main page
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/modules/documents/Documents.vue'),
    // beforeEnter: multiguard([mustBeAuth, updateIntercomData]),
    meta: {
      title: 'Documents',
      requiresAuth: true,
      updateIntercom: true,
      actions: {
        datepicker: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
        ),
      },
    },
  },

  // --- SCI
  {
    path: '/document/declaration-2072',
    name: 'd2072',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2072' },
    meta: {
      title: '2072',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'd2072' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2072' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2033-sci',
    name: 'd2033Sci',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2033Sci' },
    meta: {
      title: '2033',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'd2033Sci' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2033Sci' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2065-sci',
    name: 'd2065Sci',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2065Sci' },
    meta: {
      title: '2065',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'd2065Sci' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2065Sci' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2572-sci',
    name: 'documentDeclaration2572Sci',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2572Sci' },
    meta: {
      title: '2572',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'd2572Sci' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2572Sci' },
        },
      },
    },
  },
  {
    path: '/document/proces-verbal-assemblee-generale',
    name: 'procesVerbalAssembleeGeneraleSci',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'procesVerbalAssembleeGeneraleSci' },
    meta: {
      title: "PV d'AG",
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'procesVerbalAssembleeGeneraleSci' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: {
            documentName: 'procesVerbalAssembleeGeneraleSci',
          },
        },
      },
    },
  },
  {
    path: '/document/feuille-de-presence',
    name: 'feuilleDePresenceSci',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'feuilleDePresenceSci' },
    meta: {
      title: 'Feuille de présence',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'feuilleDePresenceSci' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: {
            documentName: 'procesVerbalAssembleeGeneraleSci',
          },
        },
      },
    },
  },
  {
    path: '/document/attestation-de-conformite',
    name: 'attestationDeConformiteSci',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'attestationDeConformiteSci' },
    meta: {
      title: 'Attestation de conformité',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'attestationDeConformiteSci' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'procesVerbalAssembleeGeneraleSci' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2044',
    name: 'd2044',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2044' },
    meta: {
      title: '2044',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/ExportDeclaration.vue')),
          ),
          props: { name: 'd2044' },
        },
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'd2044' },
        },
      },
    },
  },
  {
    path: '/document/bilan-sci',
    name: 'bilanSci',
    component: () => import('@/modules/exports/bilan/BilanSci.vue'),
    props: { documentName: 'bilanSci' },
    meta: {
      title: 'Bilan',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/Bilan/ExportBilanSci.vue')),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'bilanSci' },
        },
      },
    },
  },
  {
    path: '/document/compte-de-resultat-sci',
    name: 'compteDeResultatSci',
    component: () => import('@/modules/documents/components/CompteDeResultat/CompteDeResultatSci.vue'),
    props: { documentName: 'compteDeResultatSci' },
    meta: {
      title: 'Compte de résultat',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() =>
            import('@/modules/documents/components/CompteDeResultat/ExportCompteDeResultatSci.vue'),
          ),
        ),
        datePicker: {
          component: markRaw(
            defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
          ),
          props: { documentName: 'compteDeResultatSci' },
        },
      },
    },
  },
  {
    path: '/document/declaration-2036',
    name: 'd2036',
    component: () => import('@/modules/exports/shared/pdfExportViewer/PdfExportViewer.vue'),
    props: { documentName: 'd2036' },
    meta: {
      title: '2036',
      requiresAuth: true,
      parent: {
        title: 'Documents',
        link: 'documents',
      },
      actions: {
        downloadButton: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/D2036/ExportDeclaration2036.vue')),
        ),
        datePicker: markRaw(
          defineAsyncComponent(() => import('@/modules/documents/components/shared/DocumentsPeriodPicker.vue')),
        ),
      },
    },
  },
];

export default documentsRoutes;
