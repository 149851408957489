export async function usePwa({ router }) {
  await router.isReady();

  let pendingUpdate = false;
  let cacheComplete = false;

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      pendingUpdate = true;
    });

    navigator.serviceWorker.onmessage = (event) => {
      if (event.data.type === 'CACHE_COMPLETED') {
        cacheComplete = true;
      }
    };
  }

  router.beforeEach(async (to, from) => {
    if (to.path !== from.path) {
      if (pendingUpdate && cacheComplete) {
        // eslint-disable-next-line no-console
        console.log('Reloading page to update service worker');
        window.location.pathname = to.fullPath;
      }

      navigator.serviceWorker?.getRegistration().then((reg) => {
        reg?.update();
      });
    }
  });

  window.addEventListener('offline', () => {
    window.location.replace(window.location.href);
  });
}
