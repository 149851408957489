import { CREATION_TYPES } from '@georges-tech/georges-lib';

export const ONBOARDING_PROGRESS_STEPS = {
  info: 'Vos informations',
  entreprise: 'Votre entreprise',
  compte: 'Votre compte',
};

export const ONBOARDING_CREATION_QUERY_PARAMS = {
  creation: {
    paramName: 'creation',
    validValues: CREATION_TYPES,
  },
  zipCode: {
    paramName: 'zipCode',
  },
  capitalDeposit: {
    paramName: 'capitalDeposit',
  },
};

export const NON_BREAKING_SPACE = String.fromCharCode(160);
