export const APP_STATES = {
  fetchAuthUser: {
    status: 'Connexion à votre compte',
  },
  fetchUserData: {
    status: 'Chargement de vos données',
  },
  initialized: {
    isInitialized: true,
    status: 'Initialisation terminée',
  },
};
