import get from 'lodash/get';
import isObject from 'lodash/isObject';
import moment from 'moment';

export {
  isAdminOnlyActivity,
  isAnticipatedActivity,
  isAvailableActivity,
  isUpcomingActivity,
  setActivityLockedDescription,
  setIsLateActivity,
  setIsLockedByActivity,
  setIsOnlyForAdmin,
  setIsPendingActivity,
  setIsSoonLateActivity,
  setPeriodColor,
  setPeriodIcon,
  setPeriodText,
  todayIsBeforeDate,
};

// -- Formats data from server
function setIsPendingActivity(activity = {}) {
  const closeTypes = [
    get(activity, 'closingTimeInfo.dateToClose.type'),
    ...(activity.hasRequestedHelp ? ['pending'] : []),
  ];
  return closeTypes.some((type) => ['pending', 'inProgress'].includes(type));
}

function setIsLateActivity(activity = {}, todaysDate = Date.now()) {
  const maxDate = get(activity, 'closingTimeInfo.maxDate');
  if (!maxDate) return false;
  return moment(todaysDate).isAfter(maxDate);
}

function setIsSoonLateActivity(activity, todaysDate = Date.now()) {
  const maxDate = get(activity, 'closingTimeInfo.maxDate');
  if (!maxDate) return false;
  return !setIsLateActivity(activity, todaysDate) && moment(maxDate).diff(todaysDate, 'days') <= 7;
}

function setIsLockedByActivity(activity) {
  const lockedBy = get(activity, 'closingTimeInfo.lockedBy');
  return lockedBy;
}

function setPeriodText(activity = {}) {
  const { status, date } = activity;
  if (status.pending) return 'En cours de traitement';
  if (!date.maxDate) return 'Dès que possible';
  return date.maxDate;
}

function setPeriodColor(activity = {}) {
  const { status, date } = activity;
  if (status.late) return 'error';
  if (status.pending || !date.maxDate || status.soonLate) return 'warning';
  return 'secondary';
}

function setPeriodIcon(activity = {}) {
  const { status } = activity;
  if (status.pending) return 'fal fa-hourglass-half';
  if (status.late) return 'fal fa-clock';
  return 'fal fa-calendar-alt';
}

function setIsOnlyForAdmin(activity = {}) {
  return activity.isActiveForAdmin && !activity.isActive;
}

function setActivityLockedDescription({ activity = {}, activities } = {}) {
  const { status } = activity;
  if (!status?.lockedBy) return;

  if (status.lockedBy === 'SALES_INTEGRITY_ERROR') {
    return 'Une erreur est survenue lors de la validation de vos ventes. Veuillez contacter le support pour débloquer cette activité';
  }

  if (isObject(status.lockedBy) && status.lockedBy.key && activities[status.lockedBy.key]) {
    const { title } = activities[status.lockedBy.key];
    return `Pour débloquer cette activité, validez d'abord votre <b>${[title, status.lockedBy.period].join(' ')}</b>`;
  }

  const lockedByActivity = activities[status.lockedBy];
  if (!lockedByActivity) return;

  const { title, period } = lockedByActivity;
  return `Pour débloquer cette activité, validez d'abord votre <b>${[title, period].join(' ')}</b>`;
}

// -- Sort and filter
function todayIsBeforeDate(date, todaysDate) {
  return moment(todaysDate).isBefore(moment(date).format('YYYY-MM-DD'));
}
function todayIsSameOrAfterDate(date, todaysDate) {
  return moment(todaysDate).isSameOrAfter(moment(date).format('YYYY-MM-DD'));
}

function isAvailableActivity(activity = {}, todaysDate = Date.now()) {
  const { status, date } = activity;
  return get(status, 'forcedAvailability', false) || todayIsSameOrAfterDate(date?.minDate, todaysDate);
}

function isAnticipatedActivity(activity = {}, todaysDate = Date.now()) {
  const { status, date } = activity;
  return get(status, 'forcedAvailability', false) && todayIsBeforeDate(date?.minDate, todaysDate);
}

function isUpcomingActivity(activity = {}, todaysDate = Date.now()) {
  const { status, date } = activity;
  return !get(status, 'forcedAvailability', false) && todayIsBeforeDate(date?.minDate, todaysDate);
}

function isAdminOnlyActivity(activity = {}) {
  return get(activity, 'isOnlyForAdmin', false);
}
