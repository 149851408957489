import { useStore } from 'vuex';

import { getFirstUncompletedStepKey, isAllowedOnboardingRoute } from '@/modules/onboarding/onboarding.utils';

export async function onboardingGuard({ to = {}, next }) {
  const store = useStore();

  if (to.name === 'onboarding-not-found') {
    return next({ name: 'onboarding' });
  }
  if (to.name !== 'onboarding' && (await isAllowedOnboardingRoute({ stepKey: to.name, store }))) {
    return next();
  }
  const nextStepKey = await getFirstUncompletedStepKey({ store });
  return next({
    name: nextStepKey,
    query: to.query,
    params: to.params,
    replace: true,
  });
}
