import georgesApi from '@/shared/apis/georges.js';

export async function getToken(partner) {
  try {
    const {
      data: { token, isValid },
    } = await georgesApi({
      method: 'GET',
      url: '/facturation/tokens',
      params: {
        partner,
      },
    });

    return { token, isValid };
  } catch (err) {
    return { token: undefined, isValid: undefined };
  }
}

export async function createToken(token, partner) {
  return georgesApi({
    method: 'POST',
    url: '/facturation/tokens',
    data: { token, partner },
  });
}

export async function deleteToken(partner) {
  return georgesApi({
    method: 'DELETE',
    url: '/facturation/tokens',
    data: { partner },
  });
}
