// Styles
import '@georgestech/georges-ui-kit/styles';

// Framework
import { createKit } from '@georgestech/georges-ui-kit';
import * as components from '@georgestech/georges-ui-kit/components';
import * as directives from '@georgestech/georges-ui-kit/directives';

export const kit = createKit({
  components,
  directives,
  lang: 'fr',
  theme: {
    isDarkDisabled: true,
  },
  display: {
    notification: {
      0: {
        bottom: true,
        multiLine: true,
        showClose: true,
      },
      480: {
        top: true,
        right: true,
        offset: 90,
        showClose: true,
      },
    },
  },
});

export function useKit({ app }) {
  app.use(kit);
}
