import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import unionBy from 'lodash/unionBy';
import moment from 'moment';

// Constants
import { TRANSACTIONS_PARAMS_QUERY } from '@/modules/transactions/constants/transactions.constants';
import {
  insertTransactionAndRefreshList,
  mergeTransactionsAndRefreshList,
  updateTransactionAndRefreshList,
} from '@/modules/transactions/models/transactions.filters';
// Models
import {
  formatParamsSelectedDates,
  getActiveFilters,
  getTransactionsWithMatchingInfo,
  removeKeyFromParams,
} from '@/modules/transactions/models/transactions.models';
import { getTransactionsSummary } from '@/modules/transactions/services/accounting.services';
import { getPendingTransactions } from '@/modules/transactions/services/pendingTransactions.services';
// Services
import {
  getAmountOfTransactionsToCategorize,
  getManualTransactionTypes,
  getTransactionsPaginated,
  getTransactionsParams,
  safelyGetMatchingInvoices,
  safelyGetMatchingReceipts,
} from '@/modules/transactions/services/transactions.services';
import router from '@/shared/router';
import { logger } from '@/shared/services/logger/logger';

let timeoutId;

export const stateData = {
  isStateReady: false,
  // Transactions list paginated
  list: {},
  pendingTransactions: [],
  transactionsSummary: {
    totalAmountInCents: undefined,
    totalIncomeAmountInCents: undefined,
    totalExpenseAmountInCents: undefined,
    totalTransactionsCount: 0,
  },
  page: 1,
  hasMoreTransactions: true,
  invoicesMatchingTransactions: [],
  receiptsMatchingTransactions: [],
  toCategorizeCount: 0,
  // Params to send for pagination & filters
  params: TRANSACTIONS_PARAMS_QUERY,
  // Values to fill filters
  filters: {
    accountOrFroms: [],
    categories: [],
    allowedDates: {},
    rangePresets: [],
  },
  isFilteringParams: false,

  // Filters Panel state
  isFiltersPanelOpened: false,

  // Form panel
  activeFormPanel: {
    type: undefined, // CF server/src/modules/transactionsUI/_transactionsUI/models/manualTransaction.model.ts
    transactionId: undefined, // Needed to retrieve transaction
    opened: undefined, // sets which form is opened 'editForm' or 'createForm'
  },
  manualTransactionTypes: [],
};

export const getters = {
  // -- Filters
  params: ({ params }) => params,
  getActiveFilters: (state) => getActiveFilters(state.params),
  getAmountOfActiveFilters: (state, { getActiveFilters }) => Object.keys(getActiveFilters).length,
  hasActiveFilters: (state, { getAmountOfActiveFilters }) => getAmountOfActiveFilters > 0,
  getFocusTransactionId: (state) => get(state, 'params.focus'),
  // -- List
  transactionsPaginated: ({ list = {} }) => orderBy(Object.values(list), ['date', '_id'], ['desc', 'asc']),
  pendingTransactionsOrdered: ({ pendingTransactions = [] }) => orderBy(pendingTransactions, ['date'], ['desc']),
  transactionsPaginatedCount: (state, { transactionsPaginated }) => transactionsPaginated.length,
  hasMoreTransactions: ({ hasMoreTransactions }) => hasMoreTransactions,
  // -- Forms
  canShowCreateForm: (state) => state.activeFormPanel.opened === 'createForm',
  canShowEditForm: (state) => {
    // Panel state should be editForm && we need to have a transactionId defined
    return state.activeFormPanel.opened === 'editForm' && Boolean(state.activeFormPanel.transactionId);
  },
  // -- Filters Panel
  isFiltersPanelOpened: ({ isFiltersPanelOpened }) => isFiltersPanelOpened,
  // -- Counters
  transactionsToCategorizeCount: (state) => state.toCategorizeCount,
  // - Invoices
  invoicesMatchingTransactions: (state) => state.invoicesMatchingTransactions,
  // - Receipts
  receiptsMatchingTransactions: (state) => state.receiptsMatchingTransactions,
  manualTransactionTypes: (state) => state.manualTransactionTypes,
  activeTransactionTypeLabelKey: (state) => get(state, 'activeFormPanel.type.labelKey'),
  transactionsSummary: (state) => state.transactionsSummary,
};

export const mutations = {
  setIsStateReady(state, isStateReady) {
    state.isStateReady = isStateReady;
  },
  // ------------------------
  // Params & Filters
  // ------------------------
  toggleFiltersPanel(state) {
    state.isFiltersPanelOpened = !state.isFiltersPanelOpened;
  },
  closeFiltersPanel(state) {
    state.isFiltersPanelOpened = false;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  resetTransactionsSummary(state) {
    state.transactionsSummary = {
      totalAmountInCents: undefined,
      totalIncomeAmountInCents: undefined,
      totalExpenseAmountInCents: undefined,
      totalTransactionsCount: 0,
    };
  },
  setManualTransactionTypes(state, manualTransactionTypes) {
    state.manualTransactionTypes = manualTransactionTypes;
  },
  updateParams(state, newParams) {
    // When we focus a transaction,
    // we don't want to show transaction loading state
    // because it will fail focus
    const shouldShowLoadingTransaction = !newParams.focus;
    state.isFilteringParams = shouldShowLoadingTransaction;

    state.params = { ...state.params, ...newParams };
    state.page = 1;
    state.hasMoreTransactions = true;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => (state.isFilteringParams = false), 500);
  },
  clearSpecificParams(state, key) {
    // When we focus a transaction,
    // we don't want to show transaction loading state
    // because it will fail focus
    const shouldShowLoadingTransaction = key !== 'focus';
    state.isFilteringParams = shouldShowLoadingTransaction;

    state.params = removeKeyFromParams(key, state.params);
    state.page = 1;
    state.hasMoreTransactions = true;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => (state.isFilteringParams = false), 500);
  },
  resetParams(state) {
    state.params = TRANSACTIONS_PARAMS_QUERY;
  },
  updateSearchText(state, text) {
    if (state.params.search === text) return;
    state.params = {
      ...state.params,
      search: text,
    };
  },
  nextPage(state) {
    state.page += 1;
  },
  noMoreTransactions(state) {
    state.hasMoreTransactions = false;
  },
  setIsFiltering(state, status) {
    state.isFilteringParams = status;
  },
  // ------------------------
  // Transactions
  // ------------------------
  resetTransactionList(state, { transactions }) {
    state.page = 1;
    state.hasMoreTransactions = true;
    state.list = transactions;
    state.invoicesMatchingTransactions = [];
    state.receiptsMatchingTransactions = [];
  },
  setTransactionsSummary(
    state,
    { totalTransactionsCount, totalAmountInCents, totalIncomeAmountInCents, totalExpenseAmountInCents },
  ) {
    state.transactionsSummary = {
      totalAmountInCents,
      totalIncomeAmountInCents,
      totalExpenseAmountInCents,
      totalTransactionsCount,
    };
  },
  invoicesMatchingTransactions(state, { invoices }) {
    state.invoicesMatchingTransactions = unionBy(invoices, state.invoicesMatchingTransactions, 'id');
  },
  receiptsMatchingTransactions(state, { receipts }) {
    state.receiptsMatchingTransactions = unionBy(receipts, state.receiptsMatchingTransactions, '_id');
  },
  setTransactions(state, { transactions }) {
    state.list = transactions;
  },
  setPendingTransactions(state, { pendingTransactions }) {
    state.pendingTransactions = pendingTransactions;
  },
  setToCategorizeCount(state, { count }) {
    state.toCategorizeCount = count;
  },
  // ------------------------
  // Panels
  // ------------------------
  updateActiveFormPanel(state, { name, type, transactionId } = {}) {
    state.activeFormPanel = {
      ...state.activeFormPanel,
      type,
      transactionId,
      opened: name,
    };
  },
  // ------------------------
  // Reset store
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

async function getReceiptsAndInvoicesAndMatchingInfo({ transactions }) {
  const transactionIds = transactions.map((transaction) => transaction._id);

  const [receiptResult, invoiceResult] = await Promise.all([
    safelyGetMatchingReceipts({ transactionIds }),
    safelyGetMatchingInvoices({ transactionIds }),
  ]);

  return {
    receipts: receiptResult.receipts,
    invoices: invoiceResult.invoices,
    matchingInfoWithTransactionIds: [...receiptResult.transactions, ...invoiceResult.transactions],
  };
}

export const actions = {
  // -- List
  async getAmountOfTransactionsToCategorize({ commit }) {
    const { toCategorizeCount } = await getAmountOfTransactionsToCategorize();

    commit('setToCategorizeCount', { count: toCategorizeCount });
  },
  async initTransactions({ commit, dispatch }) {
    commit('invoicesMatchingTransactions', { invoices: [] });
    commit('receiptsMatchingTransactions', { receipts: [] });
    await Promise.all([dispatch('initFilters'), dispatch('filterTransactionList'), dispatch('getPendingTransactions')]);
  },
  async initFilters({ commit }) {
    commit('setIsStateReady', false);

    await Promise.all([
      getTransactionsParams().then(({ filters }) => {
        commit('setFilters', filters);
      }),
      getManualTransactionTypes().then((manualTransactionTypes) => {
        commit('setManualTransactionTypes', manualTransactionTypes);
      }),
    ]);

    commit('setIsStateReady', true);
  },
  async filterTransactionList({ state, rootGetters, commit, dispatch }) {
    commit('setIsFiltering', true);
    commit('resetTransactionsSummary');
    commit('resetTransactionList', { transactions: {} });

    const { transactions, toCategorizeCount } = await getTransactionsPaginated(state.params);
    const transactionsUpdated = mergeTransactionsAndRefreshList({
      oldTransactions: {},
      newTransactions: keyBy(transactions, '_id'),
      params: state.params,
      fiscalConfiguration: rootGetters['user/currentFiscalYearConfiguration'],
    });

    commit('setTransactions', { transactions: transactionsUpdated });
    commit('setToCategorizeCount', { count: toCategorizeCount });
    commit('setIsFiltering', false);

    dispatch('getReceiptsAndInvoicesAndMatchingTransactions', { transactions });
    dispatch('getTransactionsSummary');
  },
  async getTransactionsSummary({ state, commit }) {
    const { totalAmountInCents, totalIncomeAmountInCents, totalExpenseAmountInCents, totalTransactionsCount } =
      await getTransactionsSummary(state.params);

    commit('setTransactionsSummary', {
      totalAmountInCents,
      totalIncomeAmountInCents,
      totalExpenseAmountInCents,
      totalTransactionsCount,
    });
  },
  async getPendingTransactions({ commit }) {
    const { pendingTransactions } = await getPendingTransactions();

    commit('setPendingTransactions', {
      pendingTransactions,
    });
  },
  async getReceiptsAndInvoicesAndMatchingTransactions({ commit, dispatch, rootGetters }, { transactions }) {
    try {
      const { receipts, invoices, matchingInfoWithTransactionIds } = await getReceiptsAndInvoicesAndMatchingInfo({
        transactions,
      });

      commit('receiptsMatchingTransactions', { receipts });
      commit('invoicesMatchingTransactions', { invoices });

      const transactionsWithMatchingInfos = getTransactionsWithMatchingInfo({
        transactions: keyBy(transactions, '_id'),
        matchingInfoWithTransactionIds,
      });

      await dispatch('updateTransactions', transactionsWithMatchingInfos);
    } catch (err) {
      logger.error({
        userId: rootGetters['user/userId'],
        msg: '[transactions.store] Cannot getReceiptsAndInvoicesAndMatchingTransactions',
        args: { err },
      });
    }
  },
  async showMoreTransactions({ state, getters, commit, dispatch }) {
    commit('nextPage');

    const { transactions } = await getTransactionsPaginated(getters.params, state.page);

    if (isEmpty(transactions)) {
      commit('noMoreTransactions');
    }

    await dispatch('updateTransactions', keyBy(transactions, '_id'));

    dispatch('getReceiptsAndInvoicesAndMatchingTransactions', { transactions });
  },
  updateTransactions({ state, commit, rootGetters }, transactions) {
    const transactionsFiltered = mergeTransactionsAndRefreshList({
      oldTransactions: state.list,
      newTransactions: keyBy(transactions, '_id'),
      params: state.params,
      fiscalConfiguration: rootGetters['user/currentFiscalYearConfiguration'],
    });

    commit('setTransactions', { transactions: transactionsFiltered });
  },
  updateTransaction({ state, commit, rootGetters }, transaction) {
    const transactionsFiltered = updateTransactionAndRefreshList({
      transaction,
      transactions: state.list,
      params: state.params,
      fiscalConfiguration: rootGetters['user/currentFiscalYearConfiguration'],
    });

    commit('setTransactions', { transactions: transactionsFiltered });
  },
  insertTransaction({ state, commit, rootGetters }, transaction) {
    const transactionsFiltered = insertTransactionAndRefreshList({
      transaction,
      transactions: state.list,
      params: state.params,
      fiscalConfiguration: rootGetters['user/currentFiscalYearConfiguration'],
    });

    commit('setTransactions', { transactions: transactionsFiltered });
  },
  // -- Filters
  updateParamsToShowTransaction({ commit }, { transaction, params, filteringPeriod = 'monthly' }) {
    const filteringParams = {
      daily: {
        start: moment(transaction.date).toDate(),
        end: moment(transaction.date).toDate(),
      },
      weekly: {
        start: moment(transaction.date).startOf('week').toDate(),
        end: moment(transaction.date).endOf('week').toDate(),
      },
      monthly: {
        start: moment(transaction.date).startOf('month').toDate(),
        end: moment(transaction.date).endOf('month').toDate(),
      },
    };
    commit('resetParams');
    commit('updateParams', {
      focus: transaction._id,
      ...(params ?? {
        selectedDates: get(filteringParams, filteringPeriod),
      }),
    });
  },
  async goToTransactions({ commit, dispatch }, { params }) {
    const updatedParams = formatParamsSelectedDates({ params });

    commit('updateParams', {
      ...TRANSACTIONS_PARAMS_QUERY,
      ...updatedParams,
    });

    await router.push({ name: 'transactions' });

    await dispatch('getTransactionsSummary');
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  getters,
  actions,
};
