import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import moment from 'moment';

// Constants
import {
  DEFAULT_DESCRIPTION,
  FILTERS_KEY,
  TRANSACTIONS_FILTERS,
  TRANSACTIONS_PARAMS_QUERY,
} from '@/modules/transactions/constants/transactions.constants';
// Helpers
import { formatCents } from '@/shared/utils/amounts';

export {
  formatParamsSelectedDates,
  getActiveFilters,
  getTransactionRawDescription,
  getTransactionsWithMatchingInfo,
  removeKeyFromParams,
  transformApiFiltersForGeorges,
  transformParamsForApi,
};

function getTransactionsWithMatchingInfo({ transactions, matchingInfoWithTransactionIds }) {
  const transactionsWithMatchingInfo = { ...transactions };

  for (const { transactionId, ...matchingInfo } of matchingInfoWithTransactionIds) {
    transactionsWithMatchingInfo[transactionId] = {
      ...transactionsWithMatchingInfo[transactionId],
      ...matchingInfo,
    };
  }

  return transactionsWithMatchingInfo;
}

function transformApiFiltersForGeorges({
  allowedDates = {},
  accountOrFroms = [],
  categories = [],
  rangePresets = [],
  hasVatToBeDefinedField = false,
}) {
  const { start, end } = allowedDates;

  return {
    ...TRANSACTIONS_FILTERS,
    ...(start &&
      end && {
        allowedDates: {
          min: moment(start).startOf('day').subtract(1, 'day').toDate(),
          max: moment(end).endOf('day').add(1, 'day').toDate(),
        },
      }),
    accountOrFroms,
    categories,
    rangePresets,
    hasVatToBeDefinedField,
  };
}

function transformParamsForApi({
  displayAmounts = {},
  displayAttachments,
  displayIngressOutflow,
  deleted,
  displayOnlyVatToBeDefined,
  // focus,
  search,
  selectedAccounts = [],
  selectedCategories = [],
  selectedDates = {},
} = {}) {
  return {
    categories: flatMap(selectedCategories, 'id'),
    accounts: flatMap(selectedAccounts, 'id'),
    search,
    ...(deleted && { deleted }),
    ...(displayAmounts.from && { amountFrom: displayAmounts.from }),
    ...(displayAmounts.to && { amountTo: displayAmounts.to }),
    ...(selectedDates.start && { dateFrom: selectedDates.start }),
    ...(selectedDates.end && { dateTo: selectedDates.end }),
    ...(displayAttachments && { hasReceipt: displayAttachments === 'with' }),
    ...(displayIngressOutflow && { displayIngressOutflow }),
    ...(displayOnlyVatToBeDefined && { displayOnlyVatToBeDefined: true }),
  };
}

function removeKeyFromParams(filterKey, params = TRANSACTIONS_PARAMS_QUERY) {
  // ------------------------
  // FILTERS_KEY doesn't include categories & accounts, so :
  // 1. If key isnt defined in FILTERS_KEY
  // It means it's an accountSelected||categoriesSelected key
  // ------------------------
  const shouldClearAccountOrCategory = !FILTERS_KEY[filterKey] && filterKey !== 'focus';
  if (shouldClearAccountOrCategory) {
    const isAccountsKey = params.selectedAccounts.find((selectedAccount) => selectedAccount.id === filterKey);
    const paramsKeyToUpdate = isAccountsKey ? 'selectedAccounts' : 'selectedCategories';

    return {
      ...params,
      [paramsKeyToUpdate]: params[paramsKeyToUpdate].filter((value) => value.id !== filterKey),
    };
  }

  // ------------------------
  // If key is date, reset selectedDates
  // ------------------------
  const shouldClearDates = filterKey === FILTERS_KEY.date;
  if (shouldClearDates) {
    return { ...params, selectedDates: TRANSACTIONS_PARAMS_QUERY.selectedDates };
  }

  return { ...params, [filterKey]: TRANSACTIONS_PARAMS_QUERY[filterKey] };
}

function getActiveFilters({
  search,
  selectedDates = {},
  selectedAccounts = [],
  selectedCategories = [],
  displayOnlyVatToBeDefined,
  deleted,
  displayAttachments,
  displayIngressOutflow,
  displayAmounts = {},
}) {
  // Dates
  const { start, end } = selectedDates;
  const isDateFiltered = start && end;
  const dateFilter = `${moment(start).format('DD MMMM YYYY')} ~ ${moment(end).format('DD MMMM YYYY')}`;

  // Accounts
  const selectedAccountsName = compact(selectedAccounts).map((account) => ({
    [account.id]: account.title,
  }));

  // Categories
  const MAX_AMOUNT_OF_CATEGORIES = 4;
  const hasTooMuchCategories = selectedCategories.length > MAX_AMOUNT_OF_CATEGORIES;
  const selectedCategoriesName = hasTooMuchCategories
    ? [{ selectedCategories: `${selectedCategories.length} catégories` }]
    : compact(selectedCategories).map((category) => ({ [category.id]: category.name }));

  // Attachments
  const displayAttachmentsText = displayAttachments === 'with' ? 'Contenant un justificatif' : 'Sans justificatif';

  // IngressOutflow
  const displayIngressOutflowText = displayIngressOutflow === 'ingress' ? 'Entrées' : 'Sorties';

  // Amounts
  const areAmountsDefined = Object.values(displayAmounts).some((amount) => Boolean(amount));
  const displayAmountsText = amountFilterText(displayAmounts);

  const displayDeletedText = deleted === 'yes' ? 'Supprimées' : 'Non supprimées';

  const defaultActiveFilters = {
    ...(search && search !== '' && { search }),
    ...(isDateFiltered && { date: dateFilter }),
    ...(displayOnlyVatToBeDefined && { displayOnlyVatToBeDefined: 'TVA à définir' }),
    ...(deleted && { deleted: displayDeletedText }),
    ...(displayAttachments && { displayAttachments: displayAttachmentsText }),
    ...(displayIngressOutflow && { displayIngressOutflow: displayIngressOutflowText }),
    ...(areAmountsDefined && { displayAmounts: displayAmountsText }),
  };

  return Object.assign({}, defaultActiveFilters, ...selectedAccountsName, ...selectedCategoriesName);
}

function amountFilterText({ from, to }) {
  if (from && to) return `Entre ${formatCents(from * 100)} € et ${formatCents(to * 100)} €`;
  if (to) return `Inférieur à ${formatCents(to * 100)} €`;
  return `Supérieur à ${formatCents(from * 100)} €`;
}

function getTransactionRawDescription({ transaction }) {
  if (!transaction) return;
  // Imported transactions have a default raw_description which is meaningless to users,
  // so we need to take description instead in that case
  if (transaction.raw_description === DEFAULT_DESCRIPTION) {
    return transaction.description;
  }
  return transaction.raw_description;
}

function formatParamsSelectedDates({ params }) {
  return {
    ...omit(params, 'selectedDates'),
    ...omitBy(
      {
        selectedDates: omitBy(
          {
            start:
              params.selectedDates?.start &&
              moment(params.selectedDates.start, [moment.ISO_8601, 'YYYY-MM-DD']).format('YYYY-MM-DD'),
            end:
              params.selectedDates?.end &&
              moment(params.selectedDates.end, [moment.ISO_8601, 'YYYY-MM-DD']).format('YYYY-MM-DD'),
          },
          isNil,
        ),
      },
      isEmpty,
    ),
  };
}
