import compact from 'lodash/compact';

import { getFirstUncompletedStepKey } from '@/modules/onboarding/onboarding.utils';

import { userNeedsToBeDisconnectedToCreateCompany } from './authentication/authentication.models';

export default function ({ to, next }, { store }) {
  const isUserAuthenticated = store.getters['user/isAuthenticated'];

  if (isUserAuthenticated) {
    return routeAuthenticatedUser({ to, next }, { store });
  }

  return routeUnauthenticatedUser({ to, next });
}

async function routeAuthenticatedUser({ to, next }, { store }) {
  const routeName = to?.name;
  const isALoginRoute = isTryingToLoadLoginOrPasswordResetRoute(routeName);
  const isAnOnboardingRoute = isTryingToLoadOnboardinRoute(to);
  const isABankConnectorFunnelRoute = isTryingToLoadBankConnectorFunnelRoute(routeName);
  const userNeedsToFinishOnboarding = !store.getters['user/isUserOnboard'];
  const userHasRequiredRole = isUserHasRequiredRole(to, store);
  const userNeedsToBeDisconnectedForCompanyCreation = userNeedsToBeDisconnectedToCreateCompany({
    queryParams: to.query,
    isAlreadyOnCreation: store.getters['user/isInCompanyCreationMode'],
  });

  if (userNeedsToBeDisconnectedForCompanyCreation) {
    await store.dispatch('user/signOut');
    return next({ name: 'onboarding', query: to.query });
  }
  if (isALoginRoute) {
    const redirectToPath = to.query.redirectToPath;
    if (redirectToPath) {
      return next(redirectToPath);
    }
    return next({ name: 'home' });
  }
  if (isABankConnectorFunnelRoute) {
    return next();
  }
  if (userNeedsToFinishOnboarding && !isAnOnboardingRoute) {
    return next({ name: await getFirstUncompletedStepKey({ store }), params: to.params, query: to.query });
  }
  if (!userNeedsToFinishOnboarding && isAnOnboardingRoute) {
    return next({ name: 'home' });
  }
  if (!userHasRequiredRole) {
    return next({ name: 'home' });
  }

  return next();
}

function routeUnauthenticatedUser({ to, next }) {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    return next({ name: 'login', query: { redirectTo: to.name } });
  }

  return next();
}

function isUserHasRequiredRole(to, store) {
  const routeRequiredRole = compact(to.matched.flatMap((record) => record.meta.requiresRole));
  return routeRequiredRole && store.getters['auth/hasAllRequiredPermissions'](routeRequiredRole);
}

function isTryingToLoadLoginOrPasswordResetRoute(routeName) {
  const ROUTES_NAMES = ['login', 'forgotPassword', 'resetPassword'];

  return ROUTES_NAMES.includes(routeName);
}

function isTryingToLoadOnboardinRoute(to) {
  return to.name === 'onboarding' || to.matched.some((record) => record.name === 'onboarding');
}

function isTryingToLoadBankConnectorFunnelRoute(routeName) {
  const BANK_CONNECTOR_FUNNEL_ROUTES_NAMES = [
    'frameReceivingItem',
    'waitBankinFunnelOpening',
    'waitBankSynchroRedirection',
    'shineRedirect',
    'qontoRedirect',
    'swanRedirect',
  ];

  return BANK_CONNECTOR_FUNNEL_ROUTES_NAMES.includes(routeName);
}
