import get from 'lodash/get';
import partition from 'lodash/partition';

import {
  getActivitiesToDoCount,
  getAdminOnlyActivities,
  getAvailableActivities,
  getUpcomingActivities,
} from '@/modules/newActivities/models/filterAndSortActivities';
// Models
import generateActivityList, { generateSlicedActivities } from '@/modules/newActivities/models/generateActivityList';
// Services
import { refreshActivities } from '@/modules/newActivities/services/georges';
// Stores
import activity from '@/modules/newActivities/stores/activity.store';
import bicIsFiscalDeclarationActivity from '@/modules/newActivities/stores/bicIsFiscalDeclarationActivity.store';
import bicIsInitialisationDuBilanActivity from '@/modules/newActivities/stores/bicIsInitialisationDuBilanActivity.store';
import bicIsPvOfAgActivity from '@/modules/newActivities/stores/bicIsPvOfAgActivity.store';
import bicIsSoldeIsActivity from '@/modules/newActivities/stores/bicIsSoldeIsActivity.store';
import bncInitializationActivity from '@/modules/newActivities/stores/bncInitializationActivity.store';
import caDeclarationActivity from '@/modules/newActivities/stores/caDeclarationActivity.store';
import d2035Activity from '@/modules/newActivities/stores/d2035Activity.store';
import d2036Activity from '@/modules/newActivities/stores/d2036Activity.store';
import das2Activity from '@/modules/newActivities/stores/das2Activity.store';
import dCfeActivity from '@/modules/newActivities/stores/dCfeActivity.store';
import incomeDeclarationActivity from '@/modules/newActivities/stores/incomeDeclarationActivity.store';
import initBankAccountsBalancesActivity from '@/modules/newActivities/stores/initBankAccountsBalancesActivity.store';
import isAdvancePaymentActivity from '@/modules/newActivities/stores/isAdvancePaymentActivity.store';
import microBicFiscalDeclarationActivity from '@/modules/newActivities/stores/microBicFiscalDeclarationActivity.store';
import microBncActivity from '@/modules/newActivities/stores/microBncActivity.store';
import payslipActivity from '@/modules/newActivities/stores/payslipActivity.store';
import payslipInitializationActivity from '@/modules/newActivities/stores/payslipInitializationActivity.store';
import retailActivity from '@/modules/newActivities/stores/retailActivity.store';
import sciAccountingInitialisationActivity from '@/modules/newActivities/stores/sciAccountingInitialisationActivity.store';
import sciFiscalDeclarationActivity from '@/modules/newActivities/stores/sciFiscalDeclarationActivity.store';
import sciIsAccountingInitialisationActivity from '@/modules/newActivities/stores/sciIsAccountingInitialisationActivity.store';
import sciIsFiscalDeclarationActivity from '@/modules/newActivities/stores/sciIsFiscalDeclarationActivity.store';
import sciIsProcesVerbalAssembleeGeneraleActivity from '@/modules/newActivities/stores/sciIsProcesVerbalAssembleeGeneraleActivity.store';
import sciIsSoldeIsActivity from '@/modules/newActivities/stores/sciIsSoldeIsActivity.store';
import sciProcesVerbalAssembleeGeneraleActivity from '@/modules/newActivities/stores/sciProcesVerbalAssembleeGeneraleActivity.store';
import scmAccountingInitialisationActivity from '@/modules/newActivities/stores/scmAccountingInitialisationActivity.store';
import socialDeclarationActivity from '@/modules/newActivities/stores/socialDeclarationActivity.store';
import vatActivity from '@/modules/newActivities/stores/vatActivity.store';
import vatAdvancePaymentActivity from '@/modules/newActivities/stores/vatAdvancePaymentActivity.store';
import vehicleTaxesActivity from '@/modules/newActivities/stores/vehicleTaxesActivity.store';

const modules = {
  activity,
  bicIsFiscalDeclarationActivity,
  bicIsInitialisationDuBilanActivity,
  bicIsPvOfAgActivity,
  bncInitializationActivity,
  caDeclarationActivity,
  d2035Activity,
  das2Activity,
  incomeDeclarationActivity,
  initBankAccountsBalancesActivity,
  isAdvancePaymentActivity,
  microBicFiscalDeclarationActivity,
  payslipActivity,
  payslipInitializationActivity,
  socialDeclarationActivity,
  vatActivity,
  vatAdvancePaymentActivity,
  bicIsSoldeIsActivity,
  microBncActivity,
  dCfeActivity,
  sciAccountingInitialisationActivity,
  sciFiscalDeclarationActivity,
  sciProcesVerbalAssembleeGeneraleActivity,
  sciIsAccountingInitialisationActivity,
  sciIsProcesVerbalAssembleeGeneraleActivity,
  sciIsFiscalDeclarationActivity,
  sciIsSoldeIsActivity,
  retailActivity,
  d2036Activity,
  scmAccountingInitialisationActivity,
  vehicleTaxesActivity,
};

export const stateData = {
  list: {},
  loading: true,
  loadingActivities: {},
  inErrorActivities: {},
  isUpcomingActivityVisible: true,
};

export const getters = {
  loading: ({ loading }) => loading,
  isUpcomingActivityVisible: ({ isUpcomingActivityVisible }) => isUpcomingActivityVisible,
  activitiesToDoCount: ({ list: activities }) => getActivitiesToDoCount(generateSlicedActivities(activities)),
  availableActivities: ({ list: activities }) => getAvailableActivities(generateSlicedActivities(activities)),
  adminOnlyActivities: ({ list: activities }) => getAdminOnlyActivities(generateSlicedActivities(activities)),
  upcomingActivities: ({ list: activities }) => getUpcomingActivities(activities),
  getActivity:
    ({ list: activities }) =>
    ({ key, periodKey = 'current' }) =>
      get(activities, [key, 'items', periodKey], {}),
  isActivityAvailable:
    (state, { getActivity }) =>
    ({ key }) => {
      const activity = getActivity({ key });
      return activity.status?.available;
    },
  isActivityLoading:
    ({ loadingActivities }) =>
    ({ key, periodKey }) =>
      get(loadingActivities, [key, periodKey], true),
  isActivityInError:
    ({ inErrorActivities }) =>
    ({ key, periodKey }) =>
      get(inErrorActivities, [key, periodKey], false),
};

export const mutations = {
  setLoading: (state, loading) => {
    state.loading = loading;
    if (loading) {
      state.loadingActivities = {};
    }
  },
  setActivityLoading: (state, { key, periodKey, loading }) => {
    state.loadingActivities = {
      ...state.loadingActivities,
      [key]: {
        ...state.loadingActivities[key],
        [periodKey]: loading,
      },
    };
  },
  setActivityInError: (state, { key, periodKey, inError }) => {
    state.inErrorActivities = {
      ...state.inErrorActivities,
      [key]: {
        ...state.inErrorActivities[key],
        [periodKey]: inError,
      },
    };
  },
  toggleUpcomingPanel: (state) => {
    state.isUpcomingActivityVisible = !state.isUpcomingActivityVisible;
  },
  setIsUpcomingActivityVisible: (state, { isUpcomingActivityVisible }) => {
    state.isUpcomingActivityVisible = isUpcomingActivityVisible;
  },
  setActivityList: (state, { list }) => {
    state.list = list;
  },
  updateActivity: (state, { key, periodKey, activity }) => {
    state.list[key].items[periodKey] = activity;
  },
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async refreshActivities({ commit }) {
    const { activities } = await refreshActivities();
    const list = generateActivityList(activities, modules);
    commit('setActivityList', { list });
  },
  async refreshActivitiesWithClosingStates({ state, commit, dispatch }) {
    if (!state.loading) commit('setLoading', true);

    // Refresh activities
    await dispatch('refreshActivities');

    const activitiesToRefresh = Object.values(generateSlicedActivities(state.list));
    // Fetch closingStates & generate steps for each activity
    await Promise.all(
      activitiesToRefresh.map(async ({ items }) => {
        // current/next is slightly different from available/upcoming
        // the former depends on which activity is currently being done & closed (a.k.a. the latest closing state),
        // while the latter depends on whether activity due time is now or future
        const [nextOccurences, currentOccurences] = partition(items, 'isNextOccurrence');
        // Next occurences stores depend on current occurences to be initialized first
        await Promise.all(
          currentOccurences.map(({ key, periodKey }) => dispatch('refreshActivity', { key, periodKey })),
        );
        await Promise.all(nextOccurences.map(({ key, periodKey }) => dispatch('refreshActivity', { key, periodKey })));
      }),
    );

    commit('setLoading', false);
  },
  async refreshActivity({ state, commit, dispatch, rootGetters }, { key, periodKey = 'current' } = {}) {
    commit('setActivityLoading', { key, periodKey, loading: true });
    commit('setActivityInError', { key, periodKey, inError: false });

    // User context is required to define activity & steps availability
    const userIsClient = rootGetters['user/userIsClient'];
    const userIsDemo = rootGetters['user/userIsDemo'];
    const userIsChurned = rootGetters['user/isChurned'];
    const userIsInBnc = rootGetters['user/isUserInBnc'];
    const isUserInIsSas = rootGetters['user/isUserInIsSas'];

    // Generate activity context
    const activity = get(state.list, [key, 'items', periodKey]);

    const updatedActivity = { ...activity };

    try {
      const steps = await dispatch(`${key}/getClosingStateSteps`, {
        activity,
        user: { userIsInBnc, userIsClient, userIsDemo, userIsChurned, isUserInIsSas },
      });

      // Update activity with context
      updatedActivity.steps = steps;
      commit('updateActivity', { key, periodKey, activity: updatedActivity });
    } catch (error) {
      commit('setActivityInError', { key, periodKey, inError: true });
    }
    commit('setActivityLoading', { key, periodKey, loading: false });
    return updatedActivity;
  },
  resetStateData({ commit }) {
    commit('resetStateData');
    for (const [moduleKey] of Object.entries(modules)) {
      commit(`${moduleKey}/resetStateData`);
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
  modules,
};
