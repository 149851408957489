import Trackable from '@/shared/mixins/trackable';

export const trackReferralEvent = ({ action, campaignTag, currentReferralCampaignType }) => {
  Trackable.methods.trackEvent({
    action,
    properties: {
      location: 'navbar',
      referralCampaignTag: campaignTag,
      referralCampaignType: currentReferralCampaignType,
    },
  });
};
