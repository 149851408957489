// Utilities
import get from 'lodash/get';

import { COLORS_CHARTS } from '@/shared/constants';
import { amountAsText, formatAmountInCentsToNoCents } from '@/shared/utils/amounts';

// TODO: Move this to server
export const DASHBOARD_PANELS = {
  chargesExploitationPanel: (data = {}) => ({
    id: 'chargesExploitationPanel',
    ...data,
    amountInCents: data.chargesExploitationTotalInCents,
    chart: {
      labels: data.chargesExploitationCategoriesForPanel.map(({ name }) => name),
      data: data.chargesExploitationCategoriesForPanel.map(({ total }) =>
        formatAmountInCentsToNoCents({ amountInCents: total }),
      ),
      backgroundColor: data.chargesExploitationCategoriesForPanel.map(({ name }) =>
        get(COLORS_CHARTS, ['chargesExploitationColors', name]),
      ),
      legends: Object.entries(data.chargesExploitationSubcategoriesByKeyForPanel).reduce(
        (acc, [key, values]) => ({
          ...acc,
          [key]: {
            ...values,
            color: get(COLORS_CHARTS, ['chargesExploitationColors', key]),
            total: amountAsText({ amountInCents: values.total }),
          },
        }),
        {},
      ),
    },
  }),
  compteCourantAssociePanel: (data = {}) => ({
    id: 'compteCourantAssociePanel',
    ...data,
    amountInCents: data.soldeCompteCourantAssocie,
  }),
  facturation: (data = {}) => ({
    id: 'facturation',
    ...data,
    amountInCents: data.waitingForPaymentAmount,
  }),
  isAPayerPanel: (data = {}) => ({
    id: 'isAPayerPanel',
    ...data,
  }),
  personalExpensePanel: (data = {}) => ({
    id: 'personalExpensesPanel',
    ...data,
    amountInCents: data?.totalDistribution?.totalBalanceInCents,
  }),
  professionalExpenseRatePanel: (data = {}) => ({
    id: 'professionalExpenseRatePanel',
    ...data,
  }),
  professionalExpensePanel: (data = {}) => ({
    id: 'professionalExpensePanel',
    ...data,
    amountInCents: data.totalBalanceInCents,
  }),
  professionalIncomeExpensePanel: (data = {}) => ({
    id: 'professionalIncomeExpensePanel',
    ...data,
    legend: {
      expense: {
        label: get(data.expenseBalanceDistribution, 'totalDistribution.label', 'Dépenses'),
        amountInCents: get(data.expenseBalanceDistribution, 'totalDistribution.totalBalanceInCents', 0),
      },
      income: {
        label: get(data.incomeBalanceDistribution, 'totalDistribution.label', 'Recettes'),
        amountInCents: get(data.incomeBalanceDistribution, 'totalDistribution.totalBalanceInCents', 0),
      },
      gain: {
        label: get(data.gainBalanceDistribution, 'label', 'Résultat'),
        amountInCents: get(data.gainBalanceDistribution, 'totalBalanceInCents', 0),
      },
    },
  }),
  resultatApresImpotPanel: (data = {}) => ({
    id: 'resultatApresImpotPanel',
    ...data,
    amountInCents: data.resultatDeLExerciceInCents,
  }),
  soldeBancairePanel: (data = {}) => ({
    id: 'soldeBancairePanel',
    ...data,
  }),
  tvaPanel: (data = {}) => ({
    id: 'tvaPanel',
    ...data,
  }),
  vatThresholdPanel: (data = {}) => ({
    id: 'vatThresholdPanel',
    ...data,
  }),
};

export function prepareDashboardPanels(data) {
  return {
    ...data,
    panels: Object.entries(data.panels).reduce(
      (acc, [key, values]) => ({
        ...acc,
        [key]: DASHBOARD_PANELS?.[key]?.(values) ?? values,
      }),
      {},
    ),
  };
}
