import georgesApi from '@/shared/apis/georges';

export async function getD2036Activity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/activities/scm/d2036',
  });

  return data;
}
