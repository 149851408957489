import uniqBy from 'lodash/uniqBy';

import { hasBankAccountForBankAuthId } from '@/modules/bankConnector/bankAccount/bankAccount.models';
import { fetchBankAccounts } from '@/modules/bankConnector/bankAccount/bankAccount.services';
import { logger } from '@/shared/services/logger/logger';

export const defaultStateData = {
  bankAccounts: [],
  areBankAccountsFetched: false,
};

export const mutations = {
  setBankAccounts(state, bankAccounts) {
    state.bankAccounts = bankAccounts;
  },
  setAreBankAccountsFetched(state, areBankAccountsFetched) {
    state.areBankAccountsFetched = areBankAccountsFetched;
  },
  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, defaultStateData);
  },
};

export const getters = {
  bankAccounts: ({ bankAccounts }) => bankAccounts,
  bankAccountsWithNoSuspended: ({ bankAccounts }) =>
    bankAccounts.filter((bankAccount) => bankAccount.suspended !== true),
  bankAccount:
    ({ bankAccounts }) =>
    ({ bankAccountId }) => {
      return bankAccounts.find((bankAccount) => bankAccount._id === bankAccountId);
    },
  hasBankAccount: ({ bankAccounts }) => bankAccounts.length > 0,
  countDistinctBank: ({ bankAccounts }) => {
    return uniqBy(bankAccounts, 'bank.logoUrl').length;
  },
  hasBankAccountForBankAuthId: ({ bankAccounts }) => hasBankAccountForBankAuthId({ bankAccounts }),
  areBankAccountsFetched: ({ areBankAccountsFetched }) => areBankAccountsFetched,
};

export const actions = {
  async fetchBankAccounts({ commit }) {
    try {
      const bankAccounts = await fetchBankAccounts();
      commit('setBankAccounts', bankAccounts);
      commit('setAreBankAccountsFetched', true);
    } catch (error) {
      logger.error('Error while fetching bank accounts', error);
    }
  },
};

export default {
  namespaced: true,
  state: defaultStateData,
  mutations,
  getters,
  actions,
};
