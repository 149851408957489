import { DECLARATIONS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Computed
import { everyStepsDone } from '@/modules/newActivities/utils/steps';
// Services
import {
  fetchClosingState,
  refreshClosingState,
  refreshCurrentClosingState,
} from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: true,
  closingStates: {},
  defaultSteps: {
    categorizeAll: {
      title: 'Catégoriser toutes les transactions',
      shortTitle: 'Catégoriser les transactions',
      availableBeforeEndYear: true,
    },
    assets: {
      title: 'Immobilisations, amortissements et cessions',
      shortTitle: 'Immobilisations',
      availableBeforeEndYear: true,
    },
    lunch: {
      title: 'Ventiler les repas hors domicile',
      shortTitle: 'Frais de repas',
      availableBeforeEndYear: true,
    },
    carFees: {
      title: 'Retraiter les frais de véhicule',
      shortTitle: 'Frais de véhicule',
      availableBeforeEndYear: true,
    },
    loanings: {
      title: "Ventiler les échéances d'emprunts",
      shortTitle: 'Échéances d’emprunt',
      availableBeforeEndYear: true,
    },
    lateChequesPreviousYear: {
      title: ({ fiscalPeriodId }) => `Chèques non encaissés de ${Number(fiscalPeriodId) - 1}`,
      shortTitle: ({ fiscalPeriodId }) => `Chèques non encaissés ${Number(fiscalPeriodId) - 1}`,
      availableBeforeEndYear: true,
    },
    lateChequesThisYear: {
      title: ({ fiscalPeriodId }) => `Chèques non encaissés de ${Number(fiscalPeriodId)}`,
      shortTitle: ({ fiscalPeriodId }) => `Chèques non encaissés ${Number(fiscalPeriodId)}`,
      availableOnlyForClients: true,
    },
    laundry: {
      title: 'Saisir les frais de blanchissage à domicile',
      shortTitle: 'Frais de blanchissage',
      availableOnlyForClients: true,
    },
    kilometerOption: {
      title: 'Barème kilométrique forfaitaire',
      shortTitle: 'Barème kilométrique',
      availableOnlyForClients: true,
      description:
        'Vous avez opté pour le barème kilométrique. Les kilomètres professionnels ouvrent droit à une déduction forfaitaire. Ils incluent les déplacements domicile - travail et les déplacements chez vos clients.',
      declarationForKilometricPackageNotPublishedAlert: 'déclaration 2035',
    },
    initializeFinancialAccounts: {
      title: 'Vérifier les comptes financiers',
      shortTitle: 'Soldes financiers',
      availableOnlyForClients: true,
    },
    cashRegister: {
      title: 'Évolution de la caisse',
      shortTitle: 'Caisse',
      availableOnlyForClients: true,
    },
    urssaf: {
      title: 'Cotisations Urssaf à ventiler',
      shortTitle: 'Cotisations Urssaf',
      availableOnlyForClients: true,
    },
    scm: {
      title: 'Saisie du compte commun ou de la SCM',
      shortTitle: 'Compte commun / SCM',
      availableOnlyForClients: true,
    },
    accountingCosts: {
      title: "Réductions d'impôt pour les frais de comptabilité",
      shortTitle: 'Frais de comptabilité',
      availableOnlyForClients: true,
    },
    madelin: {
      title: 'Cotisations facultatives déductibles',
      shortTitle: 'Cotisations facultatives',
      availableOnlyForClients: true,
    },
    fiscalExemptions: {
      title: "Exonérations fiscales et crédits d'impôts",
      shortTitle: 'Exonérations et crédits d’impôts',
      availableOnlyForClients: true,
    },
    identity: {
      title: "Identité de l'entrepreneur",
      shortTitle: "Identité de l'entrepreneur",
      availableOnlyForClients: true,
    },
    activity: {
      title: "Informations relatives à l'activité",
      shortTitle: "Informations relatives à l'activité",
      availableOnlyForClients: true,
    },
    fiscalExercise: {
      title: "Informations relatives à l'exercice fiscal",
      shortTitle: 'Exercice fiscal',
      availableOnlyForClients: true,
    },
    vatCompliance: {
      title: 'Régularisation de TVA',
      shortTitle: 'Régularisation de TVA',
      availableOnlyForClients: true,
    },
    d2035ValidateClosing: {
      title: 'Valider la clôture',
      shortTitle: 'Valider la clôture',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,

  // -- Get a specific CLOSING STATE for a given YEAR
  getClosingState:
    ({ closingStates }) =>
    ({ year }) =>
      closingStates[year],

  // -- Detect if current closing state steps are done
  currentClosingStepsAllDone: (
    state,
    { getClosingState },
    rootState,
    { 'user/currentFiscalYear': userCurrentFiscalYear },
  ) => {
    const closingState = getClosingState({ year: userCurrentFiscalYear });
    return everyStepsDone({ steps: closingState?.steps });
  },
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingStates(state, { year, closingState }) {
    state.closingStates = {
      ...state.closingStates,
      [year]: closingState,
    };
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, getters, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];
    const closingState = getters.getClosingState({ year: userCurrentFiscalYear });

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        minDateToClose: moment(activity.date?.minDate),
        fiscalPeriodId: String(userCurrentFiscalYear),
        isInEarlyClosing: get(closingState, 'is_in_early_closing', false),
      },
      user,
      activity,
    });
  },

  // -- Refresh
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const { closingState } = await refreshCurrentClosingState({
      declaration: DECLARATIONS.D_2035.KEY,
    });

    commit('setClosingStates', {
      year: closingState.fiscal_year,
      closingState,
    });
    commit('setLoading', false);
  },

  // -- Refresh Specific
  async refresh({ commit }, { year }) {
    commit('setLoading', true);
    const { closingState } = await refreshClosingState({
      declaration: DECLARATIONS.D_2035.KEY,
      fiscalPeriodId: year,
    });
    commit('setClosingStates', { year, closingState });
    commit('setLoading', false);
  },

  // -- Fetch specific
  async fetch({ commit }, { year }) {
    commit('setLoading', true);
    const { closingState } = await fetchClosingState({
      declaration: DECLARATIONS.D_2035.KEY,
      fiscalPeriodId: year,
    });
    commit('setClosingStates', { year, closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
