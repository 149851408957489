import { hasAllRequiredPermissions } from '@/modules/auth/rbac.model.js';
import { getCustomTokenFromAutoLoginCode, signInWithIndy } from '@/modules/auth/services/auth.services';
import {
  getUserIdTokenFromFirebase,
  listenToFirebaseAuthStateChange,
  listenToIdTokenChange,
  resetPasswordWithFirebase,
  sendPasswordResetEmailWithFirebase,
  signInWithFirebaseWithCustomToken,
  signOutWithFirebase,
  verifyEmailWithFirebase,
  waitForFirebaseAuthStateChange,
} from '@/modules/auth/services/firebase.services';
import { clearImpersonateUserId, initImpersonateUserId } from '@/modules/auth/services/impersonate.services';
import * as loggerModule from '@/shared/services/logger/logger';
import { logger } from '@/shared/services/logger/logger';
import { analyticsLoggedOutFetchEvent } from '@/shared/utils/analytics';
import { isInMobileWebView } from '@/shared/utils/device';
import { $gnotify } from '@/shared/utils/notify';

export const stateData = {
  isLoading: true,
  authenticated: false,
  impersonateUserId: undefined,
  permissions: [],
  sudo: {
    token: undefined,
    expiresAt: undefined,
  },
  shouldShowAuthChangedModal: false,
  hasPendingAuthRequest: false,
  // private
  authUser: undefined,
};

export const getters = {
  isAuthenticated: ({ authenticated }) => authenticated,
  isImpersonating: ({ impersonateUserId }) => Boolean(impersonateUserId),
  hasAllRequiredPermissions: (state) => (requiredPermissions) =>
    hasAllRequiredPermissions({ permissions: state.permissions, requiredPermissions }),
};

export const mutations = {
  setIsLoading: (state) => {
    state.isLoading = true;
  },
  setAuthUser: (state, { authUser, idToken }) => {
    // resolve queued requests for idToken
    const { impersonateUserId } = state;
    resolveUserCredentials({ idToken, impersonateUserId });
    state.isLoading = false;
    state.authenticated = Boolean(idToken);
    state.authUser = authUser;
  },
  setImpersonateUserId: (state, { impersonateUserId }) => {
    state.impersonateUserId = impersonateUserId;
  },
  setAuthPermissions: (state, { permissions = [] }) => {
    state.permissions = permissions;
  },
  setSudoToken: (state, { sudoToken, expiresAt }) => {
    state.sudo.token = sudoToken;
    state.sudo.expiresAt = new Date(expiresAt);
  },
  unsetSudoToken: (state) => {
    state.sudo.token = undefined;
    state.sudo.expiresAt = undefined;
  },
  setShouldShowAuthChangedModal: (state) => {
    if (!window.GEORGES_SETTINGS.featureFlags?.enableUserTabChangeAlertModal) {
      return;
    }

    if (isInMobileWebView()) {
      return;
    }

    state.shouldShowAuthChangedModal = true;

    logger.log({
      userId: state.authUser?.uid,
      msg: '[AuthChangedModal] user has changed its connection in another tab',
      args: {
        currentAuthUser: state.authUser?.uid,
      },
    });
  },
  setHasPendingAuthRequest: (state, hasPendingAuthRequest) => {
    state.hasPendingAuthRequest = hasPendingAuthRequest;
  },
};

export const actions = {
  init: async ({ state, commit }, routeContext) => {
    const impersonateUserId = initImpersonateUserId(routeContext);
    let isAuthStateInit = false;
    commit('setImpersonateUserId', { impersonateUserId });

    const onIdTokenChanged = ({ user, claims, idToken }) => {
      if (user) {
        const authUserId = claims?.userId ?? user.uid;
        const userId = impersonateUserId ?? authUserId;
        loggerModule.onUserLogin({ userId, authUserId });
      } else {
        loggerModule.onUserLogout();
      }
      commit('setAuthUser', { authUser: user, idToken });
    };

    const onAuthStateChanged = () => {
      if (isAuthStateInit && !state.hasPendingAuthRequest) {
        commit('setShouldShowAuthChangedModal');
      }
      isAuthStateInit = true;
    };

    const firebaseState = await waitForFirebaseAuthStateChange();

    onIdTokenChanged(firebaseState);

    // with this, the store mirrors the state of Firebase DB
    listenToFirebaseAuthStateChange(onAuthStateChanged);
    listenToIdTokenChange(onIdTokenChanged);
  },
  getAuthCredentials: async ({ state }) => {
    const { isLoading, impersonateUserId } = state;

    // requests for idToken while loading are queued and receive a promise
    if (isLoading) {
      /* eslint-disable-next-line */
      return new Promise((resolve) => USER_CREDENTIALS_RESOLVES.push(resolve));
    }

    const { token: idToken } = await getUserIdTokenFromFirebase();
    return { idToken, impersonateUserId };
  },
  signInUser: async ({ commit }, { email, password, turnstileToken, mfaVerifyPayload }) => {
    commit('setIsLoading'); // lock requests for idToken
    await signOutWithFirebase();
    await signInWithIndy({ email, password, turnstileToken, mfaVerifyPayload });
  },
  signInUserWithAutoLoginCode: async ({ commit }, { autoLoginCode }) => {
    try {
      commit('setIsLoading'); // lock requests for idToken
      await signOutWithFirebase();
      const customToken = await getCustomTokenFromAutoLoginCode({ autoLoginCode });
      await signInWithFirebaseWithCustomToken({ token: customToken });
    } catch (err) {
      throw new Error("Une erreur est survenue lors de l'authentification");
    }
  },
  signInUserWithCustomToken: async ({ commit }, { token }) => {
    try {
      commit('setIsLoading'); // lock requests for idToken
      await signOutWithFirebase();
      await signInWithFirebaseWithCustomToken({ token });
    } catch (err) {
      throw new Error("Une erreur est survenue lors de l'authentification");
    }
  },
  signOutUser: async ({ commit, dispatch }) => {
    commit('setIsLoading'); // lock requests for idToken
    commit('setHasPendingAuthRequest', true);
    await dispatch('clearImpersonateUserId');
    await signOutWithFirebase();
    analyticsLoggedOutFetchEvent();
    commit('setHasPendingAuthRequest', false);
  },
  clearImpersonateUserId: ({ commit }) => {
    commit('setImpersonateUserId', {});
    clearImpersonateUserId();
  },
  sendPasswordResetEmail: async (store, { email }) => {
    await sendPasswordResetEmailWithFirebase({ email });
  },
  resetPassword: async ({ commit }, { resetToken, password }) => {
    commit('setIsLoading'); // lock requests for idToken
    await resetPasswordWithFirebase({ resetToken, password });
  },
  verifyEmail: async ({ commit }, { oobCode }) => {
    commit('setIsLoading'); // lock requests for idToken

    try {
      await verifyEmailWithFirebase({ oobCode });
      loggerModule.logger.info({ msg: '[authStore#verifyEmail] Email verification success' });
      $gnotify.success('Email validé');
    } catch (error) {
      loggerModule.logger.error({ msg: '[authStore#verifyEmail] Cannot verify email', args: { error } });
      $gnotify.error(`Impossible de valider l'email.`);
    }
  },
  setSudoToken: ({ commit }, { sudoToken, expiresAt }) => {
    commit('setSudoToken', { sudoToken, expiresAt });
  },
  getSudoToken({ state, commit }) {
    if (state.sudo.token && state.sudo.expiresAt > new Date()) {
      return state.sudo.token;
    } else {
      commit('unsetSudoToken');
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};

// basic promise queue for pending idToken requests
const USER_CREDENTIALS_RESOLVES = [];
function resolveUserCredentials({ idToken, impersonateUserId }) {
  USER_CREDENTIALS_RESOLVES.forEach((resolve) => resolve({ idToken, impersonateUserId }));
  USER_CREDENTIALS_RESOLVES.length = 0;
}
