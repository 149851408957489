<template>
  <BaseSubscriptionModal
    :title="title"
    sub-title="Abonnez-vous pour automatiser votre comptabilité et vos déclarations avec Indy."
    :features-list="featuresList"
    calendly-context="trialOver"
    :show-freemium="canDowngradeToBookkeeping"
    freemium-button-text="Démarrer"
    :on-freemium-button-click="dismissModalButtonClicked"
    @close="$emit('close')"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseSubscriptionModal from '@/modules/core/subscriptionModals/baseSubscriptionModal/BaseSubscriptionModal.vue';
import { changeUserFromDeclarationToBookkeeping } from '@/modules/user/product/product.services.js';

export default {
  name: 'TrialOverModal',
  emits: ['close'],
  components: { BaseSubscriptionModal },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      featuresList: 'settings/featuresSubscriptionModalsList',
      canDowngradeToBookkeeping: 'settings/canDowngradeToBookkeeping',
    }),
    userFirstName() {
      return this.currentUser.profile.firstname;
    },
    title() {
      return `${this.userFirstName}, votre essai gratuit est maintenant terminé !`;
    },
  },

  created() {
    this.getSubscriptionData();

    this.getProductDetails();
  },

  methods: {
    ...mapActions({
      fetchUser: 'user/fetchUser',
      getProductHistory: 'user/getProductHistory',
      getSubscriptionData: 'settings/getSubscriptionData',
      getProductDetails: 'settings/getProductDetails',
    }),
    async dismissModalButtonClicked() {
      await changeUserFromDeclarationToBookkeeping();
      await this.fetchUser();
      await this.getProductHistory();
    },
  },
};
</script>
