<template>
  <GCard>
    <GCardTitle>{{ title }}</GCardTitle>
    <GCardSubtitle>
      <div>{{ subTitle }}</div>
      <div v-if="calendlyContext">
        Vous avez besoin d'être accompagné ?
        <CalendlyButton :context="calendlyContext">Prenez rendez-vous</CalendlyButton> avec un de nos conseillers.
      </div>
    </GCardSubtitle>
    <GCardContent class="d-flex flex-column">
      <div class="w-full d-flex flex-column flex-md-row gap-16 gap-md-0 space-x-md-20">
        <!-- Freemium -->
        <PlanCard
          v-if="showFreemium"
          class="order-1 order-md-0"
          title="Indy"
          price-ht="0"
          bg="surface"
          plan-short-description="100% gratuit - Sans conditions"
          :button-text="freemiumButtonText"
          :button-action="handleFreemiumButtonClick"
          button-variant="outlined"
          :available-features="freemiumFeatures.available"
          :unavailable-features="freemiumFeatures.unavailable"
        />

        <!-- Premium -->
        <PlanCard
          class="order-0 order-md-1"
          title="Indy Premium"
          :price-ht="planPriceHT"
          bg="background"
          plan-short-description="Sans engagement"
          features-title="Toutes les fonctionnalités d'Indy Premium"
          button-variant="filled"
          button-color="primary"
          :button-action="handlePremiumButtonClick"
          :available-features="featuresList"
        />
      </div>
    </GCardContent>
  </GCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PlanCard from '@/modules/core/PlanCard.vue';
import CalendlyButton from '@/shared/components/CalendlyButton/CalendlyButton.vue';
import { filters } from '@/shared/utils/filters';

export default {
  name: 'BaseSubscriptionModal',
  emits: ['close'],
  components: { PlanCard, CalendlyButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    featuresList: {
      type: Array,
      required: true,
      default: () => [],
    },
    calendlyContext: {
      type: String,
    },
    showFreemium: {
      type: Boolean,
      required: false,
      default: true,
    },
    freemiumButtonText: {
      type: String,
      required: true,
    },
    onFreemiumButtonClick: {
      type: Function,
    },
  },
  data: () => ({
    freemiumFeatures: {
      available: ['Catégorisation intelligente des transactions', 'Devis et factures', 'Gestion des notes de frais'],
      unavailable: ['Déclarations fiscales', 'Télétransmission aux impôts'],
    },
  }),
  computed: {
    ...mapGetters({
      subscriptionPlan: 'settings/subscriptionPlan',
    }),
    planPriceHT() {
      if (!this.subscriptionPlan) return;
      return filters.formatCentsWithoutTrailingDecimalZeros(this.subscriptionPlan?.priceInCents);
    },
  },
  created() {
    this.getSubscriptionData();
  },
  methods: {
    ...mapActions({
      getSubscriptionData: 'settings/getSubscriptionData',
    }),
    async handlePremiumButtonClick() {
      this.$router.push({ name: 'userSettingsStripe' });
      this.$emit('close');
    },
    async handleFreemiumButtonClick() {
      try {
        if (this.onFreemiumButtonClick) {
          await this.onFreemiumButtonClick();
        }
        this.$emit('close');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
