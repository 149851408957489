import georgesApi from '@/shared/apis/georges';

const getUrl = (path) => `/activities/income-declaration${path}`;

export async function getIncomeDeclarationActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl(''),
  });
  return data;
}

export async function validateIncomeDeclarationActivity() {
  await georgesApi({
    method: 'POST',
    url: getUrl('/validation'),
  });
}
