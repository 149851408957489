import get from 'lodash/get';
import moment from 'moment';

import { REFERRAL_CAMPAIGN_BY_TAG, REFERRAL_CAMPAIGN_TYPES } from '@/modules/referral/constants/referral.constants';
import { formatDate } from '@/shared/utils/dates';

export function getCurrentReferralCampaignType({ currentCampaignTag }) {
  return get(REFERRAL_CAMPAIGN_BY_TAG, currentCampaignTag, REFERRAL_CAMPAIGN_BY_TAG.ref);
}

// -- Share
export function getReferralSharePayload({
  isTemporaryCampaignActive,
  referralUrl,
  promocodeName,
  userFirstname,
  hasActiveSubscription,
}) {
  const freeMonths = hasActiveSubscription
    ? `
Si tu t'abonnes grâce à mon lien de parrainage, tu bénéficieras ${
        isTemporaryCampaignActive ? "de 3 mois d'abonnement offerts" : "d'un mois d'abonnement offert"
      }.
`
    : '';

  return {
    title: 'Je te parraine sur Indy !',
    text: `Hello,

Indy, ça te parle ? C'est le n°1 de la comptabilité des indépendants !
J'utilise Indy pour ma comptabilité et mes déclarations et j'adore 👍
${freeMonths}
Tu peux créer ton compte via ce lien : ${referralUrl}

ou utiliser mon code : ${promocodeName}

À très vite,

${userFirstname}`,
    url: referralUrl,
  };
}

// -- Invitations
export const getLabelForInvitedUsers = (invitedUsers) => {
  const hasNone = invitedUsers.length < 1;
  const hasMany = invitedUsers.length > 1;
  return {
    headline: `Filleul${hasMany ? 's' : ''} invité${hasMany ? 's' : ''} par email`,
    headlineDialog: `${hasMany ? 'Mes' : 'Mon'} filleul${hasMany ? 's' : ''} invité${hasMany ? 's' : ''}`,
    body: hasNone ? `Parrainez vos proches indépendants` : `N'oubliez pas de le${hasMany ? 's' : ''} relancer !`,
    color: 'warning',
  };
};

export const getLabelForSignedUpUsers = (signedUpUsers) => {
  const hasNone = signedUpUsers.length < 1;
  const hasMany = signedUpUsers.length > 1;
  return {
    headline: `Filleul${hasMany ? 's' : ''} inscrit${hasMany ? 's' : ''}`,
    headlineDialog: `${hasMany ? 'Mes' : 'Mon'} filleul${hasMany ? 's' : ''} inscrit${hasMany ? 's' : ''}`,
    body: hasNone ? `Parrainez vos proches indépendants` : `C'est grâce à vous !`,
    color: 'primary',
  };
};

export const getLabelForSubscribedUsers = (subscribedUsers, isAlreadyAmbassador, hasActiveSubscription) => {
  const hasMany = subscribedUsers.length > 1;
  let body;
  if (isAlreadyAmbassador) {
    body = `Vous êtes ambassadeur !`;
  } else if (hasActiveSubscription) {
    body = `Parrainez et devenez ambassadeur !`;
  } else {
    body = `Merci beaucoup !`;
  }
  return {
    headline: `Filleul${hasMany ? 's' : ''} abonné${hasMany ? 's' : ''}`,
    headlineDialog: `${hasMany ? 'Mes' : 'Mon'} filleul${hasMany ? 's' : ''} abonné${hasMany ? 's' : ''}`,
    body,
    color: 'success',
  };
};

export const getLabelForProspectsCount = (prospectsCount) => {
  const hasMany = prospectsCount > 1;
  return `${prospectsCount} parrainage${hasMany ? 's' : ''}`;
};

// -- Ambassador
export function formatIban({ iban }) {
  return { iban: iban.replace(/\s/g, '') };
}

export function getCampaignDescription({ currentReferralCampaignType, promocode, hasActiveSubscription }) {
  if (currentReferralCampaignType === REFERRAL_CAMPAIGN_TYPES.ambassador) {
    return {
      surtitle: 'Ambassadeur Indy',
      title: "<b>Jusqu'à 100€ et 1 mois offert</b><br />pour chaque nouveau filleul abonné.",
      backgroundPath: '/img/referral/referral-card-background-ambassador.svg',
      type: 'premium',
    };
  }

  if (currentReferralCampaignType === REFERRAL_CAMPAIGN_TYPES.boosted) {
    const { created_at: promocodeCreatedAt } = promocode;
    const promocodeMaxAge = get(promocode, 'restrictions.@promocodeMaxAge', 'P30D');
    const deadline = moment(promocodeCreatedAt).add(promocodeMaxAge).toDate();
    return {
      surtitle: 'Parrainage boosté',
      extraInfo: `Jusqu'au ${formatDate(deadline, 'DD MMMM')}`,
      title: '<b>3&nbsp;mois offerts </b> pour <b>vous</b> et pour votre <b>filleul</b> à chaque parrainage.',
      type: 'premium',
    };
  }

  if (currentReferralCampaignType === REFERRAL_CAMPAIGN_TYPES.freemium1) {
    return {
      surtitle: 'Parrainez vos proches',
      headline: 'Vous êtes nos meilleurs ambassadeurs',
      backgroundPath: '/img/referral/referral-card-background-freemium-1.svg',
      type: 'freemium',
    };
  }

  if (currentReferralCampaignType === REFERRAL_CAMPAIGN_TYPES.freemium2) {
    return {
      surtitle: 'Tentez votre chance !',
      headline: 'Parrainez un proche',
      title: "et <b>gagnez jusqu'à 500 euros</b> avec la loterie Indy",
      backgroundPath: '/img/referral/referral-card-background-freemium-2.svg',
      type: 'freemium',
    };
  }

  if (currentReferralCampaignType === REFERRAL_CAMPAIGN_TYPES.freemium3) {
    return {
      surtitle: 'Parrainez utile',
      headline: 'Soutenez une asso !',
      title: '<b>5 euros</b> sont <b>reversés</b> à chaque parrainage',
      backgroundPath: '/img/referral/referral-card-background-freemium-3.svg',
      type: 'freemium',
    };
  }

  if (hasActiveSubscription) {
    return {
      surtitle: 'Parrainez vos proches indépendants',
      title: "<b>Gagnez jusqu'à 100€ et</b><br /><b>1 mois offert</b> pour chaque nouveau filleul abonné !",
      type: 'premium',
    };
  }

  return {
    surtitle: 'Parrainez vos proches',
    headline: 'Vous êtes nos meilleurs ambassadeurs',
    backgroundPath: '/img/referral/referral-card-background-freemium-1.svg',
    type: 'freemium',
  };
}
