<template>
  <GBannerHeader
    v-if="isVisible"
    color="error"
    variant="tonal"
    title="L'intégration avec Malt est inactive."
    @click:close="hideBanner"
    @click="goToSettingsPage"
  >
    <span>Veuillez mettre à jour le token. <u>En savoir plus</u></span>
  </GBannerHeader>
</template>

<script setup>
// Services
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { trackReferralEvent } from '@/modules/referral/referral.composable';

const router = useRouter();
const store = useStore();
const emit = defineEmits(['displayed']);

const isDismissed = ref(false);

const maltToken = computed(() => store.getters['facturation/maltToken']);

const isVisible = computed(() => {
  const isTokenVisible = maltToken?.value?.isValid === false;
  emit('displayed', isTokenVisible);
  return isTokenVisible;
});

const hideBanner = () => {
  isDismissed.value = true;
  emit('displayed', false);
  trackReferralEvent({
    action: 'Click to close the invalid Malt token banner',
  });
};

const goToSettingsPage = () => {
  router.push({ name: 'userSettingsOptions' });
  trackReferralEvent({
    action: 'Click to go to settings page (from invalid Malt token banner)',
  });
};
</script>
