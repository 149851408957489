export { computeSumPendingCreditInvoiceItems };

function computeSumPendingCreditInvoiceItems(invoiceItems) {
  const totalCredit = invoiceItems
    .filter((invoiceItem) => !invoiceItem.invoice && invoiceItem.amount)
    .reduce((total, { amount }) => total + amount, 0);
  // Credit in stripe is a negative value
  // If I have a 20 euros credit : totalCredit = -20
  return totalCredit < 0 ? totalCredit : 0;
}
