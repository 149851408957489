<template>
  <GBannerHeader color="error" v-if="hasToResync" @click="onBannerClicked({ origin: 'banner' })" hide-close>
    <div class="flex gap-4 align-start flex-column flex-md-row align-md-baseline gap-md-20">
      <div class="font-weight-bold">Votre banque n’est plus connectée</div>
      <div>Rétablissez la connexion pour qu’Indy récupère vos dernières transactions</div>
      <GButton
        link
        color="error"
        class="text-bodyS m-l-auto my-12 my-md-0"
        @click="onBannerClicked({ origin: 'banner-button' })"
      >
        Rétablir la connexion
      </GButton>
    </div>
  </GBannerHeader>
</template>

<script>
import { mapActions } from 'vuex';

import { logger } from '@/shared/services/logger/logger';

import { hasUnsynchronizedBankAuth } from '../../bankConnector.models';

export default {
  name: 'BankResyncCtaBanner',
  emits: ['bank-resync-banner-displayed'],
  data: () => ({
    bankSettingsRouteName: 'userSettingsBanks',
    hasToResync: false,
    bankAuths: [],
  }),

  async mounted() {
    const bankAuths = await this.fetchBankAuths();
    this.bankAuths = bankAuths;
    this.hasToResync = hasUnsynchronizedBankAuth({ bankAuths });

    if (this.hasToResync) {
      logger.log({
        msg: '[BankResyncCtaBanner] Resync banner displayed',
        args: {
          bankAuths: this.bankAuths.map((bankAuth) => ({
            bankAuthId: bankAuth._id,
            bankAuthStatus: bankAuth.status,
          })),
        },
      });
      this.$emit('bank-resync-banner-displayed');
    }
  },
  methods: {
    ...mapActions({
      fetchBankAuths: 'bankConnector/fetchBankAuths',
    }),

    onBannerClicked({ origin = 'unknown' }) {
      this.$router.push({ name: this.bankSettingsRouteName });

      logger.log({
        msg: '[BankResyncCtaBanner] Resync banner clicked',
        args: {
          origin,
          bankAuths: this.bankAuths.map((bankAuth) => ({
            bankAuthId: bankAuth._id,
            bankAuthStatus: bankAuth.status,
          })),
        },
      });
    },
  },
};
</script>
