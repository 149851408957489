const FOOTER_CONTEXT = {
  test: {
    title: 'Test - Imitation',
  },
  development: {
    title: 'Dev - Imitation',
  },
  preproduction: {
    title: 'Preprod - Imitation',
    color: 'warning',
  },
  production: {
    title: 'Prod - Imitation',
    color: 'error',
  },
};

export function getAdminFooterContext({ environment }) {
  const context = FOOTER_CONTEXT[environment];
  if (!context) {
    return FOOTER_CONTEXT.production;
  }
  return context;
}
