const DIALOG_WIDTHS = {
  simple: 600,
  form: 600,
  action: 800,
};

const DIALOG_BOTTOMSHEET_MOBILE = ['form', 'action'];

const generateDialogProps = (dialogProps, type, isMobile) => ({
  width: '100%',
  maxWidth: DIALOG_WIDTHS[type],
  ...(DIALOG_BOTTOMSHEET_MOBILE.includes(type) && {
    ...(isMobile && {
      bottomsheet: true,
    }),
    scrollable: true,
  }),
  ...dialogProps,
});

const dialogable = {
  name: 'Dialogable',
  computed: {
    isMobile() {
      return this.$georgesuikit.breakpoint.isMobile;
    },
  },
  methods: {
    openDialog({ component, componentProps, componentListeners, dialogProps, events }, type = 'simple') {
      return this.$gdialog.show({
        component,
        componentProps,
        componentListeners,
        dialogProps: generateDialogProps(dialogProps, type, this.isMobile),
        events,
      });
    },
    openSimpleDialog({ component, componentProps, componentListeners, dialogProps, events }) {
      return this.openDialog({ component, componentProps, dialogProps, componentListeners, events }, 'simple');
    },
    openActionDialog({ component, componentProps, componentListeners, dialogProps, events }) {
      return this.openDialog({ component, componentProps, dialogProps, componentListeners, events }, 'action');
    },
    openFormDialog({ component, componentProps, componentListeners, dialogProps, events }) {
      return this.openDialog({ component, componentProps, dialogProps, events, componentListeners }, 'form');
    },
  },
};

export default dialogable;
