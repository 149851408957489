import georgesApi from '@/shared/apis/georges.js';

export async function refreshCurrentClosingState({ declaration }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/closing/all/refresh',
    params: {
      declaration,
      fiscalPeriodId: 'current',
    },
  });
  return data;
}

export async function refreshClosingState({ declaration, fiscalPeriodId }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/closing/all/refresh',
    params: {
      declaration,
      fiscalPeriodId,
    },
  });
  return data;
}

export async function fetchClosingState({ declaration, fiscalPeriodId }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/closing/all',
    params: {
      declaration,
      fiscalPeriodId,
    },
  });
  return data;
}
