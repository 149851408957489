import get from 'lodash/get';
import moment from 'moment';

import { getD2036Activity } from '@/modules/closing/d2036Activity/d2036Activity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
import { isFeatureEnabled } from '@/modules/user/features/features.helpers';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    associatesInformation: {
      title: 'Gestion des associés',
      shortTitle: 'Associés',
      availableBeforeEndYear: true,
      availableOnlyForClients: true,
    },
    scmSharesHistory: {
      title: 'Parts de la SCM',
      shortTitle: 'Parts de la SCM',
      availableBeforeEndYear: true,
      availableOnlyForClients: true,
    },
    associatesRepartitionRule: {
      title: 'Règle de répartition des charges',
      shortTitle: 'Règle de répartition',
      availableBeforeEndYear: true,
      availableOnlyForClients: true,
    },
    categorizeAll: {
      title: 'Catégoriser toutes les transactions',
      shortTitle: 'Catégoriser les transactions',
      availableBeforeEndYear: true,
      availableOnlyForClients: true,
    },
    checkAssets: {
      title: 'Immobilisations, amortissements et cessions',
      shortTitle: 'Immobilisations',
      availableBeforeEndYear: true,
      availableOnlyForClients: true,
    },
    loanBreakdown: {
      title: "Ventiler les échéances d'emprunts",
      shortTitle: "Échéances d'emprunt",
      availableBeforeEndYear: true,
      availableOnlyForClients: true,
    },
    associatesContribution: {
      title: 'Apports des associés',
      shortTitle: "Apports d'associés",
      availableBeforeEndYear: true,
      availableOnlyForClients: true,
    },
    specificRepartitionRules: {
      title: 'Règles de répartition particulières',
      shortTitle: 'Règles particulières',
      availableOnlyForClients: true,
    },
    checkFinancialBalances: {
      title: 'Vérifier les soldes bancaires',
      shortTitle: 'Soldes bancaires',
      availableOnlyForClients: true,
    },
    generalInformation: {
      title: 'Informations de votre SCM',
      shortTitle: 'Informations',
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: 'Valider et envoyer la déclaration aux impôts',
      shortTitle: 'Valider la déclaration',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    const currentUser = rootGetters['user/currentUser'];
    const showAdminInfo = rootGetters['user/showAdminInfo'];

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        fiscalPeriodId: String(closingState.year),
        minDateToClose: moment(activity.date?.minDate),
        closingState,
        isInEarlyClosing: showAdminInfo || isFeatureEnabled({ user: currentUser, feature: 'scmD2036Activity' }),
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getD2036Activity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
