import get from 'lodash/get';
import moment from 'moment/moment';

export const getRemainingBoostedReferralDays = ({ promocode, today }) => {
  const promocodeMaxAge = get(promocode, 'restrictions.@promocodeMaxAge', 'P30D');
  const promocodeCreatedAt = promocode.created_at;
  const expiryDate = moment(promocodeCreatedAt).add(moment.duration(promocodeMaxAge));
  let diff = expiryDate.diff(today, 'days');
  if (diff < 0) {
    diff = 0;
  }
  return `${diff} ${diff > 1 ? 'jours restants' : 'jour restant'}`;
};
