import georgesApi from '@/shared/apis/georges.js';

// ---------------------------
// Retrieves documents to show
// ---------------------------
export async function getDocuments({ periodId }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/documents`,
    params: {
      periodId,
    },
  });

  return data.documents;
}

// ---------------------------
// Retrieves available periods
// ---------------------------
export async function getPeriodChoices() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/documents/period-choices',
  });

  return data;
}

// ---------------------------
// d2035
// ---------------------------
export async function getD2035Data({ fiscalYear }) {
  const { data } = await georgesApi({
    url: `/documents/d2035/${fiscalYear}`,
  });

  return data.d2035Data;
}

export async function getVatPeriodChoices() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/documents/vat/period-choices',
  });

  return data;
}

export async function getVatDeclarationsOnFiscalPeriod({ fiscalYear }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/documents/vat/declarations`,
    params: {
      fiscalYear,
    },
  });

  return data;
}

export async function getVatAdvancePaymentDeclarationsOnFiscalYear({ fiscalYear }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/documents/vat/advance-payment/declarations`,
    params: {
      fiscalYear,
    },
  });

  return data;
}

export async function getPayslipDeclarationsOnPeriod({ fiscalYear } = {}) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/documents/payslip/declarations`,
    params: {
      fiscalYear,
    },
  });

  return data;
}
