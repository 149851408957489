export const calendlyBySchools = {
  ecoa: 'liberalAvocat',
  edabordeaux: 'liberalAvocat',
  edacs: 'liberalAvocat',
  edacorse: 'liberalAvocat',
  edaguadeloupe: 'liberalAvocat',
  deaguyane: 'liberalAvocat',
  edamartinique: 'liberalAvocat',
  edareumay: 'liberalAvocat',
  edago: 'liberalAvocat',
  edara: 'liberalAvocat',
  edase: 'liberalAvocat',
  edasop: 'liberalAvocat',
  efp: 'liberalAvocat',
  erage: 'liberalAvocat',
  hedac: 'liberalAvocat',
  ixad: 'liberalAvocat',
  ifsorennes: 'liberalCreateur1',
  atman: 'liberalCreateur1',
  iobbordeaux: 'liberalCreateur1',
  isogm: 'liberalCreateur1',
  copb: 'liberalCreateur1',
  oscar: 'liberalCreateur1',
  atsa: 'liberalCreateur1',
  isop: 'liberalCreateur1',
  cetohm: 'liberalCreateur1',
  edodanhier: 'liberalCreateur1',
  ifmkesbarcelone: 'liberalCreateur1',
  ifmkeseuropeavalence: 'liberalCreateur1',
  ifmkeseuropeamadrid: 'liberalCreateur1',
  ifmkessanpablo: 'liberalCreateur1',
  ifmkesseville: 'liberalCreateur1',
  ifmkesalfonso: 'liberalCreateur1',
  ifmkesvitoria: 'liberalCreateur1',
  ifmkesnebrija: 'liberalCreateur1',
  coseurope: 'liberalCreateur1',
  cfuobesancon: 'liberalCreateur10',
  cfuonancy: 'liberalCreateur10',
  ifmkbehecondorcet: 'liberalCreateur10',
  ifmkbordeaux: 'liberalCreateur10',
  ifmkdanhier: 'liberalCreateur10',
  ifmkilfomer: 'liberalCreateur1',
  ifmkmulhouse: 'liberalCreateur10',
  ifmkstrasbourg: 'liberalCreateur10',
  ifmkbehelb: 'liberalCreateur10',
  ifmkbehe2b: 'liberalCreateur10',
  ifmkbehevinci: 'liberalCreateur10',
  ifmkbehelha: 'liberalCreateur10',
  ifmkbehepl: 'liberalCreateur10',
  ifmkbelibramont: 'liberalCreateur10',
  ifmkbeulb: 'liberalCreateur10',
  ifmkbeuliege: 'liberalCreateur10',
  cfuolille: 'liberalCreateur11',
  cfuonantes: 'liberalCreateur11',
  cfuoparis: 'liberalCreateur11',
  cfuorouen: 'liberalCreateur11',
  ifmkmeulan: 'liberalCreateur1',
  ifmkmusse: 'liberalCreateur11',
  ifmkmontpellier: 'liberalCreateur1',
  ifec: 'liberalCreateur13',
  ifmkdax: 'liberalCreateur13',
  ifmkdijon: 'liberalCreateur13',
  ifmkikpolille: 'liberalCreateur13',
  ifmknflille: 'liberalCreateur13',
  ifmkpoitiers: 'liberalCreateur1',
  cfuolyon: 'liberalCreateur14',
  ifmkbesancon: 'liberalCreateur14',
  ifmkbrest: 'liberalCreateur14',
  ifmkceerrf: 'liberalCreateur14',
  ifmkrouen: 'liberalCreateur1',
  ifmksaintmichel: 'liberalCreateur14',
  ifealencon: 'liberalCreateur2',
  ifeamiens: 'liberalCreateur2',
  ifeassas: 'liberalCreateur2',
  ifeberck: 'liberalCreateur2',
  ifebesancon: 'liberalCreateur2',
  ifebordeaux: 'liberalCreateur2',
  ifecebazat: 'liberalCreateur2',
  ifecvdl: 'liberalCreateur2',
  ifegrenoble: 'liberalCreateur2',
  ifelagarde: 'liberalCreateur2',
  ifelamusse: 'liberalCreateur2',
  ifelaval: 'liberalCreateur2',
  ifelca: 'liberalCreateur2',
  ifelimoges: 'liberalCreateur2',
  ifeloos: 'liberalCreateur2',
  ifelyon: 'liberalCreateur2',
  ifemarseille: 'liberalCreateur2',
  ifemeulan: 'liberalCreateur2',
  ifemontpellier: 'liberalCreateur2',
  ifemulhouse: 'liberalCreateur2',
  ifenevers: 'liberalCreateur2',
  ifeparisadere: 'liberalCreateur2',
  ifeparisest: 'liberalCreateur2',
  ifepoitiers: 'liberalCreateur2',
  iferennes: 'liberalCreateur2',
  ifereunion: 'liberalCreateur2',
  iferouen: 'liberalCreateur2',
  ifetoulouse: 'liberalCreateur2',
  cfuobordeaux: 'liberalCreateur2',
  cfuonice: 'liberalCreateur2',
  ealamusse: 'liberalCreateur2',
  ifmkmontbeliard: 'liberalCreateur2',
  ifmknancy: 'liberalCreateur2',
  ifmkreims: 'liberalCreateur2',
  ifmksaintmo: 'liberalCreateur2',
  ceesolyon: 'liberalCreateur3',
  esosup: 'liberalCreateur3',
  ifmkgrenoble: 'liberalCreateur3',
  ifmkorleans: 'liberalCreateur3',
  cfuoamiens: 'liberalCreateur4',
  cfuopoitiers: 'liberalCreateur4',
  cfuotours: 'liberalCreateur4',
  idheo: 'liberalCreateur1',
  ifmkbegles: 'liberalCreateur4',
  ifmkberck: 'liberalCreateur4',
  cfuobrest: 'liberalCreateur5',
  cfuomarseille: 'liberalCreateur5',
  cfuostrasbourg: 'liberalCreateur5',
  ifmkaphp: 'liberalCreateur5',
  ifmkrennes: 'liberalCreateur5',
  eoaixmarseille: 'liberalCreateur6',
  eoamiens: 'liberalCreateur6',
  eobordeaux: 'liberalCreateur6',
  eocaen: 'liberalCreateur6',
  eoclermont: 'liberalCreateur6',
  eolille: 'liberalCreateur6',
  eolimoges: 'liberalCreateur6',
  eolyon: 'liberalCreateur6',
  eomontpellier: 'liberalCreateur6',
  eonantes: 'liberalCreateur6',
  eoparis: 'liberalCreateur6',
  eorennes: 'liberalCreateur6',
  eosorbonne: 'liberalCreateur6',
  eostrasbourg: 'liberalCreateur6',
  eotoulouse: 'liberalCreateur6',
  eotours: 'liberalCreateur6',
  ifpmeulan: 'liberalCreateur6',
  ifplille: 'liberalCreateur6',
  ifprouen: 'liberalCreateur6',
  ifpalencon: 'liberalCreateur6',
  ifplyon: 'liberalCreateur6',
  ifpmarseille: 'liberalCreateur6',
  ifpparis: 'liberalCreateur6',
  ifpboulognebillancourt: 'liberalCreateur6',
  ifptoulouse: 'liberalCreateur6',
  ifphyeres: 'liberalCreateur6',
  ifpreunion: 'liberalCreateur6',
  ifpmulhouse: 'liberalCreateur6',
  ifpbordeaux: 'liberalCreateur6',
  ifporleans: 'liberalCreateur6',
  ifpvichy: 'liberalCreateur6',
  ifpbesancon: 'liberalCreateur6',
  cfuocaen: 'liberalCreateur6',
  cfuoclermont: 'liberalCreateur6',
  ifmkcrllimoges: 'liberalCreateur6',
  ifmkefom: 'liberalCreateur6',
  ifmksainte: 'liberalCreateur6',
  holistea: 'liberalCreateur7',
  idoparis: 'liberalCreateur7',
  ifmkaderf: 'liberalCreateur7',
  ifmkrodez: 'liberalCreateur7',
  ifpplyon: 'liberalCreateur8',
  ifppbordeaux: 'liberalCreateur8',
  ifpprennes: 'liberalCreateur8',
  ifppefom: 'liberalCreateur8',
  ifppdanhier: 'liberalCreateur8',
  ifppinp: 'liberalCreateur8',
  ifppafrep: 'liberalCreateur8',
  ifppinpge: 'liberalCreateur8',
  ifpptoulouse: 'liberalCreateur8',
  ifpplille: 'liberalCreateur8',
  ifppstsebastien: 'liberalCreateur8',
  ifppalencon: 'liberalCreateur8',
  ifppaixmarseille: 'liberalCreateur8',
  cfuolimoges: 'liberalCreateur8',
  cfuomontpellier: 'liberalCreateur8',
  ifmklyon: 'liberalCreateur8',
  ifmktoulouse: 'liberalCreateur8',
  ifppassas: 'liberalCreateur8',
  cfuotoulouse: 'liberalCreateur9',
  ifmkalencon: 'liberalCreateur9',
  ifmkassas: 'liberalCreateur1',
  ifmklaval: 'liberalCreateur9',
  ifmkvichy: 'liberalCreateur9',
  fmamiens: 'liberalMedecin',
  fmclermont: 'liberalMedecin',
  fmdijon: 'liberalMedecin',
  fmbesancon: 'liberalMedecin',
  fmgrenoble: 'liberalMedecin',
  fmantilles: 'liberalMedecin',
  fmreunion: 'liberalMedecin',
  fmlilledeux: 'liberalMedecin',
  fmlillecatho: 'liberalMedecin',
  fmbordeaux: 'liberalMedecin',
  fmlimoges: 'liberalMedecin',
  fmlyonest: 'liberalMedecin',
  fmbrest: 'liberalMedecin',
  fmlyonsud: 'liberalMedecin',
  fmaixmarseille: 'liberalMedecin',
  fmcaen: 'liberalMedecin',
  fmmontpellier: 'liberalMedecin',
  fmnancy: 'liberalMedecin',
  fmangers: 'liberalMedecin',
  fmnantes: 'liberalMedecin',
  fmnice: 'liberalMedecin',
  fmversailles: 'liberalMedecin',
  fmparisdescartes: 'liberalMedecin',
  fmsorbonne: 'liberalMedecin',
  fmparisdiderot: 'liberalMedecin',
  fmparissud: 'liberalMedecin',
  fmparisest: 'liberalMedecin',
  fmparisnord: 'liberalMedecin',
  fmpoitiers: 'liberalMedecin',
  fmreims: 'liberalMedecin',
  fmrennes: 'liberalMedecin',
  fmrouen: 'liberalMedecin',
  fmstetienne: 'liberalMedecin',
  fmstrasbourg: 'liberalMedecin',
  fmtoulousepur: 'liberalMedecin',
  fmtoulouseran: 'liberalMedecin',
  fmtours: 'liberalMedecin',
};

export const calendlyByFiscalRegimeKey = {
  BNC_2035: 'ibBnc',
  BIC_2031: 'ibIs',
  IS: 'ibIs',
  IS_2033: 'ibIs',
  SCI_IR: 'ibSci',
};
