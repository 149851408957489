import { DECLARATIONS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Services
import {
  fetchClosingState,
  refreshClosingState,
  refreshCurrentClosingState,
} from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: false,
  closingState: {},
  defaultSteps: {
    soldeIsAdvancePayments: {
      title: 'Renseigner les acomptes versés',
      shortTitle: 'Acomptes versés',
      availableOnlyForClients: true,
    },
    validateSoldeIsDeclaration: {
      title: "Télédéclaration et paiement du solde d'IS",
      shortTitle: 'Télédéclaration et paiement',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps, closingState } = state;
    const closingStateFiscalYear = closingState.fiscalYear;

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        minDateToClose: moment(activity.date?.minDate),
        maxDateToClose: moment(activity.date?.maxDate), // used for display purpose in validating step
        fiscalPeriodId: String(closingStateFiscalYear),
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const { closingState } = await refreshCurrentClosingState({
      declaration: DECLARATIONS.D_BIC_IS_SOLDE_IS.KEY,
    });
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
  async refresh({ commit }, { year }) {
    commit('setLoading', true);
    const { closingState } = await refreshClosingState({
      declaration: DECLARATIONS.D_BIC_IS_SOLDE_IS.KEY,
      fiscalPeriodId: year,
    });
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
  async fetch({ commit }, { year }) {
    commit('setLoading', true);
    const { closingState } = await fetchClosingState({
      declaration: DECLARATIONS.D_BIC_IS_SOLDE_IS.KEY,
      fiscalPeriodId: year,
    });
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
