/*
 * Centralize every custom color for specific needs.
 * DO NOT USE THIS TO DESIGN PAGES OR ELEMENTS.
 */

export const COLORS_CHARTS = {
  // Chartjs default values
  defaults: {
    color: 'rgba(34, 44, 54, 0.6)',
    gridColor: {
      zero: '#D4D5DE',
      default: '#E4E7EE',
    },
  },
  // Specifics for each charts
  professionalIncomeExpenseColors: {
    bars: {
      income: 'rgba(178,237,232,0.95)',
      expense: 'rgba(250,93,137,0.7)',
    },
    line: {
      positive: 'rgba(178,237,232,1)',
      negative: 'rgba(250,93,137,1)',
      curve: '#2E4254',
    },
    tick: {
      zero: 'rgba(34, 44, 54, 0.6)',
      default: '#E4E7EE',
    },
  },
  professionalExpenseColors: {
    bars: [
      'rgba(250,93,137,.9)',
      'rgba(250,93,137,.8)',
      'rgba(250,93,137,.7)',
      'rgba(250,93,137,.6)',
      'rgba(250,93,137,.5)',
      'rgba(250,93,137,.4)',
    ],
  },
  personalExpenseColors: {
    bars: {
      expense: 'rgba(123,215,227,0.9)',
      debit: 'rgba(123,215,227,0.5)',
    },
  },
  soldeBancaireColors: {
    backgroundColor: 'rgba(35,237,173,0.1)',
    hoverBackgroundColor: '#3af7bd',
    pointBorderColor: '#0ccb90cc',
    pointBackgroundColor: '#0ccb90cc',
    borderColor: '#08F5AC',
    hoverBorderColor: '#0ccb90cc',
  },
  chargesExploitationColors: {
    achats: '#CEF4F0',
    servicesExterieurs: '#FF89AA',
    impotsEtTaxes: '#D09FE7',
    remunerationsEtChargesSociales: '#FFADA4',
    dotationsAuxAmortissementsEtAuxProvisions: '#7BD7E3',
    transactionsNonCategorisees: '#2E4254',
  },
};

export const COLORS_SUBSCRIPTION_PLANS = {
  bookkeeping: {
    color: 'surface',
    chipColor: 'warning',
  },
  declaration: {
    color: 'background',
    chipColor: 'primary',
  },
};

export const COLORS_FACTURATION = {
  // Default colors for invoices that the user can customize
  customization: {
    default: {
      main: '#FEF7EC',
      background: '#FFFFFF',
    },
  },
};
