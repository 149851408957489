import { mapGetters } from 'vuex';

import {
  analyticsEvent,
  analyticsSetUserProperties,
  analyticsSignInUser,
  analyticsSignOutUser,
  analyticsSignUpUser,
} from '../utils/analytics';

const trackable = {
  name: 'Trackable',
  computed: {
    ...mapGetters({
      isImpersonating: 'user/isImpersonating',
    }),
  },
  methods: {
    trackEvent({ action, properties }) {
      if (this.isImpersonating) return;
      analyticsEvent({ action, properties });
    },
    trackBucketTestInitialEvent({ bucketTest, version }) {
      if (this.isImpersonating) return;
      analyticsEvent({
        action: `load_bucket_test_${bucketTest.analyticsKey}`,
        properties: {
          [`${bucketTest.analyticsKey}_version`]: bucketTest.versions[version],
        },
      });
    },
    trackUserProperties({ properties }) {
      if (this.isImpersonating) return;
      analyticsSetUserProperties({ properties });
    },
    trackSignUp({ userId }) {
      if (this.isImpersonating) return;
      analyticsSignUpUser({ userId });
    },
    trackSignIn({ store }) {
      if (this.isImpersonating) return;
      analyticsSignInUser({ store });
    },
    trackSignOut() {
      if (this.isImpersonating) return;
      analyticsSignOutUser({});
    },
  },
};

export default trackable;
