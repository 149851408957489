import { initializeApp } from 'firebase/app';
import {
  applyActionCode,
  confirmPasswordReset,
  getAdditionalUserInfo,
  getAuth,
  getIdTokenResult,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  onIdTokenChanged,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
  verifyPasswordResetCode,
} from 'firebase/auth';

// Constants
import { FIREBASE_OPTIONS } from '@/modules/auth/auth.constants';

// Helpers
import { hashPassword } from './password.helpers';

// Init App
const firebaseApp = initializeApp(FIREBASE_OPTIONS);

const firebaseAuth = getAuth(firebaseApp);

const authenticateWith = {
  apple: authenticateWithApple,
  google: authenticateWithGoogle,
};

export {
  authenticateWithSSO,
  firebaseAuth,
  getCurrentFirebaseUser,
  getUserIdTokenFromFirebase,
  isFirebaseUserRecentlyCreated,
  listenToFirebaseAuthStateChange,
  listenToIdTokenChange,
  resetPasswordWithFirebase,
  sendPasswordResetEmailWithFirebase,
  signInWithFirebaseWithCustomToken,
  signOutWithFirebase,
  verifyEmailWithFirebase,
  waitForFirebaseAuthStateChange,
};

async function signInWithFirebaseWithCustomToken({ token }) {
  return signInWithCustomToken(firebaseAuth, token);
}

async function authenticateWithSSO({ provider }) {
  const authProviderFn = authenticateWith[provider];
  if (!authProviderFn) {
    throw new Error('Invalid provider');
  }

  const authResult = await signInWithPopup(firebaseAuth, authProviderFn());
  const additionalUserInfo = await getAdditionalUserInfo(authResult);

  return { user: authResult.user, additionalUserInfo };
}

function authenticateWithGoogle() {
  return new GoogleAuthProvider();
}

function authenticateWithApple() {
  const authProvider = new OAuthProvider('apple.com');
  authProvider.addScope('email');
  authProvider.addScope('name');

  return authProvider;
}

async function signOutWithFirebase() {
  return await signOut(firebaseAuth);
}

async function onFirebaseIdTokenChanged(user) {
  if (user) {
    const { token: idToken, claims } = await getIdTokenResult(user);
    return {
      user,
      claims,
      idToken,
    };
  } else {
    return {
      user: undefined,
      claims: undefined,
      idToken: undefined,
    };
  }
}

function getCurrentFirebaseUser() {
  return firebaseAuth.currentUser;
}

function isFirebaseUserRecentlyCreated(firebaseUser) {
  if (!firebaseUser) {
    return false;
  }

  const creationTime = new Date(firebaseUser.metadata.creationTime);
  const oneMinuteAgo = new Date(Date.now() - 60000); // We check if the user was created in the last minute

  return creationTime > oneMinuteAgo;
}

function listenToIdTokenChange(handler) {
  return onIdTokenChanged(firebaseAuth, async (user) => handler(await onFirebaseIdTokenChanged(user)));
}

function listenToFirebaseAuthStateChange(hander) {
  return onAuthStateChanged(firebaseAuth, hander);
}

function waitForFirebaseAuthStateChange() {
  let unsubscribe;
  return new Promise((resolve) => {
    unsubscribe = onIdTokenChanged(firebaseAuth, async (user) => {
      unsubscribe();
      resolve(onFirebaseIdTokenChanged(user));
    });
  });
}

async function getUserIdTokenFromFirebase() {
  if (!firebaseAuth.currentUser) {
    return undefined;
  }

  return getIdTokenResult(firebaseAuth.currentUser);
}

async function sendPasswordResetEmailWithFirebase({ email }) {
  try {
    return await sendPasswordResetEmail(firebaseAuth, email);
  } catch (err) {
    throw new Error('[auth#sendPasswordResetEmailWithFirebase] error', { err });
  }
}

async function resetPasswordWithFirebase({ resetToken, password }) {
  try {
    const hashedPassword = hashPassword({ password });
    await verifyPasswordResetCode(firebaseAuth, resetToken);
    await confirmPasswordReset(firebaseAuth, resetToken, hashedPassword);
    return true;
  } catch (err) {
    throw new Error('[auth#resetPasswordWithFirebase] error', { err });
  }
}

async function verifyEmailWithFirebase({ oobCode }) {
  return applyActionCode(firebaseAuth, oobCode);
}
