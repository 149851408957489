<template>
  <component v-if="visible" :is="tag" :priority="-2" height="64" class="pa-0" variant="outlined">
    <GSheet :color="footerContext.color" variant="tonal" height="64" width="100%" tile>
      <div class="d-flex flex-fill align-center h-full">
        <GSheet
          :color="footerContext.color"
          :width="isMobile ? '100' : '260'"
          min-width="0"
          height="100%"
          tile
          class="d-flex align-center space-x-12 px-24"
        >
          <GAvatar size="40" color="surface">
            <GIcon size="16" :color="footerContext.color" :name="footerContext.icon" />
          </GAvatar>
          <p class="m-b-0 text-truncate font-weight-bold">{{ footerContext.title }}</p>
        </GSheet>

        <div class="d-flex flex-fill h-full align-center">
          <div class="d-flex flex-column min-w-0 space-y-4 px-16">
            <span class="text-labelL text-medium">
              {{ isImpersonating ? 'Vous imitez' : 'Nom' }}
            </span>
            <span class="text-truncate text-on-surface">{{ userFullName }}</span>
          </div>

          <GDivider vertical />

          <div class="d-flex flex-column space-y-4 px-16">
            <span class="text-labelL text-medium">Lien</span>
            <GButton
              :href="backOfficeLink"
              target="_blank"
              :color="footerContext.color"
              size="x-small"
              append-icon="fal fa-external-link-alt"
            >
              BackOffice
            </GButton>
          </div>

          <GDivider v-if="!isMobile" vertical />

          <div v-if="!isMobile" class="d-flex flex-column space-y-4 px-16">
            <label class="text-labelL text-medium">id</label>
            <GChip v-clipboard:copy="userId" v-clipboard:success="onCopyUserId" size="small" @click.stop="">
              {{ userId }}
              <GIcon name="fal fa-copy" right />
            </GChip>
          </div>

          <GDivider v-if="!isMobile" vertical />

          <div v-if="!isMobile" class="d-flex flex-column px-24 space-y-4">
            <p class="text-labelL text-medium ma-0">Profil</p>
            <div class="d-flex space-x-8 align-center">
              <GChip
                v-if="fiscalConfiguration"
                size="small"
                class="text-uppercase"
                v-tooltip="`Catégorie d'imposition + Forme juridique`"
              >
                {{ fiscalConfiguration }}
              </GChip>

              <GChip size="small">
                {{ userProfileSelectedVat }}
              </GChip>

              <GChip v-if="userProfileVatSelectedFrequency" size="small">
                {{ userProfileVatSelectedFrequency }}
              </GChip>

              <GTooltip>
                <template #activator="{ props }">
                  <GChip size="small" v-bind="props">
                    {{ currentFiscalYear }}
                  </GChip>
                </template>
                {{ formatDate(currentUserFiscalPeriod.startDate, 'D MMM YYYY') }}
                <i class="fas fa-long-arrow-alt-right m-l-8 m-r-8" />
                {{ formatDate(currentUserFiscalPeriod.endDate, 'D MMM YYYY') }}
              </GTooltip>

              <GChip size="small">
                {{ isBookkeeping ? 'Compta' : 'Décla' }}
              </GChip>
              <GTooltip v-if="salesAccountingMode">
                <template #activator="{ props }">
                  <GChip size="small" v-bind="props">
                    {{ salesAccountingMode }}
                  </GChip>
                </template>
                Version
              </GTooltip>
            </div>
          </div>

          <GDivider v-if="!isMobile" vertical />
        </div>
      </div>
    </GSheet>
  </component>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';

import { SETTINGS_BACKOFFICE_URL, SETTINGS_ENVIRONMENT } from '@/shared/constants';
import { formatDate } from '@/shared/utils/dates';

import { getAdminFooterContext } from './appAdminFooter.models.js';

export default {
  name: 'AppAdminFooter',

  props: {
    tag: { type: String, default: 'GFooter' },
  },

  computed: {
    isMobile() {
      return this.$georgesuikit.breakpoint.isMobile;
    },

    ...mapGetters({
      currentFiscalYear: 'user/currentFiscalYear',
      currentFiscalYearConfiguration: 'user/currentFiscalYearConfiguration',
      currentUserFiscalPeriod: 'user/getCurrentFiscalPeriod',
      hasAllRequiredPermissions: 'auth/hasAllRequiredPermissions',
      isBookkeeping: 'user/isBookkeeping',
      isImpersonating: 'user/isImpersonating',
      showAdminInfo: 'user/showAdminInfo',
      userFullName: 'user/fullName',
      userId: 'user/userId',
      userProfileSelectedVat: 'user/userProfileSelectedVat',
      userProfileVatSelectedFrequency: 'user/userProfileVatSelectedFrequency',
    }),

    visible() {
      return (
        (this.isImpersonating || (this.showAdminInfo && this.isAdmin)) && !this.$route?.meta?.hideImpersonateRibbon
      );
    },

    isAdmin() {
      return this.hasAllRequiredPermissions(['admin']);
    },

    fiscalConfiguration() {
      if (isEmpty(this.currentFiscalYearConfiguration)) return;
      const { taxCategory, legalForm } = this.currentFiscalYearConfiguration;
      return `${taxCategory} - ${legalForm}`;
    },

    salesAccountingMode() {
      const salesAccountingMode = this.currentFiscalYearConfiguration?.salesAccountingMode;
      if (!salesAccountingMode) return;
      if (salesAccountingMode === 'treasury') return 'Suivi encaissements';
      if (salesAccountingMode === 'commitment') return 'Suivi ventes';
      return undefined;
    },

    footerContext() {
      if (!this.isImpersonating) {
        return {
          title: 'Informations admin',
          icon: 'fal fa-user-crown',
          color: 'dark',
        };
      }
      const { title, color } = getAdminFooterContext({ environment: SETTINGS_ENVIRONMENT });
      return {
        title,
        color,
        icon: 'fal fa-user-secret',
      };
    },

    backOfficeLink() {
      const boLink = new URL(SETTINGS_BACKOFFICE_URL);
      boLink.pathname = `users/${this.userId}/account`;
      return boLink.toString();
    },
  },

  methods: {
    formatDate,
    onCopyUserId() {
      this.$gnotify.success(`Vous venez de copier l'id suivant: ${this.userId}`);
    },
  },
};
</script>
