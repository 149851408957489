<template>
  <GListItem :active="isActive" :disabled="disabled" v-bind="linkProps" class="rounded-xxl" :title="title">
    <template #prepend>
      <GIcon :name="`far ${icon}`" :class="{ 'text-high': !isActive }" />
    </template>

    <template v-if="$slots.badge" #append>
      <slot name="badge" />
    </template>
  </GListItem>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppNavigationLink',
  inheritsAttrs: false,
  props: {
    disabled: Boolean,
    href: String,
    title: { type: String, required: true },
    icon: { type: String, required: true },
    isActive: Boolean,
    to: String,
    onDisplay: Function,
  },

  computed: {
    ...mapGetters({
      userId: 'user/userId',
    }),
    linkProps() {
      return {
        ...(this.to && { to: { path: this.to } }),
        ...(this.href && { href: this.href, target: '_blank' }),
      };
    },
  },

  mounted() {
    if (this.onDisplay && !this.isMobile) {
      this.onDisplay({ userId: this.userId });
    }
  },
  methods: {
    isMobile() {
      return this.$georgesuikit.breakpoint.isMobile;
    },
  },
};
</script>
