<template>
  <div v-if="showAdminInfo && (userHasTooltipPermission || userHasKeywordPermission)" class="d-flex space-x-4">
    <GButton
      v-if="userHasTooltipPermission"
      icon="fal fa-pencil"
      size="small"
      color="primary"
      @click.stop="editTooltip(categoryGroup.key, accountingAccount)"
    />
    <GButton
      v-if="userHasKeywordPermission"
      icon="fal fa-lightbulb-on"
      size="small"
      color="primary"
      @click.stop="editKeywords(categoryGroup.key, accountingAccount)"
    />
  </div>
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapGetters } from 'vuex';

// Services
import {
  updateAccountingAccountKeywords,
  updateAccountingAccountTooltip,
} from '@/modules/categories/services/categories.services';

export default {
  name: 'CategoriesAdminEdit',
  props: {
    categoryGroup: { type: Object, required: true },
    accountingAccount: { type: Object, required: true },
  },
  computed: {
    ...mapGetters({
      hasAllRequiredPermissions: 'auth/hasAllRequiredPermissions',
      showAdminInfo: 'user/showAdminInfo',
      userFiscalRegime: 'user/getUserFiscalRegimeDefaultBnc',
    }),
    userHasTooltipPermission() {
      return this.hasAllRequiredPermissions(['accounting-account:tooltips:edit']);
    },
    userHasKeywordPermission() {
      return this.hasAllRequiredPermissions(['accounting-account:keywords:edit']);
    },
  },
  methods: {
    ...mapActions({
      initCategoryGroups: 'categories/initCategoryGroups',
    }),
    async editTooltip(category, accountingAccount) {
      const currentTooltip = get(accountingAccount, 'categorisation_modal.tooltip');
      const currentDecoration = get(accountingAccount, 'decoration');
      const newTooltip = prompt(
        `Pour le régime fiscal '${this.userFiscalRegime}' \
      et la catégorie '${category}'.
      Changement du tooltip de "${accountingAccount.category_name}"
      Nouveau tooltip :`,
        currentTooltip,
      );

      if (!newTooltip || newTooltip === currentTooltip) return;
      const number = accountingAccount.number;

      try {
        await updateAccountingAccountTooltip({
          fiscalRegime: currentDecoration.fiscal_regime,
          category,
          number,
          explicitKey: accountingAccount.explicit_key,
          currentTooltip,
          newTooltip,
        });

        await this.initCategoryGroups();

        this.$gnotify.success('Le nouveau tooltip a bien été pris en compte');
      } catch (err) {
        console.error(err);
        this.$gnotify.error('Une erreur est survenue.');
      }
    },
    async editKeywords(category, accountingAccount) {
      const currentKeywords = get(accountingAccount, 'categorisation_modal.keywords');
      const currentDecoration = get(accountingAccount, 'decoration');
      const newKeywords = prompt(
        `Pour le régime fiscal '${this.userFiscalRegime}' \
      et la catégorie '${category}'. Changement des mots clés \
      de "${accountingAccount.category_name}" Nouveaux mots clés :`,
        currentKeywords,
      );
      if (!newKeywords || newKeywords === currentKeywords) return;
      const number = accountingAccount.number;
      try {
        await updateAccountingAccountKeywords({
          fiscalRegime: currentDecoration.fiscal_regime,
          category,
          number,
          explicitKey: accountingAccount.explicit_key,
          currentKeywords,
          newKeywords,
        });

        await this.initCategoryGroups();

        this.$gnotify.success('Les nouveaux mots clés ont bien été pris en compte');
      } catch (err) {
        console.error(err);
        this.$gnotify.error('Une erreur est survenue.');
      }
    },
  },
};
</script>
