import { createRouter, createWebHistory } from 'vue-router';

import impersonateRoute from '@/modules/admin/impersonate.routes';
import bankConnectorRoutes from '@/modules/bankConnector/bankConnector.routes';
// Routes
import bankinRoutes from '@/modules/bankin/bankin.routes';
import { compteProRoutes, onboardingCompteProRoutes } from '@/modules/comptePro/comptePro.routes';
import dashboardRoutes from '@/modules/dashboard/dashboard.routes';
import documentsRoutes from '@/modules/documents/documents.routes';
import facturationRoutes from '@/modules/facturation/facturation.routes';
import loginRoutes from '@/modules/login/login.routes';
import { mobileWebViewRoutes } from '@/modules/mobile-webview/mobile.routes.js';
import newActivitiesRoutes from '@/modules/newActivities/newActivities.routes';
import onboardingRoutes from '@/modules/onboarding/onboarding.routes';
import referralRoutes from '@/modules/referral/referral.routes';
import { invoiceRoute, settingsRoutes } from '@/modules/settings/settings.routes';
import supportRoutes from '@/modules/support/support.routes';
import transactionsRoutes from '@/modules/transactions/transactions.routes';
// Layouts
import MainLayout from '@/shared/layouts/MainLayout/MainLayout.vue';
import NotFound from '@/shared/layouts/NotFound/NotFound.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...bankinRoutes,
    ...loginRoutes,
    ...onboardingRoutes,
    ...bankConnectorRoutes,
    impersonateRoute,
    invoiceRoute,
    ...onboardingCompteProRoutes,
    {
      path: '/',
      component: MainLayout,
      children: [
        ...dashboardRoutes,
        ...documentsRoutes,
        ...newActivitiesRoutes,
        ...referralRoutes,
        ...settingsRoutes,
        ...transactionsRoutes,
        ...facturationRoutes,
        ...mobileWebViewRoutes,
        ...supportRoutes,
        ...compteProRoutes,
        { name: 'home', path: '/', redirect: { name: 'dashboard' } },
      ],
    },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
  ],
  async scrollBehavior(to, from, savedPosition) {
    let main = document.querySelector('.g-main');

    if (!main) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      main = document.querySelector('.g-main');
    }

    if (to.hash && document.querySelector(to.hash)) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: main ? parseInt(getComputedStyle(main).getPropertyValue('--g-layout-top')) : 0,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else return { top: 0 };
  },
});

export function useRouter({ app }) {
  app.use(router);
  return router;
}

export default router;
