import { APP_LINK_IN_APP_STORE, APP_LINK_IN_PLAY_STORE, DEVICES_TYPES } from '@/shared/constants';

export function isInMobileWebView() {
  return !!window.ReactNativeWebView;
}

export function getDeviceType({ userAgent } = { userAgent: navigator.userAgent }) {
  const isiOS = !!userAgent.match(/(iPad|iPhone|iPod)/i);
  const isAndroid = userAgent.toLowerCase().indexOf('android') > -1;

  if (isiOS) {
    return DEVICES_TYPES.IOS;
  } else if (isAndroid) {
    return DEVICES_TYPES.ANDROID;
  } else {
    return DEVICES_TYPES.OTHERS;
  }
}

export function getAppLinkForDevice({ deviceType }) {
  switch (deviceType) {
    case DEVICES_TYPES.IOS:
      return APP_LINK_IN_APP_STORE;
    case DEVICES_TYPES.ANDROID:
      return APP_LINK_IN_PLAY_STORE;
    default:
      return undefined;
  }
}
