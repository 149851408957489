import georgesApi from '@/shared/apis/georges';

const getUrl = (path) => `/activities/is-advance-payment${path}`;

export async function getIsAdvancePaymentActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl(''),
  });
  return data;
}

export async function forceCloseActivity({ periodId }) {
  await georgesApi({
    method: 'POST',
    url: getUrl('/force-close'),
    params: {
      periodId,
    },
  });
}
