import axios from 'axios';

import { CLIENT_VERSION_RELEASE_HEADER_NAME, RELEASE } from '../constants/version';
import { getSessionId } from '../services/logger/logger';

export default async ({ method = 'GET', ...options }) => {
  return axios({
    method,
    headers: {
      ...(RELEASE && { [CLIENT_VERSION_RELEASE_HEADER_NAME]: RELEASE }),
    },
    baseURL: '/api',
    'x-client-session-id': getSessionId(),
    ...options,
  });
};
