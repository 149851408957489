import { logger } from '@/shared/services/logger/logger';

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export function loadString(key) {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    logger.error({
      msg: `[localStorage#loadString] Cannot load ${key} in local storage`,
      args: { error },
    });
    return;
  }
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function saveString(key, value) {
  try {
    window.localStorage.setItem(key, value);
    return true;
  } catch (error) {
    logger.error({
      msg: `[localStorage#saveString] Cannot save ${key} in local storage`,
      args: { error },
    });
    return false;
  }
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export function loadJSON(key) {
  try {
    const almostThere = window.localStorage.getItem(key);
    return almostThere ? JSON.parse(almostThere) : null;
  } catch (error) {
    logger.error({
      msg: `[localStorage#load] Cannot load ${key} in local storage`,
      args: { error },
    });
    return;
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function saveJSON(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    logger.error({
      msg: `[localStorage#save] Cannot save ${key} in local storage`,
      args: { error },
    });
    return false;
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key) {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    logger.error({
      msg: `[localStorage#remove] Cannot remove ${key} in local storage`,
      args: { error },
    });
    return false;
  }
}

/**
 * Burn it all to the ground.
 */
export function clear() {
  try {
    window.localStorage.clear();
  } catch (error) {
    logger.error({
      msg: `[localStorage#clear] Cannot clear local storage`,
      args: { error },
    });
    return false;
  }
}
