import georgesApi from '@/shared/apis/georges.js';

export { getCategoryGroupsWithDecorations, updateAccountingAccountKeywords, updateAccountingAccountTooltip };

async function getCategoryGroupsWithDecorations(params) {
  const { data } = await georgesApi({
    url: '/transactions/category-groups-with-decorations',
    params,
  });
  return data;
}

async function updateAccountingAccountTooltip(params) {
  const { data } = await georgesApi({
    method: 'POST',
    url: '/accounting-accounts/decorations/tooltip',
    data: params,
  });
  return data;
}

async function updateAccountingAccountKeywords(params) {
  const { data } = await georgesApi({
    method: 'POST',
    url: '/accounting-accounts/decorations/keywords',
    data: params,
  });
  return data;
}
