const OLD_ROUTES = [{ oldPath: '/activites', newPath: '/activities' }];

export default function ({ to, next }) {
  const isValidRoute = to.matched.length > 0;

  const redirect = OLD_ROUTES.find((route) => to.path === route.oldPath);

  if (redirect) next({ path: redirect.newPath });
  else if (isValidRoute) next();
  else next({ name: 'not-found' });
}
