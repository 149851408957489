import georgesApi from '@/shared/apis/georges.js';

export async function getD2571ForFiscalPeriod({ fiscalYear }) {
  const { data } = await georgesApi({
    url: '/documents/d2571',
    params: { fiscalYear },
  });

  return data;
}

export async function getD2571PdfUrl({ periodId }) {
  const { data } = await georgesApi({
    url: `/exports/document/d2571`,
    params: {
      periodId,
    },
  });

  return data;
}
