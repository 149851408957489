<template>
  <GLayoutItem :model-value="isBannerVisible" position="top" :priority="-3" size="64">
    <GSheet tile class="d-flex flex-row align-center border-b p-l-16 p-r-8" height="64">
      <div class="d-flex align-center justify-space-between space-x-12">
        <GLazyImg
          src="https://georges-public-assets.s3.eu-west-1.amazonaws.com/indy-app/icons/indy-app-36x36.png"
          width="36"
          height="36"
        />
        <div class="d-flex flex-column space-y-2">
          <label class="font-weight-bold">Indy Comptabilité</label>
          <label class="text-labelL text-medium min-w-0">Comptabilité des indépendants</label>
        </div>
      </div>
      <div class="d-flex flex-fill align-center justify-end space-x-4">
        <GButton size="small" :href="storeLink" color="primary" @click="openStore">Ouvrir</GButton>
        <GButton icon="close" @click="closeSmartBanner" />
      </div>
    </GSheet>
  </GLayoutItem>
</template>

<script>
// Utilities
// Mixins
import Trackable from '@/shared/mixins/trackable';
import { getAppLinkForDevice, getDeviceType } from '@/shared/utils/device';
import * as storage from '@/shared/utils/storage';

/**
 * Clé du local storage
 */
export const SMART_BANNER_KEYS = {
  lastSmartBannerRequestDate: 'lastSmartBannerRequestDate',
  hasOpenedStore: 'hasOpenedStore',
};

/**
 * Temps minimum écoulé entre deux présentations de la bannière
 *
 * = 1 mois (~30 jours)
 */
const MAX_SMART_BANNER_COOLDOWN = 1000 * 60 * 60 * 24 * 30;
/** ───────────────────────────────┴─────┴────┴────┴────┴────┴
────────────────────────────────── 1s─ 1min─ 1h ─ 1d ─ 30d */

/**
 * Detection du type de téléphone
 */

export default {
  name: 'SmartAppBanner',
  mixins: [Trackable],
  data: () => ({
    opened: true,
    lastSmartBannerRequestDate: storage.loadString(SMART_BANNER_KEYS.lastSmartBannerRequestDate),
    hasOpenedStore: storage.loadString(SMART_BANNER_KEYS.hasOpenedStore),
  }),
  computed: {
    storeLink() {
      const deviceType = getDeviceType();
      return getAppLinkForDevice({ deviceType });
    },
    isBannerVisible() {
      const now = Date.now();

      return (
        !window.ReactNativeWebView &&
        this.$georgesuikit.breakpoint.isMobile &&
        this.opened &&
        Boolean(this.storeLink) &&
        this.hasOpenedStore !== 'true' &&
        (!this.lastSmartBannerRequestDate || now - Number(this.lastSmartBannerRequestDate) > MAX_SMART_BANNER_COOLDOWN)
      );
    },
  },
  methods: {
    openStore() {
      this.trackEvent({
        action: '[SmartBanner] Client clicked on open store',
      });
      this.hasOpenedStore = storage.saveString(SMART_BANNER_KEYS.hasOpenedStore, true);
    },
    closeSmartBanner() {
      this.trackEvent({
        action: '[SmartBanner] Client dismissed banner',
      });
      const now = Date.now();
      this.lastSmartBannerRequestDate = storage.saveString(SMART_BANNER_KEYS.lastSmartBannerRequestDate, now);
      this.opened = false;
    },
  },
};
</script>
