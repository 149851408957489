<template>
  <GApp id="app">
    <Preloader v-if="!isInitialized" />
    <template v-else>
      <router-view v-slot="{ Component }">
        <transition name="router-anim" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
      <AppAdminFooter />
      <AppAuthChangedModal v-if="enableUserTabChangeAlertModal" />
    </template>
  </GApp>
</template>

<script>
import { mapGetters } from 'vuex';

// Meta
import pageMeta from '@/app.meta';
import AppAuthChangedModal from '@/AppAuthChangedModal.vue';
// Layouts
import Preloader from '@/shared/layouts/Preloader/Preloader.vue';

export default {
  name: 'App',
  head: { ...pageMeta },
  components: {
    Preloader,
    AppAuthChangedModal,
  },
  data() {
    return {
      enableUserTabChangeAlertModal: window.GEORGES_SETTINGS.featureFlags?.enableUserTabChangeAlertModal,
    };
  },
  computed: {
    ...mapGetters({
      isInitialized: 'isInitialized',
    }),
  },
};
</script>

<style lang="scss" scoped>
.router-anim {
  @include transition-default();

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }

  &-enter-from {
    transition-delay: 0.2s;
  }
}
</style>
